import React, {FC, useState} from 'react'
import {isNullOrEmpty} from '../../../services/Utils'
import {VolunteerAdd, VolunteerChangeImage, VolunteerUpdate} from '../../../services/main'
import {toast} from 'react-toastify'
import {
  VolunteerAddReq,
  VolunteerChangeImageReq,
  VolunteerUpdateReq,
  magicnum,
} from '../../../services/Request'
import {KTTooltip} from '../../../_metronic/helpers/components/KTTooltip'
interface IProps {
  t: any
  item: any
  onAddSuccess: any
}
const VolunteerCreate: FC<IProps> = ({t, item, onAddSuccess}) => {
  const [imageBase64, setImageBase64] = React.useState<any>('')
  const [count, setCount] = useState(0)
  const [loading, setLaoding] = useState(false)
  const [loadingImageChange, setLoadingImageChange] = useState(false)
  const [loadingImageData, setLaodingImageData] = useState(false)
  const [firstname, setFirstname] = useState(isNullOrEmpty(item) ? '' : item.firstname)
  const [lastname, setLastname] = useState(isNullOrEmpty(item) ? '' : item.lastname)
  const [gender, setGender] = useState(isNullOrEmpty(item) ? '' : item.gender)
  const [team, setTeam] = useState(isNullOrEmpty(item) ? '' : item.team)
  const [age, setAge] = useState(isNullOrEmpty(item) ? '' : item.age)
  const [email, setEMail] = useState(isNullOrEmpty(item) ? '' : item.email)
  const [phone, setPhone] = useState(isNullOrEmpty(item) ? '' : item.phone)
  const [gmobile, setGmobile] = useState(isNullOrEmpty(item) ? '' : item.gmobile)
  const [validate, setValidate] = useState<any>([])

  const changeImage = () => {
    if (isNullOrEmpty(imageBase64)) {
      validate['image'] = true
      setValidate(validate)
      setCount(count + 1)
    } else {
      setLoadingImageChange(true)
      var req = VolunteerChangeImageReq
      req.volunteer.old_photo_link = item.photo
      req.volunteer.photo = imageBase64
      req.volunteer.id = item?.id
      VolunteerChangeImage(req, onSuccessImage, onFailed, 'PUT')
    }
  }

  const onSuccessImage = (response: any) => {
    setLoadingImageChange(false)
    onAddSuccess(t('common:successonChangeImage'))
  }

  const handleSelectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLaodingImageData(true)
    if (event.target.files) {
      var file = event.target.files[0]
      var reader = new FileReader()

      reader.onload = function (event) {
        setImageBase64(event?.target?.result)
        setLaodingImageData(false)
      }

      reader.readAsDataURL(file)
    }
  }

  const update = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLaoding(true)
      var req = VolunteerUpdateReq
      req.volunteer.firstname = firstname
      req.volunteer.lastname = lastname
      req.volunteer.gender = gender
      req.volunteer.age = age
      req.volunteer.email = email
      req.volunteer.phone = phone
      req.volunteer.team = team
      req.volunteer.gmobile = gmobile
      req.volunteer.id = item?.id
      // console.log(req)
      VolunteerUpdate(req, onUpdateSuccess, onFailed, 'PUT')
    }
  }

  const save = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLaoding(true)
      var req = VolunteerAddReq
      req.volunteer.firstname = firstname
      req.volunteer.lastname = lastname
      req.volunteer.gender = gender
      req.volunteer.age = age
      req.volunteer.email = email
      req.volunteer.phone = phone
      req.volunteer.team = team
      req.volunteer.photo = imageBase64
      req.volunteer.gmobile = ''
      // console.log(req)
      VolunteerAdd(req, onSuccess, onFailed, 'POST')
    }
  }

  const onSuccess = () => {
    setLaoding(false)
    setFirstname('')
    setLastname('')
    setImageBase64('')
    setGender('')
    setTeam('')
    setEMail('')
    setPhone('')
    setAge('')
    onAddSuccess(t('common:successCreate'))
  }

  const onUpdateSuccess = () => {
    setLaoding(false)
    setFirstname('')
    setLastname('')
    setImageBase64('')
    setGender('')
    setTeam('')
    setEMail('')
    setPhone('')
    setAge('')
    onAddSuccess(t('common:successUpdate'))
  }

  const onFailed = (err: string) => {
    setLaoding(false)
    setLoadingImageChange(false)
    return toast.error(err)
  }

  const validateFunc = () => {
    var res = {retType: 0}

    if (isNullOrEmpty(firstname)) {
      res.retType = 1
      validate['firstname'] = true
    }

    if (isNullOrEmpty(lastname)) {
      res.retType = 1
      validate['lastname'] = true
    }

    if (isNullOrEmpty(gender)) {
      res.retType = 1
      validate['gender'] = true
    }

    if (isNullOrEmpty(team)) {
      res.retType = 1
      validate['team'] = true
    }

    if (isNullOrEmpty(email)) {
      res.retType = 1
      validate['email'] = true
    }

    if (isNullOrEmpty(phone)) {
      res.retType = 1
      validate['phone'] = true
    }

    if (isNullOrEmpty(age)) {
      res.retType = 1
      validate['age'] = true
    }

    if (isNullOrEmpty(gmobile)) {
      res.retType = 1
      validate['gmobile'] = true
    }

    setValidate(validate)
    setCount(count + 1)
    return res
  }

  const validateChange = (id: string, e: any) => {
    var val = ''
    e.target === undefined ? (val = e) : (val = e.target.value)
    if (val !== '') {
      validate[id] = false
    } else {
      validate[id] = true
    }
    setValidate(validate)
    setCount(count + 1)
  }

  return (
    <div className='athlete-add'>
      <div className='mx-5 mx-xl-10 pb-10 w-800px'>
        <span style={{visibility: 'hidden'}}>{count}</span>
        <div className='text-center mb-13'>
          <h1 className='mb-3'>{t('common:adminVolunteers.basicinfo')}</h1>
        </div>
        <div className='row'>
          <div className='col-lg-2 fv-row mb-8'>
            <label htmlFor='files' className='btn btn-light-primary fw-bolder'>
              {loadingImageData ? t('common:loading') : t('common:NEWS.image_link')}
            </label>
            {validate['image'] ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{t('common:validSelect')}</div>
              </div>
            ) : null}
            <KTTooltip
              iconName='information-5'
              className='fs-3'
              required={false}
              tooltip='The photo must be taken within the last 6 months and in color.
                - The image ratio is 3 x 4. Image format should be .JPGor .JPEG and size of the image should be between 100Kb and 300Kb.
                - The background of the photo should be either white or plain light blue. The boundary between a person and the background should be identifiable.
                - Head coverings, such as caps, hats, scarves and hair band, face mask and dark-tinted glasses are not permitted in a photo except for religious or medical reasons.
                - The applicant should submit a photo with face square on to the camera rather than rotated or tilted with both eyes open and clearly visible.
                - The photographs should be in sharp focus and clear and un-retouched with no smears or creases.'
            />
          </div>
          <input
            type='file'
            name='image'
            id='files'
            style={{display: 'none'}}
            accept='.pdf,.jpg,.png,.jpeg'
            onChange={handleSelectFile}
          />
          {isNullOrEmpty(imageBase64) ? null : (
            <>
              <div className='col-lg-4 fv-row d-flex flex-center'>
                <img src={imageBase64} width={120} height={160} />
              </div>
              <div className='col-lg-4 fv-row mb-8'>
                <button
                  className='btn btn-primary align-self-center'
                  onClick={changeImage}
                  disabled={loading}
                >
                  {loading ? t('common:loading') : 'Change image'}
                </button>
              </div>
            </>
          )}
        </div>
        <div className='row' style={{marginTop:20}}>
          <div className='col-lg-4 fv-row mb-8'>
            <input
              className='form-control form-control-solid'
              placeholder={t('common:athlete.firstname')}
              onChange={(e) => {
                setFirstname(e.target.value)
                validateChange('lastname', e)
              }}
              value={firstname}
              maxLength={45}
            ></input>
            {validate['firstname'] ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{t('common:validInsert')}</div>
              </div>
            ) : null}
          </div>
          <div className='col-lg-4 fv-row mb-8'>
            <input
              className='form-control form-control-solid'
              placeholder={t('common:athlete.lastname')}
              onChange={(e) => {
                setLastname(e.target.value)
                validateChange('lastname', e)
              }}
              value={lastname}
              maxLength={45}
            ></input>
            {validate['lastname'] ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{t('common:validInsert')}</div>
              </div>
            ) : null}
          </div>
          <div className='col-lg-4 fv-row mb-8'>
            <select
              name='gender'
              data-control='select2'
              data-hide-search='true'
              value={gender}
              className='form-select form-select-white'
              onChange={(e) => {
                setGender(e.target.value)
                validateChange('gender', e)
              }}
            >
              <option value=''>{t('common:athlete.selectgender')}</option>
              <option value='male'>{t('common:athlete.male')}</option>
              <option value='female'>{t('common:athlete.female')}</option>
            </select>
            {validate['gender'] ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{t('common:validSelect')}</div>
              </div>
            ) : null}
            {/* <KTTooltip
              iconName='information-5'
              className='fs-3'
              required={false}
              tooltip='Please choose in the drop-down (Male/Female)'
            /> */}
          </div>
          <div className='col-lg-4 fv-row mb-8'>
            <select
              name='team'
              data-control='select2'
              data-hide-search='true'
              value={team}
              className='form-select form-select-white'
              onChange={(e) => {
                setTeam(e.target.value)
                validateChange('team', e)
              }}
            >
              <option value=''>Select team</option>
              {magicnum.volunteer.team.map((titem: any, tindex: any) => {
                return (
                  <option value={titem} key={tindex}>
                    {t(`common:magicnum.volunteer.team.${titem}`)}
                  </option>
                )
              })}
            </select>
            {validate['team'] ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{t('common:validSelect')}</div>
              </div>
            ) : null}
            {/* <KTTooltip
              iconName='information-5'
              className='fs-3'
              required={false}
              tooltip='Please choose in the drop-down (Male/Female)'
            /> */}
          </div>
          <div className='col-lg-4 fv-row mb-8'>
            <input
              className='form-control form-control-solid'
              placeholder='Email'
              onChange={(e) => {
                setEMail(e.target.value)
                validateChange('email', e)
              }}
              value={email}
              maxLength={45}
            ></input>
            {validate['email'] ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{t('common:validInsert')}</div>
              </div>
            ) : null}
          </div>
          <div className='col-lg-4 fv-row mb-8'>
            <input
              className='form-control form-control-solid'
              placeholder='Phone'
              onChange={(e) => {
                setPhone(e.target.value)
                validateChange('phone', e)
              }}
              value={phone}
              maxLength={45}
            ></input>
            {validate['phone'] ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{t('common:validInsert')}</div>
              </div>
            ) : null}
          </div>
          <div className='col-lg-4 fv-row mb-8'>
            <input
              className='form-control form-control-solid'
              placeholder={t('common:athlete.age')}
              onChange={(e) => {
                setAge(e.target.value)
                validateChange('age', e)
              }}
              value={age}
              maxLength={45}
            ></input>
            {validate['age'] ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{t('common:validInsert')}</div>
              </div>
            ) : null}
          </div>

          <div className='col-lg-4 fv-row mb-8'>
            <input
              className='form-control form-control-solid'
              placeholder='G-Mobile'
              onChange={(e) => {
                setGmobile(e.target.value)
                validateChange('gmobile', e)
              }}
              value={gmobile}
              maxLength={45}
            ></input>
            {validate['gmobile'] ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{t('common:validInsert')}</div>
              </div>
            ) : null}
          </div>
        </div>

        <div className='pt-10' style={{textAlign: 'right'}}>
          <button
            className='btn btn-primary align-self-center'
            onClick={isNullOrEmpty(item) ? save : update}
            disabled={loading}
          >
            {loading ? t('common:loading') : null}
            {isNullOrEmpty(item) ? 'save' : t('common:update')}
          </button>
        </div>
      </div>
    </div>
  )
}

export {VolunteerCreate}
