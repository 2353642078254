import React, {FC, useState} from 'react'
import {AthleteChangeGmobile, AthleteChangePrintSuccess, CDN_URL} from '../../../../services/main'
import {toast} from 'react-toastify'
import {AthleteChangeGmobileReq, AthleteChangePrintSuccessReq} from '../../../../services/Request'
import useCookie from '../../../../services/useCookie'
import {isNullOrEmpty} from '../../../../services/Utils'
interface IProps {
  t: any
  item: any
  onGmobileChangeSuccess: any
}
const TabGmobile: FC<IProps> = ({t, item, onGmobileChangeSuccess}) => {
  const [loading, setLaoding] = useState(false)
  const [gmobile, setGmobile] = useState(isNullOrEmpty(item) ? '' : item.gmobile)

  const save = (type: any) => {
    if (!isNullOrEmpty(gmobile)) {
      setLaoding(true)
      var req = AthleteChangeGmobileReq
      req.athlete.id = item?.id
      req.athlete.gmobile = gmobile
      AthleteChangeGmobile(req, onSuccess, onFailed, 'PUT')
    }
  }

  const onSuccess = () => {
    setLaoding(false)
    onGmobileChangeSuccess('Success print status')
  }

  const onFailed = (err: string) => {
    setLaoding(false)
    return toast.error(err)
  }

  return (
    <div>
      <div className='mx-5 mx-xl-10 pt-10 pb-10 flex flex-col gap-6' style={{textAlign: 'center'}}>
        G-Mobile
        <img
          src={CDN_URL + item?.photo}
          height={60}
          width={60}
          style={{borderRadius: '50%', objectFit: 'cover'}}
        />
        {item?.firstname}-{item?.lastname}
        <input
          className='form-control form-control-solid'
          placeholder={'G-Mobile'}
          onChange={(e) => {
            setGmobile(e.target.value)
          }}
          value={gmobile}
          type='text'
        ></input>
        <button
          className='btn btn-primary align-self-center'
          onClick={() => save(0)}
          disabled={loading}
        >
          {loading ? t('common:loading') : t('common:save')}
        </button>
      </div>
    </div>
  )
}

export {TabGmobile}
