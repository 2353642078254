import React, {FC, useEffect, useState} from 'react'
import {isNullOrEmpty} from '../../../services/Utils'
import {EventAdd, EventUpdate, NewsAdd, NewsChangeImage, NewsUpdate} from '../../../services/main'
import {toast} from 'react-toastify'
import {
  EventAddReq,
  EventUpdateReq,
  NewsAddReq,
  NewsChangeImageReq,
  NewsUpdateReq,
  magicnum,
} from '../../../services/Request'
import {KTTooltip} from '../../../_metronic/helpers/components/KTTooltip'
interface IProps {
  t: any
  item: any
  onAddSuccess: any
  filteredsid: any
  filteredgender: any
}
const EventCreate: FC<IProps> = ({t, item, onAddSuccess, filteredsid, filteredgender}) => {
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [validate, setValidate] = useState<any>([])
  const [mixed, setMixed] = useState<any>(false)
  const [name, setName] = useState<any>(isNullOrEmpty(item) ? '' : item?.name)
  const [sid, setSID] = useState<any>(isNullOrEmpty(item) ? '' : item?.sid)
  const [day, setDay] = useState<any>(isNullOrEmpty(item) ? '' : item?.day)
  const [time, setTime] = useState<any>(isNullOrEmpty(item) ? '' : item?.time)
  const [gender, setGender] = useState<any>(isNullOrEmpty(item) ? '' : item?.gender)
  const [status, setStatus] = useState<any>(isNullOrEmpty(item) ? '' : item?.status)
  const [category, setCategory] = useState<any>(isNullOrEmpty(item) ? '' : item?.category)
  const [home, setHome] = useState<any>(isNullOrEmpty(item) ? 0 : item?.home)
  const [guest, setGuest] = useState<any>(isNullOrEmpty(item) ? 0 : item?.guest)

  useEffect(() => {
    if (!isNullOrEmpty(filteredsid)) {
      setSID(filteredsid)
    }
    if (!isNullOrEmpty(filteredgender)) {
      setGender(filteredgender)
    }
  }, [filteredsid, filteredgender])

  const save = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLoading(true)
      var req = EventAddReq
      req.event.name = name
      req.event.time = time
      req.event.gender = gender
      req.event.sid = sid
      req.event.day = day
      req.event.category = category
      if (home != 0 && guest != 0 && !isNullOrEmpty(home) && !isNullOrEmpty(guest)) {
        req.event.home = home
        req.event.guest = guest
      }
      EventAdd(req, onSuccess, onFailed, 'POST')
    }
  }

  const update = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLoading(true)
      var req = EventUpdateReq
      req.event.id = item?.id
      req.event.name = name
      req.event.time = time
      req.event.gender = gender
      req.event.sid = sid
      req.event.day = day
      req.event.category = category
      req.event.home = home
      req.event.guest = guest
      EventUpdate(req, onUpdateSuccess, onFailed, 'PUT')
    }
  }

  const onUpdateSuccess = () => {
    setLoading(false)
    onAddSuccess(t('common:successUpdate'))
  }

  const onSuccess = () => {
    setLoading(false)
    onAddSuccess(t('common:successCreate'))
  }

  const onFailed = (error: string) => {
    setLoading(false)
    return toast.error(error)
  }

  const validateFunc = () => {
    var res = {retType: 0}
    if (isNullOrEmpty(name)) {
      res.retType = 1
      validate['name'] = true
    }
    if (isNullOrEmpty(time)) {
      res.retType = 1
      validate['time'] = true
    }
    if (isNullOrEmpty(gender)) {
      res.retType = 1
      validate['gender'] = true
    }
    if (isNullOrEmpty(sid)) {
      res.retType = 1
      validate['sid'] = true
    }
    if (isNullOrEmpty(day)) {
      res.retType = 1
      validate['day'] = true
    }
    if (isNullOrEmpty(category)) {
      res.retType = 1
      validate['category'] = true
    }
    if (gender == 'team') {
      if (isNullOrEmpty(home)) {
        res.retType = 1
        validate['home'] = true
      }
      if (isNullOrEmpty(guest)) {
        res.retType = 1
        validate['guest'] = true
      }
    }
    setValidate(validate)
    setCount(count + 1)
    return res
  }

  const validateChange = (id: string, e: any) => {
    var val = ''
    e.target === undefined ? (val = e) : (val = e.target.value)
    if (val !== '') {
      validate[id] = false
    } else {
      validate[id] = true
    }
    setValidate(validate)
    setCount(count + 1)
  }

  return (
    <div>
      <div className='mx-5 mx-xl-10 pt-10 pb-10 w-500px'>
        <span style={{visibility: 'hidden'}}>{count}</span>
        <div className='text-center mb-13'>
          <h1 className='mb-3'>{t('main:menu.event')}</h1>
        </div>
        <div className='row'>
          {/* event name */}
          <div className='col-lg-12 fv-row mb-8'>
            <input
              className='form-control form-control-solid'
              placeholder='Event name'
              maxLength={45}
              value={name}
              onChange={(e) => {
                setName(e.target.value)
                validateChange('name', e)
              }}
            ></input>
            {validate['name'] ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{t('common:validInsert')}</div>
              </div>
            ) : null}
          </div>
        </div>
        <div className='row'>
          {/* event sid */}
          <div className='col-lg-4 fv-row mb-8'>
            <select
              name='sid'
              data-control='select2'
              data-hide-search='true'
              value={sid}
              className='form-select form-select-white'
              onChange={(e) => {
                setSID(e.target.value)
                validateChange('sid', e)
                setHome(0)
                setGuest(0)
                setCategory('')
              }}
            >
              <option value=''>Select Sport ID</option>
              {magicnum.SportCategories.map((item2, index) => {
                return (
                  <option value={item2?.id} key={index}>
                    {t(`common:magicnum.SportCategories.${item2?.id}`)}
                  </option>
                )
              })}
            </select>
            {validate['sid'] ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{t('common:validSelect')}</div>
              </div>
            ) : null}
          </div>
          {/* event gender */}
          <div className='col-lg-4 fv-row mb-8'>
            <select
              name='gender'
              data-control='select2'
              data-hide-search='true'
              className='form-select form-select-white'
              value={gender}
              onChange={(e) => {
                setGender(e.target.value)
                validateChange('gender', e)
              }}
            >
              <option value=''>{t('common:athlete.selectgender')}</option>
              <option value='male'>{t('common:athlete.male')}</option>
              <option value='female'>{t('common:athlete.female')}</option>
              <option value='team'>Team</option>
            </select>
            {validate['gender'] ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{t('common:validSelect')}</div>
              </div>
            ) : null}
            {/* <KTTooltip
              iconName='information-5'
              className='fs-3'
              required={false}
              tooltip='Please choose in the drop-down (Male/Female)'
            /> */}
          </div>
          {/* event category */}
          <div className='col-lg-4 fv-row mb-8'>
            <select
              name='category'
              data-control='select2'
              data-hide-search='true'
              className='form-select form-select-white'
              value={category}
              onChange={(e) => {
                setCategory(e.target.value)
                validateChange('category', e)
              }}
            >
              <option value=''>Select Category</option>
              {magicnum.SportSubCategories.filter(
                (sitem) => sitem?.parent == sid && sitem?.gender == gender
              ).length == 0 && !isNullOrEmpty(gender) ? (
                <option value={0}>Team</option>
              ) : null}
              {magicnum.SportSubCategories.map((sitem, sindex) => {
                return sitem?.parent == sid && sitem?.gender == gender ? (
                  <option value={sitem?.id} key={sindex}>
                    {t(`common:magicnum.SportSubCategories.${sitem?.id}`)}
                  </option>
                ) : null
              })}
            </select>
            {validate['category'] ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{t('common:validSelect')}</div>
              </div>
            ) : null}
          </div>
          {/* event day */}
          <div className='col-lg-6 fv-row mb-8'>
            <select
              name='sid'
              data-control='select2'
              data-hide-search='true'
              value={day}
              className='form-select form-select-white'
              onChange={(e) => {
                setDay(e.target.value)
                validateChange('day', e)
              }}
            >
              <option value=''>Select Day</option>
              <option value={1}>1 Day</option>
              <option value={2}>2 Day</option>
              <option value={3}>3 Day</option>
              <option value={4}>4 Day</option>
              <option value={5}>5 Day</option>
              <option value={6}>6 Day</option>
              <option value={7}>7 Day</option>
              <option value={8}>8 Day</option>
            </select>
            {validate['day'] ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{t('common:validSelect')}</div>
              </div>
            ) : null}
          </div>
          {/* event time */}
          <div className='col-lg-6 fv-row mb-8'>
            <input
              className='form-control form-control-solid'
              placeholder='Time'
              maxLength={45}
              type='time'
              value={time}
              onChange={(e) => {
                setTime(e.target.value)
                validateChange('time', e)
              }}
            ></input>
            {validate['time'] ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{t('common:validInsert')}</div>
              </div>
            ) : null}
          </div>
        </div>
        {category == 2102 ||
        category == 2202 ||
        category == 2301 ||
        category == 9102 ||
        category == 9103 ||
        category == 9202 ||
        category == 9203 ||
        category == 9301 ||
        (sid == 1 && gender == 'team') ||
        sid == 3 ||
        sid == 4 ||
        sid == 7 ||
        sid == 11 ? (
          <div className='row'>
            {/* event home */}
            {/* {gender == 'team' ? ( */}
            <div className='col-lg-6 fv-row mb-8'>
              <select
                name='home'
                data-control='select2'
                data-hide-search='true'
                value={home}
                className='form-select form-select-white'
                onChange={(e) => {
                  setHome(e.target.value)
                  validateChange('home', e)
                }}
              >
                <option value={0}>Select NOC</option>
                {magicnum.NOC.map((item2, index) => {
                  return item2?.id == 10 || item2?.id == 11 ? null : (
                    <option value={item2?.id} key={index}>
                      {t(`common:NOC.${item2?.id}`)}
                    </option>
                  )
                })}
              </select>
              {validate['home'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validSelect')}</div>
                </div>
              ) : null}
            </div>
            {/* ) : null} */}
            {/* event guest */}
            {/* {gender == 'team' ? ( */}
            <div className='col-lg-6 fv-row mb-8'>
              <select
                name='guest'
                data-control='select2'
                data-hide-search='true'
                value={guest}
                className='form-select form-select-white'
                onChange={(e) => {
                  setGuest(e.target.value)
                  validateChange('guest', e)
                }}
              >
                <option value={0}>Select NOC</option>
                {magicnum.NOC.map((item2, index) => {
                  return item2?.id == 10 || item2?.id == 11 ? null : (
                    <option value={item2?.id} key={index}>
                      {t(`common:NOC.${item2?.id}`)}
                    </option>
                  )
                })}
              </select>
              {validate['guest'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validSelect')}</div>
                </div>
              ) : null}
            </div>
            {/* ) : null} */}
          </div>
        ) : null}
        <div className='pt-10' style={{textAlign: 'right'}}>
          <button
            className='btn btn-primary align-self-center'
            onClick={isNullOrEmpty(item) ? save : update}
            disabled={loading}
          >
            {loading ? t('common:loading') : t('common:save')}
          </button>
        </div>
      </div>
    </div>
  )
}

export {EventCreate}
