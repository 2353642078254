import React, { FC, useEffect, useState } from 'react'
import { isNullOrEmpty } from '../../../services/Utils'
import {
  AthleteVisaFlightAdd,
  AthleteVisaFlightGet,
  CDN_URL,
  EventReportAdd,
  EventReportDelete,
  EventReportList,
} from '../../../services/main'
import { toast } from 'react-toastify'
import { BaseRequest, EventReportListReq, magicnum } from '../../../services/Request'
import useCookie from '../../../services/useCookie'
import { KTTooltip } from '../../../_metronic/helpers/components/KTTooltip'
import { KTIcon } from '../../../_metronic/helpers'
const EventReport = ({ t, item }) => {
  const [count, setCount] = useState(0)
  const [loading, setLaoding] = useState(false)
  const [validate, setValidate] = useState([])
  const [EmployeeID] = useCookie('EmployeeID', null)
  const [data, setData] = useState(undefined)
  const [created_at, setCreated_at] = useState('')
  const [name, setName] = useState('')
  const [passFile, setPassFile] = React.useState('')
  useEffect(() => {
    if (!isNullOrEmpty(item)) {
      list()
    }
  }, [item])

  useEffect(() => {
    if (!isNullOrEmpty(data)) {
      setName(data?.name)
    }
  }, [data])

  const list = () => {
    setLaoding(true)
    var req = EventReportListReq
    req.filter.eid = item?.id
    EventReportList(req, onListSuccess, onFailed, 'POST')
  }

  const onListSuccess = (response) => {
    setLaoding(false)
    setData(response.data)
  }

  const save = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLaoding(true)
      var event = {
        eid: item?.id,
        sid: item?.sid,
        category: item?.category,
        name: name,
        created_at: created_at
      }
      var formdata = new FormData()
      formdata.append('image', passFile)
      formdata.append('event', JSON.stringify(event))
      EventReportAdd(formdata, onSuccess, onFailed, 'POST', 'file')
    }
  }

  const onSuccess = () => {
    setLaoding(false)
    setName('')
    setCreated_at('')
    list()
    return toast.success(t('common:successCreate'))
  }

  const onFailed = (err) => {
    setLaoding(false)
    return toast.error(err)
  }

  const validateFunc = () => {
    var res = { retType: 0 }

    if (isNullOrEmpty(name)) {
      res.retType = 1
      validate['name'] = true
    }

    if (isNullOrEmpty(created_at)) {
      res.retType = 1
      validate['created_at'] = true
    }

    if (isNullOrEmpty(passFile)) {
      res.retType = 1
      validate['passFile'] = true
    }
    
    setValidate(validate)
    setCount(count + 1)
    return res
  }

  const validateChange = (id, e) => {
    var val = ''
    e.target === undefined ? (val = e) : (val = e.target.value)
    if (val !== '') {
      validate[id] = false
    } else {
      validate[id] = true
    }
    setValidate(validate)
    setCount(count + 1)
  }

  const handleSelectFile = (event) => {
    setLaoding(true)
    const files = event.target.files

    if (files && files.length > 0) {
      setPassFile(files[0])
      setLaoding(false)
    }
  }

  const deleteFunc = (id) => {
    EventReportDelete(id, BaseRequest, onDeleteSuccess, onFailed, 'DELETE')
  }

  const onDeleteSuccess = () => {
    list()
    return toast.error(t('common:successDelete'))
  }


  return (
    <div className='card mt-5 p-8'>
      <h1 className='title'>Add report</h1>
      <div className='row'>
        <div className='col-lg-3'>
          <input
            className='form-control form-control-solid'
            placeholder='Report name'
            onChange={(e) => {
              setName(e.target.value)
              validateChange('name', e)
            }}
            value={name}
            maxLength={60}
          ></input>
          {validate['name'] ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{t('common:validInsert')}</div>
            </div>
          ) : null}
        </div>
        <div className='col-lg-3'>
          <input
            className='form-control form-control-solid'
            placeholder='Report date time'
            onChange={(e) => {
              setCreated_at(e.target.value)
              validateChange('created_at', e)
            }}
            value={created_at}
            maxLength={20}
          ></input>
          {validate['created_at'] ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{t('common:validInsert')}</div>
            </div>
          ) : null}
        </div>
        <div className='col-lg-4'>
          <label
            className='btn btn-light-primary fw-bolder'
            htmlFor='pass_file'
            style={{ cursor: 'pointer', width: '100%' }}
          >
            {loading
              ? t('common:loading')
              : 'Report pdf'}
          </label>
          <KTTooltip
            iconName='information-5'
            className='fs-3'
            required={false}
            tooltip='Only PDF file'
          />

          <input
            onChange={(e) => {
              handleSelectFile(e)
              validateChange('passFile', e)
            }}
            //onChange={handleSelectFile}
            type='file'
            name='pass_file'
            id='pass_file'
            maxLength={150}
            style={{ display: 'none' }}
            accept='.pdf'
          />
          {validate['passFile'] ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{t('common:validInsert')}</div>
            </div>
          ) : null}
        </div>

        <div className='col-lg-2'>
          <button
            className='btn btn-primary align-self-center'
            disabled={loading}
            onClick={() => save()}
          >
            {loading ? t('common:loading') : t('common:save')}
          </button>
        </div>
      </div>
      <div className='row'>
        <div className='pd-10' style={{ padding: 20 }}>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <tr>
              <td>№</td>
              <td>Name</td>
              <td>Report</td>
              <td>Date</td>
              <td>Action</td>
            </tr>
            {data?.map((item, index) => {
              return <tr key={index}>
                <td>{index + 1}</td>
                <td>{item?.name}</td>
                <td> <a href={CDN_URL + item?.file_url} target='_blank'>Download</a></td>
                <td>{item.created_at}</td>
                <td><button className='btn bnt-ligth cursor-pointer flex justify-center items-center' onClick={() => deleteFunc(item?.id)}> <KTIcon iconName='bi bi-trash' className='fs-1 text-black' /> Delete</button></td>
              </tr>
            })}
          </table>
        </div>
      </div>
    </div >
  )
}

export { EventReport }
