import { useEffect, useRef, useState } from "react";
import EditorJS from "@editorjs/editorjs";
import Header from '@editorjs/header';
import ImageTool from '@editorjs/image';
import List from '@editorjs/list';
import RawTool from '@editorjs/raw';
import Embed from '@editorjs/embed';
import { BASE_URL } from "../../../services/main";
import { isNullOrEmpty } from "../../../services/Utils";
import CustomModal from '../CustomModal/modal'
import config from '../../../services/config.json'
import CryptoJS from 'crypto-js'
import { toast } from "react-toastify";
function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
}

const EditorComponent = ({ data, editorJson }) => {
    const ejInstance = useRef();
    const [imageBase64, setImageBase64] = useState('')
    //data = props?.data
    const prevData = usePrevious(data)
    const initEditor = () => {
        const editor = new EditorJS({
            holder: 'editorjs',
            onReady: () => {
                ejInstance.current = editor;
            },
            autofocus: true,
            data: prevData !== data ? data : prevData,
            onChange: async () => {
                let content = await editor.saver.save();
                editorJson(content)
            },
            tools: {
                header: {
                    class: Header,
                    config: {
                        placeholder: 'Гарчиг оруулах',
                        levels: [1, 2, 3, 4, 5, 6],
                        defaultLevel: 3
                    }
                },
                raw: RawTool,
                list: {
                    class: List,
                    inlineToolbar: true,
                    config: {
                        defaultStyle: 'unordered'
                    }
                },
                embed: {
                    class: Embed,
                    config: {
                        services: {
                            youtube: true,
                            coub: true
                        }
                    }
                },
                image: {
                    class: ImageTool,
                    config: {
                        endpoints: {
                            accept: 'image/*',
                            byFile: BASE_URL + "api/media/add"
                        },
                        additionalRequestHeaders: {
                            'x-api-key': CryptoJS.AES.encrypt(config.keyAPI, config.engryptPass).toString(),
                        },
                        additionalRequestData: {
                            'url': BASE_URL
                        }
                    }
                },
                // image: {
                //     class: ImageTool,
                //     config: {
                //         uploader: { uploadByFile: uploadFile },
                //     }
                // }
            },
        });
    };

    // This will run only once
    // useEffect(() => {
    //     if (ejInstance.current === null) {
    //         initEditor();
    //     }

    //     return () => {
    //         ejInstance?.current?.destroy();
    //         ejInstance.current = null;
    //     };
    // }, []);

    useEffect(() => {
        if (prevData !== data) {
            initEditor();
        }
    }, [data]);

    const handleSelectFile = async (file) => {
        var img = ""
        var reader = new FileReader()
        reader.onload = function (event) {
            img = event?.target?.result;
        }
        reader.readAsDataURL(file)
        return img
    }

    function getCookie(name) {
        let cookie = {}
        document.cookie.split(';').forEach(function (el) {
            let [k, v] = el.split('=')
            cookie[k.trim()] = v
        })
        return cookie[name]
    }

    const uploadFile = async (file) => {
        const EmployeeID = getCookie('EmployeeID')
        const SystemToken = getCookie('SystemToken')
        var baseHeader = {
            'x-auth-id': EmployeeID,
            'x-auth-token': SystemToken,
            'x-api-key': CryptoJS.AES.encrypt(config.keyAPI, config.engryptPass).toString(),
            lang: 'mn',
            'Content-Type': 'application/json',
        }
        //var image = await handleSelectFile(file)
        //console.log("a:", image)
        var reader = new FileReader()
        reader.readAsDataURL(file)
        return reader.onload = async function (event) {
            var img = event?.target?.result;
            return await fetch(BASE_URL + 'api/media/add', {
                crossDomain: false,
                method: 'POST',
                headers: baseHeader,
                body: JSON.stringify({ image: img }),
            })
                .then(res => {
                    console.log("c")
                    return {
                        "success": 1,
                        "file": {
                            "url": "https://www.tesla.com/tesla_theme/assets/img/_vehicle_redesign/roadster_and_semi/roadster/hero.jpg",
                            // ... and any additional fields you want to store, such as width, height, color, extension, etc
                        }
                    }
                })
                .then(function (data) {
                    console.log("b")
                    return {
                        "success": 1,
                        "file": {
                            "url": "https://www.tesla.com/tesla_theme/assets/img/_vehicle_redesign/roadster_and_semi/roadster/hero.jpg",
                            // ... and any additional fields you want to store, such as width, height, color, extension, etc
                        }
                    }
                })
                .catch(function (error) {
                    console.log("l")
                    return {
                        "success": 1,
                        "file": {
                            "url": "https://www.tesla.com/tesla_theme/assets/img/_vehicle_redesign/roadster_and_semi/roadster/hero.jpg",
                            // ... and any additional fields you want to store, such as width, height, color, extension, etc
                        }
                    }
                })
        }
    }

    return <div id='editorjs'></div>;
}

export default EditorComponent;