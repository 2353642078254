import React from 'react'
import icons from '../icons-config/icons'
import {getLayout} from '../../layout/core'
import {isNullOrEmpty} from '../../../services/Utils'

type Props = {
  className?: string
  iconType?: 'duotone' | 'solid' | 'outline'
  iconName: string
  tooltip: string
  required: boolean
}

const KTTooltip: React.FC<Props> = ({className = '', iconType, iconName, tooltip, required}) => {
  if (!iconType) {
    iconType = getLayout().main?.iconType
  }

  return (
    <>
      {isNullOrEmpty(tooltip) ? null : (
        <i
          className={`ki-${iconType} ki-${iconName}${className && ' ' + className}`}
          style={{cursor: 'help'}}
          title={tooltip}
        >
          {iconType === 'duotone' &&
            [...Array(icons[iconName])].map((e, i) => {
              return (
                <span
                  key={`${iconType}-${iconName}-${className}-path-${i + 1}`}
                  className={`path${i + 1}`}
                ></span>
              )
            })}
        </i>
      )}

      {required === true ? (
        <i
          className={`ki-${iconType} ki-${iconName}${className && ' ' + className}`}
          style={{cursor: 'help'}}
        >
          {iconType === 'duotone' &&
            [...Array(icons[iconName])].map((e, i) => {
              return (
                <span
                  key={`${iconType}-${iconName}-${className}-path-${i + 1}`}
                  className={`path${i + 1}`}
                ></span>
              )
            })}
        </i>
      ) : null}
      {/* <i
        className={`ki-${iconType} ki-${iconName}${className && ' ' + className}`}
        style={{cursor: 'help'}}
      >
        {iconType === 'duotone' &&
          [...Array(icons[iconName])].map((e, i) => {
            return (
              <span
                key={`${iconType}-${iconName}-${className}-path-${i + 1}`}
                className={`path${i + 1}`}
              ></span>
            )
          })}
      </i> */}
    </>
  )
}

export {KTTooltip}
