import React, {FC, useEffect, useState} from 'react'
import {buildAccorditionCard, isNullOrEmpty} from '../../../../services/Utils'
import {
  AthleteAdd,
  AthleteContactAdd,
  AthleteContactDelete,
  AthleteContactList,
  AthleteSportAdd,
  AthleteSportChangeStatus,
  AthleteSportDelete,
  AthleteSportList,
  NewsAdd,
  NewsUpdate,
} from '../../../../services/main'
import {toast} from 'react-toastify'
import {
  AthleteAddReq,
  AthleteContactAddReq,
  AthleteContactListReq,
  AthleteSportAddReq,
  AthleteSportChangeStatusReq,
  AthleteSportListReq,
  BaseRequest,
  NewsAddReq,
  NewsUpdateReq,
  magicnum,
} from '../../../../services/Request'
import useCookie from '../../../../services/useCookie'
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {confirmAlert} from 'react-confirm-alert'
import {Image} from 'react-bootstrap'
interface IProps {
  t: any
  item: any
}
const TabContact: FC<IProps> = ({t, item}) => {
  const [count, setCount] = useState(0)
  const [loading, setLaoding] = useState(false)
  const [validate, setValidate] = useState<any>([])
  const [EmployeeID] = useCookie('EmployeeID', null)
  const [contact_id, setContact_id] = useState('')
  const [contact_value, setContact_value] = useState('')
  const [is_public, setIs_public] = useState('1')
  const [data, setData] = useState([])

  useEffect(() => {
    if (!isNullOrEmpty(item)) {
      list()
    }
  }, [item])

  const list = () => {
    setLaoding(true)
    var req = AthleteContactListReq
    req.filter.aid = item?.id
    req.filter.contact_id = ''
    req.filter.contact_value = ''
    req.filter.is_public = ''
    AthleteContactList(req, onListSuccess, onFailed, 'POST')
  }

  const onListSuccess = (response: any) => {
    setLaoding(false)
    setData(response.data)
  }

  const save = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLaoding(true)
      var req = AthleteContactAddReq
      req.contact.aid = item?.id
      req.contact.contact_id = contact_id
      req.contact.contact_value = contact_value
      req.contact.is_public = is_public
      AthleteContactAdd(req, onSuccess, onFailed, 'POST')
    }
  }

  const onSuccess = () => {
    setLaoding(false)
    list()
    setContact_id('')
    setContact_value('')
    return toast.success(t('common:successCreate'))
  }

  const onFailed = (err: string) => {
    setLaoding(false)
    return toast.error(err)
  }

  const validateFunc = () => {
    var res = {retType: 0}

    if (isNullOrEmpty(contact_id)) {
      res.retType = 1
      validate['contact_id'] = true
    }

    if (isNullOrEmpty(contact_value)) {
      res.retType = 1
      validate['contact_value'] = true
    }

    setValidate(validate)
    setCount(count + 1)
    return res
  }

  const validateChange = (id: string, e: any) => {
    var val = ''
    e.target === undefined ? (val = e) : (val = e.target.value)
    if (val !== '') {
      validate[id] = false
    } else {
      validate[id] = true
    }
    setValidate(validate)
    setCount(count + 1)
  }

  const submit = (row: any, type: string) => {
    confirmAlert({
      customUI: ({onClose}) => {
        var statusAction = ''
        return (
          <div className='custom-confirm-ui'>
            <h1>{row.title}</h1>
            <p>
              {' '}
              {type == 'deleteMain' ? t('common:confirmDelete') : t('common:confirmChangeStatus')}
            </p>
            <div className='buttons'>
              <button className='btn btn-active-light btn-color-muted' onClick={onClose}>
                {t('common:no')}
              </button>
              <button
                className='btn btn-primary align-self-center'
                onClick={() => {
                  deleteFunc(row)
                  onClose()
                }}
              >
                {t('common:yes')}
              </button>
            </div>
          </div>
        )
      },
    })
  }

  const deleteFunc = (row: any) => {
    AthleteContactDelete(row.id, BaseRequest, onDeleteSuccess, onFailed, 'DELETE')
  }

  const onDeleteSuccess = () => {
    list()
    return toast.success(t('common:successDelete'))
  }

  return (
    <div>
      <div className='mx-5 mx-xl-10 pt-10 pb-10'>
        <span style={{visibility: 'hidden'}}>{count}</span>
        {item?.status == magicnum.Status.InActive ? (
          <div className='row'>
            <div className='col-lg-3 fv-row mb-8'>
              <select
                name='contact_id'
                data-control='select2'
                data-hide-search='true'
                value={contact_id}
                className='form-select form-select-white'
                onChange={(e) => {
                  setContact_id(e.target.value)
                  validateChange('contact_id', e)
                }}
              >
                <option value=''>{t('common:athlete.more.tabcontact.selectOption')}</option>
                {magicnum.contact.map((item2, index) => {
                  return (
                    <option value={item2} key={index}>
                      {item2}
                    </option>
                  )
                })}
              </select>
              {validate['contact_id'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validSelect')}</div>
                </div>
              ) : null}
            </div>
            <div className='col-lg-5 fv-row mb-8'>
              <input
                className='form-control form-control-solid'
                placeholder={t('common:athlete.more.tabcontact.contact_value')}
                onChange={(e) => {
                  setContact_value(e.target.value)
                  validateChange('contact_value', e)
                }}
                value={contact_value}
                maxLength={140}
              ></input>
              {validate['contact_value'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validInsert')}</div>
                </div>
              ) : null}
            </div>
            <div className='col-lg-2 fv-row mb-8'>
              <select
                name='is_public'
                data-control='select2'
                data-hide-search='true'
                value={is_public}
                className='form-select form-select-white'
                onChange={(e) => {
                  setIs_public(e.target.value)
                  validateChange('is_public', e)
                }}
              >
                <option value='1'>{t('common:athlete.more.tabcontact.public')}</option>
                <option value='0'>{t('common:athlete.more.tabcontact.private')}</option>
              </select>
              {validate['is_public'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validSelect')}</div>
                </div>
              ) : null}
            </div>
            <div className='col-lg-2 fv-row mb-8'>
              <button
                className='btn btn-primary align-self-center'
                onClick={save}
                disabled={loading}
              >
                {loading ? t('common:loading') : t('common:save')}
              </button>
            </div>
          </div>
        ) : null}
        <div className='separator mt-8 d-flex flex-center mb-8'>
          <span className='text-uppercase bg-body fs-7 fw-bold text-muted px-3'>INFO</span>
        </div>
        {data.length > 0 ? (
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table align-middle w-125px gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4  rounded-start min-w-125px'>
                    {t('common:athlete.more.tabcontact.contact_id')}
                  </th>
                  <th className='ps-4  rounded-start min-w-125px'>
                    {t('common:athlete.more.tabcontact.contact_value')}
                  </th>
                  <th className='ps-4  rounded-start min-w-125px'>
                    {t('common:athlete.more.tabcontact.is_public')}
                  </th>
                  <th className='text-end rounded-end min-w-125px'></th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {data.map((itee: any, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column items-center'>
                            <Image
                              src={toAbsoluteUrl(`/image/contact/${itee?.contact_id}.png`)}
                              height={30}
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <a
                              href={itee?.contact_value}
                              target='_blank'
                              className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                            >
                              {itee?.contact_value}
                            </a>
                          </div>
                        </div>
                      </td>
                      <td>
                        {itee?.is_public == 1 ? (
                          <span className='badge badge-light-success'>
                            {t('common:athlete.more.tabcontact.public')}
                          </span>
                        ) : (
                          <span className='badge badge-light-warning'>
                            {t('common:athlete.more.tabcontact.private')}
                          </span>
                        )}
                      </td>
                      <td className='text-end'>
                        {item?.status == magicnum.Status.InActive ? (
                          <a
                            href='#'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                            onClick={() => submit(itee, 'deleteMain')}
                          >
                            <KTIcon iconName='trash' className='fs-3' />
                          </a>
                        ) : null}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export {TabContact}
