import React, {FC, useEffect, useState} from 'react'
import {isNullOrEmpty} from '../../../../services/Utils'
import {
  AthleteTechnicalAdd,
  AthleteTechnicalGet,
  AthleteTechnicalUpdate,
} from '../../../../services/main'
import {toast, useToastContainer} from 'react-toastify'
import {
  AthleteTechnicalAddReq,
  AthleteTechnicalUpdateReq,
  BaseRequest,
  magicnum,
} from '../../../../services/Request'
import useCookie from '../../../../services/useCookie'
import {Employee} from '../../employee'
interface IProps {
  t: any
  item: any
}
const TabTechnical: FC<IProps> = ({t, item}) => {
  const [count, setCount] = useState(0)
  const [loading, setLaoding] = useState(false)
  const [validate, setValidate] = useState<any>([])
  const [EmployeeID] = useCookie('EmployeeID', null)
  const [sid, setSID] = useState('')
  const [local, setLocal] = useState('')
  const [cert_number, setCert_number] = useState('')
  const [cert_level, setCert_level] = useState('')
  const [data, setData] = useState<any>('')
  const [isDisabled, setIsDisabled] = useState(false)
  const [EmployeeTypeID] = useCookie('EmployeeTypeID', null)
  const [d_function, setD_Function] = useState('')
  // var emp = magicnum.accreditationCategory.filter((item2) => item2?.)
  useEffect(() => {
    if (!isNullOrEmpty(item)) {
      get()
    }
  }, [item])

  useEffect(() => {
    if (!isNullOrEmpty(data)) {
      setIsDisabled(true)
      setSID(data?.sid)
      setCert_level(data?.cert_level)
      setCert_number(data?.cert_number)
      setLocal(data?.local)
    }
  }, [data])

  const get = () => {
    setLaoding(true)
    AthleteTechnicalGet(item?.id, BaseRequest, onListSuccess, onFailed, 'GET')
  }

  const onListSuccess = (response: any) => {
    setLaoding(false)
    setData(response.data)
  }

  const save = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLaoding(true)
      var req = AthleteTechnicalAddReq
      req.sport.aid = item?.id
      req.sport.sid = sid
      req.sport.noc = item?.noc
      req.sport.cert_level = cert_level
      req.sport.cert_number = cert_number
      req.sport.created_by = EmployeeID
      req.sport.local = local
      AthleteTechnicalAdd(req, onSuccess, onFailed, 'POST')
    }
  }

  const onSuccess = () => {
    setLaoding(false)
    get()
    setSID('')
    setCert_level('')
    setCert_number('')
    return toast.success(t('common:successCreate'))
  }

  const update = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLaoding(true)
      var req = AthleteTechnicalUpdateReq
      req.sport.sid = sid
      req.sport.cert_level = cert_level
      req.sport.cert_number = cert_number
      req.sport.local = local
      req.sport.id = data?.id
      AthleteTechnicalUpdate(req, onUpdateSuccess, onFailed, 'PUT')
    }
  }

  const onUpdateSuccess = () => {
    setLaoding(false)
    get()
    setSID('')
    setCert_level('')
    setCert_number('')
    return toast.success(t('common:successUpdate'))
  }

  const onFailed = (err: string) => {
    setLaoding(false)
    return toast.error(err)
  }

  const validateFunc = () => {
    var res = {retType: 0}

    if (isNullOrEmpty(sid)) {
      res.retType = 1
      validate['sid'] = true
    }

    if (isNullOrEmpty(cert_level)) {
      res.retType = 1
      validate['cert_level'] = true
    }

    if (isNullOrEmpty(cert_number)) {
      res.retType = 1
      validate['cert_number'] = true
    }

    if (isNullOrEmpty(local) && item.noc == 8) {
      res.retType = 1
      validate['local'] = true
    }

    setValidate(validate)
    setCount(count + 1)
    return res
  }

  const validateChange = (id: string, e: any) => {
    var val = ''
    e.target === undefined ? (val = e) : (val = e.target.value)
    if (val !== '') {
      validate[id] = false
    } else {
      validate[id] = true
    }
    setValidate(validate)
    setCount(count + 1)
  }

  return (
    <div>
      <div className='mx-5 mx-xl-10 pt-10 pb-10'>
        <span style={{visibility: 'hidden'}}>{count}</span>

        <div className='col-lg'>
          {item.noc == 8 ? (
            <div className='col-lg-4 fv-row mb-8'>
              <input
                className='form-control form-control-solid'
                placeholder={t('common:athlete.more.tabtechnical.local')}
                value={local}
                onChange={(e) => {
                  setLocal(e.target.value)
                  validateChange('local', e)
                }}
                maxLength={140}
                disabled={isDisabled}
              ></input>
              {validate['local'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validInsert')}</div>
                </div>
              ) : null}
            </div>
          ) : null}
          <div className='row'>
            <div className='col-lg-4 fv-row mb-8'>
              <select
                name='contact_id'
                data-control='select2'
                data-hide-search='true'
                value={sid}
                className='form-select form-select-white'
                onChange={(e) => {
                  setSID(e.target.value)
                  validateChange('sid', e)
                }}
                disabled={isDisabled}
              >
                <option value=''>{t('common:athlete.more.tabtechnical.selectOption')}</option>
                {/* 5 7 8 9 11 12 select option's sport id */}
                {magicnum.SportCategories.map((item2, index) => {
                  return (
                    <>
                      {item2?.technical && EmployeeTypeID != 'super' ? (
                        <option value={item2?.id} key={index}>
                          <span>{t(`common:magicnum.SportCategories.${item2?.id}`)}</span>--{item2?.id}
                        </option>
                      ) : null}
                      {EmployeeTypeID == 'super' ? (
                        <option value={item2?.id} key={index}>
                          <span>{t(`common:magicnum.SportCategories.${item2?.id}`)}</span>-{item2?.id}
                        </option>
                      ) : null}
                    </>
                  )
                })}
              </select>
              {validate['sid'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validSelect')}</div>
                </div>
              ) : null}
            </div>
            <div className='col-lg-4 fv-row mb-8'>
              <input
                className='form-control form-control-solid'
                placeholder={t('common:athlete.more.tabtechnical.certi_number')}
                value={cert_number}
                onChange={(e) => {
                  setCert_number(e.target.value)
                  validateChange('cert_number', e)
                }}
                maxLength={140}
                disabled={isDisabled}
              ></input>
              {validate['cert_number'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validInsert')}</div>
                </div>
              ) : null}
            </div>
            <div className='col-lg-4 fv-row mb-8'>
              <input
                className='form-control form-control-solid'
                placeholder={t('common:athlete.more.tabtechnical.certi_level')}
                value={cert_level}
                onChange={(e) => {
                  setCert_level(e.target.value)
                  validateChange('cert_level', e)
                }}
                maxLength={140}
                disabled={isDisabled}
              ></input>
              {validate['cert_level'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validInsert')}</div>
                </div>
              ) : null}
            </div>
            <div
              className='col-lg-4 fv-row mb-8 flex'
              style={{width: '100%', justifyContent: 'end'}}
            >
              {isNullOrEmpty(data) ? (
                <button
                  className='btn btn-primary align-self-end'
                  disabled={loading}
                  onClick={save}
                >
                  {loading ? t('common:loading') : t('common:save')}
                </button>
              ) : null}
              {!isNullOrEmpty(data) && !isDisabled ? (
                <button
                  className='btn btn-primary align-self-end'
                  disabled={loading}
                  onClick={update}
                >
                  {loading ? t('common:loading') : t('common:save')}
                </button>
              ) : null}
              {!isNullOrEmpty(data) ? (
                <button
                  className='btn btn-primary align-self-end'
                  style={{marginLeft: 10}}
                  onClick={() => {
                    setIsDisabled(!isDisabled)
                  }}
                >
                  {isDisabled ? t('common:edit') : t('common:cancel')}
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {TabTechnical}
