import React, { FC, useEffect, useState } from 'react'
import { isNullOrEmpty } from '../../../../../services/Utils'
import { AthleteVisaFlightPasswordUpdate } from '../../../../../services/main'
import { toast } from 'react-toastify'
import useCookie from '../../../../../services/useCookie'
import { KTTooltip } from '../../../../../_metronic/helpers/components/KTTooltip'
const ChangeCopyPassword = ({ t, item,onAddSuccess }) => {
  const [count, setCount] = useState(0)
  const [loading, setLaoding] = useState(false)
  const [validate, setValidate] = useState([])
  const [EmployeeID] = useCookie('EmployeeID', null)
  const [data, setData] = useState(undefined)
  const [passFile, setPassFile] = React.useState('')

  const save = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLaoding(true)
      var sport = {
        old_password_link: item?.pass_file,
        id: item?.id,
      }
      var formdata = new FormData()
      formdata.append('image', passFile)
      formdata.append('sport', JSON.stringify(sport))
      AthleteVisaFlightPasswordUpdate(formdata, onSuccess, onFailed, 'PUT', 'file')
    }
  }

  const onSuccess = () => {
    setLaoding(false)
    onAddSuccess()    
  }

  const onFailed = (err) => {
    setLaoding(false)
    return toast.error(err)
  }


  const validateFunc = () => {
    var res = { retType: 0 }

    if (isNullOrEmpty(passFile)) {
      res.retType = 1
      validate['passFile'] = true
    }

    setValidate(validate)
    setCount(count + 1)
    return res
  }

  const validateChange = (id, e) => {
    var val = ''
    e.target === undefined ? (val = e) : (val = e.target.value)
    if (val !== '') {
      validate[id] = false
    } else {
      validate[id] = true
    }
    setValidate(validate)
    setCount(count + 1)
  }

  const handleSelectFile = (event) => {
    setLaoding(true)
    const files = event.target.files
    console.log("ok", files)
    if (files && files.length > 0) {
      setPassFile(files[0])
      console.log("yes", files[0])
      setLaoding(false)
    }
  }

  return (
    <div className='tabvisa'>
      <div className='mx-5 mx-xl-10 pb-12' style={{ padding: '10px' }}>
        <span style={{ visibility: 'hidden' }}>{count}</span>
        <div className='row'>
          <div className='separator mt-8 d-flex flex-center mb-8'>
            <span className='text-uppercase bg-body fs-7 fw-bold text-muted'>
              {t('common:athlete.more.tabvisa.passport')}
            </span>
          </div>

          <div
            className='fv-row mb-8'
          >
            {/* <>
              <label
                className='btn btn-light-primary fw-bolder'
                htmlFor='pass_file'
                style={{ cursor: 'pointer', width: "100%" }}
              >
                {loading ? t('common:loading') : t('common:athlete.more.tabvisa.copyOfPassport')}
              </label>
              <KTTooltip
                iconName='information-5'
                className='fs-3'
                required={false}
                tooltip='Only PDF file'
              />
            </> */}
            <input
              onChange={(e) => {
                handleSelectFile(e)
                validateChange('passFile', e)
              }}
              type='file'
              name='pass_file'
              id='pass_file'
              maxLength={150}
              //style={{ display: 'none' }}
              accept='.pdf'
            />
            {validate['passFile'] ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{t('common:validInsert')}</div>
              </div>
            ) : null}
          </div>
        </div>

        <div className='pt-10' style={{ textAlign: 'right' }}>
          {isNullOrEmpty(data) ? (
            <button
              className='btn btn-primary align-self-center'
              disabled={loading}
              onClick={() => save()}
            >
              {loading ? t('common:loading') : t('common:edit')}
            </button>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export { ChangeCopyPassword }
