import React, {FC, useEffect, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import DataTable from '../../../modules/CustomBootsrapTable/CustomBootsrapTable'
import CustomModal from '../../../modules/CustomModal/modal'
import {isNullOrEmpty} from '../../../../services/Utils'
import {BaseRequest, ListBase} from '../../../../services/Request'
import {PrivilegeDelete, PrivilegeList} from '../../../../services/main'
import {toast} from 'react-toastify'
import {useTranslation} from 'react-i18next'
import {PrivCreate} from './add'
import {confirmAlert} from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]

const PrivList: FC = () => {
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [PID, setPID] = useState(1)
  const [PTotal, setPTotal] = useState(1)
  const [sizePerPage, setSizePerPage] = useState(25)
  const [showModal, updateShowModal] = useState(false)
  const [item, setItem] = useState(undefined)
  const [showFilter, setShowFilter] = useState(false)

  useEffect(() => {
    list()
  }, [])

  const list = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = ListBase
    PrivilegeList(req, onSuccess, onFailed, 'GET')
  }

  const onSuccess = (response: any) => {
    setLoading(false)
    setData(response.mPrivs)
    setPTotal(1)
  }

  const onFailed = (error: string) => {
    return toast.error(error)
  }

  const onChangePage = (pid: any) => {
    if (pid >= 1) {
      setPID(pid)
      setLoading(true)
    }
  }

  const defaultRowFormat = (cell: any, row: any) => {
    return <>{cell}</>
  }

  const actionRowFormat = (cell: string, row: any) => {
    return (
      <div className='text-end'>
        <div
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => {
            setItem(row)
            updateShowModal(true)
          }}
        >
          <KTIcon iconName='pencil' className='fs-3' />
        </div>
        <div
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
          onClick={() => submit(row, 'deleteMain')}
        >
          <KTIcon iconName='trash' className='fs-3' />
        </div>
      </div>
    )
  }

  const submit = (row: any, type: string) => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (
          <div className='custom-confirm-ui'>
            <h1>{row.title}</h1>
            <p>
              {' '}
              {type == 'deleteMain' ? t('common:confirmDelete') : t('common:confirmChangeStatus')}
            </p>
            <div className='buttons'>
              <button className='btn btn-active-light btn-color-muted' onClick={onClose}>
                {t('common:no')}
              </button>
              <button
                className='btn btn-primary align-self-center'
                onClick={() => {
                  type == 'deleteMain' ? deleteFunc(row) : deleteFunc(row)
                  onClose()
                }}
              >
                {t('common:yes')}
              </button>
            </div>
          </div>
        )
      },
    })
  }

  const deleteFunc = (row: any) => {
    PrivilegeDelete(row.PrivNo, BaseRequest, onDeleteSuccess, onFailed, 'DELETE')
  }

  const onDeleteSuccess = () => {
    list()
    return toast.error(t('common:successDelete'))
  }

  const onAddSuccess = (text: string) => {
    list()
    updateShowModal(false)
    return toast.success(text)
  }

  var table = {
    sizePerPage: sizePerPage,
    headerKeyColumn: {
      title: 'id',
      dataField: 'PrivNo',
      dataSort: true,
      dataFormat: defaultRowFormat,
      hidden: true,
      filter: {
        type: 'TextFilter',
        placeholder: '...',
      },
    },
    headerOtherColumn: [
      {
        title: t('common:privilege.PrivName'),
        dataField: 'PrivName',
        dataSort: false,
        dataFormat: defaultRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:privilege.PrivNo'),
        dataField: 'PrivNo',
        dataSort: false,
        dataFormat: defaultRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:privilege.action'),
        dataField: 'id',
        dataSort: false,
        dataFormat: actionRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
    ],
  }

  return (
    <>
      <div className={`card mb-5`}>
        <div className='card-header border-1' style={{height: '60px'}}>
          <div
            className='filter flex items-center justify-center gap-0'
            onClick={() => setShowFilter(!showFilter)}
          >
            <KTIcon iconName='filter' className='fs-3' />
            <span className='text'>{t('common:filter')}</span>
          </div>
          <div className='card-toolbar'>
            <a href='#' className='btn btn-sm btn-light-primary'>
              {t('common:search')}
            </a>
          </div>
        </div>
        {showFilter ? <div className='card-body py-3'>d</div> : null}
      </div>
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>{t('common:privilege.pagetitle')}</PageTitle>
      <div className={`card`}>
        {showModal ? (
          <CustomModal
            visible={showModal}
            closeModal={() => updateShowModal(false)}
            className='VideoModal'
          >
            <PrivCreate t={t} item={item} onAddSuccess={onAddSuccess} />
          </CustomModal>
        ) : null}
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>{t('common:privilege.list')}</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>
              {t('common:privilege.total', {count: PTotal})}
            </span>
          </h3>
          <div className='card-toolbar'>
            <a
              href='#'
              className='btn btn-sm btn-light-primary'
              // data-bs-toggle='modal'
              // data-bs-target='#kt_modal_invite_friends'
              onClick={() => {
                setItem(undefined)
                updateShowModal(true)
              }}
            >
              <KTIcon iconName='plus' className='fs-3' />
              {t('common:privilege.add')}
            </a>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <DataTable
            data={data}
            table={table}
            PTotal={isNullOrEmpty(data) ? 1 : data.length}
            pageChange={onChangePage}
            PID={1}
            loading={loading}
          />
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export {PrivList}
