import React, {FC, useEffect, useState} from 'react'
import {buildAccorditionCard, isNullOrEmpty} from '../../../../services/Utils'
import {
  AthleteMediaAdd,
  AthleteMediaUpdate,
  AthleteMediaDelete,
  AthleteMediaList,
  AthleteMediaGet,
} from '../../../../services/main'
import {toast} from 'react-toastify'
import {
  AthleteMediaAddReq,
  AthleteMediaUpdateReq,
  AthleteMediaListReq,
  BaseRequest,
  magicnum,
} from '../../../../services/Request'
import useCookie from '../../../../services/useCookie'
import {KTIcon} from '../../../../_metronic/helpers'
import {confirmAlert} from 'react-confirm-alert'
import {setegid} from 'process'
interface IProps {
  t: any
  item: any
}
const TabMedia: FC<IProps> = ({t, item}) => {
  const [count, setCount] = useState(0)
  const [loading, setLaoding] = useState(false)
  const [validate, setValidate] = useState<any>([])
  const [EmployeeID] = useCookie('EmployeeID', null)
  const [sportCategory, setSportCategory] = useState(
    magicnum.SportCategories.filter((item) => isNullOrEmpty(item.id)) ? '' : item.id
  )
  // const [subCategory, setSubCategory] = useState('')
  // const [gender, setGender] = useState('')
  const [data, setData] = useState([])
  const [school_info, setSchoolInfo] = useState('')
  const [grade, setGrade] = useState('')
  const [weight, setWeight] = useState('')
  const [height, setHeight] = useState('')
  const [expierence, setExpierence] = useState('')
  const [fullName, setFullName] = useState('')
  const [relationship, setRelationship] = useState('')
  const [contactDetails, setContactDetails] = useState('')

  useEffect(() => {
    if (!isNullOrEmpty(data)) {
      // setSchoolInfo(data?.school_info)
      // setGrade(data?.grade)
      // setWeight(data?.weight)
      // setHeight(data?.height)
      // setExpierence(data?.expierence)
      // setFullName(data?.full_name)
      // setRelationship(data?.relationship)
      // setContactDetails(data?.contact_details)
    }
  }, [data])

  const list = () => {
    setLaoding(true)
    AthleteMediaGet(item?.id, BaseRequest, onListSuccess, onFailed, 'GET')
  }

  const onListSuccess = (response: any) => {
    setLaoding(false)
    setData(response.data)
  }

  const save = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLaoding(true)
      var req = AthleteMediaAddReq
      req.media.school_info = school_info
      req.media.grade = grade
      req.media.weight = weight
      req.media.height = height
      req.media.expierence = expierence
      req.media.full_name = fullName
      req.media.relationship = relationship
      req.media.contact_detailse = contactDetails
      AthleteMediaAdd(req, onSuccess, onFailed, 'POST')
    }
  }

  const onSuccess = () => {
    setLaoding(false)
    return toast.success(t('common:successCreate'))
  }

  const onFailed = (err: string) => {
    setLaoding(false)
    return toast.error(err)
  }

  const validateFunc = () => {
    var res = {retType: 0}

    if (isNullOrEmpty(school_info)) {
      res.retType = 1
      validate['school_info'] = true
    }

    if (isNullOrEmpty(grade)) {
      res.retType = 1
      validate['grade'] = true
    }

    if (isNullOrEmpty(weight)) {
      res.retType = 1
      validate['weight'] = true
    }

    if (isNullOrEmpty(height)) {
      res.retType = 1
      validate['height'] = true
    }

    if (isNullOrEmpty(expierence)) {
      res.retType = 1
      validate['expierence'] = true
    }

    if (isNullOrEmpty(fullName)) {
      res.retType = 1
      validate['full_name'] = true
    }

    if (isNullOrEmpty(relationship)) {
      res.retType = 1
      validate['relationship'] = true
    }

    if (isNullOrEmpty(contactDetails)) {
      res.retType = 1
      validate['contact_details'] = true
    }

    setValidate(validate)
    setCount(count + 1)
    return res
  }

  const validateChange = (id: string, e: any) => {
    var val = ''
    e.target === undefined ? (val = e) : (val = e.target.value)
    if (val !== '') {
      validate[id] = false
    } else {
      validate[id] = true
    }
    setValidate(validate)
    setCount(count + 1)
  }

  // const submit = (row: any, type: string) => {
  //   confirmAlert({
  //     customUI: ({onClose}) => {
  //       var statusAction = ''
  //       return (
  //         <div className='custom-confirm-ui'>
  //           <h1>{row.title}</h1>
  //           <p>
  //             {' '}
  //             {type == 'deleteMain' ? t('common:confirmDelete') : t('common:confirmChangeStatus')}
  //           </p>
  //           {type == 'changeStatus' ? (
  //             <div>
  //               <select
  //                 name='category'
  //                 data-control='select2'
  //                 data-hide-search='true'
  //                 className='form-select form-select-white'
  //                 onChange={(e) => {
  //                   statusAction = e.target.value
  //                 }}
  //               >
  //                 <option value=''>{t('common:choose')}</option>
  //                 {magicnum.StatusSport.map((item2, index) => {
  //                   return (
  //                     <option value={item2.id} key={index}>
  //                       {t(`common:magicnum.StatusSport.${item2?.id}`)}
  //                     </option>
  //                   )
  //                 })}
  //               </select>
  //             </div>
  //           ) : null}
  //           <div className='buttons'>
  //             <button className='btn btn-active-light btn-color-muted' onClick={onClose}>
  //               {t('common:no')}
  //             </button>
  //             <button
  //               className='btn btn-primary align-self-center'
  //               onClick={() => {
  //                 type == 'deleteMain' ? deleteFunc(row) : changeStatus(row, statusAction)
  //                 onClose()
  //               }}
  //             >
  //               {t('common:yes')}
  //             </button>
  //           </div>
  //         </div>
  //       )
  //     },
  //   })
  // }

  // const deleteFunc = (row: any) => {
  //   AthleteSportDelete(row.id, BaseRequest, onDeleteSuccess, onFailed, 'DELETE')
  // }

  // const onDeleteSuccess = () => {
  //   list()
  //   return toast.success(t('common:successDelete'))
  // }

  // const changeStatus = (row: any, statusAction: string) => {
  //   var req = AthleteSportChangeStatusReq
  //   req.sport.id = row.id
  //   req.sport.status = statusAction
  //   AthleteSportChangeStatus(req, onChangeStatusSuccess, onFailed, 'PUT')
  // }

  // const onChangeStatusSuccess = () => {
  //   list()
  //   return toast.success(t('common:successUpdate'))
  // }

  return (
    <div>
      <div className='mx-5 mx-xl-10 pt-10 pb-10'>
        <span style={{visibility: 'hidden'}}>{count}</span>

        <div className='row'>
          <div className='col-lg-12 fv-row mb-8 flex flex-col gap-8'>
            <input
              className='form-control form-control-solid'
              placeholder={t('common:athlete.more.tabmedia.school_info')}
              maxLength={30}
              onChange={(e) => {
                setSchoolInfo(e.target.value)
                validateChange('school_info', e)
              }}
              value={school_info}
            ></input>
            {validate['school_info'] ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{t('common:validInsert')}</div>
              </div>
            ) : null}
            <input
              className='form-control form-control-solid'
              placeholder={t('common:athlete.more.tabmedia.grade')}
              maxLength={30}
              value={grade}
              onChange={(e) => {
                setGrade(e.target.value)
                validateChange('grade', e)
              }}
            ></input>
            {validate['grade'] ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{t('common:validInsert')}</div>
              </div>
            ) : null}
          </div>
          <div className='col-lg-4 fv-row mb-8'>
            <input
              className='form-control form-control-solid'
              placeholder={t('common:athlete.more.tabmedia.weight')}
              maxLength={30}
              value={weight}
              onChange={(e) => {
                setWeight(e.target.value)
                validateChange('weight', e)
              }}
            ></input>
            {validate['weight'] ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{t('common:validInsert')}</div>
              </div>
            ) : null}
          </div>
          <div className='col-lg-4 fv-row mb-8'>
            <input
              className='form-control form-control-solid'
              placeholder={t('common:athlete.more.tabmedia.height')}
              maxLength={30}
              value={height}
              onChange={(e) => {
                setHeight(e.target.value)
                validateChange('height', e)
              }}
            ></input>
            {validate['height'] ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{t('common:validInsert')}</div>
              </div>
            ) : null}
          </div>

          <div className='separator mt-8 d-flex flex-center mb-8'>
            <span className='text-uppercase bg-body fs-7 fw-bold text-muted px-3'>
              {t('common:athlete.more.tabmedia.expierence')}
            </span>
          </div>

          <div className='col-lg-12 fv-row mb-8 flex flex-col gap-8'>
            <input
              className='form-control form-control-solid'
              placeholder={t('common:athlete.more.tabmedia.expierence_desc')}
              maxLength={30}
              value={expierence}
              onChange={(e) => {
                setExpierence(e.target.value)
                validateChange('expierence', e)
              }}
            ></input>
            {validate['expierence'] ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{t('common:validInsert')}</div>
              </div>
            ) : null}
          </div>

          <div className='separator mt-8 d-flex flex-center mb-8'>
            <span className='text-uppercase bg-body fs-7 fw-bold text-muted px-3'>
              {t('common:athlete.more.tabmedia.emergency_contact')}
            </span>
          </div>

          <div className='col-lg-12 fv-row mb-8 flex flex-col gap-8'>
            <input
              className='form-control form-control-solid'
              placeholder={t('common:athlete.more.tabmedia.full_name')}
              maxLength={30}
              value={fullName}
              onChange={(e) => {
                setFullName(e.target.value)
                validateChange('full_name', e)
              }}
            ></input>
            {validate['full_name'] ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{t('common:validInsert')}</div>
              </div>
            ) : null}
          </div>

          <div className='col-lg-12 fv-row mb-8 flex flex-col gap-8'>
            <input
              className='form-control form-control-solid'
              placeholder={t('common:athlete.more.tabmedia.relationship')}
              maxLength={30}
              value={relationship}
              onChange={(e) => {
                setRelationship(e.target.value)
                validateChange('relationship', e)
              }}
            ></input>
            {validate['relationship'] ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{t('common:validInsert')}</div>
              </div>
            ) : null}
          </div>

          <div className='col-lg-12 fv-row mb-8 flex flex-col gap-8'>
            <input
              className='form-control form-control-solid'
              placeholder={t('common:athlete.more.tabmedia.contact_details')}
              maxLength={30}
              value={contactDetails}
              onChange={(e) => {
                setContactDetails(e.target.value)
                validateChange('contact_details', e)
              }}
            ></input>
            {validate['contact_details'] ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{t('common:validInsert')}</div>
              </div>
            ) : null}
          </div>

          <div className='pt-10' style={{textAlign: 'right'}}>
            <button className='btn btn-primary align-self-center' onClick={save} disabled={loading}>
              {loading ? t('common:loading') : t('common:save')}
            </button>
          </div>
        </div>
        {/* <div className='col-lg-4 fv-row mb-8'>
            <button className='btn btn-primary align-self-center' onClick={save} disabled={loading}>
              {loading ? t('common:loading') : t('common:save')}
            </button>
          </div> */}
        {/* <div className='separator mt-8 d-flex flex-center mb-8'>
          <span className='text-uppercase bg-body fs-7 fw-bold text-muted px-3'>{t('common:athlete.info')}</span>
        </div> */}

        {/* <div className='table-responsive'>
          <table className='table align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4  rounded-start'>{t('common:athlete.more.tabsport.title')}</th>
                <th className='ps-4  rounded-start'>{t('common:athlete.more.tabsport.accr_and_gender')}</th>
                <th className='min-w-150px'>{t('common:athlete.status')}</th>
                <th className='text-end rounded-end'></th>
              </tr>
            </thead>
            <tbody>
              {data.map((itee: any, index) => {
                var vv = buildAccorditionCard(item, itee)
                var gg = magicnum.StatusSport.filter((ite) => ite.id == itee.status)
                return (
                  <tr key={index}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column items-center'>
                          <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                            {t(`common:magicnum.SportCategories.${itee?.category}`)}
                          </a>
                          <span className='text-muted fw-semibold text-muted d-block fs-7'>
                            {t(`common:magicnum.SportSubCategories.${itee?.sub_category}`)}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                            {vv}
                          </a>
                          <span className='text-muted fw-semibold text-muted d-block fs-7'>
                            {itee?.gender}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span
                        onClick={() => submit(itee, 'changeStatus')}
                        className={`badge ${gg[0].class} fw-semibold cursor-pointer`}
                      >
                        {t(`common:magicnum.StatusSport.${itee?.status}`)}
                      </span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='#'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                        onClick={() => submit(itee, 'deleteMain')}
                      >
                        <KTIcon iconName='trash' className='fs-3' />
                      </a>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div> */}
      </div>
    </div>
  )
}

export {TabMedia}
