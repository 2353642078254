import { useState, useRef, useEffect } from 'react'
import { buildAccorditionCardVolunteer, isNullOrEmpty } from '../../../../services/Utils'
import { CDN_URL } from '../../../../services/main'
import { magicnum } from '../../../../services/Request'
import useCookie from '../../../../services/useCookie'
import { Image } from 'react-bootstrap'
import { QRCode } from 'react-qrcode-logo'
import html2canvas from 'html2canvas'
import { KTIcon } from '../../../../_metronic/helpers'
const TabVolunteerCard = (props) => {
  const t = props.t
  const item = props.item
  const [loading, setLoading] = useState(false)
  const [photo, setPhoto] = useState('')
  const [EmployeeID] = useCookie('EmployeeID', null)
  const ite = magicnum.accreditationCategoryNew.filter((zitem) => zitem?.id == 'V')

  const domEl1 = useRef(null)
  const domEl2 = useRef(null)

  const htmlToImageConvert = async (type) => {
    setLoading(true)
    var element = undefined
    if (type == 'front') {
      element = domEl1.current
    } else {
      element = domEl2.current
    }

    const canvas = await html2canvas(element)

    const data = canvas.toDataURL('image/jpg')
    const link = document.createElement('a')

    if (typeof link.download === 'string') {
      link.href = data
      link.download = item?.firstname + '-' + 'Volunteer' + '-' + type + '-' + item?.id + '.jpg'

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      setLoading(false)
    } else {
      window.open(data)
      console.log('no:')
      setLoading(false)
    }
  }

  const convertImageToBase64 = async (imgUrl) => {
    const base64 = await fetch(imgUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        return new Promise((res) => {
          reader.onloadend = () => {
            res(reader.result)
          }
        })
      })
    setPhoto(base64)
  }

  useEffect(() => {
    if (!isNullOrEmpty(item)) {
      convertImageToBase64(CDN_URL + item?.photo)
    }
  }, [item])

  return (
    <div className='mx-5 mx-xl-10 pt-10 pb-10 tabacccard'>
      <div className='flex flex-col gap-8'>
        {/* Card Download Front Code Start */}
        <div className='card-1-download flex justify-center items-center' style={{ width: '800px' }}>
          <button
            className='btn btn-lg btn-light-primary'
            style={{ padding: '20px 50px' }}
            onClick={() => htmlToImageConvert('front')}
            disabled={loading}
          >
            {loading ? 'Loading...' : 'Front Card Download'}
          </button>
        </div>
        {/* Card Download Code End */}
        <div
          className='card-front'
          style={{
            backgroundImage: `url('/accrCardBackground/jpg/front_last.jpg')`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
          ref={domEl1}
        >
          <div className='top flex justify-between'>
            <div className='text flex flex-col gap-2'>
              <span>{t('common:athlete.more.tabacccard.top_text_en')}</span>
              <span>{t('common:athlete.more.tabacccard.top_text_mn')}</span>
            </div>
            <div className='logo'>
              <img
                src='/logo/png/logo-no-bg.png'
                alt='Metornic'
                style={{ height: 200, width: 200 }}
              />
            </div>
          </div>
          <div className='profile flex gap-8'>
            <div className='image' style={{ background: 'white' }}>
              {isNullOrEmpty(item?.photo) ? null : ( // </> //   ) : null} //     <img src='/image/inga.png' alt='Metornic' style={{height: 400, width: 300}} /> //   {item?.gender == 'female' ? ( //   ) : null} //     <img src='/image/haki.png' alt='Metornic' style={{height: 400, width: 300}} /> //   {item?.gender == 'male' ? ( // <>
                <img src={photo} alt='Metornic' style={{ height: 400, width: 300 }} />
              )}
            </div>
            <div className='flex'>
              <div className='accr' style={{ background: `#9738ef` }}>
                <span style={{ color: 'white' }}>V</span>
              </div>
            </div>
          </div>
          <div className='holders'>
            <span className='name'>
              {item?.firstname.toUpperCase()} {item?.lastname.toUpperCase()}
            </span>
            <span>{buildAccorditionCardVolunteer(item)}</span>
          </div>
          <div className='qr'>
            <QRCode
              logoImage='/logo/png/qrlogo.png'
              logoHeight={50}
              logoWidth={50}
              // bgColor='white'
              // logoPaddingStyle='circle'
              ecLevel='H'
              qrStyle={'dots'}
              // eyeRadius={2}
              // eyeColor={ite?.color}
              // fgColor={ite?.color}
              value={'https://ulaanbaatar2023.org/volunteer/' + item?.id}
            />
          </div>
          <div className='other-container' style={{ background: '#9738ef' }}>
            <div
              className='other-border flex justify-center items-center text-white'
              style={{ border: `1px solid purple`, position: 'absolute', right: 30, top: 50 }}
            >
              <span style={{ textAlign: 'center', fontSize: 32 }}>TP</span>
            </div>
          </div>
        </div>
        <div style={{ width: '100%', height: '20px', background: 'gray' }}></div>
        {/* Card Download Back Code Start  */}
        <div className='card-1-download flex justify-center items-center' style={{ width: '800px' }}>
          <button
            className='btn btn-sm btn-light-primary'
            onClick={() => htmlToImageConvert('backend')}
            disabled={loading}
            style={{ padding: '20px 50px' }}
          >
            {loading ? 'Loading...' : 'Back Card Download'}
          </button>
        </div>
        {/* Card Download Code End */}
        <div
          className='card-back'
          style={{
            backgroundImage: `url('/accrCardBackground/jpg/back_last.jpg')`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
          ref={domEl2}
        >
          <div>
            <div className='top flex justify-between'>
              <div className='text flex flex-col gap-2'>
                <span>{t('common:athlete.more.tabacccard.top_text_en')}</span>
                <span>{t('common:athlete.more.tabacccard.top_text_mn')}</span>
              </div>
              <div className='logo'>
                <img
                  src='/logo/png/logo-no-bg.png'
                  alt='Metornic'
                  style={{ height: 115, width: 115 }}
                />
              </div>
            </div>
            <div
              className='back-desc flex justify-center items-center'
              style={{ border: `2px solid #9738ef` }}
            >
              <span>{t('common:athlete.more.tabacccard.back_desc')}</span>
            </div>
            <div className='back-access flex justify-center items-center' style={{ width: '100%' }}>
              <div
                className='access flex justify-between'
                style={{ width: '100%', paddingRight: 80, paddingLeft: 15 }}
              >
                <div className='flex gap-5 items-center justify-center'>
                  <div
                    className='items'
                    style={{ background: `#9738ef`, color: 'white', border: 'none' }}
                  >
                    <span className='code'>V</span>
                  </div>
                  <span className='texts'>
                    {t('common:athlete.more.tabacccard.back_accr_category')}
                  </span>
                </div>
                <div className='flex gap-5 items-center justify-center'>
                  <div className='items' style={{ border: `1px solid #9738ef` }}>
                    <span>TP</span>
                  </div>
                  <span className='texts'>Shuttle bus</span>
                </div>
              </div>
            </div>
            <div className='qr' style={{ bottom: 487, rigth: 340 }}>
              <QRCode
                logoImage='/logo/png/qrlogo.png'
                logoHeight={50}
                logoWidth={50}
                // bgColor='white'
                // logoPaddingStyle='circle'
                ecLevel='H'
                qrStyle={'dots'}
                // eyeRadius={2}
                // eyeColor={ite?.color}
                // fgColor={ite?.color}
                value={'https://ulaanbaatar2023.org/volunteer/' + item?.id}
              />
            </div>
            {/* tamga */}
            {isNullOrEmpty(buildAccorditionCardVolunteer(item)) ? null : (
              <div
                className='back-tamga'
                style={{
                  backgroundImage: `url('/signature/tamga.png')`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  width: 300,
                  height: 150,
                  position: 'absolute',
                  bottom: 496,
                  left: 134,
                }}
              ></div>
            )}
            <div className='back-bottom-text flex gap-8 justify-center'>
              <div className='signature' style={{ position: 'relative', top: 40 }}>
                {/* <span className='text-center'>{t('common:athlete.more.tabacccard.signature')}</span> */}
                <div
                  className='back-signature'
                  style={{
                    backgroundImage: `url('/signature/2.png')`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    position: 'relative',
                    left: 16,
                    bottom: -12,
                    width: 113,
                    height: 83,
                    transform: `rotate(353deg)`,
                  }}
                ></div>
                <div className='flex flex-col gap-4'>
                  <span>{t('common:athlete.more.tabacccard.president')}</span>
                  <span>{t('common:athlete.more.tabacccard.eaoc')}</span>
                </div>
              </div>
              <div className='signature'>
                {/* <span className='text-center'>{t('common:athlete.more.tabacccard.signature')}</span> */}
                <div
                  className='back-signature'
                  style={{
                    backgroundImage: `url('/signature/1.png')`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    position: 'relative',
                    width: 170,
                    height: 122,
                    left: 70,
                    bottom: -40,
                    transform: `rotate(22deg)`,
                  }}
                ></div>
                <div className='flex flex-col gap-4'>
                  <span>{t('common:athlete.more.tabacccard.chairman')}</span>
                  <span>{t('common:athlete.more.tabacccard.org_ub')}</span>
                </div>
              </div>
            </div>
            <div
              className='back-bottom-period flex justify-center items-center gap-2'
              style={{ border: `2px solid #9738ef` }}
            >
              <span>{t('common:athlete.more.tabacccard.period')}:</span>
              <span>{t('common:athlete.more.tabacccard.date')}</span>
            </div>
            {/* <div
              className='back-signature'
              style={{
                backgroundImage: `url('/signature/1.png')`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                width: 171,
                height: 122,
                position: 'absolute',
                bottom: '126px',
                right: '73px',
                transform: `rotate(25deg)`,
              }}
            ></div> */}
            {/* <div className='back-bottom-colors'></div> */}
          </div>
        </div>
      </div>
      {/* Card Download Code Start  */}
      {/* <div className='card-1-download flex justify-center items-center' style={{width: '800px'}}>
        <button
          className='btn btn-sm btn-light-primary'
          onClick={() => htmlToImageConvert('backend')}
          disabled={loading}
        >
          {loading ? 'Loading...' : 'Download'}
        </button>
      </div> */}
      {/* Card Download Code End */}
    </div>
  )
}

export { TabVolunteerCard }
