import React, {FC, useEffect, useRef, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import DataTable from '../../modules/CustomBootsrapTable/CustomBootsrapTable'
import {isNullOrEmpty} from '../../../services/Utils'
import {BaseRequest, VolunteerListReq, magicnum} from '../../../services/Request'
import {CDN_URL, VolunteerDelete, VolunteerList} from '../../../services/main'
import {toast} from 'react-toastify'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import useCookie from '../../../services/useCookie'
import {confirmAlert} from 'react-confirm-alert'
import {VolunteerCreate} from './add'
import CustomModal from '../../modules/CustomModal/modal'
import {TabVolunteerCard} from './tab/volunteerCard'
import {TabVolunteerDocs} from './tab/docs'
const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]

function usePrevious(value: any) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}

const Volunteers: FC = () => {
  const queryString = window.location.search
  const params = new URLSearchParams(queryString)
  let teamQ = params.get('team')
  let pidQ = params.get('pid')
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [item, setItem] = useState<any | null>(undefined)
  const [PID, setPID] = useState<any | null>(1)
  const [PTotal, setPTotal] = useState(1)
  const [sizePerPage, setSizePerPage] = useState(20)
  const [showModal, updateShowModal] = useState(false)
  // const [showModalDoc, updateShowModalDoc] = useState(false)
  const [showPrintModal, updateShowPrintModal] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [gender, setGender] = useState<any | null>('')
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [age, setAge] = useState('')
  const [mail, setMail] = useState('')
  const [phone, setPhone] = useState('')
  const [team, setTeam] = useState<any | null>('')
  const [EmployeeTypeID] = useCookie('EmployeeTypeID', null)
  useEffect(() => {
    if (!isNullOrEmpty(teamQ)) {
      setTeam(teamQ)
    }
    if (!isNullOrEmpty(pidQ)) {
      setPID(pidQ)
    }
  }, [teamQ, pidQ])

  useEffect(() => {
    list()
  }, [PID, team])

  const search = () => {
    if (PID > 1) {
      setPID(1)
    } else {
      list()
    }
  }

  const onChangePage = (pid: any) => {
    if (pid >= 1) {
      setPID(pid)
      setLoading(true)
    }
  }

  const defaultRowFormat = (cell: any, row: any) => {
    return (
      <div className='flex justify-center items-center'>
        <span className='text-center'>{cell}</span>
      </div>
    )
  }

  const imgRowFormat = (cell: string, row: any) => {
    return (
      <div className='row flex justify-center items-center' style={{position: 'relative'}}>
        <div className='flex justify-center items-center'>
          {isNullOrEmpty(row?.photo) ? (
            <>
              <span>No Photo</span>
            </>
          ) : (
            <>
              <img src={CDN_URL + row?.photo} alt='' width={60} height={60} />
            </>
          )}
        </div>
      </div>
    )
  }

  const nameRowFormat = (cell: string, row: any) => {
    return (
      <Link to={``}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <span>{row?.lastname}</span>
          <span className='text-muted fw-semibold text-muted d-block'>{row?.firstname}</span>
        </div>
      </Link>
    )
  }

  const ageRowFormat = (cell: string, row: any) => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '5px',
          flexDirection: 'column',
        }}
      >
        <span className='text-muted fw-semibold text-muted d-block'>{cell}</span>
      </div>
    )
  }

  const mailRowFormat = (cell: string, row: any) => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '5px',
          flexDirection: 'column',
        }}
      >
        <span className='text-muted fw-semibold text-muted d-block'>{row?.email}</span>
      </div>
    )
  }

  const phoneRowFormat = (cell: string, row: any) => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '5px',
          flexDirection: 'column',
        }}
      >
        <a className='text-muted fw-semibold text-muted d-block' href={`callto:+976${cell}`}>
          {cell}
        </a>
      </div>
    )
  }

  const teamRowFormat = (cell: string, row: any) => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '5px',
          flexDirection: 'column',
        }}
      >
        <span className='text-muted fw-semibold text-muted d-block'>
          {t(`common:magicnum.volunteer.team.${cell}`)}
        </span>
      </div>
    )
  }

  const list = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = VolunteerListReq
    req.pid = PID
    req.pSize = sizePerPage
    req.filter.firstname = firstname
    req.filter.lastname = lastname
    req.filter.gender = gender
    req.filter.age = age
    req.filter.email = mail
    req.filter.phone = phone
    req.filter.team = team
    VolunteerList(req, onSuccess, onFailed, 'POST')
  }

  const onSuccess = (response: any) => {
    setLoading(false)
    setData(response.data)
    setPTotal(response.pagination.ptotal)
  }

  const submit = (row: any, type: string) => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (
          <div className='custom-confirm-ui'>
            <h1>{row.title}</h1>
            <p>
              {type == 'deleteMain' ? t('common:confirmDelete') : t('common:confirmChangeStatus')}
            </p>
            <div className='buttons'>
              <button className='btn btn-active-light btn-color-muted' onClick={onClose}>
                {t('common:no')}
              </button>
              <button
                className='btn btn-primary align-self-center'
                onClick={() => {
                  deleteFunc(row)
                  onClose()
                }}
              >
                {t('common:yes')}
              </button>
            </div>
          </div>
        )
      },
    })
  }

  const onFailed = (error: string) => {
    setLoading(false)
    return toast.error(error)
  }

  const deleteFunc = (row: any) => {
    VolunteerDelete(row.id, BaseRequest, onDeleteSuccess, onFailed, 'DELETE')
  }

  const onDeleteSuccess = () => {
    list()
    return toast.error(t('common:successDelete'))
  }

  const actionRowFormat = (cell: string, row: any) => {
    return (
      <div className='text-end flex gap-1 justify-center items-center'>
        <div
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => {
            setItem(row)
            updateShowModal(true)
          }}
        >
          <KTIcon iconName='pencil' className='fs-3' />
        </div>
        <div
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
          onClick={() => submit(row, 'deleteMain')}
        >
          <KTIcon iconName='trash' className='fs-3' />
        </div>
        <div
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
          onClick={() => {
            setItem(row)
            updateShowPrintModal(true)
          }}
        >
          <KTIcon iconName='bi bi-printer-fill' className='fs-3' />
        </div>
        <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
          <a href={`https://ulaanbaatar2023.org/certificate/V-${row?.id}`} target='_blank'>
            <KTIcon iconName='bi bi-person-bounding-box' className='fs-3' />
          </a>
        </div>
        {/* <div
          className='text-end flex gap-1 justify-center items-center'
          onClick={() => {
            updateShowModalDoc(true)
            setItem(row)
          }}
        >
          <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
            <KTIcon iconName='bi bi-file-earmark-word-fill' className='fs-3' />
          </div>
        </div> */}
      </div>
    )
  }

  const onAddSuccess = (text: string) => {
    list()
    updateShowModal(false)
    return toast.success(text)
  }

  var table = {
    sizePerPage: sizePerPage,
    headerKeyColumn: {
      title: 'id',
      dataField: 'id',
      dataSort: true,
      dataFormat: defaultRowFormat,
      hidden: true,
      filter: {
        type: 'TextFilter',
        placeholder: '...',
      },
    },
    headerOtherColumn: [
      {
        title: t('common:NEWS.image_link'),
        dataField: 'photo',
        dataSort: false,
        dataFormat: imgRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:athlete.name'),
        dataField: 'firstname',
        dataSort: false,
        dataFormat: nameRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:adminVolunteers.team'),
        dataField: 'team',
        dataSort: false,
        dataFormat: teamRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:athlete.gender'),
        dataField: 'gender',
        dataSort: false,
        dataFormat: defaultRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },

      {
        title: t('common:adminVolunteers.age'),
        dataField: 'age',
        dataSort: false,
        dataFormat: ageRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:adminVolunteers.mail'),
        dataField: 'mail',
        dataSort: false,
        dataFormat: mailRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:adminVolunteers.phone'),
        dataField: 'phone',
        dataSort: false,
        dataFormat: phoneRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },

      {
        title: t('common:athlete.action'),
        dataField: 'id',
        dataSort: false,
        dataFormat: actionRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
    ],
  }

  return (
    <>
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>
        {t('common:adminVolunteers.volunteer')}
      </PageTitle>
      {showModal ? (
        <CustomModal
          visible={showModal}
          closeModal={() => updateShowModal(false)}
          className='VideoModal'
        >
          <VolunteerCreate t={t} item={item} onAddSuccess={onAddSuccess} />
        </CustomModal>
      ) : null}

      {showPrintModal ? (
        <CustomModal
          visible={showPrintModal}
          closeModal={() => updateShowPrintModal(false)}
          className='accr-modal'
        >
          <TabVolunteerCard item={item} t={t} />
        </CustomModal>
      ) : null}

      {/* {showModalDoc ? (
        <CustomModal
          visible={showModalDoc}
          closeModal={() => updateShowModalDoc(false)}
          className='accr-modal'
        >
          <TabVolunteerDocs item={item} t={t} type={'volunteer'} />
        </CustomModal>
      ) : null} */}

      <div className={`card mb-5`}>
        <div className='card-header border-1' style={{height: '60px'}}>
          <div
            className='filter flex items-center justify-center gap-0 cursor-pointer'
            onClick={() => setShowFilter(!showFilter)}
          >
            <KTIcon iconName='filter' className='fs-3' />
            <span className='text'>{t('common:filter')}</span>
          </div>
          <div className='card-toolbar'>
            <a href='#' className='btn btn-sm btn-light-primary' onClick={() => search()}>
              {t('common:search')}
            </a>
          </div>
        </div>
        {showFilter ? (
          <div className='card-body'>
            <div className='row'>
              <div className='col'>
                <select
                  name='team'
                  data-control='select2'
                  data-hide-search='true'
                  value={team}
                  className='form-select form-select-white'
                  onChange={(e) => {
                    setTeam(e.target.value)
                  }}
                >
                  <option value=''>{t('common:adminVolunteers.selectTeam')}</option>
                  {magicnum.volunteer.team.map((uu, uindex) => {
                    return (
                      <option value={uu} key={uindex}>
                        {t(`common:magicnum.volunteer.team.${uu}`)}
                      </option>
                    )
                  })}
                </select>
              </div>
              <div className='col'>
                <select
                  name='gender'
                  data-control='select2'
                  data-hide-search='true'
                  value={gender}
                  className='form-select form-select-white'
                  onChange={(e) => {
                    setGender(e.target.value)
                  }}
                >
                  <option value=''>{t('common:adminVolunteers.gender')}</option>
                  <option value='male'>{t('common:adminVolunteers.male')}</option>
                  <option value='female'>{t('common:adminVolunteers.female')}</option>
                </select>
              </div>

              <div className='col'>
                <input
                  className='form-control form-control-solid'
                  placeholder='Firstname'
                  onChange={(e) => {
                    setFirstname(e.target.value)
                  }}
                  value={firstname}
                  maxLength={45}
                ></input>
              </div>
              <div className='col'>
                <input
                  className='form-control form-control-solid'
                  placeholder='Lastname'
                  onChange={(e) => {
                    setLastname(e.target.value)
                  }}
                  value={lastname}
                  maxLength={45}
                ></input>
              </div>
              <div className='col'>
                <input
                  className='form-control form-control-solid'
                  placeholder='Age'
                  onChange={(e) => {
                    setAge(e.target.value)
                  }}
                  value={age}
                  maxLength={45}
                ></input>
              </div>
              <div className='col'>
                <input
                  className='form-control form-control-solid'
                  placeholder='Mail'
                  onChange={(e) => {
                    setMail(e.target.value)
                  }}
                  value={mail}
                  maxLength={45}
                ></input>
              </div>
              <div className='col'>
                <input
                  className='form-control form-control-solid'
                  placeholder='Phone'
                  onChange={(e) => {
                    setPhone(e.target.value)
                  }}
                  value={phone}
                  maxLength={45}
                ></input>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <div className={`card`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>
              {t('common:adminVolunteers.volunteers')}
            </span>
            <span className='text-muted mt-1 fw-semibold fs-7'>
              {t('common:adminVolunteers.total', {count: PTotal})}
            </span>
          </h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
            <a
              href='#'
              className='btn btn-sm btn-light-primary'
              onClick={() => {
                setItem(undefined)
                updateShowModal(true)
              }}
            >
              <KTIcon iconName='plus' className='fs-3' />
              {t('common:athlete.add')}
            </a>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <DataTable
            data={data}
            table={table}
            PTotal={PTotal}
            pageChange={onChangePage}
            PID={PID}
            loading={loading}
          />
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export {Volunteers}
