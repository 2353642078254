import React, {FC, useEffect, useRef, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import DataTable from '../../modules/CustomBootsrapTable/CustomBootsrapTable'
import CustomModal from '../../modules/CustomModal/modal'
import {AthleteCreate} from './add'
import {dateFormat, dateTimeFormat, isNullOrEmpty} from '../../../services/Utils'
import {
  AthleteListReq,
  AthleteSportListReq,
  AthleteStatusChangeReq,
  BaseRequest,
  NewsChangeStatusReq,
  magicnum,
} from '../../../services/Request'
import {
  AthleteDelete,
  AthleteList,
  AthleteListExcel,
  AthleteSportList,
  AthleteStatusChange,
  CDN_URL,
  NewsChangeStatus,
  NewsDelete,
} from '../../../services/main'
import {toast} from 'react-toastify'
import {confirmAlert} from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import {Image} from 'react-bootstrap'
import useCookie from '../../../services/useCookie'
import {TabAccCard} from './tab/tabacccard'
import {TabPrint} from './tab/tabprintSuccess'
import {TabGmobile} from './tab/tabGmobile'
import {ExportToExcel} from './ExportToExcel'
const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]

function usePrevious(value: any) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}

const Athlete: FC = () => {
  const queryString = window.location.search
  const params = new URLSearchParams(queryString)
  let nocQ = params.get('noc')
  let accrQ = params.get('accr')
  let pidQ = params.get('pid')
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [loadingExcel, setLoadingExcel] = useState(false)
  const [data, setData] = useState<any>([])
  const [exceldata, setExcelData] = useState<any>([])
  const [sportItem, setSportItem] = useState<any>(undefined)
  const [item, setItem] = useState<any>(undefined)
  const [PID, setPID] = useState<any | null>(1)
  const [PTotal, setPTotal] = useState(1)
  const [sizePerPage, setSizePerPage] = useState(20)
  const [showModal, updateShowModal] = useState(false)
  const [showModalExcel, updateShowModalExcel] = useState(false)
  const [showModalPrint, updateShowModalPrint] = useState(false)
  const [showModalPrintSuccess, updateShowModalPrintSuccess] = useState(false)
  const [showModalGmobile, updateShowModalGmobile] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [noc, setNoc] = useState<any | null>('')
  const [accr_category, setAccr_category] = useState<any | null>('')
  const [accr_sub_category, setAccr_sub_category] = useState<any | null>('')
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [status_desc, setStatus_desc] = useState('')
  const [food, setFood] = useState('')
  const [acc_id, setAcc_id] = useState('')
  const [NocID] = useCookie('NocID', null)
  const [EmployeeTypeID] = useCookie('EmployeeTypeID', null)
  const prevNoc = usePrevious(noc)
  const prevPID = usePrevious(PID)
  const prevAccrCategory = usePrevious(accr_category)
  const prevAccrSubCategory = usePrevious(accr_sub_category)
  const site = magicnum.SportCategories.filter((sitem) => sitem?.sid == data[0]?.sport)

  console.log(item)

  useEffect(() => {
    if (!isNullOrEmpty(accrQ)) {
      setAccr_category(accrQ)
    }
    if (!isNullOrEmpty(nocQ)) {
      setNoc(nocQ)
    }
    if (!isNullOrEmpty(pidQ)) {
      setPID(pidQ)
    }
  }, [accrQ, nocQ, pidQ])

  useEffect(() => {
    list()
  }, [PID])

  useEffect(() => {
    if (!isNullOrEmpty(noc) && prevNoc != noc && prevNoc != undefined) {
      list()
    }
    if (
      !isNullOrEmpty(accr_category) &&
      prevAccrCategory != accr_category &&
      prevAccrCategory != undefined
    ) {
      list()
    }
    if (
      !isNullOrEmpty(accr_sub_category) &&
      prevAccrSubCategory != accr_sub_category &&
      prevAccrSubCategory != undefined
    ) {
      list()
    }
  }, [noc, accr_category, accr_sub_category])

  const search = () => {
    if (PID > 1) {
      setPID(1)
    } else {
      list()
    }
  }

  const list = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = AthleteListReq
    req.pid = PID
    req.pSize = sizePerPage
    if (EmployeeTypeID == 'noc') {
      req.filter.noc = NocID
    } else {
      req.filter.noc = noc
    }
    req.filter.accr_category = accr_category
    req.filter.accr_sub_category = accr_sub_category
    req.filter.firstname = firstname
    req.filter.lastname = lastname
    req.filter.food = food
    req.filter.acc_id = acc_id
    req.filter.status_desc = status_desc
    AthleteList(req, onSuccess, onFailed, 'POST')
  }

  const onSuccess = (response: any) => {
    setLoading(false)
    setData(response.data)
    setPTotal(response.pagination.ptotal)
  }

  const onFailed = (error: string) => {
    setLoading(false)
    setLoadingExcel(false)
    return toast.error(error)
  }

  const onChangePage = (pid: any) => {
    if (pid >= 1) {
      setPID(pid)
      setLoading(true)
    }
  }

  const onAddSuccess = (text: string) => {
    list()
    updateShowModal(false)
    return toast.success(text)
  }

  const defaultRowFormat = (cell: any, row: any) => {
    return <>{cell}</>
  }

  const imgRowFormat = (cell: string, row: any) => {
    return (
      <Link to={`/athlete/${row.id}`}>
        <div className='text-center'>
          <img
            src={CDN_URL + cell}
            height={60}
            width={60}
            style={{borderRadius: '50%', objectFit: 'cover'}}
          />
        </div>
      </Link>
    )
  }

  const actionRowFormat = (cell: string, row: any) => {
    return (
      <div className='flex flex-col gap-2'>
        <div className='text-end flex gap-1 justify-center items-center'>
          <Link to={`/athlete/${cell}`}>
            <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
              <KTIcon iconName='switch' className='fs-3' />
            </div>
          </Link>
          {row?.status == 0 || row?.status == 3 ? (
            <div
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={() => {
                setItem(row)
                updateShowModal(true)
              }}
            >
              <KTIcon iconName='pencil' className='fs-3' />
            </div>
          ) : null}
          {row?.status == 0 ? (
            <div
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
              onClick={() => submit(row, 'deleteMain')}
            >
              <KTIcon iconName='trash' className='fs-3' />
            </div>
          ) : null}
          {/* </div>
        <div className='text-end flex gap-1 justify-center items-center'> */}
          {EmployeeTypeID == 'super' ? (
            <div
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={() => {
                updateShowModalPrint(true)
                setSportItem(row)
              }}
            >
              <KTIcon iconName='bi bi-printer-fill' className='fs-3' />
            </div>
          ) : null}
          {EmployeeTypeID == 'super' ? (
            <div
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={() => {
                updateShowModalPrintSuccess(true)
                setSportItem(row)
              }}
            >
              <KTIcon
                iconName='bi bi-person-badge-fill'
                className={`fs-3 ${row?.status_desc == 1 ? 'bg_green' : 'bg_red'}`}
              />
            </div>
          ) : null}
          {EmployeeTypeID == 'super' ? (
            <div
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={() => {
                updateShowModalGmobile(true)
                setSportItem(row)
              }}
            >
              <KTIcon
                iconName='bi bi-telephone-inbound'
                className={`fs-3 ${isNullOrEmpty(row?.gmobile) ? 'bg_red' : 'bg_green'}`}
              />
            </div>
          ) : null}
          <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
            <a
              href={`https://ulaanbaatar2023.org/certificate/${row?.accr_category}-${row?.id}`}
              target='_blank'
            >
              <KTIcon iconName='bi bi-person-bounding-box' className='fs-3' />
            </a>
          </div>
        </div>
      </div>
    )
  }

  const nameRowFormat = (cell: string, row: any) => {
    return (
      <Link to={`/athlete/${row.id}`}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <span>{row.lastname}</span>
          <span className='text-muted fw-semibold text-muted d-block'>{cell}</span>
        </div>
      </Link>
    )
  }

  const dateRowFormat = (cell: string, row: any) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <span>{dateFormat(cell)}</span>
        <span>{row.gender}</span>
        {/* <span className='text-muted fw-semibold text-muted d-block' style={{textAlign: 'center'}}>
          {row.birth_country}
        </span> */}
      </div>
    )
  }

  const statusRowFormat = (cell: any, row: any) => {
    var gg = magicnum.StatusAthlete.filter((ite) => ite.id == cell)
    return (
      <div
        className='text-center cursor-pointer'
        onClick={() => submit(row, 'changeStatus')}
        title={row?.status_desc}
      >
        <span className={`badge ${gg[0].class} cursor-pointer`}>
          {t(`common:magicnum.StatusAthlete.${cell}`)}
        </span>
      </div>
    )
  }

  const submit = (row: any, type: string) => {
    confirmAlert({
      customUI: ({onClose}) => {
        var statusAction = '',
          statusActionDesc = ''
        return (
          <div className='custom-confirm-ui'>
            <h1>{row.title}</h1>
            <p>
              {type == 'deleteMain' ? t('common:confirmDelete') : t('common:confirmChangeStatus')}
            </p>
            {type == 'changeStatus' ? (
              <div>
                <select
                  name='category'
                  data-control='select2'
                  data-hide-search='true'
                  className='form-select form-select-white'
                  onChange={(e) => {
                    statusAction = e.target.value
                  }}
                >
                  <option value=''>{t('common:choose')}</option>
                  {magicnum.StatusAthlete.map((item2, index) => {
                    return (EmployeeTypeID == 'super' && item2?.id > 1) || item2?.id < 2 ? (
                      <option value={item2.id} key={index}>
                        {t(`common:magicnum.StatusAthlete.${item2?.id}`)}
                      </option>
                    ) : null
                  })}
                </select>
                {statusAction}
                {EmployeeTypeID == 'super' ? (
                  <textarea
                    onChange={(e) => {
                      statusActionDesc = e.target.value
                    }}
                    className='form-control form-control-solid'
                    rows={3}
                    cols={30}
                    placeholder='Fix section info'
                    maxLength={200}
                    style={{marginTop: 15}}
                  ></textarea>
                ) : null}

                <></>
              </div>
            ) : null}
            <div className='buttons'>
              <button className='btn btn-active-light btn-color-muted' onClick={onClose}>
                {t('common:no')}
              </button>
              <button
                className='btn btn-primary align-self-center'
                onClick={() => {
                  type == 'deleteMain'
                    ? deleteFunc(row)
                    : changeStatus(statusAction, statusActionDesc, row)
                  onClose()
                }}
              >
                {t('common:yes')}
              </button>
            </div>
          </div>
        )
      },
    })
  }

  const deleteFunc = (row: any) => {
    AthleteDelete(row.id, BaseRequest, onDeleteSuccess, onFailed, 'DELETE')
  }

  const onDeleteSuccess = () => {
    list()
    return toast.error(t('common:successDelete'))
  }

  const changeStatus = (statusAction: any, statusActionDesc: any, row: any) => {
    var req = AthleteStatusChangeReq
    req.athlete.id = row.id
    req.athlete.status = statusAction
    req.athlete.status_desc = statusActionDesc

    AthleteStatusChange(req, onChangeStatusSuccess, onFailed, 'PUT')
  }

  const onChangeStatusSuccess = () => {
    list()
    return toast.success(t('common:successUpdate'))
  }

  const accr_categoryRowFormat = (cell: string, row: any) => {
    var ite = magicnum.accreditationCategoryNew.filter((item) => item?.id == cell)[0]
    var sport: any = [{name: ''}]
    if (!isNullOrEmpty(row.sport)) {
      sport = magicnum.SportCategories.filter((item) => (item.id = row.sport))
    }

    return (
      <div
        style={{
          backgroundColor: ite?.color,
          color: 'white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '5px',
          flexDirection: 'column',
        }}
      >
        <span className='fw-semibold d-block' style={{fontSize: '20px', color: 'white'}}>
          {ite?.id}
        </span>
        {row?.accr_category == 'Ss' ||
        row?.accr_category == 'St' ||
        row?.accr_category == 'So' ||
        row?.accr_category == 'Sl' ||
        row?.accr_category == 'S' ||
        row?.accr_category == 'W' ||
        row?.accr_category == 'OC' ? (
          <span>
            {t(`common:magicnum.accreditationSubCategoryNewLocal.${row.accr_sub_category}`)}
          </span>
        ) : (
          <span>{t(`common:magicnum.accreditationSubCategoryNew.${row.accr_sub_category}`)}</span>
        )}
        {isNullOrEmpty(row.sport) ? null : sport[0]?.sid == data[0]?.sport ? (
          <span>{sport?.name}</span>
        ) : null}
      </div>
    )
  }

  const nocRowFormat = (cell: string, row: any) => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          rowGap: 5,
        }}
      >
        <Image src={toAbsoluteUrl(`/noc-flag/${cell}.svg`)} height={16} />
        <span>{t(`common:NOC.${cell}`)}</span>
      </div>
    )
  }

  const accRowFormat = (cell: string, row: any) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          columnGap: 5,
          rowGap: 5,
        }}
      >
        {!isNullOrEmpty(cell) ? <span>{cell}</span> : <Link to={`/athlete/${row.id}`}>GET</Link>}
        {!isNullOrEmpty(row?.gmobile) ? (
          <div>
            <KTIcon iconName='bi bi-telephone-inbound' className={`fs-3 bg_green`} />
            &nbsp;<span>{row?.gmobile}</span>
          </div>
        ) : null}
      </div>
    )
  }

  const onPrintChangeSuccess = (text: string) => {
    list()
    updateShowModalPrintSuccess(false)
    return toast.success(text)
  }

  const onGmobileChangeSuccess = (text: string) => {
    list()
    updateShowModalGmobile(false)
    return toast.success(text)
  }

  const excel = () => {
    if (!loadingExcel) {
      setLoadingExcel(true)
    }
    var req = AthleteListReq
    req.pid = PID
    req.pSize = 5000
    if (EmployeeTypeID == 'noc') {
      req.filter.noc = NocID
    } else {
      req.filter.noc = noc
    }
    req.filter.accr_category = accr_category
    req.filter.accr_sub_category = accr_sub_category
    req.filter.firstname = firstname
    req.filter.lastname = lastname
    req.filter.food = food
    req.filter.acc_id = acc_id
    req.filter.status_desc = status_desc
    AthleteListExcel(req, onExcelSuccess, onFailed, 'POST')
  }

  const onExcelSuccess = (response: any) => {
    var data = response.data
    const newExcelData: Array<HTMLElement> = []
    for (let i = 0; i < data.length; i++) {
      const obj: any = {
        lastname: data[i].lastname,
        firstname: data[i].firstname,
        accr_category: `${t(`common:magicnum.accrDescriptionNew.${data[i].accr_category}`)}`,
        accr_sub_category: `${
          data[i].accr_category == 'Ss' ||
          data[i].accr_category == 'St' ||
          data[i].accr_category == 'So' ||
          data[i].accr_category == 'Sl' ||
          data[i].accr_category == 'S' ||
          data[i].accr_category == 'W' ||
          data[i].accr_category == 'OC'
            ? `${t(
                `common:magicnum.accreditationSubCategoryNewLocal.${data[i].accr_sub_category}`
              )}`
            : `${t(`common:magicnum.accreditationSubCategoryNew.${data[i].accr_sub_category}`)}`
        }`,
        noc: `${t(`common:NOC.${data[i].noc}`)}`,
      }
      newExcelData.push(obj)
    }
    setExcelData(newExcelData)
    setLoadingExcel(false)
    updateShowModalExcel(true)
  }

  var table = {
    sizePerPage: sizePerPage,
    headerKeyColumn: {
      title: 'id',
      dataField: 'id',
      dataSort: true,
      dataFormat: defaultRowFormat,
      hidden: true,
      filter: {
        type: 'TextFilter',
        placeholder: '...',
      },
    },
    headerOtherColumn: [
      {
        title: t('common:NEWS.image_link'),
        dataField: 'photo',
        dataSort: false,
        dataFormat: imgRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:athlete.name'),
        dataField: 'firstname',
        dataSort: false,
        dataFormat: nameRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:athlete.accr_category'),
        dataField: 'accr_category',
        dataSort: false,
        dataFormat: accr_categoryRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:athlete.acc_id'),
        dataField: 'acc_id',
        dataSort: false,
        dataFormat: accRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:athlete.noc'),
        dataField: 'noc',
        dataSort: false,
        dataFormat: nocRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      // {
      //   title: t('common:athlete.gender'),
      //   dataField: 'suffix',
      //   dataSort: false,
      //   dataFormat: genderSuffixRowFormat,
      //   hidden: false,
      //   filter: {
      //     type: 'TextFilter',
      //     placeholder: '...',
      //   },
      // },
      // {
      //   title: t('common:athlete.food'),
      //   dataField: 'food',
      //   dataSort: false,
      //   dataFormat: foodRowFormat,
      //   hidden: false,
      //   filter: {
      //     type: 'TextFilter',
      //     placeholder: '...',
      //   },
      // },
      {
        title: t('common:athlete.created_at'),
        dataField: 'birth',
        dataSort: false,
        dataFormat: dateRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:athlete.status'),
        dataField: 'status',
        dataSort: false,
        dataFormat: statusRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:athlete.action'),
        dataField: 'id',
        dataSort: false,
        dataFormat: actionRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
    ],
  }

  return (
    <>
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>{t('common:athlete.pagetitle')}</PageTitle>
      {showModal ? (
        <CustomModal
          visible={showModal}
          closeModal={() => updateShowModal(false)}
          className='VideoModal'
        >
          <AthleteCreate t={t} item={item} onAddSuccess={onAddSuccess} />
        </CustomModal>
      ) : null}
      {showModalPrint ? (
        <CustomModal
          visible={showModalPrint}
          closeModal={() => updateShowModalPrint(false)}
          className='accr-modal'
        >
          <TabAccCard t={t} item={sportItem} />
        </CustomModal>
      ) : null}

      {showModalPrintSuccess ? (
        <CustomModal
          visible={showModalPrintSuccess}
          closeModal={() => updateShowModalPrintSuccess(false)}
          className='accr-modal'
        >
          <TabPrint t={t} item={sportItem} onPrintChangeSuccess={onPrintChangeSuccess} />
        </CustomModal>
      ) : null}

      {showModalGmobile ? (
        <CustomModal
          visible={showModalGmobile}
          closeModal={() => updateShowModalGmobile(false)}
          className='accr-modal'
        >
          <TabGmobile t={t} item={sportItem} onGmobileChangeSuccess={onGmobileChangeSuccess} />
        </CustomModal>
      ) : null}
      {showModalExcel ? (
        <CustomModal
          visible={showModalExcel}
          closeModal={() => updateShowModalExcel(false)}
          className='excel-modal'
        >
          <ExportToExcel apiData={exceldata} fileName={'ub2023'} />
        </CustomModal>
      ) : null}
      <div className={`card mb-5`}>
        <div className='card-header border-1' style={{height: '60px'}}>
          <div
            className='filter flex items-center justify-center gap-0 cursor-pointer'
            onClick={() => setShowFilter(!showFilter)}
          >
            <KTIcon iconName='filter' className='fs-3' />
            <span className='text'>{t('common:filter')}</span>
          </div>
          <div className='card-toolbar'>
            <button
              className='btn btn-sm btn-light-info'
              disabled={loadingExcel}
              onClick={() => excel()}
              style={{marginRight: 10}}
            >
              <KTIcon iconName='bi bi-file-earmark-excel' className='fs-3' />{' '}
              {loadingExcel ? 'Loading...' : 'export'}
            </button>
            <button className='btn btn-sm btn-light-primary' onClick={() => search()}>
              {t('common:search')}
            </button>
          </div>
        </div>
        {showFilter ? (
          <div className='card-body'>
            <div className='row'>
              <div className='col'>
                <select
                  name='noc'
                  data-control='select2'
                  data-hide-search='true'
                  value={noc}
                  className='form-select form-select-white'
                  onChange={(e) => {
                    setNoc(e.target.value)
                  }}
                >
                  <option value=''>{t('common:NOC.selectnoc')}</option>
                  {magicnum.NOC.map((item2, index) => {
                    return (EmployeeTypeID == 'noc' && item2?.id == NocID) ||
                      EmployeeTypeID == 'super' ? (
                      <option value={item2.id} key={index}>
                        {t(`common:NOC.${item2.id}`)}
                      </option>
                    ) : null
                  })}
                </select>
              </div>
              <div className='col'>
                <select
                  name='accr_category'
                  data-control='select2'
                  data-hide-search='true'
                  value={accr_category}
                  className='form-select form-select-white'
                  onChange={(e) => {
                    setAccr_sub_category('')
                    setAccr_category(e.target.value)
                  }}
                >
                  <option value=''>{t('common:athlete.selectAccreditationCategory')}</option>
                  {magicnum.accreditationCategoryNew.map((item2, index) => {
                    return EmployeeTypeID == 'noc' &&
                      (item2?.parent == 'S' || item2?.parent == 'OC') ? null : (
                      <option value={item2.id} key={index}>
                        {item2.id} - {t(`common:magicnum.accrDescriptionNew.${item2?.id}`)}
                      </option>
                    )
                  })}
                </select>
              </div>
              <div className='col'>
                <select
                  name='accr_sub_category'
                  data-control='select2'
                  data-hide-search='true'
                  value={accr_sub_category}
                  className='form-select form-select-white'
                  onChange={(e) => {
                    setAccr_sub_category(e.target.value)
                  }}
                >
                  <option value=''>{t('common:athlete.selectAccreditationSubCategory')}</option>
                  {magicnum.accreditationSubCategoryNew.map((item2, index) => {
                    return accr_category == item2.parent ? (
                      <option value={item2.id} key={index}>
                        {t(`common:magicnum.accreditationSubCategoryNew.${item2.id}`)}
                      </option>
                    ) : null
                  })}
                </select>
              </div>
              <div className='col'>
                <select
                  name='status_desc'
                  data-control='select2'
                  data-hide-search='true'
                  value={status_desc}
                  className='form-select form-select-white'
                  onChange={(e) => {
                    setStatus_desc(e.target.value)
                  }}
                >
                  <option value=''>Choose print status</option>
                  <option value={1}>Yes</option>
                  <option value={0}>No</option>
                </select>
              </div>
            </div>
            <div className='row' style={{marginTop: 10}}>
              <div className='col'>
                <input
                  className='form-control form-control-solid'
                  placeholder='Accreditation'
                  onChange={(e) => {
                    setAcc_id(e.target.value)
                  }}
                  value={acc_id}
                ></input>
              </div>
              <div className='col'>
                <input
                  className='form-control form-control-solid'
                  placeholder='Firstname'
                  onChange={(e) => {
                    setFirstname(e.target.value)
                  }}
                  value={firstname}
                  maxLength={45}
                ></input>
              </div>
              <div className='col'>
                <input
                  className='form-control form-control-solid'
                  placeholder='Lastname'
                  onChange={(e) => {
                    setLastname(e.target.value)
                  }}
                  value={lastname}
                  maxLength={45}
                ></input>
              </div>
              <div className='col'>
                <select
                  name='food'
                  data-control='select2'
                  data-hide-search='true'
                  value={food}
                  className='form-select form-select-white'
                  onChange={(e) => {
                    setFood(e.target.value)
                  }}
                >
                  <option value=''>{t('common:athlete.selectfood')}</option>
                  {magicnum.food.map((item2, index) => {
                    return (
                      <option value={item2} key={index}>
                        {item2}
                      </option>
                    )
                  })}
                </select>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <div className={`card`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>{t('common:athlete.list')}</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>
              {t('common:athlete.total', {count: PTotal})}
            </span>
          </h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
            <a
              href='#'
              className='btn btn-sm btn-light-primary'
              onClick={() => {
                setItem(undefined)
                updateShowModal(true)
              }}
            >
              <KTIcon iconName='plus' className='fs-3' />
              {t('common:athlete.add')}
            </a>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <DataTable
            data={data}
            table={table}
            PTotal={PTotal}
            pageChange={onChangePage}
            PID={PID}
            loading={loading}
          />
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export {Athlete}
