import React, {FC, useState} from 'react'
import {isNullOrEmpty} from '../../../services/Utils'
import {
  AthleteAdd,
  AthleteChangeImage,
  AthleteUpdate,
  NewsAdd,
  NewsUpdate,
} from '../../../services/main'
import {toast} from 'react-toastify'
import {
  AthleteAddReq,
  AthleteChangeImageReq,
  AthleteUpdateReq,
  NewsAddReq,
  NewsUpdateReq,
  magicnum,
} from '../../../services/Request'
import useCookie from '../../../services/useCookie'
import {KTIcon} from '../../../_metronic/helpers'
import {KTTooltip} from '../../../_metronic/helpers/components/KTTooltip'
interface IProps {
  t: any
  item: any
  onAddSuccess: any
}
const AthleteCreate: FC<IProps> = ({t, item, onAddSuccess}) => {
  const [title, setTitle] = useState(isNullOrEmpty(item) ? '' : item.title)
  const [desc, setDesc] = useState(isNullOrEmpty(item) ? '' : item.description)
  const [imageBase64, setImageBase64] = React.useState<any>('')
  const [lang, setLang] = useState(isNullOrEmpty(item) ? 'mn' : item.lang)
  const [category, setCategory] = useState(isNullOrEmpty(item) ? '' : item.category)
  const [count, setCount] = useState(0)
  const [loading, setLaoding] = useState(false)
  const [loadingImageData, setLaodingImageData] = useState(false)
  const [noc, setNoc] = useState(isNullOrEmpty(item) ? '' : item.noc)
  const [accr_category, setAccr_category] = useState(isNullOrEmpty(item) ? '' : item.accr_category)
  const [accr_sub_category, setAccr_sub_category] = useState(
    isNullOrEmpty(item) ? '' : item.accr_sub_category
  )
  const [suffix, setSuffix] = useState(isNullOrEmpty(item) ? '' : item.suffix)
  const [firstname, setFirstname] = useState(isNullOrEmpty(item) ? '' : item.firstname)
  const [lastname, setLastname] = useState(isNullOrEmpty(item) ? '' : item.lastname)
  const [gender, setGender] = useState(isNullOrEmpty(item) ? '' : item.gender)
  const [birth, setBirth] = useState(isNullOrEmpty(item) ? '' : item.birth?.substr(0, 10))
  const [birth_country, setBirth_country] = useState(isNullOrEmpty(item) ? '' : item.birth_country)
  const [address, setAddress] = useState(isNullOrEmpty(item) ? '' : item.address)
  const [hobby, setHobby] = useState(isNullOrEmpty(item) ? '' : item.hobby)
  const [add_info, setAdd_info] = useState(isNullOrEmpty(item) ? '' : item.add_info)
  const [food, setFood] = useState(isNullOrEmpty(item) ? '' : item.food)
  const [validate, setValidate] = useState<any>([])
  const [EmployeeID] = useCookie('EmployeeID', null)
  const [NocID] = useCookie('NocID', null)
  const [EmployeeTypeID] = useCookie('EmployeeTypeID', null)
  const [height, setHeight] = useState(isNullOrEmpty(item) ? '' : item.height)
  const [weight, setWeight] = useState(isNullOrEmpty(item) ? '' : item.weight)
  const [blood_type, setBlood_type] = useState(isNullOrEmpty(item) ? '' : item.blood_type)
  const [sportCategory, setSportCategory] = useState(isNullOrEmpty(item) ? '' : item.sport)
  const zite = magicnum.accreditationSubCategoryNew.filter(
    (zitem) => zitem?.main == item?.accr_category
  )
  const addDays = (date: any, days: any) => {
    // var result = new Date(date)
    // result.setDate(result.getDate() + days)
    // return result.toISOString().substring(0, 10)
    return date.substring(0, 10)
  }

  const save = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      var torsonodor = addDays(birth, 1)
      setLaoding(true)
      var req = AthleteAddReq
      req.athlete.photo = imageBase64
      req.athlete.accr_category = accr_category
      req.athlete.accr_sub_category = accr_sub_category
      req.athlete.suffix = suffix
      req.athlete.firstname = firstname
      req.athlete.lastname = lastname
      req.athlete.gender = gender
      req.athlete.birth = torsonodor
      req.athlete.birth_country = birth_country
      req.athlete.address = address
      req.athlete.hobby = hobby
      req.athlete.add_info = add_info
      req.athlete.food = food
      req.athlete.status = magicnum.Status.InActive
      req.athlete.created_by = EmployeeID
      req.athlete.noc = noc
      req.athlete.blood_type = blood_type
      req.athlete.height = height
      req.athlete.weight = weight
      req.athlete.sport = sportCategory
      //console.log(req)
      AthleteAdd(req, onSuccess, onFailed, 'POST')
    }
  }

  const handleSelectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLaodingImageData(true)
    if (event.target.files) {
      var file = event.target.files[0]
      var reader = new FileReader()

      reader.onload = function (event) {
        setImageBase64(event?.target?.result)
        setLaodingImageData(false)
      }

      reader.readAsDataURL(file)
    }
  }

  const onSuccess = () => {
    setLaoding(false)
    onAddSuccess(t('common:successCreate'))
  }

  const onFailed = (err: string) => {
    setLaoding(false)
    return toast.error(err)
  }

  const update = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLaoding(true)
      var req = AthleteUpdateReq
      req.athlete.accr_category = accr_category
      req.athlete.accr_sub_category = accr_sub_category
      req.athlete.suffix = suffix
      req.athlete.firstname = firstname
      req.athlete.lastname = lastname
      req.athlete.gender = gender
      req.athlete.birth = birth
      req.athlete.birth_country = birth_country
      req.athlete.address = address
      req.athlete.hobby = hobby
      req.athlete.add_info = add_info
      req.athlete.food = food
      req.athlete.status = magicnum.Status.InActive
      req.athlete.noc = noc
      req.athlete.blood_type = blood_type
      req.athlete.height = height
      req.athlete.weight = weight
      req.athlete.sport = sportCategory
      req.athlete.id = item?.id

      AthleteUpdate(req, onUpdateSuccess, onFailed, 'PUT')
    }
  }

  const onUpdateSuccess = () => {
    setLaoding(false)
    onAddSuccess(t('common:successUpdate'))
  }

  const validateFunc = () => {
    var res = {retType: 0}
    if (isNullOrEmpty(imageBase64) && isNullOrEmpty(item)) {
      res.retType = 1
      validate['image'] = true
      //return toast.error('common.athlete.select_image')
    }

    if (isNullOrEmpty(noc)) {
      res.retType = 1
      validate['noc'] = true
    }

    // if (isNullOrEmpty(blood_type)) {
    //   res.retType = 1
    //   validate['blood_type'] = true
    // }

    if (isNullOrEmpty(accr_category)) {
      res.retType = 1
      validate['accr_category'] = true
    }

    if (isNullOrEmpty(accr_sub_category)) {
      res.retType = 1
      validate['accr_sub_category'] = true
    }

    if (isNullOrEmpty(suffix)) {
      res.retType = 1
      validate['suffix'] = true
    }

    if (isNullOrEmpty(firstname)) {
      res.retType = 1
      validate['firstname'] = true
    }

    if (isNullOrEmpty(lastname)) {
      res.retType = 1
      validate['lastname'] = true
    }

    if (isNullOrEmpty(gender)) {
      res.retType = 1
      validate['gender'] = true
    }

    if (isNullOrEmpty(birth)) {
      res.retType = 1
      validate['birth'] = true
    }

    if (isNullOrEmpty(birth_country)) {
      res.retType = 1
      validate['birth_country'] = true
    }

    if (isNullOrEmpty(address)) {
      res.retType = 1
      validate['address'] = true
    }

    if (
      isNullOrEmpty(sportCategory) &&
      ((accr_category == 'Ao' && accr_sub_category == 'A-3') || accr_category == 'D')
    ) {
      res.retType = 1
      validate['sportCategory'] = true
    }

    // if (isNullOrEmpty(food)) {
    //   res.retType = 1
    //   validate['food'] = true
    // }

    setValidate(validate)
    setCount(count + 1)
    return res
  }

  const validateChange = (id: string, e: any) => {
    var val = ''
    e.target === undefined ? (val = e) : (val = e.target.value)
    if (val !== '') {
      validate[id] = false
    } else {
      validate[id] = true
    }
    setValidate(validate)
    setCount(count + 1)
  }

  const changeimage = () => {
    if (!isNullOrEmpty(imageBase64) && !isNullOrEmpty(item)) {
      setLaoding(true)
      var req = AthleteChangeImageReq
      req.athlete.photo = imageBase64
      req.athlete.old_image_link = item?.photo
      req.athlete.id = item?.id
      AthleteChangeImage(req, onChangeImageSuccess, onFailed, 'PUT')
    } else {
      return toast.warning(t('common:valid'))
    }
  }

  const onChangeImageSuccess = () => {
    setLaoding(false)
    onAddSuccess(t('common:successonChangeImage'))
  }

  return (
    <div className='athlete-add'>
      <div className='mx-5 mx-xl-10 pb-10 w-800px'>
        <span style={{visibility: 'hidden'}}>{count}</span>
        <div className='text-center mb-13'>
          <h1 className='mb-3'>{t('common:athlete.basicinfo')}</h1>
        </div>
        <div className='row'>
          <div className='col-lg-2 fv-row mb-8'>
            <label htmlFor='files' className='btn btn-light-primary fw-bolder'>
              {loadingImageData ? t('common:loading') : t('common:NEWS.image_link')}
            </label>
            {validate['image'] ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{t('common:validSelect')}</div>
              </div>
            ) : null}
            <KTTooltip
              iconName='information-5'
              className='fs-3'
              required={false}
              tooltip='The photo must be taken within the last 6 months and in color.
                - The image ratio is 3 x 4. Image format should be .JPGor .JPEG and size of the image should be between 100Kb and 300Kb.
                - The background of the photo should be either white or plain light blue. The boundary between a person and the background should be identifiable.
                - Head coverings, such as caps, hats, scarves and hair band, face mask and dark-tinted glasses are not permitted in a photo except for religious or medical reasons.
                - The applicant should submit a photo with face square on to the camera rather than rotated or tilted with both eyes open and clearly visible.
                - The photographs should be in sharp focus and clear and un-retouched with no smears or creases.'
            />
          </div>

          <input
            type='file'
            name='image'
            id='files'
            style={{display: 'none'}}
            accept='.pdf,.jpg,.png,.jpeg'
            onChange={handleSelectFile}
          />
          {isNullOrEmpty(imageBase64) ? null : (
            <>
              <div className='col-lg-4 fv-row d-flex flex-center'>
                <img src={imageBase64} width={120} height={160} />
              </div>
            </>
          )}
          {!isNullOrEmpty(item) ? (
            <div className='col-lg-4 fv-row mb-8'>
              <button
                className='btn btn-primary align-self-center'
                onClick={changeimage}
                disabled={loading}
              >
                {loading ? t('common:loading') : t('common:changeimage')}
              </button>
            </div>
          ) : null}
        </div>
        <div className='separator mt-8 d-flex flex-center mb-8'>
          <span className='text-uppercase bg-body fs-7 fw-bold text-muted px-3'>
            {t('common:athlete.info')}
          </span>
        </div>
        <div className='row'>
          <div className='col-lg-4 fv-row mb-8'>
            <select
              name='noc'
              data-control='select2'
              data-hide-search='true'
              value={noc}
              className='form-select form-select-white'
              onChange={(e) => {
                setNoc(e.target.value)
                validateChange('noc', e)
              }}
            >
              <option value=''>{t('common:NOC.selectnoc')}</option>
              {magicnum.NOC.map((item2, index) => {
                return (EmployeeTypeID == 'noc' && item2?.id == NocID) ||
                  EmployeeTypeID == 'super' ? (
                  <option value={item2.id} key={index}>
                    {t(`common:NOC.${item2.id}`)}
                  </option>
                ) : null
              })}
            </select>
            {validate['noc'] ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{t('common:validSelect')}</div>
              </div>
            ) : null}
          </div>
          <div className='col-lg-4 fv-row mb-8'>
            <select
              name='accr_category'
              data-control='select2'
              data-hide-search='true'
              value={accr_category}
              className='form-select form-select-white'
              onChange={(e) => {
                setAccr_category(e.target.value)
                validateChange('accr_category', e)
              }}
            >
              <option value=''>{t('common:athlete.selectAccreditationCategory')}</option>
              {magicnum.accreditationCategoryNew.map((item2, index) => {
                return (EmployeeTypeID == 'super' &&
                  (item2.parent == 'S' || item2.parent == 'OC')) ||
                  (item2.parent !== 'S' && item2.parent !== 'OC') ? (
                  <option value={item2.id} key={index}>
                    {item2.id} - {t(`common:magicnum.accrDescriptionNew.${item2?.id}`)}
                  </option>
                ) : null
              })}
            </select>
            {validate['accr_category'] ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{t('common:validSelect')}</div>
              </div>
            ) : null}
          </div>
          <div className='col-lg-4 fv-row mb-8'>
            {accr_category == 'Ss' ||
            accr_category == 'St' ||
            accr_category == 'So' ||
            accr_category == 'Sl' ||
            accr_category == 'Ss' ||
            accr_category == 'S' ||
            accr_category == 'W' ||
            accr_category == 'OC' ? (
              <>
                <select
                  name='accr_sub_category'
                  data-control='select2'
                  data-hide-search='true'
                  value={accr_sub_category}
                  className='form-select form-select-white'
                  onChange={(e) => {
                    setAccr_sub_category(e.target.value)
                    validateChange('accr_sub_category', e)
                  }}
                >
                  <option value=''>{t('common:athlete.selectAccreditationSubCategory')}</option>
                  {magicnum.accreditationSubCategoryNew.map((item2, index) => {
                    return accr_category == item2.parent
                      ? magicnum.accreditationSubCategoryNewLocal.map((litem, lindex) => {
                          return (
                            <option value={litem} key={lindex}>
                              {t(`common:magicnum.accreditationSubCategoryNewLocal.${litem}`)}
                            </option>
                          )
                        })
                      : null
                  })}
                </select>
                {validate['accr_sub_category'] ? (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{t('common:validSelect')}</div>
                  </div>
                ) : null}
              </>
            ) : (
              <>
                <select
                  name='accr_sub_category'
                  data-control='select2'
                  data-hide-search='true'
                  value={accr_sub_category}
                  className='form-select form-select-white'
                  onChange={(e) => {
                    setAccr_sub_category(e.target.value)
                    validateChange('accr_sub_category', e)
                  }}
                >
                  <option value=''>{t('common:athlete.selectAccreditationSubCategory')}</option>
                  {magicnum.accreditationSubCategoryNew.map((item2, index) => {
                    return accr_category == item2.parent ? (
                      <option value={item2.id} key={index}>
                        {t(`common:magicnum.accreditationSubCategoryNew.${item2.id}`)}
                      </option>
                    ) : null
                  })}
                </select>
                {validate['accr_sub_category'] ? (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{t('common:validSelect')}</div>
                  </div>
                ) : null}
              </>
            )}
          </div>
          {(accr_category == 'Ao' && accr_sub_category == 'A-3') || accr_category == 'D' ? (
            <div className='col-lg-4 fv-row mb-8'>
              <select
                name='category'
                data-control='select2'
                data-hide-search='true'
                value={sportCategory}
                className='form-select form-select-white'
                onChange={(e) => {
                  setSportCategory(e.target.value)
                  validateChange('sportCategory', e)
                }}
              >
                <option value=''>{t('common:magicnum.SportCategories.category')}</option>
                {magicnum.SportCategories.map((itemm2, index) => {
                  return (
                    <option value={itemm2.sid} key={index}>
                      {itemm2?.name} - {t(`common:magicnum.SportCategories.${itemm2?.sid}`)}
                    </option>
                  )
                })}
              </select>
              {validate['sportCategory'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validSelect')}</div>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>

        <div className='row'>
          <div className='col-lg-4 fv-row mb-8' style={{position: 'relative'}}>
            <select
              name='suffix'
              data-control='select2'
              data-hide-search='true'
              value={suffix}
              className='form-select form-select-white'
              onChange={(e) => {
                setSuffix(e.target.value)
                validateChange('suffix', e)
              }}
            >
              <option value=''>{t('common:athlete.selectsuffix')}</option>
              {magicnum.suffix.map((item2, index) => {
                return (
                  <option value={item2} key={index}>
                    {item2}
                  </option>
                )
              })}
            </select>
            {validate['suffix'] ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{t('common:validSelect')}</div>
              </div>
            ) : null}
            {/* <KTTooltip
              iconName='information-5'
              className='fs-3'
              required={false}
              tooltip='Dr, Miss, Mr, Mrs, Ms, Prof'
            /> */}
            {/* <KTTooltip iconName='required' className='fs-3' required={true} tooltip=""/> */}
          </div>
          <div className='col-lg-4 fv-row mb-8'>
            <input
              className='form-control form-control-solid'
              placeholder={t('common:athlete.lastname')}
              onChange={(e) => {
                setLastname(e.target.value)
                validateChange('lastname', e)
              }}
              value={lastname}
              maxLength={45}
            ></input>
            {validate['lastname'] ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{t('common:validInsert')}</div>
              </div>
            ) : null}
            <KTTooltip
              iconName='information-5'
              className='fs-3'
              required={false}
              tooltip='Name of applicant should be entered using Latin letters and must correspond with  the names shown on identification document.'
            />
          </div>
          <div className='col-lg-4 fv-row mb-8'>
            <input
              className='form-control form-control-solid'
              placeholder={t('common:athlete.firstname')}
              onChange={(e) => {
                setFirstname(e.target.value)
                validateChange('firstname', e)
              }}
              value={firstname}
              maxLength={45}
            ></input>
            {validate['firstname'] ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{t('common:validInsert')}</div>
              </div>
            ) : null}
            <KTTooltip
              iconName='information-5'
              className='fs-3'
              required={false}
              tooltip='Name of applicant should be entered using Latin letters and must correspond with  the names shown on identification document.'
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-lg-4 fv-row mb-8'>
            <select
              name='gender'
              data-control='select2'
              data-hide-search='true'
              value={gender}
              className='form-select form-select-white'
              onChange={(e) => {
                setGender(e.target.value)
                validateChange('gender', e)
              }}
            >
              <option value=''>{t('common:athlete.selectgender')}</option>
              <option value='male'>{t('common:athlete.male')}</option>
              <option value='female'>{t('common:athlete.female')}</option>
            </select>
            {validate['gender'] ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{t('common:validSelect')}</div>
              </div>
            ) : null}
            {/* <KTTooltip
              iconName='information-5'
              className='fs-3'
              required={false}
              tooltip='Please choose in the drop-down (Male/Female)'
            /> */}
          </div>
          <div className='col-lg-4 fv-row mb-8'>
            <select
              name='food'
              data-control='select2'
              data-hide-search='true'
              value={food}
              className='form-select form-select-white'
              onChange={(e) => {
                setFood(e.target.value)
                validateChange('food', e)
              }}
            >
              <option value=''>{t('common:athlete.selectfood')}</option>
              {magicnum.food.map((item2, index) => {
                return (
                  <option value={item2} key={index}>
                    {item2}
                  </option>
                )
              })}
            </select>
            {validate['food'] ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{t('common:validSelect')}</div>
              </div>
            ) : null}
          </div>
          <div className='col-lg-4 fv-row mb-8'>
            <input
              className='form-control form-control-solid'
              placeholder={t('common:athlete.birth')}
              onChange={(e) => {
                setBirth(e.target.value)
                validateChange('birth', e)
              }}
              value={birth}
              type='date'
            ></input>
            {validate['birth'] ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{t('common:validInsert')}</div>
              </div>
            ) : null}
            <KTTooltip
              iconName='information-5'
              className='fs-3'
              required={false}
              tooltip='Applicant’s date 
              of birth must be consistent 
              with that shown on the ID document. 
              Applicants are able to select the date and year 
              from the calendar function in the Online 
              Application Form.'
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-lg-4 fv-row mb-8'>
            <select
              name='blood_type'
              data-control='select2'
              data-hide-search='true'
              value={blood_type}
              className='form-select form-select-white'
              onChange={(e) => {
                setBlood_type(e.target.value)
                validateChange('blood_type', e)
              }}
            >
              <option value=''>{t('common:athlete.selectBloodType')}</option>
              {magicnum.bloodType.map((item2, index) => {
                return (
                  <option value={item2} key={index}>
                    {item2}
                  </option>
                )
              })}
            </select>
            {validate['blood_type'] ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{t('common:validSelect')}</div>
              </div>
            ) : null}
          </div>
          {accr_category == 'Aa' ? (
            <div className='col-lg-4 fv-row mb-8'>
              <input
                className='form-control form-control-solid'
                placeholder={t('common:athlete.height')}
                onChange={(e) => {
                  setHeight(e.target.value)
                  validateChange('height', e)
                }}
                value={height}
                type='number'
                maxLength={100}
              ></input>
            </div>
          ) : null}
          {accr_category == 'Aa' ? (
            <div className='col-lg-4 fv-row mb-8'>
              <input
                className='form-control form-control-solid'
                placeholder={t('common:athlete.weight')}
                onChange={(e) => {
                  setWeight(e.target.value)
                  validateChange('weight', e)
                }}
                value={weight}
                maxLength={3}
                type='number'
              ></input>
            </div>
          ) : null}
        </div>

        <div className='mb-8'>
          <input
            className='form-control form-control-solid'
            placeholder={t('common:athlete.birth_country')}
            onChange={(e) => {
              setBirth_country(e.target.value)
              validateChange('birth_country', e)
            }}
            value={birth_country}
            maxLength={100}
          ></input>
          {validate['birth_country'] ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{t('common:validInsert')}</div>
            </div>
          ) : null}
          <KTTooltip
            iconName='information-5'
            className='fs-3'
            required={false}
            tooltip='Applicant’s country of birth should be entered. e.g.) Mongolia'
          />
        </div>
        <div className='mb-8'>
          <textarea
            className='form-control form-control-solid'
            placeholder={t('common:athlete.address')}
            onChange={(e) => {
              setAddress(e.target.value)
              validateChange('address', e)
            }}
            value={address}
            rows={3}
            cols={20}
            maxLength={250}
          ></textarea>
          {validate['address'] ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{t('common:validInsert')}</div>
            </div>
          ) : null}
        </div>
        <div className='mb-8'>
          <textarea
            className='form-control form-control-solid'
            placeholder={t('common:athlete.hobby')}
            onChange={(e) => {
              setHobby(e.target.value)
              validateChange('hobby', e)
            }}
            value={hobby}
            rows={3}
            cols={20}
            maxLength={250}
          ></textarea>
        </div>
        <div className='mb-8'>
          <textarea
            className='form-control form-control-solid'
            placeholder={t('common:athlete.add_info')}
            onChange={(e) => {
              setAdd_info(e.target.value)
              validateChange('add_info', e)
            }}
            value={add_info}
            rows={3}
            cols={20}
            maxLength={250}
          ></textarea>
        </div>

        <div className='pt-10' style={{textAlign: 'right'}}>
          <button
            className='btn btn-primary align-self-center'
            onClick={isNullOrEmpty(item) ? save : update}
            disabled={loading}
          >
            {loading ? t('common:loading') : t('common:save')}
          </button>
        </div>
      </div>
    </div>
  )
}

export {AthleteCreate}
