import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
// import {
//   ListsWidget2,
//   ListsWidget3,
//   ListsWidget4,
//   ListsWidget6,
//   ListsWidget9,
//   MixedWidget3,
//   MixedWidget8,
//   StatisticsWidget4,
//   TablesWidget5,
//   TablesWidget9,
// } from '../../../_metronic/partials/widgets'
import {useTranslation} from 'react-i18next'
// import {ListsWidget10} from '../../../_metronic/partials/widgets/lists/ListsWidget10'
import {
  BaseRequest,
  DashboardAthleteReq,
  DashboardSportsReq,
  DashboardVolunteerReq,
  magicnum,
} from '../../../services/Request'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {toast} from 'react-toastify'
import {
  DashboardAthlete,
  DashboardFlight,
  DashboardSports,
  DashboardVolunteer,
} from '../../../services/main'
import useCookie from '../../../services/useCookie'
import {Link} from 'react-router-dom'
import CountUp from 'react-countup'
import {dateAgo} from '../../../services/Utils'

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]

const DashboardPage = () => {
  useEffect(() => {
    // We have to show toolbar only for dashboard page
    document.getElementById('kt_layout_toolbar')?.classList.remove('d-none')
    return () => {
      document.getElementById('kt_layout_toolbar')?.classList.add('d-none')
    }
  }, [])

  return <></>
}

const DashboardWrapper = () => {
  const intl = useIntl()
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [noc, setNoc] = useState('')
  const [acc_types, setAccTypes] = useState([])
  const [nocs, setNocs] = useState([])
  const [vdata, setVData] = useState<any>([])
  const [totalAthlete, setTotalAthlete] = useState(0)
  const [NocID] = useCookie('NocID', null)
  const [EmployeeTypeID] = useCookie('EmployeeTypeID', null)
  const [count, setCount] = useState(0)
  const [volunteerTotal, setVolunteerTotal] = useState(0)
  const [sport, setSport] = useState([])
  const [ArrDay, setArrDay] = useState([])
  const [DepDay, setDepDay] = useState([])
  const [FlightNo, setFlightNo] = useState([])

  useEffect(() => {
    if (EmployeeTypeID == 'super') {
      volunteer()
    }
  }, [])

  useEffect(() => {
    athlete()
    sports()
    flight()
  }, [noc])

  const athlete = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = DashboardAthleteReq
    if (EmployeeTypeID == 'noc') {
      req.filter.noc = NocID
    } else {
      req.filter.noc = noc
    }
    DashboardAthlete(req, onSuccess, onFailed, 'POST')
  }

  const sports = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = DashboardSportsReq
    if (EmployeeTypeID == 'noc') {
      req.filter.noc = NocID
    } else {
      req.filter.noc = noc
    }
    DashboardSports(req, onSuccessSports, onFailed, 'POST')
  }

  const volunteer = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = DashboardVolunteerReq
    req.filter.gender = ''
    DashboardVolunteer(req, onSuccessVolunteer, onFailed, 'POST')
  }

  const onSuccessVolunteer = (response: any) => {
    setVData(response.nocs)
    setLoading(false)
  }

  const onSuccess = (response: any) => {
    var sum = 0
    response?.nocs?.map((uu: any) => {
      sum += uu.niit
    })
    setTotalAthlete(sum)
    setNocs(response.nocs)
    setAccTypes(response.acc_types)
    setLoading(false)
  }

  const onSuccessSports = (response: any) => {
    setSport(response.sports)
    setLoading(false)
  }

  const flight = () => {
    var req = BaseRequest
    DashboardFlight(req, onSuccessFlight, onFailed, 'POST')
  }

  const onSuccessFlight = (response: any) => {
    console.log(response)
    setArrDay(response.ArrDay)
    setDepDay(response.DeppDay)
    setFlightNo(response.FlightNo)
  }

  const onFailed = (error: string) => {
    setLoading(false)
    return toast.error(error)
  }

  const getCountVolunteer = (team: any) => {
    var too = 0
    var niit = 0
    vdata?.map((ite: any) => {
      if (ite.team == team) {
        too = ite.niit
      }
      niit += ite?.niit
    })
    if (volunteerTotal == 0 && niit > 0) {
      setVolunteerTotal(niit)
    }

    return too
  }

  const getCountNoc = (nocNO: any) => {
    var too = 0
    nocs.map((ite: any) => {
      if (ite.noc == nocNO) {
        too = ite.niit
      }
    })
    return too
  }

  const getCountSport = (category: any) => {
    var too = 0
    sport.map((ite: any) => {
      if (ite.category == category) {
        too = ite.niit
      }
    })
    return too
  }

  const getCountAcc = (nocNO: any) => {
    var too = 0
    acc_types.map((ite: any) => {
      if (ite.accr_category == nocNO) {
        too = ite.niit
      }
    })
    return too
  }

  return (
    <>
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>
        {intl.formatMessage({id: 'MENU.DASHBOARD'})}
      </PageTitle>
      <div className='flex flex-col gap-8'>
        {/* <img src='./image/reg-sys5.jpg' style={{width: '100%'}} /> */}
        <div className='flex flex-col'>
          <div className='flex flex-col'>
            <div className='flex flex-row justify-between' style={{padding: '0px 5px'}}>
              <span className='text-dark' style={{fontSize: 25, fontWeight: 600}}>
                {t('common:dregisteration').toUpperCase()}
              </span>
              <CountUp start={0} end={totalAthlete + volunteerTotal} delay={0}>
                {({countUpRef}) => (
                  <div style={{fontSize: 18, fontWeight: 500}}>
                    Total : <span className='text-dark' ref={countUpRef} />
                  </div>
                )}
              </CountUp>
            </div>
            <div className='linear-line'></div>
          </div>
          <div className='card-toolbar'>
            <div>
              <select
                className='form-select form-select-solid'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                defaultValue={'1'}
                onChange={(e) => {
                  setNoc(e.target.value)
                }}
                value={noc}
                style={{
                  background: '#ffffff',
                }}
              >
                <option value=''>{t('common:NOC.allnoc')}</option>
                {magicnum.NOC.map((item2, index) => {
                  return (EmployeeTypeID == 'noc' && item2?.id == NocID) ||
                    EmployeeTypeID == 'super' ? (
                    <option value={item2.id} key={index}>
                      {t(`common:NOC.${item2.id}`)}
                    </option>
                  ) : null
                })}
              </select>
            </div>
          </div>
        </div>

        <div className='flex flex-col' style={{rowGap: 60}}>
          <div className='row-xxl-4'>
            <div className='card' style={{background: 'none', boxShadow: 'none'}}>
              <div className='card-body' style={{padding: '0px'}}>
                <div className='d-flex flex-col gap-2 justify-center items-center'>
                  <div
                    className='symbol symbol-60px symbol-2by3 flex-shrink-0 me-4 flex flex-wrap justify-center items-center'
                    style={{columnGap: 15, rowGap: 15}}
                  >
                    {magicnum.NOC.map((item2, index) => {
                      return (EmployeeTypeID == 'noc' && item2?.id == NocID) ||
                        EmployeeTypeID == 'super' ? (
                        <div
                          className={`dNoc-card flex flex-col gap-2 items-center text-center justify-center ${
                            item2?.interest ? '' : 'deactive'
                          }`}
                          key={index}
                        >
                          <Link
                            to={`/registeration?noc=${item2.id}`}
                            className='dashboard-accr-link'
                          >
                            <div className='flag-container'>
                              <img
                                src={toAbsoluteUrl(`/noc-flag/${item2?.id}.svg`)}
                                style={{width: '60px'}}
                                alt=''
                              />
                              <span style={{color: 'black'}}> {item2?.code}</span>
                            </div>
                            {/* {getCountNoc(item2?.id)} */}
                            <CountUp start={0} end={getCountNoc(item2?.id)} delay={0}>
                              {({countUpRef}) => (
                                <div>
                                  <span className='dcount-text text-dark' ref={countUpRef} />
                                </div>
                              )}
                            </CountUp>
                          </Link>
                        </div>
                      ) : null
                    })}

                    {/* <div
                      className=''
                      style={{
                        background: `url('/sports/card-image/1.png')`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        width: '150px',
                      }}
                    ></div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* end::Col */}

          {/* begin::Col Accreditation*/}
          <div className='row-xxl-4'>
            <div className='card'>
              <div className='dtitle'>Accreditation</div>
              <div
                className='card-body flex justify-center items-center flex-wrap'
                style={{gap: 22, padding: '40px 10px'}}
              >
                {magicnum.accreditationCategoryNew.map((item, index) => {
                  return (
                    <div className='d-flex flex-col gap-2 justify-center items-center' key={index}>
                      <div className='symbol symbol-60px symbol-2by3 flex-shrink-0'>
                        <Link to={`/registeration?accr=${item.id}`} className='dashboard-accr-link'>
                          <div
                            className='dashboard-accr-card'
                            style={{
                              borderTop: `40px solid ${item?.color}`,
                              borderLeft: `1px solid ${item?.color}`,
                              borderRight: `1px solid ${item?.color}`,
                              borderBottom: `1px solid ${item?.color}`,
                            }}
                          >
                            <span className='dacc-text'>{item?.id}</span>

                            {item?.parent == 'V' ? (
                              <CountUp start={0} end={volunteerTotal} delay={0}>
                                {({countUpRef}) => (
                                  <div>
                                    <span className='dacc-count-text text-dark' ref={countUpRef} />
                                  </div>
                                )}
                              </CountUp>
                            ) : (
                              <CountUp start={0} end={getCountAcc(item?.id)} delay={0}>
                                {({countUpRef}) => (
                                  <div>
                                    <span className='dacc-count-text text-dark' ref={countUpRef} />
                                  </div>
                                )}
                              </CountUp>
                            )}
                          </div>
                        </Link>
                      </div>
                      {/* <div className='d-flex align-items-center flex-wrap flex-grow-1 mt-n2 mt-lg-n1'>
                      <div className='d-flex flex-column flex-grow-1 my-lg-0 my-2 pe-3'>
                        <span className='text-gray-800 fw-boldest fs-3'></span>
                      </div>
                    </div> */}
                    </div>
                  )
                })}
              </div>
              {/* end::Body */}
            </div>
          </div>
          {/* end::Col */}

          {/* begin::Col Sports*/}
          <div className='row-xxl-4'>
            <div className='card' style={{background: 'none', boxShadow: 'none'}}>
              <div
                className='card-body flex flex-wrap justify-center items-center'
                style={{columnGap: 44, padding: 0}}
              >
                {magicnum.SportCategories.map((item, index) => {
                  return (
                    <div
                      className='d-flex flex-col gap-2 mb-7 justify-center items-center'
                      key={index}
                    >
                      <div
                        className='symbol flex justify-center dsCard'
                        style={{
                          background: 'white',
                          width: 160,
                          height: 190,
                          boxShadow: 'var(--bs-card-box-shadow)',
                        }}
                      >
                        <Link
                          to={`/sports?sport=${item.id}&noc=${noc}`}
                          className='dashboard-accr-link'
                        >
                          <div
                            style={{
                              background: `url('/sports/card-image/${item?.id}.png')`,
                              width: 150,
                              height: 150,
                              backgroundSize: 'cover',
                              position: 'relative',
                              zIndex: 1,
                            }}
                            className='image'
                          ></div>
                          <CountUp start={0} end={getCountSport(item?.id)} delay={0}>
                            {({countUpRef}) => (
                              <div
                                className='flex justify-center items-center'
                                style={{
                                  background: '#36a93a',
                                  position: 'absolute',
                                  top: 140,
                                  left: 0,
                                  width: 160,
                                  height: 52,
                                  borderBottomLeftRadius: 'var(--bs-scrollbar-size)',
                                  borderBottomRightRadius: 'var(--bs-scrollbar-size)',
                                  columnGap: '10px',
                                  zIndex: 0,
                                }}
                              >
                                <span
                                  className='text-white'
                                  style={{fontSize: 16, fontWeight: 500}}
                                >
                                  {item?.name}:
                                </span>
                                <span
                                  className='text-white'
                                  style={{fontSize: 22, fontWeight: 500}}
                                  ref={countUpRef}
                                />
                              </div>
                            )}
                          </CountUp>
                          {/* {sport?.category} */}
                        </Link>
                      </div>
                      {/* <div className='d-flex align-items-center flex-wrap flex-grow-1 mt-n2 mt-lg-n1'>
                      <div className='d-flex flex-column flex-grow-1 my-lg-0 my-2 pe-3'>
                        <span className='text-gray-800 fw-boldest fs-3'></span>
                      </div>
                    </div> */}
                    </div>
                  )
                })}
              </div>
              {/* end::Body */}
            </div>
          </div>
          {/* end::Col */}

          {/* begin::Col Volunteer*/}
          {EmployeeTypeID == 'super' ? (
            <div className='row-xxl-4'>
              <div className='card'>
                <div className='dtitle'>Volunteers</div>
                <div
                  className='card-body flex justify-center items-center flex-wrap'
                  style={{gap: 22, padding: '40px 10px'}}
                >
                  {magicnum.volunteer.team.map((item, index) => {
                    return (
                      <div
                        className='d-flex flex-col gap-2 justify-center items-center'
                        key={index}
                      >
                        <div className='symbol symbol-60px symbol-2by3 flex-shrink-0'>
                          <Link to={`/volunteer?team=${item}`} className='dashboard-accr-link'>
                            <div className='dashboard-volunteer-card mdShadow'>
                              <span className='volunteer-text'>
                                {t(`common:magicnum.volunteer.team.${item}`)}
                              </span>

                              <CountUp start={0} end={getCountVolunteer(item)} delay={0}>
                                {({countUpRef}) => (
                                  <div className='dcount-volunteer'>
                                    <span
                                      className='volunteer-count-text text-dark'
                                      ref={countUpRef}
                                    />
                                  </div>
                                )}
                              </CountUp>
                            </div>
                          </Link>
                        </div>
                      </div>
                    )
                  })}
                </div>
                {/* end::Body */}
              </div>
            </div>
          ) : null}
          {/* end::Col */}
        </div>

        {/* begin::Col Flight*/}
        <div className='row-xxl-4 dflight'>
          <div className='dtitle'>Flight</div>
          <div className='card' style={{padding: 30, paddingTop: 0}}>
            <div className='card-body d-flex flex-col gap-x-4' style={{padding: '10px 10px'}}>
              <span style={{fontWeight: 'bold', fontSize: 16, marginTop: 0, marginBottom: 10}}>
                Arrival
              </span>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(5,1fr)',
                  gap: '10px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  justifyItems: 'center',
                }}
              >
                {ArrDay?.map((item: any, index) => {
                  var dago = dateAgo('2023-08-' + item?.arr_day + ' 20:30')
                  return (
                    <div
                      key={index}
                      className='d-flex gap-6 justify-start items-center mdShadow dNoc-card'
                      style={{
                        padding: '10px 5px',
                        paddingLeft: 30,
                        borderRadius: '8px',
                        background: '#04c8c8',
                        width: 220,
                        ...(dago < 1 ? {background: 'green'} : null),
                        ...(dago > 0 && dago < 3 ? {background: 'red'} : null),
                      }}
                    >
                      <KTIcon iconName='bi bi-airplane' className='ftext' />
                      <div className='flex flex-col justify-center items-center'>
                        <span style={{color: 'white', fontWeight: '900', fontSize: 16}}>
                          {dago < 1 ? (
                            <div className='flex flex-col items-center'>
                              <span style={{whiteSpace: 'nowrap'}}>Welcome</span>
                              <span style={{fontSize: 26}}>{item?.niit}</span>
                            </div>
                          ) : (
                            <div className='flex flex-col items-center'>
                              <span>{dago} days left</span>
                              <span style={{fontSize: 26}}>{item?.niit}</span>
                            </div>
                          )}
                        </span>
                        <span style={{whiteSpace: 'nowrap', color: 'white'}}>
                          {item?.arr_day < 10
                            ? `2023-08-0${item?.arr_day}`
                            : `2023-08-${item?.arr_day}`}
                        </span>
                      </div>
                    </div>
                  )
                })}
              </div>
              <span style={{fontWeight: 'bold', fontSize: 16, marginTop: 30, marginBottom: 10}}>
                Departure
              </span>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(5,1fr)',
                  gap: '10px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  justifyItems: 'center',
                }}
              >
                {DepDay?.map((item: any, index) => {
                  var dago = dateAgo('2023-08-' + item?.dep_day + ' 20:30')
                  return (
                    <div
                      key={index}
                      className='d-flex gap-6 justify-start items-center dNoc-card'
                      style={{
                        padding: '10px 5px',
                        paddingLeft: 30,
                        borderRadius: '8px',
                        background: '#ffbd16',
                        width: 220,
                        ...(dago < 1 ? {background: 'purple'} : null),
                        ...(dago > 0 && dago < 3 ? {background: 'red'} : null),
                      }}
                    >
                      <KTIcon iconName='bi bi-airplane' className='dtext' />
                      <div className='flex flex-col justify-center items-center'>
                        <span style={{color: 'white', fontWeight: '900', fontSize: 16}}>
                          {dago < 1 ? (
                            <div className='flex flex-col items-center'>
                              <span style={{whiteSpace: 'nowrap'}}>Good Bye</span>
                              <span style={{fontSize: 26}}>{item?.niit}</span>
                            </div>
                          ) : (
                            <div className='flex flex-col items-center'>
                              <span>{dago} days left</span>
                              <span style={{fontSize: 26}}>{item?.niit}</span>
                            </div>
                          )}
                        </span>
                        <span style={{whiteSpace: 'nowrap', color: 'white'}}>
                          {item?.arr_day < 10
                            ? `2023-08-0${item?.dep_day}`
                            : `2023-08-${item?.dep_day}`}
                        </span>
                      </div>
                    </div>
                  )
                })}
              </div>
              <span style={{fontWeight: 'bold', fontSize: 16, marginTop: 30, marginBottom: 10}}>
                Flight NO
              </span>
              <div style={{display: 'grid', gridTemplateColumns: 'repeat(9,1fr)', gap: '10px'}}>
                {FlightNo?.map((item: any, index) => {
                  return (
                    <div
                      key={index}
                      className='mdShadow flex flex-col justify-center items-center dNoc-card'
                      style={{
                        border: '1px solid #e3e3e3',
                        padding: '10px 15px',
                        background: '#d9d8d8',
                        borderRadius: '8px',
                        fontSize: 28,
                      }}
                    >
                      <span style={{fontSize: 18}}>{item?.arr_flight_no}</span>
                      <span style={{fontWeight: '900'}}>{item?.niit}</span>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
        {/* end::Col */}
      </div>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
