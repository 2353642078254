import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {HeaderNotificationsMenu, HeaderUserMenu, QuickLinks} from '../../../partials'
import config from '../../../../services/config.json'
import {useAuth} from '../../../../app/modules/auth'
import {CDN_URL} from '../../../../services/main'
import { useEffect, useState } from 'react'
import { getRealData } from '../../../../services/Utils'
import useCookie from '../../../../services/useCookie'

const AsideFooter = () => {
  //const {currentUser} = useAuth()
  const debugMode = config.debug
  const [Data1, setData1, removeData1] = useCookie('Data1', null)
  const [currentUser, setCurrentUser] = useState<any>('')
  useEffect(() => {
    var d = getRealData(Data1)
    setCurrentUser(JSON.parse(d))
  }, [Data1])

  return (
    <div
      className='aside-footer d-flex flex-column justify-center items-center flex-column-auto'
      id='kt_aside_footer'
    >
      {/* begin::Quick links */}
      {debugMode == 'test' ? (
        <div className='d-flex align-items-center mb-2'>
          {/* begin::Menu wrapper */}
          <div
            className='btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light'
            data-kt-menu-trigger='click'
            data-kt-menu-overflow='true'
            data-kt-menu-placement='top-start'
            data-bs-toggle='tooltip'
            data-bs-placement='right'
            data-bs-dismiss='click'
            title='Quick links'
          >
            <KTIcon iconName='element-plus' className='fs-2 text-lg-1' />
          </div>
          {/* end::Menu wrapper */}
          <QuickLinks backgroundUrl='/media/misc/pattern-1.jpg' />
        </div>
      ) : null}
      {/* end::Quick links */}

      {/* begin::Activities */}
      {debugMode == 'test' ? (
        <div className='d-flex align-items-center mb-3'>
          {/* begin::Drawer toggle */}
          <div
            className='btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light'
            data-kt-menu-trigger='click'
            data-kt-menu-overflow='true'
            data-kt-menu-placement='top-start'
            data-bs-toggle='tooltip'
            data-bs-placement='right'
            data-bs-dismiss='click'
            title='Activity Logs'
            id='kt_activities_toggle'
          >
            <KTIcon iconName='chart-simple' className='fs-2 text-lg-1' />
          </div>
          {/* end::drawer toggle */}
        </div>
      ) : null}

      {/* end::Activities */}

      {/* begin::Notifications */}
      {debugMode == 'test' ? (
        <div className='d-flex align-items-center mb-2'>
          {/* begin::Menu wrapper */}
          <div
            className='btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light'
            data-kt-menu-trigger='click'
            data-kt-menu-overflow='true'
            data-kt-menu-placement='top-start'
            data-bs-toggle='tooltip'
            data-bs-placement='right'
            data-bs-dismiss='click'
            title='Notifications'
          >
            <KTIcon iconName='element-11' className='fs-2 text-lg-1' />
          </div>
          {/* end::Menu wrapper */}
          <HeaderNotificationsMenu backgrounUrl='/media/misc/pattern-1.jpg' />
        </div>
      ) : null}
      {/* end::Notifications */}

      {/* begin::User */}
      <div className='flex justify-center items-center mb-10' id='kt_header_user_menu_toggle'>
        {/* begin::Menu wrapper */}
        <div
          className='cursor-pointer symbol symbol-40px'
          data-kt-menu-trigger='click'
          data-kt-menu-overflow='false'
          data-kt-menu-placement='top-start'
          title='User profile'
          style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}
        >
          <img src={CDN_URL + currentUser?.photo} alt='avatar' />
          <div style={{paddingTop: '10px'}}>{currentUser?.firstname}</div>
        </div>
        {/* end::Menu wrapper */}
        <HeaderUserMenu />
      </div>
      {/* end::User */}
    </div>
  )
}

export {AsideFooter}
