import React, {FC, useEffect, useState} from 'react'
import {buildAccorditionCard, isNullOrEmpty} from '../../../../services/Utils'
import {
  AthleteSportAdd,
  AthleteSportChangeStatus,
  AthleteSportDelete,
  AthleteSportList,
} from '../../../../services/main'
import {toast} from 'react-toastify'
import {
  AthleteSportAddReq,
  AthleteSportChangeStatusReq,
  AthleteSportListReq,
  BaseRequest,
  magicnum,
} from '../../../../services/Request'
import useCookie from '../../../../services/useCookie'
import {KTIcon} from '../../../../_metronic/helpers'
import {confirmAlert} from 'react-confirm-alert'
interface IProps {
  t: any
  item: any
  onSportSuccess: any
}
const TabSport: FC<IProps> = ({t, item, onSportSuccess}) => {
  const [count, setCount] = useState(0)
  const [loading, setLaoding] = useState(false)
  const [validate, setValidate] = useState<any>([])
  const [EmployeeID] = useCookie('EmployeeID', null)
  const [sportCategory, setSportCategory] = useState(
    magicnum.SportCategories.filter((item) => isNullOrEmpty(item.id)) ? '' : item.id
  )
  const [subCategory, setSubCategory] = useState('')
  const [gender, setGender] = useState('')
  const [data, setData] = useState([])

  useEffect(() => {
    if (!isNullOrEmpty(item)) {
      list()
    }
  }, [item])

  const list = () => {
    setLaoding(true)
    var req = AthleteSportListReq
    req.filter.noc = item?.noc
    req.filter.athlete_id = item?.id
    AthleteSportList(req, onListSuccess, onFailed, 'POST')
  }

  const onListSuccess = (response: any) => {
    setLaoding(false)
    setData(response.data)
    onSportSuccess(response.data)
  }

  const save = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLaoding(true)
      var gg = magicnum.SportSubCategories.filter((item) => item.id == parseInt(subCategory))
      var req = AthleteSportAddReq
      req.sport.status = magicnum.Status.InActive
      req.sport.category = sportCategory
      req.sport.sub_category = subCategory
      req.sport.created_by = EmployeeID
      req.sport.gender = gg[0].gender
      req.sport.noc = item?.noc
      req.sport.athlete_id = item?.id
      AthleteSportAdd(req, onSuccess, onFailed, 'POST')
    }
  }

  const onSuccess = () => {
    setLaoding(false)
    list()
    return toast.success(t('common:successCreate'))
  }

  const onFailed = (err: string) => {
    setLaoding(false)
    return toast.error(err)
  }

  const validateFunc = () => {
    var res = {retType: 0}

    if (isNullOrEmpty(sportCategory)) {
      res.retType = 1
      validate['category'] = true
    }

    if (isNullOrEmpty(subCategory)) {
      res.retType = 1
      validate['sub_category'] = true
    }

    setValidate(validate)
    setCount(count + 1)
    return res
  }

  const validateChange = (id: string, e: any) => {
    var val = ''
    e.target === undefined ? (val = e) : (val = e.target.value)
    if (val !== '') {
      validate[id] = false
    } else {
      validate[id] = true
    }
    setValidate(validate)
    setCount(count + 1)
  }

  const submit = (row: any, type: string) => {
    confirmAlert({
      customUI: ({onClose}) => {
        var statusAction = ''
        return (
          <div className='custom-confirm-ui'>
            <h1>{row.title}</h1>
            <p>
              {' '}
              {type == 'deleteMain' ? t('common:confirmDelete') : t('common:confirmChangeStatus')}
            </p>
            {type == 'changeStatus' ? (
              <div>
                <select
                  name='category'
                  data-control='select2'
                  data-hide-search='true'
                  className='form-select form-select-white'
                  onChange={(e) => {
                    statusAction = e.target.value
                  }}
                >
                  <option value=''>{t('common:choose')}</option>
                  {magicnum.StatusSport.map((item2, index) => {
                    return (
                      <option value={item2.id} key={index}>
                        {t(`common:magicnum.StatusSport.${item2?.id}`)}
                      </option>
                    )
                  })}
                </select>
              </div>
            ) : null}
            <div className='buttons'>
              <button className='btn btn-active-light btn-color-muted' onClick={onClose}>
                {t('common:no')}
              </button>
              <button
                className='btn btn-primary align-self-center'
                onClick={() => {
                  type == 'deleteMain' ? deleteFunc(row) : changeStatus(row, statusAction)
                  onClose()
                }}
              >
                {t('common:yes')}
              </button>
            </div>
          </div>
        )
      },
    })
  }

  const deleteFunc = (row: any) => {
    AthleteSportDelete(row.id, BaseRequest, onDeleteSuccess, onFailed, 'DELETE')
  }

  const onDeleteSuccess = () => {
    list()
    return toast.success(t('common:successDelete'))
  }

  const changeStatus = (row: any, statusAction: string) => {
    var req = AthleteSportChangeStatusReq
    req.sport.id = row.id
    req.sport.status = statusAction
    AthleteSportChangeStatus(req, onChangeStatusSuccess, onFailed, 'PUT')
  }

  const onChangeStatusSuccess = () => {
    list()
    return toast.success(t('common:successUpdate'))
  }

  return (
    <div>
      <div className='mx-5 mx-xl-10 pt-10 pb-10'>
        <span style={{visibility: 'hidden'}}>{count}</span>
        {item?.status == magicnum.Status.InActive ? (
          <div className='row'>
            <div className='col-lg-4 fv-row mb-8'>
              <select
                name='category'
                data-control='select2'
                data-hide-search='true'
                value={sportCategory}
                className='form-select form-select-white'
                onChange={(e) => {
                  setSportCategory(e.target.value)
                  validateChange('category', e)
                }}
              >
                <option value=''>{t('common:magicnum.SportCategories.category')}</option>
                {magicnum.SportCategories.map((item2, index) => {
                  return (
                    <option value={item2.id} key={index}>
                      {item2?.name} - {t(`common:magicnum.SportCategories.${item2?.id}`)}
                    </option>
                  )
                })}
              </select>
              {validate['category'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validSelect')}</div>
                </div>
              ) : null}
            </div>
            <div className='col-lg-4 fv-row mb-8'>
              <select
                name='sub_category'
                data-control='select2'
                data-hide-search='true'
                value={subCategory}
                className='form-select form-select-white'
                onChange={(e) => {
                  setSubCategory(e.target.value)
                  validateChange('sub_category', e)
                }}
              >
                <option value=''>{t('common:magicnum.SportCategories.sub_category')}</option>
                <optgroup label='male'>
                  {magicnum.SportSubCategories.map((item2, index) => {
                    return sportCategory == item2.parent &&
                      item2.gender == 'male' &&
                      item.gender == 'male' ? (
                      <option value={item2.id} key={index}>
                        {t(`common:magicnum.SportSubCategories.${item2?.id}`)}
                      </option>
                    ) : null
                  })}
                </optgroup>
                <optgroup label='female'>
                  {magicnum.SportSubCategories.map((item2, index) => {
                    return sportCategory == item2.parent &&
                      item2.gender == 'female' &&
                      item.gender == 'female' ? (
                      <option value={item2.id} key={index}>
                        {t(`common:magicnum.SportSubCategories.${item2?.id}`)}
                      </option>
                    ) : null
                  })}
                </optgroup>
                <optgroup label='team'>
                  {magicnum.SportSubCategories.map((item2, index) => {
                    return sportCategory == item2.parent && item2.gender == 'team' ? (
                      <option value={item2.id} key={index}>
                        {t(`common:magicnum.SportSubCategories.${item2?.id}`)}
                      </option>
                    ) : null
                  })}
                </optgroup>
              </select>
              {validate['sub_category'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validSelect')}</div>
                </div>
              ) : null}
            </div>
            <div className='col-lg-4 fv-row mb-8'>
              <button
                className='btn btn-primary align-self-center'
                onClick={save}
                disabled={loading}
              >
                {loading ? t('common:loading') : t('common:save')}
              </button>
            </div>
          </div>
        ) : null}
        <div className='separator mt-8 d-flex flex-center mb-8'>
          <span className='text-uppercase bg-body fs-7 fw-bold text-muted px-3'>
            {t('common:athlete.info')}
          </span>
        </div>

        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4  rounded-start'>{t('common:athlete.more.tabsport.title')}</th>
                <th className='ps-4  rounded-start'>
                  {t('common:athlete.more.tabsport.accr_and_gender')}
                </th>
                <th className='min-w-150px'>{t('common:athlete.status')}</th>
                <th className='text-end rounded-end'></th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {data.map((itee: any, index) => {
                var vv = buildAccorditionCard(item, itee)
                var gg = magicnum.StatusSport.filter((ite) => ite.id == itee.status)
                return (
                  <tr key={index}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column items-center'>
                          <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                            {t(`common:magicnum.SportCategories.${itee?.category}`)}
                          </a>
                          <span className='text-muted fw-semibold text-muted d-block fs-7'>
                            {t(`common:magicnum.SportSubCategories.${itee?.sub_category}`)}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                            {vv}
                          </a>
                          <span className='text-muted fw-semibold text-muted d-block fs-7'>
                            {itee?.gender}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      {itee?.status == 0 ? (
                        <span
                          onClick={() => submit(itee, 'changeStatus')}
                          className={`badge ${gg[0]?.class} fw-semibold cursor-pointer`}
                        >
                          {t(`common:magicnum.StatusSport.${itee?.status}`)}
                        </span>
                      ) : (
                        <span className={`badge ${gg[0]?.class} fw-semibold`}>
                          {t(`common:magicnum.StatusSport.${itee?.status}`)}
                        </span>
                      )}
                    </td>
                    <td className='text-end'>
                      {itee?.status == 0 && item?.status == magicnum.Status.InActive ? (
                        <a
                          href='#'
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                          onClick={() => submit(itee, 'deleteMain')}
                        >
                          <KTIcon iconName='trash' className='fs-3' />
                        </a>
                      ) : null}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export {TabSport}
