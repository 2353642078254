import React, {FC, useEffect, useState} from 'react'
import {isNullOrEmpty} from '../../../../services/Utils'
import {
  AthleteContactDelete,
  AthleteVisaFlightAdd,
  AthleteVisaFlightGet,
  AthleteVisaFlightUpdate,
  CDN_URL,
} from '../../../../services/main'
import {toast} from 'react-toastify'
import {AthleteVisaFlightUpdateReq, BaseRequest, magicnum} from '../../../../services/Request'
import useCookie from '../../../../services/useCookie'
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {confirmAlert} from 'react-confirm-alert'
import {KTTooltip} from '../../../../_metronic/helpers/components/KTTooltip'
import CustomModal from '../../../modules/CustomModal/modal'
import {ChangeCopyPassword} from './common/ChangeCopyPassword'
const TabVisa = ({t, item}) => {
  const [count, setCount] = useState(0)
  const [loading, setLaoding] = useState(false)
  const [validate, setValidate] = useState([])
  const [EmployeeID] = useCookie('EmployeeID', null)
  const [data, setData] = useState(undefined)
  const [passType, setPassType] = useState('')
  const [passSerialNumID, setPassSerialNumID] = useState('')
  const [passExpiredDate, setPassExpiredDate] = useState('')
  const [passFile, setPassFile] = React.useState('')
  const [arrFlightNo, setArrFlightNo] = useState('')
  const [arrMonth, setArrMonth] = useState('8')
  const [arrDay, setArrDay] = useState('')
  const [arrTime, setArrTime] = useState('')
  const [arrPlace, setArrPlace] = useState('Chinggis Khaan International Airport')
  const [arrRailway, setArrRailway] = useState('')
  const [depMonth, setDepMonth] = useState('8')
  const [depDay, setDepDay] = useState('')
  const [depTime, setDepTime] = useState('')
  const [depPlace, setDepPlace] = useState('Chinggis Khaan International Airport')
  const [baggage, setBaggage] = useState('')
  const [depRailway, setDepRailway] = useState('')
  const [isDisabled, setIsDisabled] = useState(false)
  const [edit, setEdit] = useState(false)
  const [showModal, updateShowModal] = useState(false)
  useEffect(() => {
    if (!isNullOrEmpty(item)) {
      list()
    }
  }, [item])

  useEffect(() => {
    if (!isNullOrEmpty(data)) {
      setPassExpiredDate(data?.pass_expired_date?.substr(0, 10))
      setPassSerialNumID(data?.pass_serial_num_id)
      setPassType(data?.pass_type)
      setDepPlace(data?.dep_place)
      setDepTime(data?.dep_time)
      setDepDay(data?.dep_day)
      setDepMonth(data?.dep_month)
      setDepRailway(data?.dep_railway)
      setArrPlace(data?.arr_place)
      setArrTime(data?.arr_time)
      setArrMonth(data?.arr_month)
      setArrDay(data?.arr_day)
      setArrFlightNo(data?.arr_flight_no)
      setArrRailway(data?.arr_railway)
      setBaggage(data?.baggage)
      setIsDisabled(true)
    }
  }, [data])

  const list = () => {
    setLaoding(true)
    AthleteVisaFlightGet(item?.id, BaseRequest, onListSuccess, onFailed, 'GET')
  }

  const onListSuccess = (response) => {
    setLaoding(false)
    setData(response.data)
  }

  const addDays = (date, days) => {
    // var result = new Date(date)
    // result.setDate(result.getDate() + days)
    // return result.toISOString().substring(0, 10)
    return date.substring(0, 10)
  }

  const save = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLaoding(true)
      var sport = {
        aid: item?.id,
        noc: item?.noc,
        pass_type: passType,
        pass_serial_num_id: passSerialNumID,
        pass_expired_date: addDays(passExpiredDate, 1),
        arr_flight_no: arrFlightNo,
        arr_month: arrMonth,
        arr_day: arrDay,
        arr_time: arrTime,
        arr_place: arrPlace,
        arr_railway: '',
        dep_month: depMonth,
        dep_day: depDay,
        dep_time: depTime,
        dep_place: depPlace,
        dep_railway: '',
        created_by: EmployeeID,
        baggage: baggage,
      }
      var formdata = new FormData()
      formdata.append('image', passFile)
      formdata.append('sport', JSON.stringify(sport))
      AthleteVisaFlightAdd(formdata, onSuccess, onFailed, 'POST', 'file')
    }
  }

  const onSuccess = () => {
    setLaoding(false)
    setEdit(true)
    list()
    return toast.success(t('common:successCreate'))
  }

  const onFailed = (err) => {
    setLaoding(false)
    return toast.error(err)
  }

  const update = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLaoding(true)
      var req = AthleteVisaFlightUpdateReq

      req.sport.pass_type = passType
      req.sport.pass_serial_num_id = passSerialNumID
      req.sport.pass_expired_date = passExpiredDate
      req.sport.arr_flight_no = arrFlightNo
      req.sport.arr_month = arrMonth
      req.sport.arr_day = arrDay
      req.sport.arr_time = arrTime
      req.sport.arr_place = arrPlace
      //req.sport.arr_railway = '',
      req.sport.dep_month = depMonth
      req.sport.dep_day = depDay
      req.sport.dep_time = depTime
      req.sport.dep_place = depPlace
      req.sport.baggage = baggage
      //req.sport.dep_railway = '',
      req.sport.id = data?.id
      console.log(req)
      AthleteVisaFlightUpdate(req, onUpdateSuccess, onFailed, 'PUT')
    }
  }

  const onUpdateSuccess = () => {
    setLaoding(false)
    return toast.success(t('common:successUpdate'))
  }

  const validateFunc = () => {
    var res = {retType: 0}

    if (isNullOrEmpty(passType)) {
      res.retType = 1
      validate['passport_type'] = true
    }
    if (isNullOrEmpty(passSerialNumID)) {
      res.retType = 1
      validate['serialNumID'] = true
    }
    if (isNullOrEmpty(passExpiredDate)) {
      res.retType = 1
      validate['passExpireDate'] = true
    }
    if (isNullOrEmpty(passFile)) {
      res.retType = 1
      validate['passFile'] = true
    }
    if (isNullOrEmpty(arrFlightNo)) {
      res.retType = 1
      validate['flightNo'] = true
    }
    if (isNullOrEmpty(arrPlace)) {
      res.retType = 1
      validate['arrPlace'] = true
    }
    if (isNullOrEmpty(arrMonth)) {
      res.retType = 1
      validate['arrMotnh'] = true
    }
    if (isNullOrEmpty(arrDay)) {
      res.retType = 1
      validate['arrDay'] = true
    }
    if (isNullOrEmpty(arrTime)) {
      res.retType = 1
      validate['arrTime'] = true
    }
    if (isNullOrEmpty(depPlace)) {
      res.retType = 1
      validate['depPlace'] = true
    }
    if (isNullOrEmpty(depMonth)) {
      res.retType = 1
      validate['depMonth'] = true
    }
    if (isNullOrEmpty(depDay)) {
      res.retType = 1
      validate['depDay'] = true
    }
    if (isNullOrEmpty(depTime)) {
      res.retType = 1
      validate['depTime'] = true
    }
    setValidate(validate)
    setCount(count + 1)
    return res
  }

  const validateChange = (id, e) => {
    var val = ''
    e.target === undefined ? (val = e) : (val = e.target.value)
    if (val !== '') {
      validate[id] = false
    } else {
      validate[id] = true
    }
    setValidate(validate)
    setCount(count + 1)
  }

  const handleSelectFile = (event) => {
    setLaoding(true)
    const files = event.target.files

    if (files && files.length > 0) {
      setPassFile(files[0])
      setLaoding(false)
    }
  }

  const changepassfilesuccess = () => {
    updateShowModal(false)
    list()
    return toast.success(t('common:successUpdate'))
  }

  return (
    <div className='tabvisa'>
      {showModal ? (
        <CustomModal
          visible={showModal}
          closeModal={() => updateShowModal(false)}
          className='VideoModal'
        >
          <ChangeCopyPassword t={t} item={data} onAddSuccess={changepassfilesuccess} />
        </CustomModal>
      ) : null}
      <div className='mx-5 mx-xl-10 pb-12' style={{padding: '10px'}}>
        <span style={{visibility: 'hidden'}}>{count}</span>

        <div className='row'>
          <div className='separator mt-8 d-flex flex-center mb-8'>
            <span className='text-uppercase bg-body fs-7 fw-bold text-muted'>
              {t('common:athlete.more.tabvisa.passport')}
            </span>
          </div>

          <div
            className='col-lg-4 fv-row mb-8'
            style={{display: 'flex', gap: '15px', width: '100%'}}
          >
            <div className='col-lg-3'>
              <select
                name='passport_type'
                data-control='select2'
                data-hide-search='true'
                value={passType}
                className='form-select form-select-white'
                onChange={(e) => {
                  setPassType(e.target.value)
                  validateChange('passport_type', e)
                }}
                disabled={isDisabled}
              >
                <option value=''>{t('common:athlete.more.tabvisa.selectPassport')}</option>
                {magicnum.passport.map((item2, index) => {
                  return (
                    <option value={item2} key={index}>
                      <span>{t(`common:athlete.more.tabvisa.pass_type.${item2}`)}</span>
                    </option>
                  )
                })}
              </select>
              {validate['passport_type'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validSelect')}</div>
                </div>
              ) : null}
            </div>
            <div className='col-lg-3'>
              <input
                className='form-control form-control-solid'
                placeholder={t('common:athlete.more.tabvisa.serialNumber')}
                onChange={(e) => {
                  setPassSerialNumID(e.target.value)
                  validateChange('serialNumID', e)
                }}
                value={passSerialNumID}
                maxLength={25}
                disabled={isDisabled}
              ></input>
              {validate['serialNumID'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validInsert')}</div>
                </div>
              ) : null}
            </div>
            <div className='col-lg-3' style={{width: '155px'}}>
              <input
                className='form-control form-control-solid'
                style={{position: 'relative'}}
                placeholder={t('common:athlete.more.tabvisa.expiryDate')}
                value={passExpiredDate}
                onChange={(e) => {
                  setPassExpiredDate(e.target.value)
                  validateChange('passExpireDate', e)
                }}
                type='date'
                disabled={isDisabled}
              ></input>
              {validate['passExpireDate'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validInsert')}</div>
                </div>
              ) : null}
              <KTTooltip
                iconName='information-5'
                className='fs-3'
                required={false}
                tooltip='Passport expiry date'
              />
            </div>
            <div className='col-lg-4'>
              {!isNullOrEmpty(data) && !edit ? (
                <div style={{display: 'flex', columnGap: 10}}>
                  <a
                    className='btn btn-light-primary fw-bolder'
                    href={CDN_URL + data?.pass_file}
                    target='_blank'
                  >
                    {t('common:athlete.more.tabvisa.downloadCopyOfPassport')}
                  </a>
                  {item?.status == magicnum.Status.InActive ? (
                    <div
                      className='btn btn-light-primary fw-bolder'
                      onClick={() => {
                        updateShowModal(true)
                      }}
                    >
                      {t('common:edit')}
                      <KTIcon iconName='pencil' />
                    </div>
                  ) : null}
                </div>
              ) : (
                <>
                  <label
                    className='btn btn-light-primary fw-bolder'
                    htmlFor='pass_file'
                    style={{cursor: 'pointer', width: '100%'}}
                  >
                    {loading
                      ? t('common:loading')
                      : t('common:athlete.more.tabvisa.copyOfPassport')}
                  </label>
                  <KTTooltip
                    iconName='information-5'
                    className='fs-3'
                    required={false}
                    tooltip='Only PDF file'
                  />
                </>
              )}
              <input
                onChange={(e) => {
                  handleSelectFile(e)
                  validateChange('passFile', e)
                }}
                //onChange={handleSelectFile}
                type='file'
                name='pass_file'
                id='pass_file'
                maxLength={150}
                style={{display: 'none'}}
                accept='.pdf'
              />
              {validate['passFile'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validInsert')}</div>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className='separator mt-8 d-flex flex-center mb-8'>
          <span className='text-uppercase bg-body fs-7 fw-bold text-muted px-3'>
            {t('common:athlete.more.tabvisa.arrival')}
          </span>
        </div>

        <div className='col-lg-4 fv-row mb-8' style={{display: 'flex', gap: '15px', width: '100%'}}>
          <div className='col-lg-8'>
            <input
              className='form-control form-control-solid'
              placeholder={t('common:athlete.more.tabvisa.flight_no')}
              onChange={(e) => {
                setArrFlightNo(e.target.value)
                validateChange('flightNo', e)
              }}
              disabled={isDisabled}
              value={arrFlightNo}
              maxLength={30}
            ></input>
            {validate['flightNo'] ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{t('common:validInsert')}</div>
              </div>
            ) : null}
          </div>
          <div className='col-lg-4'>
            <input
              className='form-control form-control-solid'
              placeholder={t('common:athlete.more.tabvisa.arr_place')}
              onChange={(e) => {
                setArrPlace(e.target.value)
                validateChange('arrPlace', e)
              }}
              value={arrPlace}
              maxLength={150}
              disabled={true}
            ></input>
            {validate['arrPlace'] ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{t('common:validInsert')}</div>
              </div>
            ) : null}
          </div>
        </div>

        <div className='col-lg-4 fv-row mb-8' style={{display: 'flex', gap: '15px', width: '100%'}}>
          <div className='col-lg-4'>
            <select
              name='arrMotnh'
              data-control='select2'
              data-hide-search='true'
              value={arrMonth}
              className='form-select form-select-white'
              disabled={isDisabled}
              onChange={(e) => {
                setArrMonth(e.target.value)
                validateChange('arrMotnh', e)
              }}
            >
              <option value=''>{t('common:athlete.more.tabvisa.select_month')}</option>
              {magicnum.month.map((item2, index) => {
                return (
                  <option value={item2} key={index}>
                    <span>{t(`common:athlete.more.tabvisa.month.${item2}`)}</span>
                  </option>
                )
              })}
            </select>
            {validate['arrMotnh'] ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{t('common:validSelect')}</div>
              </div>
            ) : null}
          </div>
          <div className='col-lg-4'>
            <select
              name='arrDay'
              data-control='select2'
              data-hide-search='true'
              value={arrDay}
              className='form-select form-select-white'
              disabled={isDisabled}
              onChange={(e) => {
                setArrDay(e.target.value)
                validateChange('arrDay', e)
              }}
            >
              <option value=''>{t('common:athlete.more.tabvisa.select_date')}</option>
              {magicnum.day.map((item2, index) => {
                return (
                  <option value={item2} key={index}>
                    <span>{item2}</span>
                  </option>
                )
              })}
            </select>
            {validate['arrDay'] ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{t('common:validSelect')}</div>
              </div>
            ) : null}
          </div>
          <div className='col-lg-4'>
            <input
              className='form-control form-control-solid'
              placeholder={t('common:athlete.more.tabvisa.input_of_hour')}
              onChange={(e) => {
                setArrTime(e.target.value)
                validateChange('arrTime', e)
              }}
              value={arrTime}
              maxLength={10}
              disabled={isDisabled}
            ></input>
            {validate['arrTime'] ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{t('common:validInsert')}</div>
              </div>
            ) : null}
            <KTTooltip
              iconName='information-5'
              className='fs-3'
              required={false}
              tooltip='Insert your flight time. Format should be: 24-Hour Time Format (Hour : Minute)'
            />
          </div>
        </div>

        <div className='separator d-flex flex-center mb-8' style={{marginTop: '100px'}}>
          <span className='text-uppercase bg-body fs-7 fw-bold text-muted px-3'>
            {t('common:athlete.more.tabvisa.departure')}
          </span>
        </div>

        <div className='col-lg-4 fv-row mb-8' style={{display: 'flex', gap: '15px', width: '100%'}}>
          <div className='col-lg-4'>
            <select
              name='depMonth'
              data-control='select2'
              data-hide-search='true'
              value={depMonth}
              className='form-select form-select-white'
              disabled={isDisabled}
              onChange={(e) => {
                setDepMonth(e.target.value)
                validateChange('depMonth', e)
              }}
            >
              <option value=''>{t('common:athlete.more.tabvisa.select_month')}</option>
              {magicnum.month.map((item2, index) => {
                return (
                  <option value={item2} key={index}>
                    <span>{t(`common:athlete.more.tabvisa.month.${item2}`)}</span>
                  </option>
                )
              })}
            </select>
            {validate['depMonth'] ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{t('common:validInsert')}</div>
              </div>
            ) : null}
          </div>
          <div className='col-lg-4'>
            <select
              name='depDay'
              data-control='select2'
              data-hide-search='true'
              value={depDay}
              className='form-select form-select-white'
              disabled={isDisabled}
              onChange={(e) => {
                setDepDay(e.target.value)
                validateChange('depDay', e)
              }}
            >
              <option value=''>{t('common:athlete.more.tabvisa.select_date')}</option>
              {magicnum.day.map((item2, index) => {
                return (
                  <option value={item2} key={index}>
                    <span>{item2}</span>
                  </option>
                )
              })}
            </select>
            {validate['depDay'] ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{t('common:validInsert')}</div>
              </div>
            ) : null}
          </div>
          <div className='col-lg-4'>
            <input
              className='form-control form-control-solid'
              placeholder={t('common:athlete.more.tabvisa.input_of_hour')}
              disabled={isDisabled}
              onChange={(e) => {
                setDepTime(e.target.value)
                validateChange('depTime', e)
              }}
              value={depTime}
              maxLength={10}
            ></input>
            {validate['depTime'] ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{t('common:validInsert')}</div>
              </div>
            ) : null}
            <KTTooltip
              iconName='information-5'
              className='fs-3'
              required={false}
              tooltip='Insert your flight time. Format should be: 24-Hour Time Format (Hour : Minute)'
            />
          </div>
        </div>

        <div className='col-lg-12 fv-row mb-8'>
          <input
            className='form-control form-control-solid'
            placeholder={t('common:athlete.more.tabvisa.dep_place')}
            disabled={true}
            onChange={(e) => {
              setDepPlace(e.target.value)
              validateChange('depPlace', e)
            }}
            value={depPlace}
            maxLength={150}
          ></input>
          {validate['depPlace'] ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{t('common:validInsert')}</div>
            </div>
          ) : null}
        </div>

        <div className='col-lg-12 fv-row mb-8'>
          <input
            className='form-control form-control-solid'
            placeholder={t('common:athlete.more.tabvisa.baggagePLH')}
            disabled={isDisabled}
            onChange={(e) => {
              setBaggage(e.target.value)
              validateChange('baggage', e)
            }}
            value={baggage}
            maxLength={150}
          ></input>
        </div>

        <div className='pt-10' style={{textAlign: 'right'}}>
          {isNullOrEmpty(data) ? (
            <button
              className='btn btn-primary align-self-center'
              disabled={loading}
              onClick={() => save()}
            >
              {loading ? t('common:loading') : t('common:save')}
            </button>
          ) : null}
          {!isNullOrEmpty(data) && !edit && item?.status == magicnum.Status.InActive ? (
            <button
              className='btn btn-primary align-self-center'
              disabled={loading}
              onClick={() => {
                setIsDisabled(false)
                setEdit(true)
              }}
            >
              {loading ? t('common:loading') : t('common:edit')}
            </button>
          ) : null}
          {!isNullOrEmpty(data) && edit ? (
            <button
              className='btn btn-primary align-self-center'
              disabled={loading}
              onClick={() => {
                setIsDisabled(true)
                setEdit(false)
                update()
              }}
            >
              {loading ? t('common:loading') : t('common:save')}
            </button>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export {TabVisa}
