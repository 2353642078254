import React, {FC, useState} from 'react'
import {AthleteChangePrintSuccess, CDN_URL} from '../../../../services/main'
import {toast} from 'react-toastify'
import {AthleteChangePrintSuccessReq} from '../../../../services/Request'
import useCookie from '../../../../services/useCookie'
interface IProps {
  t: any
  item: any
  onPrintChangeSuccess: any
}
const TabPrint: FC<IProps> = ({t, item, onPrintChangeSuccess}) => {
  const [loading, setLaoding] = useState(false)

  const save = (type: any) => {
    setLaoding(true)
    var req = AthleteChangePrintSuccessReq
    req.athlete.id = item?.id
    req.athlete.status_desc = type
    AthleteChangePrintSuccess(req, onSuccess, onFailed, 'PUT')
  }

  const onSuccess = () => {
    setLaoding(false)
    onPrintChangeSuccess('Success print status')
  }

  const onFailed = (err: string) => {
    setLaoding(false)
    return toast.error(err)
  }

  return (
    <div>
      <div className='mx-5 mx-xl-10 pt-10 pb-10 flex flex-col gap-6' style={{textAlign: 'center'}}>
        Print status
        <img
          src={CDN_URL + item?.photo}
          height={60}
          width={60}
          style={{borderRadius: '50%', objectFit: 'cover'}}
        />
        {item?.firstname}-{item?.lastname}
        <div>
          <button
            className='btn btn-primary align-self-center'
            onClick={() => save(1)}
            disabled={loading}
          >
            {loading ? t('common:loading') : t('common:yes')}
          </button>
          &nbsp; &nbsp;
          <button
            className='btn btn-danger align-self-center'
            onClick={() => save(0)}
            disabled={loading}
          >
            {loading ? t('common:loading') : t('common:no')}
          </button>
        </div>
      </div>
    </div>
  )
}

export {TabPrint}
