export const BaseRequest = {
  lang: 'en',
}

export const ListBase = {
  ...BaseRequest,
  pid: 1,
  pSize: 25,
}

export const magicnum = {
  Status: {
    Active: 1,
    InActive: 0,
  },
  EventStatus: [
    {id: 1, color: '#4AC2E8', name: 'Today'},
    {id: 2, color: '#FAB80F', name: 'Upcoming'},
    {id: 3, color: '#36A83B', name: 'Finished'},
  ],
  StatusAthlete: [
    {id: 0, class: 'badge-light-warning'},
    {id: 1, class: 'badge-light-info'},
    {id: 2, class: 'badge-light-success'},
    {id: 3, class: 'badge-light-danger'},
  ],
  StatusSport: [
    {id: 0, class: 'badge-light-warning'},
    {id: 1, class: 'badge-light-success'},
  ],
  Venue: [
    {
      id: 1,
      sports: [],
      capacity: 12500,
      map: {
        long: 47.90229526986327,
        lat: 106.91624263416213,
      },
      order: 6,
    },
    {
      id: 2,
      sports: [1],
      capacity: 3000,
      map: {
        long: 47.77933851477782,
        lat: 107.23481239548794,
      },
      order: 7,
    },
    {
      id: 3,
      sports: [2],
      capacity: 640,
      map: {
        long: 47.900175022149874,
        lat: 106.9156243060704,
      },
      order: 8,
    },
    {
      id: 4,
      sports: [3],
      capacity: 3000,
      map: {
        long: 47.9047363655452,
        lat: 106.89903279950263,
      },
      order: 9,
    },
    {
      id: 5,
      sports: [4],
      capacity: 600,
      map: {
        long: 47.91871303539227,
        lat: 106.91760543381731,
      },
      order: 10,
    },
    {
      id: 6,
      sports: [5],
      capacity: 2500,
      map: {
        long: 47.91796978740863,
        lat: 106.93540812678005,
      },
      order: 11,
    },
    {
      id: 7,
      sports: [6],
      capacity: 1300,
      map: {
        long: 47.91965953416477,
        lat: 106.91919441146376,
      },
      order: 1,
    },
    {
      id: 8,
      sports: [7],
      capacity: 5000,
      map: {
        long: 47.900175022149874,
        lat: 106.9156243060704,
      },
      order: 3,
    },
    {
      id: 9,
      sports: [8, 10, 12],
      capacity: 2600,
      map: {
        long: 47.847669815441165,
        lat: 106.79298398174015,
      },
      order: 4,
    },
    {
      id: 10,
      sports: [9],
      capacity: 300,
      map: {
        long: 47.90436236776291,
        lat: 106.89824959454056,
      },
      order: 5,
    },
    {
      id: 11,
      sports: [11],
      capacity: 5000,
      map: {
        long: 47.85453070329822,
        lat: 106.7848735369919,
      },
      order: 1,
    },
  ],
  VideoList: [
    {
      id: 1,
    },
    {
      id: 2,
    },
    {
      id: 3,
    },
    {
      id: 4,
    },
  ],
  SportCategories: [
    {
      id: 1,
      sid: 1,
      name: 'ATH',
      male: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
      female: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
      mixed: [1],
      venues: [2],
      technical: false,
    },
    {
      id: 2,
      sid: 2,
      name: 'BDM',
      male: [1, 2],
      female: [1, 2],
      mixed: [1],
      venues: [3],
      technical: false,
    },
    {
      id: 3,
      sid: 3,
      name: 'BKB',
      male: [1],
      female: [1],
      venues: [4],
      mixed: [],
      technical: false,
    },
    {
      id: 4,
      sid: 4,
      name: 'BK3',
      male: [1],
      female: [1],
      venues: [5],
      mixed: [],
      technical: false,
    },
    {
      id: 5,
      sid: 5,
      name: 'BOX',
      male: [1, 2, 3, 4, 5, 6, 7],
      female: [1, 2, 3],
      venues: [6],
      mixed: [],
      technical: true,
    },
    {
      id: 6,
      sid: 6,
      name: 'ESP',
      male: [1, 2, 3],
      female: [1, 2, 3],
      venues: [7],
      mixed: [],
      technical: false,
    },
    {
      id: 7,
      sid: 7,
      name: 'FBL',
      male: [1],
      female: [1],
      venues: [8],
      mixed: [],
      technical: true,
    },
    {
      id: 8,
      sid: 8,
      name: 'JUD',
      male: [1, 2, 3, 4, 5, 6],
      female: [1, 2, 3, 4, 5, 6],
      venues: [9],
      mixed: [],
      technical: true,
    },
    {
      id: 9,
      sid: 9,
      name: 'TTE',
      male: [1, 2, 3],
      female: [1, 2, 3],
      mixed: [1],
      venues: [10],
      technical: true,
    },
    {
      id: 10,
      sid: 10,
      name: 'TKW',
      male: [1, 2, 3, 4, 5],
      female: [1, 2, 3, 4, 5],
      venues: [9],
      mixed: [],
      technical: false,
    },
    {
      id: 11,
      sid: 11,
      name: 'VOL',
      male: [1],
      female: [1],
      venues: [11],
      mixed: [],
      technical: true,
    },
    {
      id: 12,
      sid: 12,
      name: 'WRS',
      male: [1, 2, 3, 4, 5, 6],
      female: [1, 2, 3, 4, 5, 6],
      venues: [9],
      mixed: [],
      technical: true,
    },
  ],
  SportSubCategories: [
    {
      parent: 1,
      id: 1101,
      gender: 'male',
    },
    {
      parent: 1,
      id: 1102,
      gender: 'male',
    },
    {
      parent: 1,
      id: 1103,
      gender: 'male',
    },
    {
      parent: 1,
      id: 1104,
      gender: 'male',
    },
    {
      parent: 1,
      id: 1105,
      gender: 'male',
    },
    {
      parent: 1,
      id: 1106,
      gender: 'male',
    },
    {
      parent: 1,
      id: 1107,
      gender: 'male',
    },
    {
      parent: 1,
      id: 1108,
      gender: 'male',
    },
    {
      parent: 1,
      id: 1109,
      gender: 'male',
    },
    {
      parent: 1,
      id: 1110,
      gender: 'male',
    },
    {
      parent: 1,
      id: 1111,
      gender: 'male',
    },
    {
      parent: 1,
      id: 1112,
      gender: 'male',
    },
    {
      parent: 1,
      id: 1201,
      gender: 'female',
    },
    {
      parent: 1,
      id: 1202,
      gender: 'female',
    },
    {
      parent: 1,
      id: 1203,
      gender: 'female',
    },
    {
      parent: 1,
      id: 1204,
      gender: 'female',
    },
    {
      parent: 1,
      id: 1205,
      gender: 'female',
    },
    {
      parent: 1,
      id: 1206,
      gender: 'female',
    },
    {
      parent: 1,
      id: 1207,
      gender: 'female',
    },
    {
      parent: 1,
      id: 1208,
      gender: 'female',
    },
    {
      parent: 1,
      id: 1209,
      gender: 'female',
    },
    {
      parent: 1,
      id: 1210,
      gender: 'female',
    },
    {
      parent: 1,
      id: 1211,
      gender: 'female',
    },
    {
      parent: 1,
      id: 1301,
      gender: 'team',
    },

    {
      parent: 2,
      id: 2101,
      gender: 'male',
    },
    {
      parent: 2,
      id: 2102,
      gender: 'male',
    },
    {
      parent: 2,
      id: 2201,
      gender: 'female',
    },
    {
      parent: 2,
      id: 2202,
      gender: 'female',
    },
    {
      parent: 2,
      id: 2301,
      gender: 'team',
    },

    {
      parent: 3,
      id: 3301,
      gender: 'team',
    },
    {
      parent: 4,
      id: 4301,
      gender: 'team',
    },

    {
      parent: 5,
      id: 5101,
      gender: 'male',
    },
    {
      parent: 5,
      id: 5102,
      gender: 'male',
    },
    {
      parent: 5,
      id: 5103,
      gender: 'male',
    },
    {
      parent: 5,
      id: 5104,
      gender: 'male',
    },
    {
      parent: 5,
      id: 5105,
      gender: 'male',
    },
    {
      parent: 5,
      id: 5106,
      gender: 'male',
    },
    {
      parent: 5,
      id: 5107,
      gender: 'male',
    },
    {
      parent: 5,
      id: 5201,
      gender: 'female',
    },
    {
      parent: 5,
      id: 5202,
      gender: 'female',
    },
    {
      parent: 5,
      id: 5203,
      gender: 'female',
    },
    {
      parent: 6,
      id: 6301,
      gender: 'team',
    },
    {
      parent: 6,
      id: 6302,
      gender: 'team',
    },
    {
      parent: 6,
      id: 6303,
      gender: 'team',
    },
    {
      parent: 7,
      id: 7301,
      gender: 'team',
    },
    {
      parent: 8,
      id: 8101,
      gender: 'male',
    },
    {
      parent: 8,
      id: 8102,
      gender: 'male',
    },
    {
      parent: 8,
      id: 8103,
      gender: 'male',
    },
    {
      parent: 8,
      id: 8104,
      gender: 'male',
    },
    {
      parent: 8,
      id: 8105,
      gender: 'male',
    },
    {
      parent: 8,
      id: 8106,
      gender: 'male',
    },
    {
      parent: 8,
      id: 8201,
      gender: 'female',
    },
    {
      parent: 8,
      id: 8202,
      gender: 'female',
    },
    {
      parent: 8,
      id: 8203,
      gender: 'female',
    },
    {
      parent: 8,
      id: 8204,
      gender: 'female',
    },
    {
      parent: 8,
      id: 8205,
      gender: 'female',
    },
    {
      parent: 8,
      id: 8206,
      gender: 'female',
    },
    {
      parent: 9,
      id: 9101,
      gender: 'male',
    },
    {
      parent: 9,
      id: 9102,
      gender: 'male',
    },
    {
      parent: 9,
      id: 9103,
      gender: 'male',
    },
    {
      parent: 9,
      id: 9201,
      gender: 'female',
    },
    {
      parent: 9,
      id: 9202,
      gender: 'female',
    },
    {
      parent: 9,
      id: 9203,
      gender: 'female',
    },
    {
      parent: 9,
      id: 9301,
      gender: 'team',
    },
    {
      parent: 10,
      id: 10101,
      gender: 'male',
    },
    {
      parent: 10,
      id: 10102,
      gender: 'male',
    },
    {
      parent: 10,
      id: 10103,
      gender: 'male',
    },
    {
      parent: 10,
      id: 10104,
      gender: 'male',
    },
    {
      parent: 10,
      id: 10105,
      gender: 'male',
    },
    {
      parent: 10,
      id: 10201,
      gender: 'female',
    },
    {
      parent: 10,
      id: 10202,
      gender: 'female',
    },
    {
      parent: 10,
      id: 10203,
      gender: 'female',
    },
    {
      parent: 10,
      id: 10204,
      gender: 'female',
    },
    {
      parent: 10,
      id: 10205,
      gender: 'female',
    },
    {
      parent: 11,
      id: 11301,
      gender: 'team',
    },
    {
      parent: 12,
      id: 12101,
      gender: 'male',
    },
    {
      parent: 12,
      id: 12102,
      gender: 'male',
    },
    {
      parent: 12,
      id: 12103,
      gender: 'male',
    },
    {
      parent: 12,
      id: 12104,
      gender: 'male',
    },
    {
      parent: 12,
      id: 12105,
      gender: 'male',
    },
    {
      parent: 12,
      id: 12106,
      gender: 'male',
    },
    {
      parent: 12,
      id: 12201,
      gender: 'female',
    },
    {
      parent: 12,
      id: 12202,
      gender: 'female',
    },
    {
      parent: 12,
      id: 12203,
      gender: 'female',
    },
    {
      parent: 12,
      id: 12204,
      gender: 'female',
    },
    {
      parent: 12,
      id: 12205,
      gender: 'female',
    },
    {
      parent: 12,
      id: 12206,
      gender: 'female',
    },
  ],
  NOC: [
    {
      id: 1,
      code: 'CHN',
      name: "People's Republic of China",
      web: 'http://en.olympic.cn/',
      email: 'coc@olympic.cn',
      phone: '+861067116669',
      order: 1,
      interest: true,
    },
    {
      id: 2,
      code: 'PRK',
      name: 'D.P.R Korea',
      web: 'https://olympics.com/ioc/democratic-people-s-republic-of-korea',
      email: 'prk@star-co.net.kp',
      phone: '+850218111999',
      order: 10,
      interest: false,
    },
    {
      id: 3,
      code: 'HKG',
      name: 'Hong Kong, China',
      web: 'https://www.hkolympic.org/',
      email: 'secretariat@hkolympic.org',
      phone: '+85225048560',
      order: 3,
      interest: true,
    },
    {
      id: 4,
      code: 'JPN',
      name: 'Japan',
      web: 'http://www.joc.or.jp',
      email: 'jpn-noc@joc.or.jp',
      phone: '+81369105956',
      order: 4,
      interest: true,
    },
    {
      id: 5,
      code: 'KOR',
      name: 'Republic of Korea',
      web: 'http://www.sports.or.kr/eng',
      email: 'koc@sports.or.kr',
      phone: '+8224204214',
      order: 5,
      interest: true,
    },
    {
      id: 6,
      code: 'MAC',
      name: 'Macau, China',
      web: 'http://www.macauolympic.org/EN/',
      email: 'noc@macauolympic.org',
      phone: '+85328571037',
      order: 6,
      interest: true,
    },
    {
      id: 7,
      code: 'TPE',
      name: 'Chinese Taipei',
      web: 'http://www.tpenoc.net/',
      email: 'international@tpe-olympic.org.tw',
      phone: '+886227521442',
      order: 7,
      interest: true,
    },
    {
      id: 9,
      code: 'GUM',
      name: 'Guam',
      web: 'https://oceaniasport.com',
      email: 'secretariat@guamnoc.org',
      phone: '+16716474662',
      order: 2,
      interest: false,
    },
    {
      id: 8,
      code: 'MGL',
      name: 'Mongolia',
      web: 'https://olympic.mn/',
      email: 'noc@olympic.mn',
      phone: '+97611345285',
      order: 8,
      interest: true,
    },
    {
      id: 10,
      code: 'OTHER',
      name: 'OTHER',
      web: 'https://olympics.com/ioc/democratic-people-s-republic-of-korea',
      email: 'prk@star-co.net.kp',
      phone: '+850218111999',
      order: 11,
      interest: true,
    },
    {
      id: 11,
      code: 'LOCAL',
      name: 'LOCAL',
      web: 'https://olympics.com/ioc/democratic-people-s-republic-of-korea',
      email: 'prk@star-co.net.kp',
      phone: '+850218111999',
      order: 12,
      interest: true,
    },
  ],
  subMenu: [
    {
      parent: 1,
      id: 1,
      link: '/about',
    },
    {
      parent: 1,
      id: 2,
      link: '/organizing-committee',
    },
    {
      parent: 1,
      id: 3,
      link: '/ambassador',
    },
    {
      parent: 1,
      id: 4,
      link: '#',
    },
    {
      parent: 1,
      id: 5,
      link: '#',
    },
    {
      parent: 1,
      id: 6,
      link: '/sponsors',
    },
    {
      parent: 1,
      id: 7,
      link: '#       ',
    },
    {
      parent: 2,
      id: 1,
      link: '/sports/all',
    },
    {
      parent: 2,
      id: 2,
      link: '/noc',
    },
    {
      parent: 2,
      id: 3,
      link: '/venues',
    },
    {
      parent: 2,
      id: 4,
      link: '#',
    },
    {
      parent: 3,
      id: 1,
      link: '/cep',
    },
    {
      parent: 3,
      id: 2,
      link: '/volunteer',
    },
    {
      parent: 3,
      id: 3,
      link: '/doping',
    },
    {
      parent: 4,
      id: 4,
      link: '/contact',
    },
  ],
  moreMenu: [
    {
      parent: 1,
      sub: [
        {id: 1, link: '/#'},
        {id: 2, link: '/#'},
      ],
    },
    {
      parent: 2,
      sub: [
        {id: 1, link: '/#'},
        {id: 2, link: '/#'},
        {id: 3, link: '/#'},
      ],
    },
    {
      parent: 3,
      link: '/volunteer',
    },
    {
      parent: 4,
      link: '#',
    },
    {
      parent: 5,
      link: 'doping',
    },
    {
      parent: 6,
      link: '#',
    },
    {
      parent: 7,
      link: '#',
    },
  ],
  sponsor: [
    {
      parent: 1,
      id: 1,
      logos: [1, 2, 3],
    },
    {
      id: 2,
      parent: 2,
      logos: [],
    },
    {
      id: 3,
      parent: 3,
      logos: [],
    },
    {
      id: 4,
      parent: 4,
      logos: [],
    },
    {
      id: 5,
      parent: 5,
      logos: [],
    },
    {
      id: 6,
      parent: 6,
      logos: [1, 2, 3],
    },
  ],
  Associations: [
    {
      sid: 1,
      id: 1,
      fb: '',
      web: 'http://www.athleticsasia.org',
      email: 'sec@asianathleticsassociation.org',
      tel: '+6620266321',
    },
    {
      sid: 1,
      id: 2,
      fb: 'https://www.facebook.com/profile.php?id=100032389461714&sk=about',
      web: 'mglathletics.org',
      email: 'mgl@mf.worldathletics.org',
      tel: '+11324294',
    },
    {
      sid: 2,
      id: 1,
      fb: '',
      web: 'https://www.badmintonasia.org/',
      email: 'admin@badmintonasia.org​',
      tel: '+60377338962',
    },
    {
      sid: 2,
      id: 2,
      fb: 'https://www.facebook.com/badminton.mn',
      web: '',
      email: 'uuganaa89bwf@gmail.com',
      tel: '+97680538053',
    },
    {
      sid: 3,
      id: 1,
      fb: 'https://www.facebook.com/FIBAAsiaCC/',
      web: 'https://www.fiba.basketball/asia ',
      email: 'info@fiba.basketball',
      tel: '+41225450000',
    },
    {
      sid: 3,
      id: 2,
      fb: 'https://www.facebook.com/Mongolia.Basketball',
      web: 'https://mongolia.basketball/public',
      email: 'secretary@mongolia.basketball',
      tel: '+97677774144',
    },
    {
      sid: 4,
      id: 1,
      fb: 'https://www.facebook.com/2023FIBA3x3AsiaCup',
      web: 'https://fiba3x3.com/',
      email: '',
      tel: '',
    },
    {
      sid: 4,
      id: 2,
      fb: 'https://www.facebook.com/MGL3x3',
      web: 'https://fiba3x3.com/',
      email: 'loc@mgl3x3.com',
      tel: '+97688886579',
    },
    {
      sid: 5,
      id: 1,
      fb: 'https://www.facebook.com/ASBC.Official',
      web: 'http://www.asbcnews.org/',
      email: 'info@asbcnews.org',
      tel: '',
    },
    {
      sid: 5,
      id: 2,
      fb: 'https://www.facebook.com/mongolianboxingfederation',
      web: '',
      email: 'mongolianboxing@yahoo.com',
      tel: '11331181',
    },
    {
      sid: 6,
      id: 1,
      fb: 'https://www.facebook.com/asian.esports',
      web: 'https://www.aesf.com/en/index.html ',
      email: 'secretariat@aesf.com',
      tel: '+85264605555',
    },
    {
      sid: 6,
      id: 2,
      fb: '',
      web: '',
      email: '',
      tel: '+97699188762',
    },
    {
      sid: 7,
      id: 1,
      fb: '',
      web: 'https://eaff.com/ ',
      email: 'info@theeaff.com',
      tel: '',
    },
    {
      sid: 7,
      id: 2,
      fb: 'https://www.facebook.com/MongolianFootballFederation/',
      web: 'http://www.the-mff.mn/',
      email: 'mongolianff@the-mff.mn',
      tel: '+97611345964',
    },
    {
      sid: 8,
      id: 1,
      fb: '',
      web: 'https://www.onlinejua.org/ ',
      email: 'alanzi@jua-president.com',
      tel: '+9652265349',
    },
    {
      sid: 8,
      id: 2,
      fb: 'https://www.facebook.com/MongolianJudoAssociation',
      web: 'http://www.the-mff.mn/',
      email: 'judodata01@gmail.com',
      tel: '+97698236666',
    },
    {
      sid: 9,
      id: 1,
      fb: 'facebook.com/AsianTableTennisUnion',
      web: 'https://asia.ittf.com/home  ',
      email: 'm_kabbani@attu.org',
      tel: '+97444944190',
    },
    {
      sid: 9,
      id: 2,
      fb: 'https://www.facebook.com/officialmtta',
      web: '',
      email: 'mtta@mongol.net',
      tel: '+97699156872',
    },
    {
      sid: 10,
      id: 1,
      fb: 'https://www.facebook.com/AsianTaekwondoUnion',
      web: 'http://www.wtasia.org/ ',
      email: 'admin@wtasia.org',
      tel: '+82317089994',
    },
    {
      sid: 10,
      id: 2,
      fb: 'https://www.facebook.com/MongolTaekwondo/',
      web: 'https://www.mongol-taekwondo.com/',
      email: 'mongoliataekwondo@gmail.com ',
      tel: '+97670155500',
    },
    {
      sid: 11,
      id: 1,
      fb: 'https://www.facebook.com/avcvolley/',
      web: 'https://asianvolleyball.net/new/ ',
      email: 'avc.bk@asianvolleyball.net',
      tel: '+66213628613',
    },
    {
      sid: 11,
      id: 2,
      fb: 'https://www.facebook.com/mongolianvolleyballassociation',
      web: 'https://mva.mn/',
      email: 'info@mva.mn',
      tel: '+97611344340',
    },
    {
      sid: 12,
      id: 1,
      fb: 'https://www.facebook.com/UWWASIA',
      web: 'https://uww.asia/?lang=en ',
      email: 'office@uww.asia',
      tel: '',
    },
    {
      sid: 12,
      id: 2,
      fb: 'https://www.facebook.com/MongolianWrestlingFederation',
      web: 'http://www.wrestling.mn/',
      email: 'mgl@fila-wrestling.com',
      tel: '+97611314976',
    },
  ],
  contact: [
    {
      id: 1,
      title: 'Organising Commitee Headquarters',
      description:
        'Quisque nullam sed eu magna lacus semper massa felis massa. Luctus quam tincidunt feugiat lacus.',
    },
  ],
  socials: [
    {
      id: 1,
      web: 'Web: http://www.ulaanbaatar.org/​',
      tel: 'Tel: (+976) 9999 9999',
      email: 'Mail: info@ulaanbaatar2023.org',
    },
  ],
  news: [1, 2, 3, 4, 5, 6, 7, 8],

  accreditationCategory: [
    {
      parent: 'VVIP',
      id: 'VVIP',
      color: '#36A93A',
      transportation: 'T1',
      hotel: 'HQ',
      dining: true,
      venue: 'ALL',
      zone: [4, 5],
      num: 1,
    },
    {
      parent: 'VIP',
      id: 'VIP',
      color: '#36A93A',
      transportation: 'T1',
      hotel: 'HQ',
      dining: true,
      venue: 'ALL',
      zone: [4, 5],
      num: 1,
    },

    {
      parent: 'D',
      id: 'D',
      color: '#49C1E8',
      transportation: 'T2',
      hotel: 'H',
      dining: true,
      venue: 'specific',
      zone: [1, 2, 3, 4],
      num: 2,
    },

    {
      parent: 'R',
      id: 'R',
      color: '#49C1E8',
      transportation: 'T2',
      hotel: 'H',
      dining: true,
      venue: 'specific',
      zone: [1, 2, 3, 4],
      num: 2,
    },

    {
      parent: 'A',
      id: 'Ao',
      color: '#FAB710',
      transportation: 'TA',
      hotel: 'H',
      dining: true,
      venue: 'specific',
      zone: [1, 2],
      num: 3,
    },
    {
      parent: 'A',
      id: 'Ac',
      color: '#FAB710',
      transportation: 'TA',
      hotel: 'H',
      dining: true,
      venue: 'specific',
      zone: [1, 2],
      num: 3,
    },
    {
      parent: 'A',
      id: 'Aa',
      color: '#FAB710',
      transportation: 'TA',
      hotel: 'H',
      dining: true,
      venue: 'specific',
      zone: [1, 2],
      num: 3,
    },
    {
      parent: 'A',
      id: 'Am',
      color: '#FAB710',
      transportation: 'TA',
      hotel: 'H',
      dining: true,
      venue: 'specific',
      zone: [1, 2, 3, 4, 5],
      num: 3,
    },

    {
      parent: 'E',
      id: 'E',
      color: '#FF404C',
      transportation: 'T3',
      hotel: 'H',
      dining: true,
      venue: 'ALL',
      zone: [3, 4],
      num: 4,
    },
    {
      parent: 'E',
      id: 'Ep',
      color: '#FF404C',
      transportation: 'T3',
      hotel: 'H',
      dining: true,
      venue: 'ALL',
      zone: [1, 2, 3, 4],
      num: 4,
    },
    {
      parent: 'E',
      id: 'ENR',
      color: '#FF404C',
      transportation: 'T3',
      hotel: 'H',
      dining: true,
      venue: 'ALL',
      zone: [1, 2, 3, 4],
      num: 4,
    },
    {
      parent: 'E',
      id: 'RT',
      color: '#FF404C',
      transportation: 'T3',
      hotel: 'H',
      dining: true,
      venue: 'ALL',
      zone: [1, 2, 3, 4],
      num: 4,
    },
    {
      parent: 'E',
      id: 'YRP',
      color: '#FF404C',
      transportation: 'T3',
      hotel: 'H',
      dining: true,
      venue: 'ALL',
      zone: [3, 4],
      num: 4,
    },

    {
      parent: 'S',
      id: 'Ss',
      color: '#023263',
      transportation: 'T3',
      hotel: 'H',
      dining: true,
      venue: 'ALL',
      zone: [1, 2, 3, 4, 5],
      num: 5,
    },
    {
      parent: 'S',
      id: 'St',
      color: '#023263',
      transportation: 'T3',
      hotel: '',
      dining: true,
      venue: 'ALL',
      zone: [1, 2, 3, 4, 5],
      num: 5,
    },
    {
      parent: 'S',
      id: 'So',
      color: '#023263',
      transportation: 'T3',
      hotel: '',
      dining: true,
      venue: 'ALL',
      zone: [1, 2, 3, 4, 5],
      num: 5,
    },
    {
      parent: 'S',
      id: 'Sl',
      color: '#023263',
      transportation: 'T3',
      hotel: '',
      dining: true,
      venue: 'ALL',
      zone: [1, 2, 3, 4, 5],
      num: 5,
    },
    {
      parent: 'S',
      id: 'S',
      color: '#023263',
      transportation: 'T3',
      hotel: '',
      dining: true,
      venue: 'ALL',
      zone: [1, 2, 3, 4, 5],
      num: 5,
    },
    {
      parent: 'S',
      id: 'W',
      color: '#023263',
      transportation: 'T3',
      hotel: '',
      dining: true,
      venue: 'ALL',
      zone: [1, 2, 3, 4, 5],
      num: 5,
    },

    {
      parent: 'V',
      id: 'V',
      color: '#9738ef',
      transportation: 'TP',
      hotel: '',
      dining: false,
      venue: 'ALL',
      zone: [1, 2, 3, 4, 5],
      num: 6,
    },

    {
      parent: 'OC',
      id: 'OC',
      color: '#c8c8c8',
      transportation: 'T3',
      hotel: '',
      dining: true,
      venue: 'ALL',
      zone: [1, 2, 3, 4, 5],
      num: 6,
    },
  ],
  accreditationSubCategory: [
    {
      parent: 'VVIP',
      id: '1',
      num: 1,
    },
    {
      parent: 'VVIP',
      id: '2',
      num: 2,
    },
    {
      parent: 'VVIP',
      id: '3',
      num: 3,
    },
    {
      parent: 'VVIP',
      id: '4',
      num: 4,
    },

    {
      parent: 'VIP',
      id: '5',
      num: 5,
    },
    {
      parent: 'VIP',
      id: '6',
      num: 5,
    },
    {
      parent: 'VIP',
      id: '7',
      num: 6,
    },
    {
      parent: 'VIP',
      id: '8',
      num: 7,
    },
    {
      parent: 'VIP',
      id: '9',
      num: 8,
    },

    {
      parent: 'D',
      id: '10',
      num: 1,
    },
    {
      parent: 'R',
      id: '11',
      num: 2,
    },
    // {
    //   parent: 'D',
    //   id: '12',
    //   num: 3,
    // },

    {
      parent: 'Ao',
      id: '13',
      num: 1,
    },
    {
      parent: 'Ac',
      id: '14',
      num: 2,
    },
    {
      parent: 'Aa',
      id: '15',
      num: 3,
    },
    {
      parent: 'Am',
      id: '16',
      num: 4,
    },
    {
      parent: 'Am',
      id: '17',
      num: 4,
    },
    {
      parent: 'Am',
      id: '12',
      num: 4,
    },

    {
      parent: 'E',
      id: '18',
      num: 1,
    },
    {
      parent: 'Ep',
      id: '19',
      num: 2,
    },
    {
      parent: 'ENR',
      id: '20',
      num: 3,
    },
    {
      parent: 'RT',
      id: '21',
      num: 4,
    },
    {
      parent: 'YRP',
      id: '22',
      num: 5,
    },

    {
      parent: 'Ss',
      id: '23',
      num: 1,
    },
    {
      parent: 'St',
      id: '24',
      num: 2,
    },
    {
      parent: 'So',
      id: '25',
      num: 3,
    },
    {
      parent: 'Sl',
      id: '26',
      num: 4,
    },
    {
      parent: 'S',
      id: '27',
      num: 5,
    },
    {
      parent: 'W',
      id: '28',
      num: 6,
    },

    {
      parent: 'V',
      id: '29',
      num: 1,
    },
    {
      parent: 'OC',
      id: '30',
      num: 1,
    },
  ],
  accreditationCategoryNew: [
    {
      parent: 'VVIP',
      id: 'VVIP',
      color: '#36A93A',
      num: 1,
    },
    {
      parent: 'VIP',
      id: 'VIP',
      color: '#36A93A',
      num: 1,
    },

    {
      parent: 'D',
      id: 'D',
      color: '#49C1E8',
      num: 2,
    },

    {
      parent: 'A',
      id: 'Ac',
      color: '#FAB710',
      num: 3,
    },
    {
      parent: 'A',
      id: 'Ao',
      color: '#FAB710',
      num: 3,
    },

    {
      parent: 'A',
      id: 'Aa',
      color: '#FAB710',
      num: 3,
    },
    {
      parent: 'A',
      id: 'Am',
      color: '#FAB710',
      num: 3,
    },

    {
      parent: 'E',
      id: 'E',
      color: '#FF404C',
      num: 4,
    },
    {
      parent: 'E',
      id: 'Ep',
      color: '#FF404C',
      num: 4,
    },
    {
      parent: 'E',
      id: 'ENR',
      color: '#FF404C',
      num: 4,
    },
    {
      parent: 'E',
      id: 'RT',
      color: '#FF404C',
      num: 4,
    },
    {
      parent: 'E',
      id: 'YRP',
      color: '#FF404C',
      num: 4,
    },

    {
      parent: 'S',
      id: 'Ss',
      color: '#023263',
      num: 5,
    },
    {
      parent: 'S',
      id: 'St',
      color: '#023263',
      num: 5,
    },
    {
      parent: 'S',
      id: 'So',
      color: '#023263',
      num: 5,
    },
    {
      parent: 'S',
      id: 'Sl',
      color: '#023263',
      num: 5,
    },
    {
      parent: 'S',
      id: 'S',
      color: '#023263',
      num: 5,
    },
    {
      parent: 'S',
      id: 'W',
      color: '#023263',
      num: 5,
    },

    {
      parent: 'V',
      id: 'V',
      color: '#9738ef',
      num: 6,
    },

    {
      parent: 'OC',
      id: 'OC',
      color: '#c8c8c8',
      num: 6,
    },
  ],
  accreditationSubCategoryNew: [
    {
      main: 'VVIP',
      parent: 'VVIP',
      id: 'VVIP-1',
      num: 1,
      transportation: 'T1',
      hotel: 'HQ',
      dining: true,
      venue: 'ALL',
      seat: 'VIP',
      zone: [1, 2, 4, 5],
    },
    {
      main: 'VVIP',
      parent: 'VVIP',
      id: 'VVIP-2',
      num: 2,
      transportation: 'T1',
      hotel: 'HQ',
      dining: true,
      venue: 'ALL',
      seat: 'VIP',
      zone: [1, 2, 4, 5],
    },
    {
      main: 'VVIP',
      parent: 'VVIP',
      id: 'VVIP-3',
      num: 3,
      transportation: 'T1',
      hotel: 'HQ',
      dining: true,
      venue: 'ALL',
      seat: 'VIP',
      zone: [1, 2, 4, 5],
    },
    {
      main: 'VVIP',
      parent: 'VVIP',
      id: 'VVIP-4',
      num: 4,
      transportation: 'T1',
      hotel: 'HQ',
      dining: true,
      venue: 'ALL',
      seat: 'VIP',
      zone: [1, 2, 4, 5],
    },
    {
      main: 'VVIP',
      parent: 'VVIP',
      id: 'VVIP-5',
      num: 5,
      transportation: 'T1',
      hotel: 'HQ',
      dining: true,
      venue: 'ALL',
      seat: 'VIP',
      zone: [1, 2, 4, 5],
    },
    {
      main: 'VVIP',
      parent: 'VVIP',
      id: 'VVIP-6',
      num: 6,
      transportation: 'T1',
      hotel: 'HQ',
      dining: true,
      venue: 'ALL',
      seat: 'VIP',
      zone: [1, 2, 4, 5],
    },
    {
      main: 'VVIP',
      parent: 'VVIP',
      id: 'VVIP-7',
      num: 7,
      transportation: 'T1',
      hotel: 'HQ',
      dining: true,
      venue: 'ALL',
      seat: 'VIP',
      zone: [1, 2, 4, 5],
    },
    {
      main: 'VVIP',
      parent: 'VVIP',
      id: 'VVIP-8',
      num: 8,
      transportation: 'T1',
      hotel: 'HQ',
      dining: true,
      venue: 'ALL',
      seat: 'VIP',
      zone: [1, 2, 4, 5],
    },

    {
      main: 'VIP',
      parent: 'VIP',
      id: 'VIP-1',
      num: 1,
      transportation: 'T1',
      hotel: 'HQ',
      dining: true,
      venue: 'ALL',
      seat: 'VIP',
      zone: [1, 2, 4, 5],
    },
    {
      main: 'VIP',
      parent: 'VIP',
      id: 'VIP-2',
      num: 2,
      transportation: 'T1',
      hotel: 'HQ',
      dining: true,
      venue: 'ALL',
      seat: 'VIP',
      zone: [1, 2, 4, 5],
    },
    {
      main: 'VIP',
      parent: 'VIP',
      id: 'VIP-3',
      num: 3,
      transportation: 'T1',
      hotel: 'HQ',
      dining: true,
      venue: 'ALL',
      seat: 'VIP',
      zone: [1, 2, 4, 5],
    },
    {
      main: 'VIP',
      parent: 'VIP',
      id: 'VIP-4',
      num: 4,
      transportation: 'T1',
      hotel: 'HQ',
      dining: true,
      venue: 'ALL',
      seat: 'VIP',
      zone: [1, 2, 4, 5],
    },
    {
      main: 'VIP',
      parent: 'VIP',
      id: 'VIP-5',
      num: 5,
      transportation: 'T1',
      hotel: 'HQ',
      dining: true,
      venue: 'ALL',
      seat: 'VIP',
      zone: [1, 2, 4, 5],
    },
    {
      main: 'VIP',
      parent: 'VIP',
      id: 'VIP-6',
      num: 6,
      transportation: '7-seat',
      hotel: 'HQ',
      dining: true,
      venue: 'ALL',
      seat: 'VIP',
      zone: [1, 2, 4, 5],
    },
    {
      main: 'VIP',
      parent: 'VIP',
      id: 'VIP-7',
      num: 7,
      transportation: 'T1 VVIP',
      hotel: 'HQ',
      dining: true,
      venue: 'ALL',
      seat: 'VIP',
      zone: [1, 2, 4, 5],
    },

    {
      main: 'D',
      parent: 'D',
      id: 'D-1',
      num: 1,
      transportation: 'TF',
      hotel: 'H',
      dining: true,
      venue: 'specific',
      seat: 'D',
      zone: [1, 2, 3, 4],
    },
    {
      main: 'D',
      parent: 'D',
      id: 'D-2',
      num: 2,
      transportation: 'TF',
      hotel: 'H',
      dining: true,
      venue: 'specific',
      seat: 'D',
      zone: [1, 2, 3, 4],
    },
    // {
    //   main: 'D',
    //   parent: 'D',
    //   id: 'D-3',
    //   num: 3,
    //   transportation: 'TF',
    //   hotel: 'H',
    //   dining: true,
    //   venue: 'specific',
    //   seat: 'D',
    //   zone: [1, 2, 3, 4],
    // },

    {
      main: 'A',
      parent: 'Ac',
      id: 'A-1',
      num: 1,
      transportation: 'TA',
      hotel: 'HQ',
      dining: true,
      venue: 'all',
      seat: 'VIP',
      zone: [1, 2, 4, 5],
    },
    {
      main: 'A',
      parent: 'Ao',
      id: 'A-2',
      num: 2,
      transportation: 'TA',
      hotel: 'HQ',
      dining: true,
      venue: 'all',
      seat: 'A',
      zone: [1, 2],
    },
    {
      main: 'A',
      parent: 'Ao',
      id: 'A-3',
      num: 3,
      transportation: 'TA',
      hotel: 'H',
      dining: true,
      venue: 'specific',
      seat: 'A',
      zone: [1, 2],
    },
    {
      main: 'A',
      parent: 'Aa',
      id: 'A-4',
      num: 4,
      transportation: 'TA',
      hotel: 'H',
      dining: true,
      venue: 'specific',
      seat: 'A',
      zone: [1, 2],
    },
    {
      main: 'A',
      parent: 'Am',
      id: 'A-5',
      num: 5,
      transportation: 'TA',
      hotel: 'H',
      dining: true,
      venue: 'all',
      seat: 'A',
      zone: [1, 2],
    },
    {
      main: 'A',
      parent: 'Am',
      id: 'A-6',
      num: 6,
      transportation: 'TA',
      hotel: 'HQ',
      dining: true,
      venue: 'all',
      seat: 'A',
      zone: [1, 2],
    },
    {
      main: 'A',
      parent: 'Am',
      id: 'A-7',
      num: 7,
      transportation: 'TA',
      hotel: 'H',
      dining: true,
      venue: 'all',
      seat: 'A',
      zone: [1, 2],
    },

    {
      main: 'E',
      parent: 'E',
      id: 'E-1',
      num: 1,
      transportation: 'TM',
      hotel: 'H',
      dining: true,
      venue: 'all',
      seat: 'E',
      zone: [3, 4],
    },
    {
      main: 'E',
      parent: 'Ep',
      id: 'E-2',
      num: 2,
      transportation: 'TM',
      hotel: 'H',
      dining: true,
      venue: 'all',
      seat: 'E',
      zone: [1, 2, 3, 4],
    },
    {
      main: 'E',
      parent: 'ENR',
      id: 'E-3',
      num: 3,
      transportation: 'TM',
      hotel: 'H',
      dining: true,
      venue: 'all',
      seat: 'E',
      zone: [1, 2, 3, 4],
    },
    {
      main: 'E',
      parent: 'RT',
      id: 'E-4',
      num: 4,
      transportation: 'TM',
      hotel: 'H',
      dining: true,
      venue: 'all',
      seat: 'E',
      zone: [1, 2, 3, 4],
    },
    {
      main: 'E',
      parent: 'YRP',
      id: 'E-5',
      num: 5,
      transportation: 'TM',
      hotel: 'H',
      dining: true,
      venue: 'all',
      seat: 'E',
      zone: [3, 4],
    },

    {
      main: 'S',
      parent: 'Ss',
      id: 'S-1',
      num: 1,
      transportation: 'TP',
      hotel: '',
      dining: true,
      venue: 'Depending on the function',
      seat: 'S',
      zone: ['Depending on the function'],
    },
    {
      main: 'S',
      parent: 'St',
      id: 'S-2',
      num: 2,
      transportation: 'TP',
      hotel: '',
      dining: true,
      venue: 'Depending on the function',
      seat: 'S',
      zone: ['Depending on the function'],
    },
    {
      main: 'S',
      parent: 'So',
      id: 'S-3',
      num: 3,
      transportation: 'TP',
      hotel: '',
      dining: true,
      venue: 'Depending on the function',
      seat: 'S',
      zone: ['Depending on the function'],
    },
    {
      main: 'S',
      parent: 'Sl',
      id: 'S-4',
      num: 4,
      transportation: 'TP',
      hotel: '',
      dining: true,
      venue: 'Depending on the function',
      seat: 'S',
      zone: ['Depending on the function'],
    },
    {
      main: 'S',
      parent: 'S',
      id: 'S-5',
      num: 5,
      transportation: 'TP',
      hotel: '',
      dining: true,
      venue: 'Depending on the function',
      seat: 'S',
      zone: ['Depending on the function'],
    },
    {
      main: 'S',
      parent: 'W',
      id: 'S-6',
      num: 6,
      transportation: 'TP',
      hotel: '',
      dining: true,
      venue: 'Depending on the function',
      seat: 'S',
      zone: ['Depending on the function'],
    },

    {
      main: 'V',
      parent: 'V',
      id: 'V-1',
      num: 1,
      transportation: 'TP',
      hotel: '',
      dining: false,
      venue: 'Depending on the function',
      seat: '',
      zone: ['Depending on the function'],
    },

    {
      main: 'OC',
      parent: 'OC',
      id: 'OC-1',
      num: 1,
      transportation: 'TP',
      hotel: '',
      dining: false,
      venue: 'ALL',
      seat: 'ALL',
      zone: ['ALL'],
    },
  ],
  accreditationSubCategoryNewLocal: ['LO-1', 'LO-2', 'LO-3', 'LO-4', 'LO-5', 'LO-6'],
  hotel: [
    {
      code: 'H1',
      name: 'Resort World Terelj Hotel',
      phone: '976-99992233',
      address: 'Nalaikh District, 6th microdistrict, Ulaanbaatar, Mongolia',
      sid: [1],
    },
    {
      code: 'H2',
      name: 'Amar Hotel',
      phone: '976-70078188',
      address: 'Peace Avenue, 13-1, Bayanzurh District Khoroo 14, Ulaanbaatar, 11000, Mongolia',
      sid: [2, 6],
    },
    {
      code: 'H3',
      name: 'Grand Hill Hotel',
      phone: '976-77115599',
      address: 'Damdinbazar street, 4th micro district, Ulaanbaatar, Mongolia',
      sid: [3],
    },
    {
      code: 'H4',
      name: 'Millennium Plaza Hotel',
      phone: '976-77305599',
      address: 'Bayanzurkh District building 46, Tokyo street, Ulaanbaatar, Mongolia',
      sid: [5, 10],
    },
    {
      code: 'H5',
      name: 'Bayangol Hotel',
      phone: '976-11312255',
      address: 'Bayangol Hotel, Chinggis avenue-5, Ulaanbaatar 14251, Mongolia',
      sid: [4, 7],
    },
    {
      code: 'H6',
      name: 'Zuchi Hotel',
      phone: '976-77777710',
      address: 'Chinggis Ave 15, Khan-Uul district, Ulaanbaatar, Mongolia',
      sid: [8],
    },
    {
      code: 'H7',
      name: 'Handu Hotel',
      phone: '976-70009777',
      address: 'Bayangol District, Khoroo 5, Ulaanbaatar, Mongolia',
      sid: [9],
    },
    {
      code: 'H8',
      name: 'Eagle Town Hotel',
      phone: '976-99095941',
      address:
        'Khan Uul district, 21st khoroo, Archive road street, Eagle town, Ulaanbaatar, Mongolia',
      sid: [11],
    },
    {
      code: 'H9',
      name: 'Aurora Hotel',
      phone: '976-70070808',
      address:
        '10th Khoroolol 8, 5th Khoroo, Peace Avenue, Bayangol District, Ulaanbaatar, Mongolia',
      sid: [12],
    },
    {
      code: 'HQ-1',
      name: 'Tuushin Hotel',
      phone: '976-11323162',
      address: "Premier Minister Amar's street 15, Ulaanbaatar 142008, Mongolia",
      sid: [],
    },
    {
      code: 'HQ-2',
      name: 'Blue Sky Hotel',
      phone: '976-70100505',
      address: 'Peace Avenue-17, Blue Sky Tower, Ulaanbaatar, Mongolia',
      sid: [],
    },
    {
      code: 'HD',
      name: 'Chinggis Khan Hotel',
      phone: '976-70000099',
      address: 'Tokyo street 10, Ulaanbaatar, Mongolia',
      sid: [],
    },
    {
      code: 'HV',
      name: 'UB City Hotel',
      phone: '976-70137778',
      address: 'Too Vangiin avenue, Khan-Uul district, Ulaanbaatar, Mongolia',
      sid: [],
    },
  ],
  passport: [1, 2, 3, 4],
  month: [7, 8],
  day: [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
    27, 28, 29, 30, 31,
  ],
  suffix: ['Dr', 'Miss', 'Mr', 'Mrs', 'Ms', 'Prof'],
  food: ['No preference', 'Vegan', 'Vegetarian', 'Halal'],
  bloodType: ['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-'],
  contact: [
    'Phone',
    'Email',
    'Facebook',
    'Instagram',
    'Tiktok',
    'Douyin',
    'Wechat',
    'Kuaishou',
    'Bilibili',
    'NaverBlog',
  ],
  volunteer: {
    team: [1, 2, 3, 4, , 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
    category: [1, 2, 3, 4, , 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
  },
  docs: [
    {name: 'Малгай', id: 1, number: 1},
    {name: 'Футболк', id: 2, number: 2},
    {name: 'Өмд', id: 3, number: 1},
    {name: 'Борооны цув', id: 4, number: 1},
    {name: 'Спорт гутал', id: 5, number: 1},
    {name: 'Оймс', id: 6, number: 2},
    {name: 'Хоолойвч', id: 7, number: 1},
    {name: 'Алчуур ', id: 8, number: 1},
    {name: 'Усны сав', id: 9, number: 1},
    {name: 'Бүсний цүнх (Паск)', id: 10, number: 1},
  ],
}

//#region employee
export const AuthReq = {
  ...BaseRequest,
  Username: '',
  Password: '',
  Status: '',
  FirstName: '',
  LastName: '',
  ExpiredDate: '',
  TypeID: '',
  file: '',
}

export const AuthCreateReq = {
  ...BaseRequest,
  UserName: '',
  Password: '',
}

export const ChangePassReq = {
  ...BaseRequest,
  EmployeeID: '',
  newPassword: '',
  OLdPass: '',
  IsAdmin: false,
  realOldPass: '',
}

export const EmployeeChangeInfoReq = {
  ...BaseRequest,
  FirstName: '',
  LastName: '',
  Username: '',
  Status: 0,
  TypeID: '',
  Noc: '',
  id: '',
}

export const EmployeeAddReq = {
  ...BaseRequest,
  Username: '',
  Password: '',
  FirstName: '',
  LastName: '',
  Status: 0,
  TypeID: '',
  Noc: '',
  Photo: '',
}

export const EmployeeChangeStatusReq = {
  ...BaseRequest,
  status: 0,
  id: '',
}

export const listEmployeeReq = {
  ...ListBase,
  filter: {
    Name: '',
    SoHoID: '',
    HeHoID: '',
    HesegID: '',
    BagID: '',
    Status: '',
    Noc: '',
  },
}
//#endregion employee

//#region Privilege
export const PrivilegeGroupAddReq = {
  ...BaseRequest,
  mPriv_Group: {
    PrivGroupID: '',
    PrivGroupName: '',
    IsUserGroup: '',
  },
}

export const PrivilegeGroupMoreReq = {
  ...BaseRequest,
  mPriv_Group: {},
}

export const PrivilegeGroupDeleteReq = {
  ...BaseRequest,
  mPriv_Group: {},
}

export const PrivilegeAddReq = {
  ...BaseRequest,
  priv: {
    privNo: '',
    privName: '',
  },
}

export const PrivilegeDeleteReq = {
  ...BaseRequest,
  mPriv: {},
}

export const PrivilegeUserListReq = {
  ...BaseRequest,
  privNo: '',
}

export const GetEmployeeByIdReq = {
  ...BaseRequest,
  EmployeeID: '',
}

export const PrivilegeMP_GroupUserAddReq = {
  ...BaseRequest,
  userKey: '',
  mPriv_Groups: [{privGroupId: null}],
}

export const AddMPriv_GroupPrivReq = {
  ...BaseRequest,
  privGroupId: '',
  mPrivs: [],
}

export const GetMPriv_GroupPrivReq = {
  ...BaseRequest,
  privGroupId: '',
}

export const ListMPriv_GroupPrivReq = {
  ...BaseRequest,
  MPriv_Group: {},
}
//#endregion

//#region Log
export const LogActionReq = {
  ...ListBase,
  filter: {
    Controller: '',
    Action: '',
    UserID: '',
    BeginDate: '',
    EndDate: '',
  },
}

export const LogErrorReq = {
  ...ListBase,
  filter: {
    Controller: '',
    Error: '',
    UserID: '',
    BeginDate: '',
    EndDate: '',
  },
}
//#endregion

//#region News
export const NewsListReq = {
  ...ListBase,
  filter: {
    title: '',
    lang: '',
    category: '',
  },
}

export const NewsAddReq = {
  ...BaseRequest,
  news: {
    title: '',
    lang: '',
    category: 0,
    desc: '',
    image_link: '',
  },
}

export const NewsChangeImageReq = {
  ...BaseRequest,
  news: {
    old_image_link: '',
    image_link: '',
    id: '',
  },
}

export const NewsUpdateReq = {
  ...BaseRequest,
  News: {
    title: '',
    desc: '',
    lang: '',
    category: '',
    id: '',
  },
}

export const NewsChangeStatusReq = {
  ...BaseRequest,
  News: {
    status: 0,
    id: '',
  },
}

export const NewsContentAddReq = {
  ...BaseRequest,
  News: {
    rel_id: '',
    type: '',
    body: '',
  },
}

export const NewsContentUpdateReq = {
  ...BaseRequest,
  News: {
    id: '',
    body: '',
  },
}

export const NewsContentGetReq = {
  ...BaseRequest,
  News: {
    rel_id: '',
    type: '',
  },
}
//#endregion

//#region Pages
export const PagesListReq = {
  ...ListBase,
  filter: {
    pageid: '',
    title: '',
  },
}

export const PagesAddReq = {
  ...BaseRequest,
  page: {
    title: '',
    pageid: '',
  },
}

export const PagesUpdateReq = {
  ...BaseRequest,
  page: {
    title: '',
    pageid: '',
    id: '',
  },
}

export const PagesContentAddReq = {
  ...BaseRequest,
  page: {
    pageid: '',
    lang: '',
    type: '',
    body: '',
  },
}

export const PagesContentUpdateReq = {
  ...BaseRequest,
  page: {
    id: '',
    pageid: '',
    lang: '',
    body: '',
  },
}

export const PagesContentGetReq = {
  ...BaseRequest,
  page: {
    pageid: '',
    lang: '',
  },
}
//#endregion

//#region Videos
export const VideosListReq = {
  ...ListBase,
  filter: {
    title: '',
    lang: '',
    category: '',
    order: false,
  },
}

export const VideosAddReq = {
  ...BaseRequest,
  video: {
    title: '',
    lang: '',
    category: 0,
    desc: '',
    image_link: '',
    youtube_id: '',
  },
}

export const VideosUpdateReq = {
  ...BaseRequest,
  video: {
    title: '',
    desc: '',
    lang: '',
    category: '',
    youtube_id: '',
    id: '',
  },
}

export const VideosChangeStatusReq = {
  ...BaseRequest,
  video: {
    status: 0,
    id: '',
  },
}

export const VideoChangeImageReq = {
  ...BaseRequest,
  video: {
    image_link: '',
    old_image_link: '',
    id: '',
  },
}
//#endregion

//#region Sports
export const SportsAddReq = {
  ...BaseRequest,
  sport: {
    did: '',
    project_id: '',
    img: '',
  },
}
//#endregion

//#region Athlete
export const AthleteChangeImageReq = {
  ...BaseRequest,
  athlete: {
    old_image_link: '',
    photo: '',
    id: '',
  },
}

export const AthleteAddReq = {
  ...BaseRequest,
  athlete: {
    noc: '',
    accr_category: '',
    accr_sub_category: '',
    suffix: '',
    photo: '',
    firstname: '',
    lastname: '',
    gender: '',
    birth: '',
    birth_country: '',
    address: '',
    hobby: '',
    add_info: '',
    food: '',
    height: '',
    weight: '',
    blood_type: '',
    status: 0,
    created_by: '',
    sport: '',
  },
}

export const AthleteUpdateReq = {
  ...BaseRequest,
  athlete: {
    noc: '',
    accr_category: '',
    accr_sub_category: '',
    suffix: '',
    firstname: '',
    lastname: '',
    gender: '',
    birth: '',
    birth_country: '',
    address: '',
    hobby: '',
    add_info: '',
    food: '',
    height: '',
    weight: '',
    blood_type: '',
    status: 0,
    sport: '',
    id: '',
  },
}

export const AthleteStatusChangeReq = {
  ...BaseRequest,
  athlete: {
    status: 0,
    status_desc: null,
    id: '',
  },
}

export const AthleteChangeGmobileReq = {
  ...BaseRequest,
  athlete: {
    gmobile: null,
    id: '',
  },
}

export const AthleteChangePrintSuccessReq = {
  ...BaseRequest,
  athlete: {
    status_desc: 0,
    id: '',
  },
}

export const AthleteSetAccIDReq = {
  ...BaseRequest,
  athlete: {
    acc_id: 0,
    id: '',
  },
}

export const AthleteListReq = {
  ...ListBase,
  filter: {
    noc: '',
    accr_category: '',
    accr_sub_category: '',
    suffix: '',
    firstname: '',
    lastname: '',
    gender: '',
    blood_type: '',
    food: '',
    status: '',
    status_desc: '',
    acc_id: '',
    id: '',
  },
}
//#endregion

//#region AthleteSport
export const AthleteSportAddReq = {
  ...BaseRequest,
  sport: {
    noc: '',
    category: '',
    sub_category: '',
    status: 0,
    created_by: '',
    athlete_id: '',
    gender: '',
  },
}

export const AthleteSportListReq = {
  ...ListBase,
  filter: {
    created_by: '',
    sub_category: '',
    category: '',
    noc: '',
    status: '',
    gender: '',
    athlete_id: '',
    id: '',
  },
}

export const AthleteSportWithAthleteListReq = {
  ...ListBase,
  filter: {
    sub_category: '',
    category: '',
    firstname: '',
    lastname: '',
    noc: '',
    status: '',
    gender: '',
    athlete_id: '',
    id: '',
  },
}

export const AthleteSportChangeStatusReq = {
  ...BaseRequest,
  sport: {
    status: '',
    id: '',
  },
}
//#endregion

//#region AthleteContact
export const AthleteContactAddReq = {
  ...BaseRequest,
  contact: {
    aid: '',
    contact_id: '',
    contact_value: '',
    is_public: '',
  },
}

export const AthleteContactListReq = {
  ...ListBase,
  filter: {
    aid: '',
    contact_id: '',
    contact_value: '',
    is_public: '',
  },
}

export const AthleteContactUpdateReq = {
  ...ListBase,
  contact: {
    contact_value: '',
    is_public: '',
    id: '',
  },
}

//#endregion

//#region Arrival&VISA&Departures
export const AthleteVisaFlightAddReq = {
  ...BaseRequest,
  sport: {
    aid: '',
    noc: '',
    pass_type: '',
    pass_serial_num_id: '',
    pass_expired_date: '',
    pass_file: '',
    arr_flight_no: '',
    arr_month: '',
    arr_day: '',
    arr_time: '',
    arr_place: '',
    arr_railway: '',
    dep_month: '',
    dep_day: '',
    dep_time: '',
    dep_place: '',
    dep_railway: '',
    baggage: '',
  },
}

export const AthleteVisaFlightUpdateReq = {
  ...BaseRequest,
  sport: {
    pass_type: '',
    pass_serial_num_id: '',
    pass_expired_date: '',
    pass_file: '',
    arr_flight_no: '',
    arr_month: '',
    arr_day: '',
    arr_time: '',
    arr_place: '',
    arr_railway: '',
    dep_month: '',
    dep_day: '',
    dep_time: '',
    dep_place: '',
    baggage: '',
    id: '',
  },
}

export const AthleteVisaFlightListReq = {
  ...ListBase,
  filter: {
    noc: '',
    firstname: '',
    lastname: '',
    accr_category: '',
    accr_sub_category: '',
    arr_month: '',
    arr_day: '',
    dep_month: '',
    dep_day: '',
  },
}

export const AthleteVisaFlightPasswordUpdateReq = {
  ...BaseRequest,
  sport: {
    old_password_link: '',
    id: '',
  },
}
//#endregion

//#region Technical
export const AthleteTechnicalAddReq = {
  ...BaseRequest,
  sport: {
    aid: '',
    noc: '',
    sid: '',
    cert_number: '',
    cert_level: '',
    created_by: '',
    local: '',
  },
}

export const AthleteTechnicalUpdateReq = {
  ...BaseRequest,
  sport: {
    sid: '',
    cert_number: '',
    cert_level: '',
    local: '',
    id: '',
  },
}

export const AthleteTechnicalListReq = {
  ...ListBase,
  filter: {
    noc: '',
    created_by: '',
    aid: '',
    sid: '',
    cert_number: '',
    cert_level: '',
  },
}
//#endregion

//#region Technical
export const AthleteMediaAddReq = {
  ...BaseRequest,
  media: {
    school_info: '',
    grade: '',
    weight: '',
    height: '',
    expierence: '',
    full_name: '',
    relationship: '',
    contact_detailse: '',
  },
}

export const AthleteMediaUpdateReq = {
  ...BaseRequest,
  media: {
    school_info: '',
    grade: '',
    weight: '',
    height: '',
    expierence: '',
    full_name: '',
    relationship: '',
    contact_detailse: '',
  },
}

export const AthleteMediaListReq = {
  ...ListBase,
  filter: {
    expierence: '',
    full_name: '',
  },
}
//#endregion

//#region Dashboard
export const DashboardAthleteReq = {
  ...BaseRequest,
  filter: {
    noc: '',
  },
}

export const DashboardVolunteerReq = {
  ...BaseRequest,
  filter: {
    gender: '',
  },
}

export const DashboardSportsReq = {
  ...BaseRequest,
  filter: {
    noc: '',
  },
}
//#endregion

//#region Volunteers
export const VolunteerAddReq = {
  ...BaseRequest,
  volunteer: {
    firstname: '',
    lastname: '',
    gender: '',
    age: '',
    email: '',
    phone: '',
    team: '',
    photo: '',
    gmobile: '',
  },
}

export const VolunteerAddImageReq = {
  ...BaseRequest,
  volunteer: {
    image_link: '',
  },
}

export const VolunteerUpdateReq = {
  ...BaseRequest,
  volunteer: {
    firstname: '',
    lastname: '',
    gender: '',
    age: '',
    email: '',
    phone: '',
    team: '',
    gmobile: '',
    id: '',
  },
}

export const VolunteerStatusChangeReq = {
  ...BaseRequest,
  volunteer: {
    status: 0,
    status_desc: null,
    id: '',
  },
}

export const VolunteerListReq = {
  ...ListBase,
  filter: {
    firstname: '',
    lastname: '',
    gender: '',
    age: '',
    email: '',
    phone: '',
    team: '',
  },
}

export const VolunteerChangeImageReq = {
  ...BaseRequest,
  volunteer: {
    old_photo_link: '',
    photo: '',
    id: '',
  },
}

export const VolunteerGetReq = {
  ...BaseRequest,
  volunteer: {
    id: '',
  },
}
//#endregion

//#region Event
export const EventAddReq = {
  ...BaseRequest,
  event: {
    name: '',
    sid: '',
    day: '',
    time: '',
    gender: '',
    status: 0,
    category: '',
    home: 0,
    homepoint: 0,
    guest: 0,
    guestpoint: 0,
  },
}

export const EventUpdateReq = {
  ...BaseRequest,
  event: {
    id: '',
    name: '',
    sid: '',
    day: '',
    time: '',
    gender: '',
    status: 0,
    category: '',
    home: 0,
    homepoint: 0,
    guest: 0,
    guestpoint: 0,
  },
}

export const EventAddPointReq = {
  ...BaseRequest,
  event: {
    eid: '',
    homepoint: 0,
    guestpoint: 0,
  },
}

export const SingleAddPointReq = {
  ...BaseRequest,
  event: {
    id: '',
    homepoint: 0,
    guestpoint: 0,
  },
}

export const EventChangeStatusReq = {
  ...BaseRequest,
  event: {
    eid: '',
    status: 0,
  },
}

export const EventAddYoutubeReq = {
  ...BaseRequest,
  event: {
    eid: '',
    livestream: '',
  },
}

export const EventListReq = {
  ...ListBase,
  filter: {
    name: '',
    sid: '',
    day: '',
    time: '',
    gender: '',
    status: '',
    category: '',
  },
}

export const ScheduleSingleAddReq = {
  ...BaseRequest,
  event: {
    eid: '',
    home: 0,
    guest: 0,
    homepoint: 0,
    guestpoint: 0,
  },
}

export const MedalAddReq = {
  ...BaseRequest,
  medal: {
    noc: 0,
    sport: 0,
    sub_category: 0,
    gender: '',
    aid: 0,
    eid: 0,
    medal_type: 0,
  },
}

export const MedalListReq = {
  ...ListBase,
  filter: {
    noc: 0,
    sport: 0,
    sub_category: 0,
    gender: '',
    aid: 0,
    eid: 0,
    medal_type: 0,
  },
}

export const SingleListReq = {
  ...ListBase,
  filter: {
    eid: '',
  },
}
//#endregion

export const AthletesListReq = {
  ...ListBase,
  filter: {
    noc: '',
    category: '',
    firstname: '',
    lastname: '',
    sub_category: '',
    gender: '',
  },
}


export const EventReportListReq = {
  ...ListBase,
  filter: {
    eid: ''
  },
}
