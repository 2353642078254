/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {Languages} from './Languages'
import {toAbsoluteUrl} from '../../../helpers'
import useCookie from '../../../../services/useCookie'
import config from '../../../../services/config.json'
import {CDN_URL} from '../../../../services/main'
import {useTranslation} from 'react-i18next'
import {getRealData} from '../../../../services/Utils'
// import logo from "../../../../../public/image/logo_icon.webp"

const HeaderUserMenu: FC = () => {
  //const {currentUser} = useAuth()
  const [getToken, setGetToken, removeGetToken] = useCookie('SystemToken', null)
  const [Data1, setData1, removeData1] = useCookie('Data1', null)
  const [Data2, setData2, removeData2] = useCookie('Data2', null)
  const [DesignerID, setDesignerID, removeDesignerID] = useCookie('DesignerID', null)
  const [DesignerType, setDesignerType, removeDesignerType] = useCookie('DesignerType', null)
  const [currentUser, setCurrentUser] = useState<any>('')
  const debugMode = config.debug
  const {t} = useTranslation()

  useEffect(() => {
    var d = getRealData(Data1)
    setCurrentUser(JSON.parse(d))
  }, [Data1])

  const logout = () => {
    removeGetToken('SystemToken')
    removeData1('Data1')
    removeData2('Data2')
    removeDesignerID('EmployeeID')
    removeDesignerType('EmployeeTypeID')
    localStorage.clear()
    window.location.replace('/auth')
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-80px me-5'>
            <img alt='Logo' src={CDN_URL + currentUser?.photo} />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser?.firstname} {currentUser?.lastname}
              <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>
                {currentUser?.typeid}
              </span>
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {currentUser?.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      {debugMode == 'test' ? (
        <>
          <div className='menu-item px-5'>
            <Link to={'/crafted/pages/profile'} className='menu-link px-5'>
              My Profile
            </Link>
          </div>
        </>
      ) : null}
      {debugMode == 'test' ? (
        <>
          {' '}
          <div className='menu-item px-5'>
            <a href='#' className='menu-link px-5'>
              <span className='menu-text'>My Projects</span>
              <span className='menu-badge'>
                <span className='badge badge-light-danger badge-circle fw-bolder fs-7'>3</span>
              </span>
            </a>
          </div>
          <div
            className='menu-item px-5'
            data-kt-menu-trigger='hover'
            data-kt-menu-placement='left-start'
            data-kt-menu-flip='bottom'
          >
            <a href='#' className='menu-link px-5'>
              <span className='menu-title'>My Subscription</span>
              <span className='menu-arrow'></span>
            </a>

            <div className='menu-sub menu-sub-dropdown w-175px py-4'>
              <div className='menu-item px-3'>
                <a href='#' className='menu-link px-5'>
                  Referrals
                </a>
              </div>

              <div className='menu-item px-3'>
                <a href='#' className='menu-link px-5'>
                  Billing
                </a>
              </div>

              <div className='menu-item px-3'>
                <a href='#' className='menu-link px-5'>
                  Payments
                </a>
              </div>

              <div className='menu-item px-3'>
                <a href='#' className='menu-link d-flex flex-stack px-5'>
                  Statements
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='View your statements'
                  ></i>
                </a>
              </div>

              <div className='separator my-2'></div>

              <div className='menu-item px-3'>
                <div className='menu-content px-3'>
                  <label className='form-check form-switch form-check-custom form-check-solid'>
                    <input
                      className='form-check-input w-30px h-20px'
                      type='checkbox'
                      value='1'
                      defaultChecked={true}
                      name='notifications'
                    />
                    <span className='form-check-label text-muted fs-7'>Notifications</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className='menu-item px-5'>
            <a href='#' className='menu-link px-5'>
              My Statements
            </a>
          </div>
        </>
      ) : null}

      <div className='separator my-2'></div>

      <Languages />

      {debugMode == 'test' ? (
        <div className='menu-item px-5 my-1'>
          <Link to='/crafted/account/settings' className='menu-link px-5'>
            Account Settings
          </Link>
        </div>
      ) : null}

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          {t('common:sign_out')}
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
