import {useState, useRef, useEffect} from 'react'
import {isNullOrEmpty} from '../../../../services/Utils'
import {AthleteSportList, CDN_URL} from '../../../../services/main'
import {AthleteSportListReq, magicnum} from '../../../../services/Request'
import useCookie from '../../../../services/useCookie'
import {Image} from 'react-bootstrap'
import {QRCode} from 'react-qrcode-logo'
// import QRCode from 'react-qr-code'
import html2canvas from 'html2canvas'
import {KTIcon} from '../../../../_metronic/helpers'
import {toast} from 'react-toastify'
import {left} from '@popperjs/core'
const TabAccCard = (props) => {
  const t = props.t
  const item = props.item
  const [loading, setLoading] = useState(false)
  const [photo, setPhoto] = useState('')
  const [EmployeeID] = useCookie('EmployeeID', null)
  const [sport, setSport] = useState([])
  const zite = magicnum.accreditationSubCategoryNew.filter(
    (item2) => item2?.id == item?.accr_sub_category
  )[0]
  const ocite = magicnum.accreditationSubCategoryNew.filter(
    (item2) => item2?.parent == item?.accr_category
  )[0]
  const ite = magicnum.accreditationCategoryNew.filter(
    (item2) => item2?.id == item?.accr_category
  )[0]

  const domEl1 = useRef(null)
  const domEl2 = useRef(null)

  const htmlToImageConvert = async (type) => {
    setLoading(true)
    var element = undefined
    if (type == 'front') {
      element = domEl1.current
    } else {
      element = domEl2.current
    }

    const canvas = await html2canvas(element)

    const data = canvas.toDataURL('image/jpg')
    const link = document.createElement('a')

    if (typeof link.download === 'string') {
      link.href = data
      link.download = item?.firstname + '-' + type + '-' + item?.id + '.jpg'

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      setLoading(false)
    } else {
      window.open(data)
      console.log('no:')
      setLoading(false)
    }
  }

  const convertImageToBase64 = async (imgUrl) => {
    const base64 = await fetch(imgUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        return new Promise((res) => {
          reader.onloadend = () => {
            res(reader.result)
          }
        })
      })
    setPhoto(base64)
  }

  useEffect(() => {
    if (!isNullOrEmpty(item)) {
      convertImageToBase64(CDN_URL + item?.photo)
    }
  }, [item])

  useEffect(() => {
    if (!isNullOrEmpty(sport) && item?.accr_category == 'Aa') {
      list()
    }
  }, [item?.id])

  const list = () => {
    setLoading(true)
    var req = AthleteSportListReq
    req.filter.noc = item?.noc
    req.filter.athlete_id = item?.id
    AthleteSportList(req, onListSuccess, onFailed, 'POST')
  }

  const onListSuccess = (response) => {
    setLoading(false)
    setSport(response.data[0])
  }

  const onFailed = (err) => {
    setLoading(false)
    return toast.error(err)
  }

  return (
    <div>
      <div className='mx-5 mx-xl-10 pt-10 pb-10 tabacccard'>
        <div className='flex flex-col gap-8'>
          {/* Card Download Front Code Start */}
          <div
            className='card-1-download flex justify-center items-center'
            style={{width: '800px'}}
          >
            <button
              className='btn btn-lg btn-light-primary'
              style={{padding: '20px 50px'}}
              onClick={() => htmlToImageConvert('front')}
              disabled={loading}
            >
              {loading ? 'Loading...' : 'Front Card Download'}
            </button>
          </div>
          {/* Card Download Code End */}
          <div
            className='card-front'
            style={{
              backgroundImage: `url('/accrCardBackground/jpg/front_last.jpg')`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }}
            ref={domEl1}
          >
            <div className='top flex justify-between'>
              <div className='text flex flex-col gap-2'>
                <span>{t('common:athlete.more.tabacccard.top_text_en')}</span>
                <span>{t('common:athlete.more.tabacccard.top_text_mn')}</span>
              </div>
              <div className='logo'>
                <img
                  src='/logo/png/logo-no-bg.png'
                  alt='Metornic'
                  style={{height: 200, width: 200}}
                />
              </div>
            </div>
            <div className='profile flex gap-8'>
              <div className='image'>
                {isNullOrEmpty(photo) ? null : (
                  <img src={photo} alt='Metornic' style={{height: 400, width: 300}} />
                )}
              </div>
              <div className='flex'>
                <div className='accr' style={{background: `${ite?.color}`}}>
                  <span className='text-white'>{item?.accr_category}</span>
                </div>
              </div>
            </div>
            <div className='holders flex flex-col justify-between'>
              <span className='name'>
                {item?.firstname.toUpperCase()} {item?.lastname.toUpperCase()}
              </span>
              {item?.noc == 11 ? (
                <>
                  {(item?.accr_category != 'Ss' &&
                    item?.accr_category != 'St' &&
                    item?.accr_category != 'So' &&
                    item?.accr_category != 'Sl' &&
                    item?.accr_category != 'S' &&
                    item?.accr_category != 'W') ||
                  item?.accr_category == 'OC' ? (
                    <>
                      {item?.accr_category == 'OC' ? (
                        <span className='rp'>{item?.hobby}</span>
                      ) : (
                        <span>
                          {t(
                            `common:magicnum.accreditationSubCategoryNew.${item?.accr_sub_category}`
                          )}
                        </span>
                      )}
                    </>
                  ) : (
                    <span>
                      {t(
                        `common:magicnum.accreditationSubCategoryNewLocal.${item?.accr_sub_category}`
                      )}
                    </span>
                  )}
                  <span style={{width: 520, fontSize: 22}}>UEAYGOC</span>
                </>
              ) : (
                <>
                  <span>
                    {t(`common:magicnum.accreditationSubCategoryNew.${item?.accr_sub_category}`)}
                  </span>
                  <span style={{width: 520, fontSize: 22}}>{t(`common:NOC.${item?.noc}`)}</span>
                </>
              )}
              {isNullOrEmpty(item?.acc_id) ? (
                <span style={{color: 'red'}}>Not approved !!!</span>
              ) : (
                <span>{item?.acc_id}</span>
              )}
              {item?.accr_category == 'D' ? <span>{item?.birth_country}</span> : null}
            </div>
            <div className='qr'>
              <QRCode
                logoImage='/logo/png/qrlogo.png'
                logoHeight={50}
                logoWidth={50}
                // bgColor='white'
                // logoPaddingStyle='circle'
                ecLevel='H'
                qrStyle={'dots'}
                // eyeRadius={2}
                // eyeColor={ite?.color}
                // fgColor={ite?.color}
                value={'https://ulaanbaatar2023.org/athlete/' + item?.id}
              />
            </div>
            <div className='other-container' style={{background: `${ite?.color}`}}>
              <div
                className='access flex-col gap-4'
                style={{...(item?.accr_category == 'So' ? {display: 'none'} : {display: 'flex'})}}
              >
                {item?.accr_category == 'Aa' ||
                item?.accr_sub_category == 'D-1' ||
                item?.accr_sub_category == 'D-2' ||
                item?.accr_sub_category == 'A-3' ||
                item?.accr_sub_category == 'E-4' ||
                item?.accr_category == 'Ss' ||
                item?.accr_category == 'So' ||
                item?.accr_category == 'St' ||
                item?.accr_category == 'Sl' ||
                item?.accr_category == 'S' ||
                item?.accr_category == 'W' ||
                item?.accr_category == 'OC' ? null : (
                  <>
                    <div
                      className='venue-access flex justify-center items-center'
                      style={{
                        border: `1px solid ${ite?.color}`,
                        ...(item?.accr_category == 'Ss' ||
                        item?.accr_category == 'St' ||
                        item?.accr_category == 'So' ||
                        item?.accr_category == 'Sl' ||
                        item?.accr_category == 'S' ||
                        item?.accr_category == 'W'
                          ? {fontSize: '22px', color: 'white'}
                          : {fontSize: '40px', color: 'white'}),
                      }}
                    >
                      {zite?.venue.toUpperCase()}
                    </div>
                    <div
                      className='venue-access flex justify-center items-center gap-2'
                      style={{border: `1px solid ${ite?.color}`, fontSize: 28}}
                    >
                      {zite?.zone.map((item1, index) => {
                        return (
                          <span
                            key={index}
                            style={{
                              ...(item?.accr_category == 'Ss'
                                ? {fontSize: 22, color: 'white'}
                                : {fontSize: 28, color: 'white'}),
                            }}
                          >
                            {item1} {index !== zite?.zone.length - 1 ? ',' : ''}
                          </span>
                        )
                      })}
                    </div>
                  </>
                )}
                {item?.accr_category == 'Aa' && !isNullOrEmpty(sport?.category) ? (
                  <>
                    <div className='venue-access flex  gap-2 justify-center items-center'>
                      <Image
                        className='image-hover cursor-pointer'
                        src={`/sports/webp/hover/${sport?.category}.webp`}
                        alt=''
                        width={50}
                        height={50}
                      />
                      <span className='text-style text-white' style={{fontSize: 16}}>
                        {t(`common:magicnum.SportCategories.${sport?.category}`)}
                      </span>
                    </div>
                    <div
                      className='venue-access flex gap-4 justify-center items-center'
                      style={{border: `1px solid ${ite?.color}`}}
                    >
                      {magicnum.accreditationSubCategoryNew.map((item1, index) => {
                        return item1?.id == item?.accr_sub_category
                          ? item1?.zone.map((zitem, zindex) => {
                              return (
                                <span key={zindex} className='text-white'>
                                  {zitem} {zindex !== zite?.zone.length - 1 ? ',' : ''}
                                </span>
                              )
                            })
                          : null
                      })}
                    </div>
                  </>
                ) : null}
                {item?.accr_category == 'E-4' ? (
                  <>
                    <div
                      className='venue-access flex justify-center items-center text-white'
                      style={{border: `1px solid ${ite?.color}`}}
                    >
                      {zite?.venue.toUpperCase()}
                    </div>
                    <div
                      className='venue-access flex justify-center items-center gap-2'
                      style={{border: `1px solid ${ite?.color}`, fontSize: 28}}
                    >
                      {zite.zone.map((item1, index) => {
                        return zite?.id == item?.accr_sub_category ? (
                          <span key={index} className='text-white'>
                            {item1} {index !== zite?.zone.length - 1 ? ',' : ''}
                          </span>
                        ) : null
                      })}
                    </div>
                  </>
                ) : null}
                {item?.accr_sub_category == 'D-2' ||
                item?.accr_sub_category == 'D-1' ||
                item?.accr_sub_category == 'A-3' ? (
                  <>
                    {!isNullOrEmpty(item?.sport) ? (
                      <div className='venue-access flex gap-2 justify-center items-center'>
                        <Image
                          className='image-hover cursor-pointer'
                          src={`/sports/webp/hover/${item?.sport}.webp`}
                          alt=''
                          width={50}
                          height={50}
                        />
                        <span className='text-style text-white' style={{fontSize: 16}}>
                          {t(`common:magicnum.SportCategories.${item?.sport}`)}
                        </span>
                      </div>
                    ) : (
                      <div className='venue-access flex gap-2 justify-center items-center'>
                        <span>{zite?.venue.toUpperCase()}</span>
                      </div>
                    )}
                    <div
                      className='venue-access flex gap-4 justify-center items-center'
                      style={{border: `1px solid ${ite?.color}`}}
                    >
                      {magicnum.accreditationSubCategoryNew.map((item1, index) => {
                        return item1?.id == item?.accr_sub_category
                          ? item1?.zone.map((zitem, zindex) => {
                              return (
                                <span key={zindex} className='text-white'>
                                  {zitem} {zindex !== zite?.zone.length - 1 ? ',' : ''}
                                </span>
                              )
                            })
                          : null
                      })}
                    </div>
                  </>
                ) : null}
                {item?.accr_category == 'OC' ? (
                  <>
                    <div
                      className='venue-access flex justify-center items-center text-white'
                      style={{border: `1px solid ${ite?.color}`}}
                    >
                      {ocite?.venue.toUpperCase()}
                    </div>
                    <div
                      className='venue-access flex justify-center items-center gap-2'
                      style={{border: `1px solid ${ite?.color}`, fontSize: 28}}
                    >
                      {ocite?.zone.map((item1, index) => {
                        return ocite?.parent == item?.accr_category ? (
                          <span key={index} className='text-white'>
                            {item1}
                          </span>
                        ) : null
                      })}
                    </div>
                  </>
                ) : null}
              </div>
              <div
                className='other gap-8 justify-center items-end'
                style={{...(item?.accr_category == 'So' ? {display: 'none'} : {display: 'flex'})}}
              >
                {/* transportation */}
                {item?.accr_category == 'So' ||
                item?.accr_category == 'OC' ||
                item?.accr_category == 'St' ||
                item?.accr_category == 'Ss' ||
                item?.accr_category == 'Sl' ||
                item?.accr_category == 'S' ||
                item?.accr_category == 'W' ? (
                  <div
                    className='other-border flex justify-center items-center text-white'
                    style={{border: `1px solid ${ite?.color}`}}
                  >
                    <span style={{textAlign: 'center', fontSize: 32}}>{ocite?.transportation}</span>
                  </div>
                ) : (
                  <div
                    className='other-border flex justify-center items-center text-white'
                    style={{border: `1px solid ${ite?.color}`}}
                  >
                    <span style={{textAlign: 'center', fontSize: 32}}>{zite?.transportation}</span>
                  </div>
                )}

                {/* hotel  */}
                {item?.accr_category == 'Aa' ? (
                  <div
                    className='other-border flex justify-center items-center'
                    style={{border: `1px solid ${ite?.color}`}}
                  >
                    {magicnum.hotel.map((hitem, hindex) => {
                      return hitem?.sid.map((hhitem, hhindex) => {
                        return hhitem == sport?.category ? (
                          <span className='text-white' key={hhindex}>
                            {hitem?.code}
                          </span>
                        ) : null
                      })
                    })}
                  </div>
                ) : (
                  <div
                    className='other-border justify-center items-center'
                    style={{
                      ...(item?.accr_category == 'Ss' ||
                      item?.accr_category == 'St' ||
                      item?.accr_category == 'So' ||
                      item?.accr_category == 'Sl' ||
                      item?.accr_category == 'S' ||
                      item?.accr_category == 'W' ||
                      item?.accr_category == 'OC'
                        ? {display: 'none'}
                        : {display: 'flex'}),
                      border: `1px solid ${ite?.color}`,
                    }}
                  >
                    <span className='text-white'>{zite?.hotel}</span>
                  </div>
                )}
                {/* dining */}
                <div
                  className='other-border justify-center items-center'
                  style={{
                    border: `1px solid ${ite?.color}`,
                    ...(item?.accr_category == 'OC' ||
                    item?.accr_category == 'So' ||
                    item?.accr_category == 'St' ||
                    item?.accr_category == 'Ss' ||
                    item?.accr_category == 'Sl' ||
                    item?.accr_category == 'S' ||
                    item?.accr_category == 'W'
                      ? {display: 'none'}
                      : {display: 'flex'}),
                  }}
                >
                  {zite?.dining == true ? (
                    <div
                      className=''
                      style={{
                        background: `url('/icons/png/dinner_white.png')`,
                        width: '21px',
                        height: '56px',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        fill: 'white',
                      }}
                    ></div>
                  ) : null}
                </div>
                {item?.accr_category == 'So' ||
                item?.accr_category == 'St' ||
                item?.accr_category == 'Ss' ||
                item?.accr_category == 'Sl' ||
                item?.accr_category == 'S' ||
                item?.accr_category == 'W' ? (
                  <div className='other-border flex justify-center items-center'>
                    {ocite?.dining == true ? (
                      <div
                        className=''
                        style={{
                          background: `url('/icons/png/dinner_white.png')`,
                          width: '21px',
                          height: '56px',
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          fill: 'white',
                        }}
                      ></div>
                    ) : null}
                  </div>
                ) : null}
                {/* seat */}
                {item?.accr_category == zite?.parent && item?.accr_category != 'So' ? (
                  <div className='other-border flex justify-center items-center'>
                    <span className='text-white'>{zite?.seat}</span>
                  </div>
                ) : null}

                {item?.accr_category == 'OC' ||
                item?.accr_category == 'Ss' ||
                item?.accr_category == 'St' ||
                item?.accr_category == 'So' ||
                item?.accr_category == 'Sl' ||
                item?.accr_category == 'S' ||
                item?.accr_category == 'W' ? (
                  <div className='other-border flex justify-center items-center'>
                    <span className='text-white'>{ocite?.seat}</span>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {/* Card Download Back Code Start  */}
          <div
            className='card-1-download flex justify-center items-center'
            style={{width: '800px'}}
          >
            <button
              className='btn btn-sm btn-light-primary'
              onClick={() => htmlToImageConvert('backend')}
              disabled={loading}
              style={{padding: '20px 50px'}}
            >
              {loading ? 'Loading...' : 'Back Card Download'}
            </button>
          </div>
          {/* Card Download Code End */}
          <div
            className='card-back'
            style={{
              backgroundImage: `url('/accrCardBackground/jpg/back_last.jpg')`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }}
            ref={domEl2}
          >
            <div>
              <div className='top flex justify-between'>
                <div className='text flex flex-col gap-2'>
                  <span>{t('common:athlete.more.tabacccard.top_text_en')}</span>
                  <span>{t('common:athlete.more.tabacccard.top_text_mn')}</span>
                </div>
                <div className='logo'>
                  <img
                    src='/logo/png/logo-no-bg.png'
                    alt='Metornic'
                    style={{height: 115, width: 115}}
                  />
                </div>
              </div>
              <div
                className='back-desc flex justify-center items-center'
                style={{border: `2px solid ${ite?.color}`}}
              >
                <span>{t('common:athlete.more.tabacccard.back_desc')}</span>
              </div>
              <div className='back-access flex justify-center items-center'>
                <div className='access flex'>
                  {/* accr_category */}
                  <div className='flex gap-5 items-center justify-center'>
                    <div
                      className='items'
                      style={{background: `${ite?.color}`, color: 'white', border: 'none'}}
                    >
                      <span className='code'>{item?.accr_category}</span>
                    </div>
                    <span className='texts'>
                      {t('common:athlete.more.tabacccard.back_accr_category')}
                    </span>
                  </div>
                  {/* zone access */}
                  {item?.accr_category == 'Ss' ||
                  item?.accr_category == 'St' ||
                  item?.accr_category == 'So' ||
                  item?.accr_category == 'Sl' ||
                  item?.accr_category == 'S' ||
                  item?.accr_category == 'W' ||
                  item?.accr_category == 'OC' ? null : (
                    <div>
                      <div className='flex gap-5 items-center justify-center'>
                        <div className='items' style={{border: `1px solid ${ite?.color}`}}>
                          <div
                            className='justfiy-center items-center flex-wrap'
                            style={{
                              display: 'grid',
                              columnGap: 16,
                              paddingLeft: 8,
                              gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
                            }}
                          >
                            {item?.accr_sub_category == zite?.id
                              ? zite?.zone.map((zzitem, zzindex) => {
                                  return (
                                    <>
                                      <span
                                        key={zzindex}
                                        style={{
                                          fontWeight: '900',
                                          ...(item?.accr_category == 'Ss'
                                            ? {fontSize: 16}
                                            : {fontSize: 22}),
                                        }}
                                      >
                                        {zzitem}
                                      </span>
                                    </>
                                  )
                                })
                              : null}
                          </div>
                        </div>
                        <span className='texts'>
                          {t('common:athlete.more.tabacccard.back_zone')}
                        </span>
                      </div>
                    </div>
                  )}
                  {item?.accr_category == 'OC' ? (
                    <div>
                      <div className='flex gap-5 items-center justify-center'>
                        <div className='items' style={{border: `1px solid ${ite?.color}`}}>
                          <div className='flex justfiy-center items-center'>
                            {item?.accr_category == 'OC'
                              ? ocite?.zone.map((zzitem, zzindex) => {
                                  return (
                                    <>
                                      <span
                                        key={zzindex}
                                        style={{
                                          fontWeight: '900',
                                          fontSize: 22,
                                        }}
                                      >
                                        {zzitem}
                                      </span>
                                    </>
                                  )
                                })
                              : null}
                          </div>
                        </div>
                        <span className='texts'>
                          {t('common:athlete.more.tabacccard.back_zone')}
                        </span>
                      </div>
                    </div>
                  ) : null}
                  {/* vip */}
                  {item?.accr_category == 'OC' || item?.accr_category == 'So' ? null : (
                    <div className='flex gap-5 items-center justify-center'>
                      <div
                        className='items'
                        style={{
                          color: 'black',
                          border: `1px solid ${ite?.color}`,
                        }}
                      >
                        {item?.accr_category == 'Ss' ||
                        item?.accr_category == 'St' ||
                        item?.accr_category == 'Sl' ||
                        item?.accr_category == 'S' ||
                        item?.accr_category == 'W' ? (
                          <span className='code'>{ocite?.transportation}</span>
                        ) : (
                          <span className='code'>{zite?.transportation}</span>
                        )}
                      </div>
                      {item?.accr_category == 'VVIP' || item?.accr_category == 'VIP' ? (
                        <span className='texts'>VIP</span>
                      ) : null}
                      {item?.accr_category == 'D' ? (
                        <span className='texts'>VIP Shuttle</span>
                      ) : null}
                      {item?.accr_category == 'Ac' ? (
                        <span className='texts'>VIP transport</span>
                      ) : null}
                      {item?.accr_category == 'Ao' ||
                      item?.accr_category == 'Aa' ||
                      item?.accr_category == 'Am' ? (
                        <span className='texts'>Transport</span>
                      ) : null}
                      {item?.accr_category == 'E' ||
                      item?.accr_category == 'Ep' ||
                      item?.accr_category == 'ENR' ||
                      item?.accr_category == 'RT' ||
                      item?.accr_category == 'YRP' ? (
                        <span className='texts'>Shuttle</span>
                      ) : null}
                      {item?.accr_category == 'Ss' ||
                      item?.accr_category == 'St' ||
                      item?.accr_category == 'Sl' ||
                      item?.accr_category == 'S' ||
                      item?.accr_category == 'W' ||
                      item?.accr_category == 'So' ? (
                        <span className='texts'>Shuttle bus</span>
                      ) : null}
                    </div>
                  )}
                  {/* dining */}
                  {item?.accr_category == 'So' ||
                  item?.accr_category == 'Ss' ||
                  item?.accr_category == 'Sl' ||
                  item?.accr_category == 'S' ||
                  item?.accr_category == 'W' ? (
                    <div
                      style={{
                        ...(item?.accr_category == 'So' ? {display: 'none'} : {display: 'block'}),
                      }}
                    >
                      <div className='flex gap-5 items-center justify-center'>
                        <div
                          className='items flex justify-center items-center'
                          style={{border: `1px solid ${ite?.color}`}}
                        >
                          {ocite?.dining == true ? (
                            <div
                              className=''
                              style={{
                                background: `url('/icons/png/dinner.png')`,
                                width: '19px',
                                height: '55px',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                              }}
                            ></div>
                          ) : null}

                          {/* <KTIcon iconName='dining' className='fs-2' /> */}
                        </div>
                        <span className='texts'>
                          {t('common:athlete.more.tabacccard.back_dining')}
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div
                        className='gap-5 items-center justify-center'
                        style={{
                          ...(item?.accr_category == 'OC' ? {display: 'none'} : {display: 'flex'}),
                        }}
                      >
                        <div
                          className='items flex justify-center items-center'
                          style={{border: `1px solid ${ite?.color}`}}
                        >
                          {zite?.dining == true ? (
                            <div
                              className=''
                              style={{
                                background: `url('/icons/png/dinner.png')`,
                                width: '19px',
                                height: '55px',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                              }}
                            ></div>
                          ) : null}

                          {/* <KTIcon iconName='dining' className='fs-2' /> */}
                        </div>
                        <span className='texts'>
                          {t('common:athlete.more.tabacccard.back_dining')}
                        </span>
                      </div>
                    </div>
                  )}
                  {/* venue access */}
                  {item?.accr_sub_category == 'A-4' && !isNullOrEmpty(sport?.category) ? (
                    <div className='flex gap-6 justify-center items-center'>
                      <div
                        className='items flex flex-col gap-2 justify-center items-center'
                        style={{border: `1px solid ${ite?.color}`}}
                      >
                        <Image
                          className='image-hover cursor-pointer'
                          src={`/sports/webp/hover/${sport?.category}.webp`}
                          alt=''
                          width={50}
                          height={50}
                        />
                        <span className='texts'>
                          {t(`common:magicnum.SportCategories.${sport?.category}`)}
                        </span>
                      </div>
                      <span className='texts'>
                        {t('common:athlete.more.tabacccard.back_venue')}
                      </span>
                    </div>
                  ) : null}
                  {item?.accr_category == 'D' && !isNullOrEmpty(item?.sport) ? (
                    <div className='flex gap-6 justify-center items-center'>
                      <div
                        className='items flex flex-col gap-2 justify-center items-center'
                        style={{border: `1px solid ${ite?.color}`}}
                      >
                        <Image
                          className='image-hover cursor-pointer'
                          src={`/sports/webp/hover/${item?.sport}.webp`}
                          alt=''
                          width={50}
                          height={50}
                        />
                        <span className='texts'>
                          {t(`common:magicnum.SportCategories.${item?.sport}`)}
                        </span>
                      </div>
                      <span className='texts'>
                        {t('common:athlete.more.tabacccard.back_venue')}
                      </span>
                    </div>
                  ) : null}
                  {item?.accr_sub_category == 'A-3' && !isNullOrEmpty(item?.sport) ? (
                    <div className='flex gap-6 justify-center items-center'>
                      <div
                        className='items flex flex-col gap-2 justify-center items-center'
                        style={{border: `1px solid ${ite?.color}`}}
                      >
                        <Image
                          className='image-hover cursor-pointer'
                          src={`/sports/webp/hover/${item?.sport}.webp`}
                          alt=''
                          width={50}
                          height={50}
                        />
                        <span className='texts'>
                          {t(`common:magicnum.SportCategories.${item?.sport}`)}
                        </span>
                      </div>
                      <span className='texts'>
                        {t('common:athlete.more.tabacccard.back_venue')}
                      </span>
                    </div>
                  ) : null}
                  {(item?.accr_sub_category == zite?.id && item?.accr_sub_category == 'A-4') ||
                  item?.accr_category == 'D' ||
                  item?.accr_sub_category == 'A-3' ||
                  item?.accr_category == 'Ss' ||
                  item?.accr_category == 'St' ||
                  item?.accr_category == 'So' ||
                  item?.accr_category == 'Sl' ||
                  item?.accr_category == 'S' ||
                  item?.accr_category == 'W' ||
                  item?.accr_category == 'OC' ? null : (
                    <div className='flex justify-center items-center gap-5'>
                      <div
                        className='items flex justify-center items-center'
                        style={{
                          border: `1px solid ${ite?.color}`,
                        }}
                      >
                        <span>{zite?.venue.toUpperCase()}</span>
                      </div>
                      <span className='texts'>
                        {t('common:athlete.more.tabacccard.back_venue')}
                      </span>
                    </div>
                  )}

                  {item?.accr_category == 'OC' ? (
                    <div className='flex justify-center items-center gap-5'>
                      <div
                        className='items flex justify-center items-center'
                        style={{
                          border: `1px solid ${ite?.color}`,
                        }}
                      >
                        <span>{ocite?.venue.toUpperCase()}</span>
                      </div>
                      <span className='texts'>
                        {t('common:athlete.more.tabacccard.back_venue')}
                      </span>
                    </div>
                  ) : null}
                  {/* Seating access */}
                  {item?.accr_category == 'Ss' ||
                  item?.accr_category == 'St' ||
                  item?.accr_category == 'So' ||
                  item?.accr_category == 'Sl' ||
                  item?.accr_category == 'S' ||
                  item?.accr_category == 'W' ||
                  item?.accr_category == 'OC' ? null : (
                    <div>
                      <div className='flex gap-5 items-center justify-center'>
                        <div className='items' style={{border: `1px solid ${ite?.color}`}}>
                          {item?.accr_category == zite?.parent ? <span>{zite?.seat}</span> : null}
                        </div>
                        <span className='texts'>
                          {t('common:athlete.more.tabacccard.back_seating')}
                        </span>
                      </div>
                    </div>
                  )}

                  {item?.accr_category == 'OC' ? (
                    <div>
                      <div className='flex gap-5 items-center justify-center'>
                        <div className='items' style={{border: `1px solid ${ite?.color}`}}>
                          {item?.accr_category == ocite?.parent ? <span>{ocite?.seat}</span> : null}
                        </div>
                        <span className='texts'>
                          {t('common:athlete.more.tabacccard.back_seating')}
                        </span>
                      </div>
                    </div>
                  ) : null}
                  {/* hotel  */}
                  {item?.accr_category == 'Aa' ? (
                    <div className='flex gap-8 justify-center items-center'>
                      <div
                        className='items flex justify-center items-center'
                        style={{border: `1px solid ${ite?.color}`}}
                      >
                        {magicnum.hotel.map((hitem, hindex) => {
                          return hitem?.sid.map((hhitem, hhindex) => {
                            return hhitem == sport?.category ? (
                              <span key={hhindex}>{hitem?.code}</span>
                            ) : null
                          })
                        })}
                      </div>
                      <span className='texts'>{t('common:athlete.more.tabacccard.hotel')}</span>
                    </div>
                  ) : (
                    <div
                      className='gap-5 items-center justify-center'
                      style={{
                        ...(item?.accr_category == 'Ss' ||
                        item?.accr_category == 'St' ||
                        item?.accr_category == 'So' ||
                        item?.accr_category == 'Sl' ||
                        item?.accr_category == 'S' ||
                        item?.accr_category == 'W' ||
                        item?.accr_category == 'OC'
                          ? {display: 'none'}
                          : {display: 'flex'}),
                      }}
                    >
                      <div className='items' style={{border: `1px solid ${ite?.color}`}}>
                        <span>{zite?.hotel}</span>
                      </div>
                      <span className='texts'>{t('common:athlete.more.tabacccard.hotel')}</span>
                    </div>
                  )}
                </div>
              </div>
              {/* tamga */}
              {isNullOrEmpty(item?.acc_id) ? null : (
                <div
                  className='back-tamga'
                  style={{
                    backgroundImage: `url('/signature/tamga.png')`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    width: 300,
                    height: 150,
                    position: 'absolute',
                    bottom: 415,
                    left: 134,
                  }}
                ></div>
              )}
              <div className='qr'>
                <QRCode
                  logoImage='/logo/png/qrlogo.png'
                  logoHeight={50}
                  logoWidth={50}
                  // bgColor='white'
                  // logoPaddingStyle='circle'
                  ecLevel='H'
                  qrStyle={'dots'}
                  // eyeRadius={2}
                  // eyeColor={ite?.color}
                  // fgColor={ite?.color}
                  value={'https://ulaanbaatar2023.org/athlete/' + item?.id}
                />
              </div>
              <div className='back-bottom-text flex gap-8 justify-center'>
                <div className='signature' style={{position: 'relative', top: 40}}>
                  {/* <span className='text-center'>{t('common:athlete.more.tabacccard.signature')}</span> */}
                  <div
                    className='back-signature'
                    style={{
                      backgroundImage: `url('/signature/2.png')`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      position: 'relative',
                      left: 21,
                      bottom: -12,
                      width: 113,
                      height: 83,
                      transform: `rotate(353deg)`,
                    }}
                  ></div>
                  <div className='flex flex-col gap-4'>
                    {/* <div className='line' style={{border: `1px solid ${ite?.color}`}}></div> */}
                    <span>{t('common:athlete.more.tabacccard.president')}</span>
                    <span>{t('common:athlete.more.tabacccard.eaoc')}</span>
                  </div>
                </div>
                <div className='signature'>
                  {/* <span className='text-center'>
                    {t('common:athlete.more.tabacccard.signature')}
                  </span> */}
                  <div
                    className='back-signature'
                    style={{
                      backgroundImage: `url('/signature/1.png')`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      position: 'relative',
                      width: 170,
                      height: 122,
                      left: 70,
                      bottom: -38,
                      transform: `rotate(22deg)`,
                    }}
                  ></div>
                  <div className='flex flex-col gap-4'>
                    {/* <div className='line' style={{border: `1px solid ${ite?.color}`}}></div> */}
                    <span>{t('common:athlete.more.tabacccard.chairman')}</span>
                    <span>{t('common:athlete.more.tabacccard.org_ub')}</span>
                  </div>
                </div>
              </div>
              <div
                className='back-bottom-period flex justify-center items-center gap-2'
                style={{border: `2px solid ${ite?.color}`}}
              >
                <span>{t('common:athlete.more.tabacccard.period')}:</span>
                <span>{t('common:athlete.more.tabacccard.date')}</span>
              </div>
              {/* <div
                className='back-signature'
                style={{
                  backgroundImage: `url('/signature/1.png')`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  width: 171,
                  height: 122,
                  position: 'absolute',
                  bottom: '126px',
                  right: '73px',
                  transform: `rotate(25deg)`,
                }}
              ></div> */}
              {/* <div className='back-bottom-colors'></div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {TabAccCard}
