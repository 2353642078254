import React, {FC, useEffect, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import DataTable from '../../modules/CustomBootsrapTable/CustomBootsrapTable'
import CustomModal from '../../modules/CustomModal/modal'
import {EventCreate} from './add'
import {TimeFormat, dateTimeFormat, isNullOrEmpty} from '../../../services/Utils'
import {
  BaseRequest,
  NewsChangeStatusReq,
  NewsListReq,
  EventChangeStatusReq,
  magicnum,
  EventListReq,
  EventAddPointReq,
  EventAddYoutubeReq,
} from '../../../services/Request'
import {
  CDN_URL,
  EventAddPoint,
  EventAddYoutube,
  EventChangeStatus,
  EventDelete,
  EventList,
  NewsChangeStatus,
  NewsDelete,
  NewsList,
} from '../../../services/main'
import {toast} from 'react-toastify'
import {confirmAlert} from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import {Row} from 'react-bootstrap'
import {SelectLocationModal} from '../../../_metronic/partials'
const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]

const Event: FC = () => {
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [loadingPoint, setLoadingPoint] = useState(false)
  const [loadingPointAdd, setLoadingPointAdd] = useState(false)
  const [loadingYoutube, setLoadingYoutube] = useState(false)
  const [data, setData] = useState([])
  const [point, setPoint] = useState([])
  const [item, setItem] = useState<any>(undefined)
  const [PID, setPID] = useState(1)
  const [PTotal, setPTotal] = useState(1)
  const [sizePerPage, setSizePerPage] = useState(10)
  const [showModal, updateShowModal] = useState(false)
  const [showPointModal, updateShowPointModal] = useState(false)
  const [showPointAddModal, updateShowPointAddModal] = useState(false)
  const [showAddYoutubeModal, updateShowAddYoutubeModal] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [status, setStatus] = useState<any>(0)
  const [homepoint, setHomepoint] = useState<any>('')
  const [guestpoint, setGuestpoint] = useState<any>('')
  const [youtube_id, setYoutubeID] = useState<any>('')

  const [sid, setSID] = useState<any>('')
  const [gender, setGender] = useState<any>('')
  const [category, setCategory] = useState<any>('')
  const [day, setDay] = useState<any>('')

  useEffect(() => {
    list()
  }, [PID])

  const list = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = EventListReq
    req.pid = PID
    req.pSize = sizePerPage
    req.filter.sid = sid
    req.filter.gender = gender
    req.filter.category = category
    req.filter.day = day
    EventList(req, onSuccess, onFailed, 'POST')
  }

  const onSuccess = (response: any) => {
    setLoading(false)
    setData(response.data)
    setPTotal(response.pagination.ptotal)
  }

  const onFailed = (error: string) => {
    setLoading(false)
    setLoadingPoint(false)
    setLoadingPointAdd(false)
    return toast.error(error)
  }

  const add = () => {
    setLoadingPoint(true)
    var req = EventAddPointReq
    req.event.eid = item?.id
    req.event.homepoint = homepoint
    req.event.guestpoint = guestpoint
    EventAddPoint(req, onSuccessPoint, onFailed, 'PUT')
  }

  const onSuccessPoint = (response: any) => {
    setLoadingPoint(false)
    updateShowPointAddModal(false)
    list()
    return toast.success(t('common:successUpdate'))
  }

  const onChangePage = (pid: any) => {
    if (pid >= 1) {
      setPID(pid)
      setLoading(true)
    }
  }

  const defaultRowFormat = (cell: any, row: any) => {
    return <>{cell}</>
  }

  const eventNameRowFormat = (cell: string, row: any) => {
    return <div className='flex justify-center items-center'>{row?.name}</div>
  }

  const eventIDRowFormat = (cell: string, row: any) => {
    return <div className='flex justify-center items-center'>{row?.id}</div>
  }

  const onAddSuccess = (text: string) => {
    list()
    updateShowModal(false)
    return toast.success(text)
  }

  const actionRowFormat = (cell: string, row: any) => {
    return (
      <div className='text-end'>
        <Link to={`/events/${row?.id}`}>
          <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
            <KTIcon iconName='bi bi-three-dots' className='fs-3' />
          </div>
        </Link>
        {/* {row?.home == 0 || row?.guest == 0 ? null : (
          <div
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
            onClick={() => {
              setItem(row)
              updateShowPointModal(true)
            }}
          >
            <KTIcon iconName='bi bi-plus-square' className='fs-3' />
          </div>
        )} */}
        <div
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => {
            setItem(row)
            updateShowModal(true)
          }}
        >
          <KTIcon iconName='pencil' className='fs-3' />
        </div>
        <div
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
          onClick={() => submit(row, 'deleteMain')}
        >
          <KTIcon iconName='trash' className='fs-3' />
        </div>
      </div>
    )
  }

  const genderRowFormat = (cell: string, row: any) => {
    return (
      <div className='flex justify-center items-center'>
        <span>{cell}</span>
      </div>
    )
  }

  const statusRowFormat = (cell: any, row: any) => {
    return cell == 0 ? (
      <div
        className='text-center cursor-pointer'
        onClick={() => {
          setItem(row)
          updateShowPointModal(true)
        }}
      >
        <span
          style={{
            background: '#0d2c5d',
            color: 'white',
            padding: '5px 10px',
            borderRadius: 30,
            whiteSpace: 'nowrap',
          }}
        >
          {t(`common:event_status.${cell}`)}
        </span>
      </div>
    ) : (
      <div
        className='text-center cursor-pointer'
        onClick={() => {
          setItem(row)
          updateShowPointModal(true)
        }}
      >
        {magicnum.EventStatus.map((eitem, eindex) => {
          return eitem?.id == cell ? (
            <span
              style={{
                background: eitem?.color,
                color: 'white',
                padding: '5px 10px',
                borderRadius: 30,
                whiteSpace: 'nowrap',
              }}
              key={eindex}
            >
              {eitem?.name}
            </span>
          ) : null
        })}
      </div>
    )
  }

  const addPointRowFormat = (cell: any, row: any) => {
    return row?.home != 0 && row?.guest != 0 ? (
      row?.homepoint == 0 && row?.guestpoint == 0 ? (
        <div
          className='text-center cursor-pointer'
          onClick={() => {
            setItem(row)
            updateShowPointAddModal(true)
          }}
        >
          <span
            style={{
              background: '#0d2c5d',
              color: 'white',
              padding: '5px 10px',
              borderRadius: 30,
              whiteSpace: 'nowrap',
            }}
          >
            Add Game Results
          </span>
        </div>
      ) : (
        <div
          className='text-center cursor-pointer'
          onClick={() => {
            setItem(row)
            updateShowPointAddModal(true)
          }}
        >
          <span
            style={{
              background: 'green',
              color: 'white',
              padding: '5px 10px',
              borderRadius: 30,
              whiteSpace: 'nowrap',
            }}
          >
            <span>
              {row?.homepoint}:{row?.guestpoint}
            </span>
          </span>
        </div>
      )
    ) : null
  }

  const addYoutubeRowFormat = (cell: any, row: any) => {
    return isNullOrEmpty(row?.livestream) ? (
      <div
        className='text-center cursor-pointer'
        onClick={() => {
          setItem(row)
          updateShowAddYoutubeModal(true)
        }}
      >
        <span
          style={{
            background: '#0d2c5d',
            color: 'white',
            padding: '5px 10px',
            borderRadius: 30,
            whiteSpace: 'nowrap',
          }}
        >
          Add LiveStream
        </span>
      </div>
    ) : (
      <div
        className='text-center cursor-pointer'
        onClick={() => {
          setItem(row)
          updateShowAddYoutubeModal(true)
        }}
      >
        <span
          style={{
            background: 'green',
            color: 'white',
            padding: '5px 10px',
            borderRadius: 30,
            whiteSpace: 'nowrap',
          }}
        >
          {row?.livestream}
        </span>
      </div>
    )
  }

  const dayRowFormat = (cell: any, row: any) => {
    return (
      <div className='text-center flex justify-center items-center'>
        <span>DAY-{cell}</span>
      </div>
    )
  }

  const dateTimeRowFormat = (cell: any, row: any) => {
    return <div className='text-center flex justify-center items-center'>{cell.substr(0, 5)}</div>
  }

  const sportRowFormat = (cell: any, row: any) => {
    return (
      <div className='text-center flex flex-col gap-2 justify-center items-center'>
        <span>{t(`common:magicnum.SportCategories.${row?.sid}`)}</span>
        <span>{t(`common:magicnum.SportSubCategories.${row?.category}`)}</span>
      </div>
    )
  }

  // const categoryRowFormat = (cell: any, row: any) => {
  //   return (
  //     <div className='text-center flex justify-center items-center'>
  //       {t(`common:magicnum.SportSubCategories.${cell}`)}
  //     </div>
  //   )
  // }

  const homeRowFormat = (cell: any, row: any) => {
    return (
      <div className='text-center flex justify-center items-center'>
        {cell > 0 ? (
          magicnum.NOC.map((nitem, nindex) => {
            return nitem?.id == cell ? (
              <div className='flex flex-col' key={nindex}>
                <img src={`/noc-flag/${nitem?.id}.svg`} height={30} />
                <span>{nitem?.code}</span>
              </div>
            ) : null
          })
        ) : (
          <span></span>
        )}
      </div>
    )
  }

  const guestRowFormat = (cell: any, row: any) => {
    return (
      <div className='text-center flex justify-center items-center'>
        {cell > 0 ? (
          magicnum.NOC.map((nitem, nindex) => {
            return nitem?.id == cell ? (
              <div className='flex flex-col' key={nindex}>
                <img src={`/noc-flag/${nitem?.id}.svg`} height={30} />
                <span>{nitem?.code}</span>
              </div>
            ) : null
          })
        ) : (
          <span></span>
        )}
      </div>
    )
  }

  const gameRowFormat = (cell: any, row: any) => {
    return (
      <div className='text-center flex justify-center items-center'>
        {row?.home == 0 || row?.guest == 0 ? null : <span>VS</span>}
      </div>
    )
  }

  const submit = (row: any, type: string) => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (
          <div className='custom-confirm-ui'>
            <h1>{row.title}</h1>
            <p>
              {type == 'deleteMain' ? t('common:confirmDelete') : t('common:confirmChangeStatus')}
            </p>
            {type == 'changeStatusEvent' ? (
              <div>
                <select
                  name='home'
                  data-control='select2'
                  data-hide-search='true'
                  value={status}
                  className='form-select form-select-white'
                  onChange={(e) => {
                    setStatus(e.target.value)
                  }}
                >
                  <option value={0}>{t(`common:event_status.0`)}</option>
                  <option value={1}>{t(`common:event_status.1`)}</option>
                  <option value={2}>{t(`common:event_status.2`)}</option>
                </select>
              </div>
            ) : null}
            <div className='buttons'>
              <button className='btn btn-active-light btn-color-muted' onClick={onClose}>
                {t('common:no')}
              </button>
              <button
                className='btn btn-primary align-self-center'
                onClick={() => {
                  type == 'deleteMain' ? deleteFunc(row) : changeStatus(row)
                  onClose()
                }}
              >
                {t('common:yes')}
              </button>
            </div>
          </div>
        )
      },
    })
  }

  const deleteFunc = (row: any) => {
    EventDelete(row.id, BaseRequest, onDeleteSuccess, onFailed, 'DELETE')
  }

  const onDeleteSuccess = () => {
    list()
    return toast.error(t('common:successDelete'))
  }

  const changeStatus = (row: any) => {
    setLoadingPoint(true)
    var req = EventChangeStatusReq
    req.event.eid = row.id
    req.event.status = status

    EventChangeStatus(req, onChangeStatusSuccess, onFailed, 'PUT')
  }

  const onChangeStatusSuccess = () => {
    setLoadingPoint(false)
    updateShowPointModal(false)
    list()
    return toast.success(t('common:successUpdate'))
  }

  const addYoutube = (row: any) => {
    setLoadingYoutube(true)
    var req = EventAddYoutubeReq
    req.event.eid = row.id
    req.event.livestream = youtube_id

    EventAddYoutube(req, onYoutubeAddSuccess, onFailed, 'PUT')
  }

  const onYoutubeAddSuccess = () => {
    setLoadingYoutube(false)
    updateShowAddYoutubeModal(false)
    list()
    return toast.success(t('common:successUpdate'))
  }

  var table = {
    sizePerPage: sizePerPage,
    headerKeyColumn: {
      title: 'id',
      dataField: 'PrivNo',
      dataSort: true,
      dataFormat: defaultRowFormat,
      hidden: true,
      filter: {
        type: 'TextFilter',
        placeholder: '...',
      },
    },
    headerOtherColumn: [
      {
        title: 'Event Name',
        dataField: 'name',
        dataSort: false,
        dataFormat: eventNameRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Sport',
        dataField: 'sport',
        dataSort: false,
        dataFormat: sportRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Gender',
        dataField: 'gender',
        dataSort: false,
        dataFormat: genderRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      // {
      //   title: 'Sport Category',
      //   dataField: 'category',
      //   dataSort: false,
      //   dataFormat: categoryRowFormat,
      //   hidden: false,
      //   filter: {
      //     type: 'TextFilter',
      //     placeholder: '...',
      //   },
      // },
      {
        title: 'Event Day',
        dataField: 'day',
        dataSort: false,
        dataFormat: dayRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Time',
        dataField: 'time',
        dataSort: false,
        dataFormat: dateTimeRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Status',
        dataField: 'status',
        dataSort: false,
        dataFormat: statusRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Home',
        dataField: 'home',
        dataSort: false,
        dataFormat: homeRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: '',
        dataField: 'game',
        dataSort: false,
        dataFormat: gameRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Guest',
        dataField: 'guest',
        dataSort: false,
        dataFormat: guestRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Add Result',
        dataField: 'AddPoint',
        dataSort: false,
        dataFormat: addPointRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Live Youtube ID',
        dataField: 'youtube',
        dataSort: false,
        dataFormat: addYoutubeRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Action',
        dataField: 'action',
        dataSort: false,
        dataFormat: actionRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
    ],
  }

  return (
    <>
      <div className={`card mb-5`}>
        <div className='card-header border-1' style={{height: '60px'}}>
          <div
            className='filter flex items-center justify-center gap-0'
            onClick={() => setShowFilter(!showFilter)}
          >
            <KTIcon iconName='filter' className='fs-3' />
            <span className='text'>{t('common:filter')}</span>
          </div>
          <div className='card-toolbar'>
            <a
              href='#'
              className='btn btn-sm btn-light-primary'
              onClick={() => {
                setPID(1)
                list()
              }}
            >
              {t('common:search')}
            </a>
          </div>
        </div>
        {showFilter ? (
          <div className='card-body py-3'>
            <div className='row'>
              <div className='col'>
                <select
                  name='sid'
                  data-control='select2'
                  data-hide-search='true'
                  value={sid}
                  className='form-select form-select-white'
                  onChange={(e) => {
                    setSID(e.target.value)
                  }}
                >
                  <option value=''>Select Sport ID</option>
                  {magicnum.SportCategories.map((item2, index) => {
                    return (
                      <option value={item2?.id} key={index}>
                        {t(`common:magicnum.SportCategories.${item2?.id}`)}
                      </option>
                    )
                  })}
                </select>
              </div>
              <div className='col'>
                <select
                  name='gender'
                  data-control='select2'
                  data-hide-search='true'
                  className='form-select form-select-white'
                  value={gender}
                  onChange={(e) => {
                    setGender(e.target.value)
                  }}
                >
                  <option value=''>{t('common:athlete.selectgender')}</option>
                  <option value='male'>{t('common:athlete.male')}</option>
                  <option value='female'>{t('common:athlete.female')}</option>
                  <option value='team'>Team</option>
                </select>
              </div>

              <div className='col'>
                <select
                  name='category'
                  data-control='select2'
                  data-hide-search='true'
                  className='form-select form-select-white'
                  value={category}
                  onChange={(e) => {
                    setCategory(e.target.value)
                  }}
                >
                  <option value=''>Select Category</option>
                  {magicnum.SportSubCategories.map((sitem, sindex) => {
                    return sitem?.parent == sid && sitem?.gender == gender ? (
                      <option value={sitem?.id} key={sindex}>
                        {t(`common:magicnum.SportSubCategories.${sitem?.id}`)}
                      </option>
                    ) : null
                  })}
                </select>
              </div>
              <div className='col'>
                <select
                  name='day'
                  data-control='select2'
                  data-hide-search='true'
                  className='form-select form-select-white'
                  value={day}
                  onChange={(e) => {
                    setDay(e.target.value)
                  }}
                >
                  <option value=''>All day</option>
                  <option value={1}>DAY-1</option>
                  <option value={2}>DAY-2</option>
                  <option value={3}>DAY-3</option>
                  <option value={4}>DAY-4</option>
                  <option value={5}>DAY-5</option>
                  <option value={6}>DAY-6</option>
                  <option value={7}>DAY-7</option>
                  <option value={8}>DAY-8</option>
                </select>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>{t('main:menu.event')}</PageTitle>
      {showModal ? (
        <CustomModal
          visible={showModal}
          closeModal={() => updateShowModal(false)}
          className='VideoModal'
        >
          <EventCreate
            t={t}
            item={item}
            onAddSuccess={onAddSuccess}
            filteredsid={sid}
            filteredgender={gender}
          />
        </CustomModal>
      ) : null}
      {showPointModal ? (
        <CustomModal
          visible={showPointModal}
          closeModal={() => updateShowPointModal(false)}
          className='VideoModal'
        >
          <div
            className='flex flex-col gap-4 justify-center items-center'
            style={{width: 400, height: 300}}
          >
            <h1 className='mb-3'>Change Status</h1>
            <div className='row'>
              <div className='col-lg-12 fv-row mb-8'>
                <select
                  name='category'
                  data-control='select2'
                  data-hide-search='true'
                  value={status}
                  className='form-select form-select-white'
                  onChange={(e) => {
                    setStatus(e.target.value)
                  }}
                >
                  <option value={0}>Change status</option>
                  <option value={1}>Today</option>
                  <option value={2}>Upcoming</option>
                  <option value={3}>Finished</option>
                </select>
              </div>
            </div>
            <button
              className='btn btn-primary align-self-center'
              onClick={() => changeStatus(item)}
            >
              {loadingPoint ? t('common:loading') : 'Change Status'}
            </button>
          </div>
        </CustomModal>
      ) : null}
      {showAddYoutubeModal ? (
        <CustomModal
          visible={showAddYoutubeModal}
          closeModal={() => updateShowAddYoutubeModal(false)}
          className='VideoModal'
        >
          <div
            className='flex flex-col gap-4 justify-center items-center'
            style={{width: 400, height: 300}}
          >
            <h1 className='mb-3'>Add Livestream Link</h1>
            <div className='row'>
              <div className='col-lg-12 fv-row mb-8'>
                <input
                  className='form-control form-control-solid'
                  placeholder='Add Youtube Link'
                  maxLength={45}
                  value={youtube_id}
                  onChange={(e) => {
                    setYoutubeID(e.target.value)
                  }}
                ></input>
              </div>
            </div>
            <button className='btn btn-primary align-self-center' onClick={() => addYoutube(item)}>
              {loadingPoint ? t('common:loading') : 'Add'}
            </button>
          </div>
        </CustomModal>
      ) : null}
      {showPointAddModal ? (
        <CustomModal
          visible={showPointAddModal}
          closeModal={() => updateShowPointAddModal(false)}
          className='VideoModal'
        >
          {item?.home != 0 || item?.guest != 0 ? (
            <div
              className='flex flex-col gap-4 justify-center items-center'
              style={{width: 400, height: 300}}
            >
              <h1 className='mb-3'>Add Point</h1>
              <div className='row'>
                <div className='col-lg-6 fv-row mb-8'>
                  {magicnum.NOC.map((nitem, nindex) => {
                    return nitem?.id == item?.home ? (
                      <div
                        className='flex gap-2 justify-center items-center pb-2 pt-2'
                        style={{background: '#00000013'}}
                        key={nindex}
                      >
                        <img src={`/noc-flag/${nitem?.id}.svg`} height={40} />
                        <span>{nitem?.code}</span>
                      </div>
                    ) : null
                  })}
                  <input
                    className='form-control form-control-solid'
                    placeholder='Home Point'
                    maxLength={45}
                    value={homepoint}
                    onChange={(e) => {
                      setHomepoint(e.target.value)
                    }}
                  ></input>
                </div>
                <div className='col-lg-6 fv-row mb-8'>
                  {magicnum.NOC.map((nitem, nindex) => {
                    return nitem?.id == item?.guest ? (
                      <div
                        className='flex gap-2 justify-center items-center pb-2 pt-2'
                        style={{background: '#00000013'}}
                        key={nindex}
                      >
                        <img src={`/noc-flag/${nitem?.id}.svg`} height={40} />
                        <span>{nitem?.code}</span>
                      </div>
                    ) : null
                  })}
                  <input
                    className='form-control form-control-solid'
                    placeholder='Guest Point'
                    maxLength={45}
                    value={guestpoint}
                    onChange={(e) => {
                      setGuestpoint(e.target.value)
                    }}
                  ></input>
                </div>
              </div>
              <button className='btn btn-primary align-self-center' onClick={() => add()}>
                {loadingYoutube ? t('common:loading') : t('common:save')}
              </button>
            </div>
          ) : null}
        </CustomModal>
      ) : null}
      <div className={`card`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>{t('main:menu.event')}</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>
              {t('common:NEWS.totale', {count: PTotal})}
            </span>
          </h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
            <a
              href='#'
              className='btn btn-sm btn-light-primary'
              onClick={() => {
                setItem(undefined)
                updateShowModal(true)
              }}
            >
              <KTIcon iconName='plus' className='fs-3' />
              {t('common:event.add')}
            </a>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <DataTable
            data={data}
            table={table}
            PTotal={PTotal}
            pageChange={onChangePage}
            PID={PID}
            loading={loading}
          />
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export {Event}
