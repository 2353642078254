import {FC, lazy, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {PrivList} from '../pages/privilege/Priv/PrivList'
import {PrivGroupList} from '../pages/privilege/PrivGroup/PrivGroupList'
import {PrivGroupMore} from '../pages/privilege/PrivGroup/PrivGroupMore'
import {News} from '../pages/news/index'
import {NewsMore} from '../pages/news/more'
import {Page} from '../pages/page/index'
import {Video} from '../pages/video/index'
import {PageMore} from '../pages/page/more'
import {Employee} from '../pages/employee/index'
import {EmployeeMore} from '../pages/employee/more'
import {Athlete} from '../pages/athlete/index'
import {AthleteMore} from '../pages/athlete/more'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {Sports} from '../pages/sports'
import {Volunteers} from '../pages/volunteers'
import {VolunteerMore} from '../pages/volunteers/more'
import {Flight} from '../pages/flight'
import {Event} from '../pages/event'
import {EventMore} from '../pages/event/more'
const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='/privilege/privlist' element={<PrivList />} />
        <Route path='/privilege/privgrouplist' element={<PrivGroupList />} />
        <Route path='/news' element={<News />} />
        <Route path='/videos' element={<Video />} />
        <Route path='/newsmore/:id' element={<NewsMore />} />
        <Route path='/privilege/privgroup/:id' element={<PrivGroupMore />} />
        <Route path='/pages' element={<Page />} />
        <Route path='/page/:id' element={<PageMore />} />
        <Route path='/athletes' element={<Athlete />} />
        <Route path='/events' element={<Event />} />
        <Route path='/events/:id' element={<EventMore />} />
        <Route path='/athlete/:id' element={<AthleteMore />} />
        <Route path='/registeration' element={<Athlete />} />
        <Route path='/sports' element={<Sports />} />
        <Route path='/volunteer' element={<Volunteers />} />
        <Route path='/flight' element={<Flight />} />
        <Route path='/volunteer/:id' element={<VolunteerMore />} />
        <Route path='/registeration/:id' element={<AthleteMore />} />
        <Route path='/employee' element={<Employee />} />
        <Route path='/employeemore/:id' element={<EmployeeMore />} />

        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
