import React, {FC, useEffect, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import DataTable from '../../modules/CustomBootsrapTable/CustomBootsrapTable'
import CustomModal from '../../modules/CustomModal/modal'
import {isNullOrEmpty} from '../../../services/Utils'
import {
  AthletesListReq,
  BaseRequest,
  MedalAddReq,
  MedalListReq,
  ScheduleSingleAddReq,
  SingleAddPointReq,
  SingleListReq,
  magicnum,
} from '../../../services/Request'
import {
  AthletesList,
  CDN_URL,
  EventGet,
  MedalAdd,
  MedalDelete,
  MedalList,
  ScheduleSingleAdd,
  SingleAddPoint,
  SingleDelete,
  SingleList,
} from '../../../services/main'
import {toast} from 'react-toastify'
import {confirmAlert} from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useTranslation} from 'react-i18next'
import {useParams, useSearchParams} from 'react-router-dom'
import {EventReport} from './eventreport'
const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Event',
    path: '/events',
    isSeparator: false,
    isActive: false,
  },
]

const EventMore: FC = () => {
  const {id} = useParams()
  const [searchParams] = useSearchParams()
  const title = searchParams.get('title')
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [loadingPoint, setLoadingPoint] = useState(false)
  const [loadingSchedule, setLoadingSchedule] = useState(false)
  const [LoadingMedalAdd, setLoadingMedalAdd] = useState(false)
  const [LoadingMedalList, setLoadingMedalList] = useState(false)
  const [loadingSingle, setLoadingSingle] = useState(false)
  const [singloading, setSingleLoading] = useState(false)
  const [body, setBody] = useState('')
  const [bodyContent, setBodyContent] = useState('')
  const [PID, setPID] = useState(1)
  const [medal_PID, setMedal_PID] = useState(1)
  const [PTotal, setPTotal] = useState(1)
  const [sizePerPage, setSizePerPage] = useState(200)
  const [medal_sizePerPage, setMedal_SizePerPage] = useState(200)
  const [medal_PTotal, setMedal_PTotal] = useState(1)
  const [data, setData] = useState<any>('')
  const [item, setItem] = useState<any>([])
  const [medalData, setMedalData] = useState<any>([])
  const [singleData, setSingleData] = useState<any>([])
  const [itemHome, setItemHome] = useState<any>([])
  const [itemGuest, setItemGuest] = useState<any>([])
  const [homea, setHomeA] = useState<any>('')
  const [guesta, setGuestA] = useState<any>('')
  const [m_aid, setM_AID] = useState<any>('')
  const [m_noc, setM_Noc] = useState<any>('')
  const [m_MedalType, setM_MedalType] = useState<any>('')
  const [showPointAddModal, updateShowPointAddModal] = useState(false)
  const [homepoint, setHomepoint] = useState<any>('')
  const [guestpoint, setGuestpoint] = useState<any>('')
  const [selectedHomePoint, setSelectedHomePoint] = useState<any>('')
  const [selectedGuestPoint, setSelectedGuestPoint] = useState<any>('')
  const [selectedPoint, setSelectedPoint] = useState<any>()
  useEffect(() => {
    get()
  }, [id])

  useEffect(() => {
    if (!isNullOrEmpty(data)) {
      if (data?.home > 0) {
        list('home')
      }
      if (data?.guest > 0) {
        list('guest')
      }
      if (data?.guest == 0 && data?.home == 0) {
        list('')
      }
      singleList()
      medalList()
    }
  }, [data])

  const get = () => {
    if (!loading) {
      setLoading(true)
    }
    EventGet(id, BaseRequest, onSuccess, onFailed, 'GET')
  }

  const list = (selectedType: any) => {
    if (!loading) {
      setLoading(true)
    }
    var req = AthletesListReq
    req.pid = PID
    req.pSize = sizePerPage
    req.filter.category = data?.sid
    req.filter.gender = data?.gender
    if (data?.sid == 6) {
      req.filter.gender = ''
    }
    if (data?.category == 0) {
      req.filter.sub_category = ''
    } else {
      req.filter.sub_category = data?.category
    }
    if (selectedType == 'home') {
      req.filter.noc = data?.home
    } else if (selectedType == 'guest') {
      req.filter.noc = data?.guest
    } else {
      req.filter.noc = ''
    }
    AthletesList(
      req,
      selectedType == 'home'
        ? onSuccessListHome
        : selectedType == 'guest'
        ? onSuccessListGuest
        : onSuccessList,
      onFailed,
      'POST'
    )
  }

  const onSuccessList = (response: any) => {
    setLoading(false)
    setItem(response.data)
  }

  const onSuccessListHome = (response: any) => {
    setLoading(false)
    list('guest')
    setItemHome(response.data)
  }
  const onSuccessListGuest = (response: any) => {
    setLoading(false)
    setItemGuest(response.data)
  }
  const onSuccess = (response: any) => {
    setLoading(false)
    setData(response.data)
  }

  const add = () => {
    setLoadingSchedule(true)
    var req = ScheduleSingleAddReq
    req.event.eid = data.id
    req.event.home = homea
    req.event.guest = guesta
    ScheduleSingleAdd(req, successAdd, onFailed, 'POST')
  }

  const singleList = () => {
    setSingleLoading(true)
    var req = SingleListReq
    req.pid = PID
    req.pSize = sizePerPage
    req.filter.eid = data?.id
    SingleList(req, successSingleList, onFailed, 'POST')
  }

  const successSingleList = (response: any) => {
    console.log(response)
    setSingleLoading(false)
    setSingleData(response.data)
  }

  const successAdd = () => {
    setLoadingSchedule(false)
    setHomeA('')
    setGuestA('')
    singleList()
    return toast.success(t('common:successUpdate'))
  }

  const addMedal = () => {
    if (
      ((data?.category == 2102 ||
        data?.category == 2202 ||
        data?.category == 2301 ||
        data?.category == 9102 ||
        data?.category == 9103 ||
        data?.category == 9202 ||
        data?.category == 9203 ||
        data?.category == 9301 ||
        (data?.sid == 1 && data?.gender=='team') ||
        data?.sid == 3 ||
        data?.sid == 4 ||
        data?.sid == 7 ||
        data?.sid == 11) &&
        !isNullOrEmpty(m_noc) &&
        !isNullOrEmpty(m_MedalType)) ||
      ((data?.category == 2102 ||
        data?.category == 2202 ||
        data?.category == 2301 ||
        data?.category == 9102 ||
        data?.category == 9103 ||
        data?.category == 9202 ||
        data?.category == 9203 ||
        data?.category == 9301 ||
        data?.sid !== 3 ||
        data?.sid !== 4 ||
        data?.sid !== 7 ||
        data?.sid !== 11) &&
        !isNullOrEmpty(m_aid) &&
        !isNullOrEmpty(m_MedalType))
    ) {
      setLoadingMedalAdd(true)
      var req = MedalAddReq
      req.medal.sport = data?.sid
      req.medal.sub_category = data?.category
      req.medal.gender = data?.gender
      if (!isNullOrEmpty(m_aid)) {
        req.medal.aid = m_aid
        req.medal.noc = item?.filter((item: any) => item?.id == m_aid)[0].noc
      }
      if (!isNullOrEmpty(m_noc)) {
        req.medal.noc = m_noc
      }
      req.medal.eid = data?.id
      req.medal.medal_type = m_MedalType
      MedalAdd(req, successAddMedal, onFailed, 'POST')
    } else {
      return toast.error('Enter your complete data')
    }
  }

  const successAddMedal = () => {
    setLoadingMedalAdd(false)
    setM_AID('')
    setM_Noc('')
    setM_MedalType('')
    medalList()
    return toast.success('Success Added')
  }

  const medalList = () => {
    setLoadingMedalList(true)
    var req = MedalListReq
    req.pid = medal_PID
    req.pSize = medal_sizePerPage
    req.filter.eid = data?.id
    MedalList(req, successMedalList, onFailed, 'POST')
  }

  const successMedalList = (response: any) => {
    setLoadingMedalAdd(false)
    setMedalData(response.data)
  }

  const deleteFunc = (m_item: any) => {
    MedalDelete(m_item?.id, BaseRequest, onDeleteSuccess, onFailed, 'DELETE')
  }

  const onDeleteSuccess = () => {
    medalList()
    return toast.error(t('common:successDelete'))
  }

  const deleteFuncSingle = (s_item: any) => {
    setLoadingSingle(true)
    SingleDelete(s_item?.id, BaseRequest, onDeleteSuccessSingle, onFailed, 'DELETE')
  }

  const onDeleteSuccessSingle = () => {
    setLoadingSingle(false)
    singleList()
    return toast.error(t('common:successDelete'))
  }

  const onFailed = (error: string) => {
    setLoading(false)
    setLoadingSchedule(false)
    setLoadingMedalAdd(false)
    setLoadingMedalList(false)
    setLoadingSingle(false)
    setSingleLoading(false)
    return toast.error(error)
  }

  const addPoint = (idd: any) => {
    if (!isNullOrEmpty(homepoint) && !isNullOrEmpty(guestpoint)) {
      setLoadingPoint(true)
      var req = SingleAddPointReq
      req.event.id = selectedPoint
      req.event.homepoint = homepoint
      req.event.guestpoint = guestpoint
      SingleAddPoint(req, onSuccessPoint, onFailed, 'PUT')
    }
  }

  const onSuccessPoint = (response: any) => {
    setGuestpoint(0)
    setHomepoint(0)
    setLoadingPoint(false)
    updateShowPointAddModal(false)
    singleList()
    return toast.success(t('common:successUpdate'))
  }

  return (
    <>
      {showPointAddModal ? (
        <CustomModal
          visible={showPointAddModal}
          closeModal={() => updateShowPointAddModal(false)}
          className='VideoModal'
        >
          {item?.home != 0 || item?.guest != 0 ? (
            <div
              className='flex flex-col gap-4 justify-center items-center'
              style={{width: 400, height: 300}}
            >
              <h1 className='mb-3'>Add Point</h1>
              <div className='row'>
                <div className='col-lg-6 fv-row mb-8 flex flex-col gap-2'>
                  <div className='flex gap-2 items-center'>
                    <img
                      src={`${CDN_URL}${selectedHomePoint?.photo}`}
                      width={50}
                      height={50}
                      style={{borderRadius: '50%'}}
                    />
                    <span>
                      {selectedHomePoint?.firstname} {selectedHomePoint?.lastname}
                    </span>
                  </div>
                  <input
                    className='form-control form-control-solid'
                    placeholder='Home Point'
                    maxLength={45}
                    value={homepoint}
                    onChange={(e) => {
                      setHomepoint(e.target.value)
                    }}
                  ></input>
                </div>
                <div className='col-lg-6 fv-row mb-8 flex flex-col gap-2'>
                  <div className='flex gap-2 items-center'>
                    <img
                      src={`${CDN_URL}${selectedGuestPoint?.photo}`}
                      width={50}
                      height={50}
                      style={{borderRadius: '50%'}}
                    />
                    <span>
                      {selectedGuestPoint?.firstname} {selectedGuestPoint?.lastname}
                    </span>
                  </div>
                  <input
                    className='form-control form-control-solid'
                    placeholder='Guest Point'
                    maxLength={45}
                    value={guestpoint}
                    onChange={(e) => {
                      setGuestpoint(e.target.value)
                    }}
                  ></input>
                </div>
              </div>
              <button
                className='btn btn-primary align-self-center'
                onClick={() => addPoint(selectedHomePoint?.id)}
              >
                {loadingPoint ? t('common:loading') : t('common:save')}
              </button>
            </div>
          ) : null}
        </CustomModal>
      ) : null}
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>{data?.name}</PageTitle>
      <div className='card mt-5 p-8'>
        <div className='flex justify-between'>
          <div className='flex flex-col' style={{gap: 60}}>
            <div className='infos flex gap-8' style={{fontWeight: '600', fontSize: 22}}>
              {/* <span>{data?.name}</span> */}
              <span>{t(`common:magicnum.SportCategories.${data?.sid}`)}</span>
              <span>{data?.gender?.toUpperCase()}</span>
              <span>{t(`common:magicnum.SportSubCategories.${data?.category}`)}</span>
            </div>
            {data?.home > 0 || data?.guest > 0 ? (
              <div className='flex' style={{gap: 150}}>
                <div className='flex flex-col gap-4'>
                  {itemHome?.map((aitem: any, aindex: any) => {
                    return (
                      <div key={aindex} className='flex gap-4 items-center'>
                        <img
                          src={CDN_URL + aitem?.photo}
                          height={60}
                          width={60}
                          style={{borderRadius: '50%'}}
                        />
                        <div className='flex flex-col items-start gap-2'>
                          <div>
                            <img src={`/noc-flag/${aitem?.noc}.svg`} height={20} />
                          </div>
                          <div className='flex gap-2' style={{fontWeight: '600'}}>
                            <span>{aitem?.firstname}</span>
                            <span>{aitem?.lastname}</span>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
                <div className='flex flex-col gap-4'>
                  {itemGuest?.map((aitem: any, aindex: any) => {
                    return (
                      <div key={aindex} className='flex gap-4 items-center'>
                        <img
                          src={CDN_URL + aitem?.photo}
                          height={60}
                          width={60}
                          style={{borderRadius: '50%'}}
                        />
                        <div className='flex flex-col items-start gap-2'>
                          <div>
                            <img src={`/noc-flag/${aitem?.noc}.svg`} height={20} />
                          </div>
                          <div className='flex gap-2' style={{fontWeight: '600'}}>
                            <span>{aitem?.firstname}</span>
                            <span>{aitem?.lastname}</span>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            ) : (
              <div className='flex flex-wrap gap-10'>
                {item?.map((aitem: any, aindex: any) => {
                  return (
                    <div key={aindex} className='flex gap-4 items-center card-styles-schedule'>
                      <img
                        src={CDN_URL + aitem?.photo}
                        height={60}
                        width={60}
                        style={{borderRadius: '50%'}}
                      />
                      <div className='flex flex-col items-start gap-2'>
                        <div>
                          <img src={`/noc-flag/${aitem?.noc}.svg`} height={20} />
                        </div>
                        <div className='flex gap-2' style={{fontWeight: '600'}}>
                          <span>{aitem?.firstname}</span>
                          <span>{aitem?.lastname}</span>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        </div>
      </div>
      {/* add schedule */}

      {data?.category == 2102 ||
      data?.category == 2202 ||
      data?.category == 2301 ||
      data?.category == 9102 ||
      data?.category == 9103 ||
      data?.category == 9202 ||
      data?.category == 9203 ||
      data?.category == 9301 ||
      data?.sid == 3 ||
      data?.sid == 4 ||
      data?.sid == 7 ||
      data?.sid == 11 ? null : (
        <div className='card mt-5 p-8'>
          <div className='flex flex-col gap-4'>
            <div className='add flex flex-col gap-2'>
              <h1 className='title'>Add Schedule</h1>
              <div className='flex gap-4' style={{width: 600}}>
                <select
                  name='category'
                  data-control='select2'
                  data-hide-search='true'
                  className='form-select form-select-white'
                  value={homea}
                  onChange={(e) => {
                    setHomeA(e.target.value)
                  }}
                >
                  <option value=''> Select Athlete</option>
                  {item?.map((citem: any, cindex: any) => {
                    return (
                      <option key={cindex} value={citem?.id}>
                        {citem?.firstname} {citem?.lastname}
                      </option>
                    )
                  })}
                </select>
                <select
                  name='category'
                  data-control='select2'
                  data-hide-search='true'
                  className='form-select form-select-white'
                  value={guesta}
                  onChange={(e) => {
                    setGuestA(e.target.value)
                  }}
                >
                  <option value=''> Select Athlete</option>
                  {item?.map((citem: any, cindex: any) => {
                    return (
                      <option key={cindex} value={citem?.id}>
                        {citem?.firstname} {citem?.lastname}
                      </option>
                    )
                  })}
                </select>
                <button
                  className='btn btn-primary align-self-center'
                  disabled={loadingSchedule}
                  onClick={() => add()}
                >
                  {loadingSchedule ? t('common:loading') : t('common:save')}
                </button>
              </div>
            </div>
            <div className='flex gap-10 items-center'>
              <div className='flex flex-col gap-8'>
                {singleData?.map((ssitem: any, ssindex: any) => {
                  const hite = item?.filter((ffitem: any) => ffitem?.id == ssitem?.home)[0]
                  const gite = item?.filter((ffitem: any) => ffitem?.id == ssitem?.guest)[0]
                  return (
                    <div className='flex gap-6 items-center' style={{width: '100%'}}>
                      <div
                        key={ssindex}
                        className='card-styles-schedule flex justify-between items-centery gap-4 items-center'
                        style={{padding: 30}}
                      >
                        {ssitem?.home == hite?.id ? (
                          <div className='flex gap-2 items-center'>
                            <img
                              src={`${CDN_URL}${hite?.photo}`}
                              width={50}
                              height={50}
                              style={{borderRadius: '50%'}}
                            />
                            <div className='flex flex-col'>
                              <span>
                                {hite?.firstname} {hite?.lastname}
                              </span>
                              {ssitem?.homepoint}
                            </div>
                          </div>
                        ) : null}
                        <span style={{fontSize: 22, fontWeight: '600'}}>VS</span>
                        {ssitem?.guest == gite?.id ? (
                          <div className='flex gap-2 items-center'>
                            <img
                              src={`${CDN_URL}${gite?.photo}`}
                              width={50}
                              height={50}
                              style={{borderRadius: '50%'}}
                            />
                            <div className='flex flex-col'>
                              <span>
                                {gite?.firstname} {gite?.lastname}
                              </span>
                              {ssitem?.guestpoint}
                            </div>
                          </div>
                        ) : null}
                      </div>
                      <div style={{height: 100, background: '#cfcfcf', width: 1}}></div>
                      <div className='action flex items-center justify-center' style={{gap: 40}}>
                        <div
                          className='flex gap-2 items-center btn btn-light'
                          style={{cursor: 'pointer'}}
                          onClick={() => {
                            updateShowPointAddModal(true)
                            setSelectedGuestPoint(gite)
                            setSelectedHomePoint(hite)
                            setSelectedPoint(ssitem?.id)
                          }}
                        >
                          <KTIcon iconName='bi bi-patch-plus' className='fs-1 text-black lg-n1' />
                          <span>Add Point</span>
                        </div>
                        <button
                          className='flex items-center btn btn-light'
                          onClick={() => deleteFuncSingle(ssitem)}
                          disabled={loadingSingle}
                        >
                          <KTIcon iconName='bi bi-trash' className='fs-1 text-red lg-n1' />
                          <span className='text-red'>Delete</span>
                        </button>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* result to schedule */}
      {/* <div className='card mt-5 p-8'>
        <div>
          {data?.sid == 3 || data?.sid == 4 || data?.sid == 7 || data?.sid == 11 ? null : (
            <div className='add flex flex-col gap-2'>
              <h1 className='title'>Add Schedule Result</h1>
              <div className='flex gap-4' style={{width: 600}}>
                <select
                  name='category'
                  data-control='select2'
                  data-hide-search='true'
                  className='form-select form-select-white'
                  value={homea}
                  onChange={(e) => {
                    setHomeA(e.target.value)
                  }}
                >
                  <option value=''> Select Athlete</option>
                  {item?.map((citem: any, cindex: any) => {
                    return (
                      <option key={cindex} value={citem?.id}>
                        {citem?.firstname} {citem?.lastname}
                      </option>
                    )
                  })}
                </select>
                <select
                  name='category'
                  data-control='select2'
                  data-hide-search='true'
                  className='form-select form-select-white'
                  value={guesta}
                  onChange={(e) => {
                    setGuestA(e.target.value)
                  }}
                >
                  <option value=''> Select Athlete</option>
                  {item?.map((citem: any, cindex: any) => {
                    return (
                      <option key={cindex} value={citem?.id}>
                        {citem?.firstname} {citem?.lastname}
                      </option>
                    )
                  })}
                </select>
                <button
                  className='btn btn-primary align-self-center'
                  disabled={loadingSchedule}
                  onClick={() => add()}
                >
                  {loadingSchedule ? t('common:loading') : t('common:save')}
                </button>
              </div>
            </div>
          )}
        </div>
      </div> */}
      {/* medal result */}
      <div className='card mt-5 p-8'>
        <div>
          <div className='flex flex-col gap-4'>
            <div className='add flex flex-col gap-2'>
              <h1 className='title'>Add Medal</h1>
              <div className='flex gap-4' style={{width: 900}}>
                {data?.category == 2102 ||
                data?.category == 2202 ||
                data?.category == 2301 ||
                data?.category == 9102 ||
                data?.category == 9103 ||
                data?.category == 9202 ||
                data?.category == 9203 ||
                data?.category == 9301 ||
                (data?.sid == 1 && data?.gender == 'team') ||
                data?.sid == 3 ||
                data?.sid == 4 ||
                data?.sid == 7 ||
                data?.sid == 11 ? (
                  <select
                    name='category'
                    data-control='select2'
                    data-hide-search='true'
                    className='form-select form-select-white'
                    value={m_noc}
                    onChange={(e) => {
                      setM_Noc(e.target.value)
                    }}
                  >
                    <option value=''> Select NOC</option>
                    <option value={data?.home}>
                      ({magicnum.NOC.filter((nitem: any) => nitem?.id == data?.home)[0]?.code})
                    </option>
                    <option value={data?.guest}>
                      {magicnum.NOC.filter((nitem: any) => nitem?.id == data?.guest)[0]?.code}
                    </option>
                  </select>
                ) : (
                  <select
                    name='category'
                    data-control='select2'
                    data-hide-search='true'
                    className='form-select form-select-white'
                    value={m_aid}
                    onChange={(e) => {
                      setM_AID(e.target.value)
                    }}
                  >
                    <option value=''> Select Athlete</option>
                    {item?.map((citem: any, cindex: any) => {
                      return (
                        <option key={cindex} value={citem?.id}>
                          ({magicnum.NOC.filter((nitem: any) => nitem?.id == citem?.noc)[0]?.code}
                          )-
                          {citem?.firstname} {citem?.lastname}
                        </option>
                      )
                    })}
                  </select>
                )}
                <select
                  name='category'
                  data-control='select2'
                  data-hide-search='true'
                  className='form-select form-select-white'
                  value={m_MedalType}
                  onChange={(e) => {
                    setM_MedalType(e.target.value)
                  }}
                >
                  <option value=''> Select Medal Type</option>
                  <option value={1}>Gold</option>
                  <option value={2}>Silver</option>
                  <option value={3}>Bronze</option>
                </select>
                <button
                  className='btn btn-primary align-self-center'
                  disabled={LoadingMedalAdd}
                  onClick={() => addMedal()}
                  style={{whiteSpace: 'nowrap'}}
                >
                  {LoadingMedalAdd ? t('common:loading') : 'Add Medal'}
                </button>
              </div>
            </div>
            <div className='flex flex-col gap-4'>
              {medalData?.map((mitem: any, mindex: any) => {
                return (
                  <div
                    key={mindex}
                    className='flex justify-between add-medal-list-card items-center'
                    style={{width: 450}}
                  >
                    <div className='flex gap-4 items-center'>
                      <img src={`/medal/${mitem?.medal_type}.png`} height={30} />
                      <img src={`/noc-flag/${mitem?.noc}.svg`} height={20} />
                      {!isNullOrEmpty(mitem?.aid)
                        ? item?.map((fitem: any, findex: any) => {
                            return fitem?.id == mitem?.aid ? (
                              <span key={findex} style={{whiteSpace: 'nowrap'}}>
                                {fitem?.firstname} {fitem?.lastname}{' '}
                              </span>
                            ) : null
                          })
                        : null}
                    </div>
                    <div
                      onClick={() => deleteFunc(mitem)}
                      className='cursor-pointer flex justify-center items-center'
                    >
                      <KTIcon iconName='bi bi-trash' className='fs-1 text-black' />
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <EventReport t={t} item={data} />
    </>
  )
}

export {EventMore}
