import React, {FC, useEffect, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {buildAccorditionCard, dateTimeFormat, isNullOrEmpty} from '../../../services/Utils'
import {AthleteSetAccIDReq, BaseRequest, magicnum} from '../../../services/Request'
import {AthleteGet, AthleteSetAccID, CDN_URL} from '../../../services/main'
import {toast} from 'react-toastify'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useTranslation} from 'react-i18next'
import {Link, useLocation, useParams, useSearchParams} from 'react-router-dom'
import {TabSport} from './tab/tabsport'
import {TabContact} from './tab/tabcontact'
import {TabVisa} from './tab/tabvisa'
import {Image} from 'react-bootstrap'
import {TabTechnical} from './tab/tabtechnical'
import {TabMedia} from './tab/tabmedia'
import {TabVip} from './tab/tabvip'
import QRCode from 'react-qr-code'
import {TabAccCard} from './tab/tabacccard'
import useCookie from '../../../services/useCookie'
import {Item1} from '../../../_metronic/partials/content/activity/Item1'
const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Athletes',
    path: `/athletes`,
    isSeparator: false,
    isActive: false,
  },
]

const AthleteMore: FC = () => {
  const {id} = useParams()
  const [searchParams] = useSearchParams()
  const title = searchParams.get('title')
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [count, setCount] = useState(0)
  const [countRefresh, setCountRefresh] = useState(0)
  const [tab, setTab] = useState('tabsport')
  const [accCard, setAccCard] = useState<any>('')
  const [item, setItem] = useState<any>(undefined)
  const [sports, setSports] = useState<any>([])
  const [selectedSport, setSelectedSport] = useState('')
  const [loadingSetAccrID, setLoadingSetAccrID] = useState(false)
  const [EmployeeTypeID] = useCookie('EmployeeTypeID', null)
  const location = useLocation()
  const birth = item?.birth
  const gender = item?.gender.toUpperCase()
  var ite = magicnum.accreditationCategory.filter((item2) => item2?.id == item?.accr_category)[0]

  useEffect(() => {
    get()
  }, [id])

  useEffect(() => {
    if (!isNullOrEmpty(item)) {
      var vv = buildAccorditionCard(item, sports[0])
      setAccCard(isNullOrEmpty(vv) ? '' : vv)
      if (sports.length > 0) {
        setSelectedSport(sports[0]?.category)
      }
    }
    setCountRefresh(countRefresh+1)
  }, [item, sports])

  const get = () => {
    if (!loading) {
      setLoading(true)
    }
    AthleteGet(id, BaseRequest, onGetSuccess, onFailed, 'GET')
  }

  const onGetSuccess = (response: any) => {
    setLoading(false)
    var data = response?.data
    setItem(data)
    if (response?.data?.accr_category != 'Aa') {
      setTab('tabcontact')
    }
  }

  const onFailed = (error: string) => {
    setLoading(false)
    setLoadingSetAccrID(false)
    return toast.error(error)
  }

  const calculateAge = () => {
    const currentDate = new Date()
    const dob = new Date(birth)

    let age = currentDate.getFullYear() - dob.getFullYear()

    if (
      currentDate.getMonth() < dob.getMonth() ||
      (currentDate.getMonth() === dob.getMonth() && currentDate.getDate() < dob.getDate())
    ) {
      age--
    }

    return age
  }

  const age = calculateAge()

  const onSportSuccess = (response: any) => {
    setSports(response)
  }

  const setAcc = (acc_id: any) => {
    var activeStatus = 0
    var reqA = true
    if (item?.accr_category == 'Aa') {
      sports.map((hh: any) => {
        if (hh.status == 1) {
          activeStatus++
        }
      })
      if (sports.length > activeStatus) {
        reqA = false
        return toast.warning(t('common:athlete.allSportActiveReq'))
      }
      if (sports.length == 0) {
        reqA = false
        return toast.warning(t('common:athlete.SelectSportActiveReq'))
      }
    }
    if (reqA) {
      if (!loadingSetAccrID) {
        setLoadingSetAccrID(true)
      }
      var req = AthleteSetAccIDReq
      req.athlete.id = item.id
      req.athlete.acc_id = acc_id
      AthleteSetAccID(req, onSetSuccess, onFailed, 'PUT')
    }
  }

  const onSetSuccess = (response: any) => {
    setLoadingSetAccrID(false)
    setCount(count + 1)
    return toast.success(t('common:athlete.setAccIDSuccess'))
  }

  return (
    <>
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>{t('common:athlete.moretitle')}</PageTitle>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            
            <div className='me-7 mb-4'>
              <div className='symbol symbol-fixed position-relative'>
                <img src={CDN_URL + item?.photo} alt='Metornic' style={{height: 170, width: 128}} />
                {/* <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div> */}
              </div>
              <span style={{color:"white"}}>{countRefresh}</span>
            </div>
            <div
              className='flex-grow-1'
              style={{rowGap: '18px', display: 'flex', flexDirection: 'column'}}
            >
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-2'>
                    <span className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                      {item?.lastname} {item?.firstname}
                    </span>
                    {item?.status == 0 ? null : (
                      <a href='#'>
                        <KTIcon
                          iconName='verify'
                          className={`fs-1 ${item?.status == 1 ? 'text-info' : 'text-success'}`}
                        />
                      </a>
                    )}
                  </div>

                  <div className='d-flex flex-wrap fw-bold fs-6 pe-2'>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                    >
                      {/* <KTIcon iconName='profile-circle' className='fs-4 me-1' />
                      {t(`common:NOC.${item?.noc}`)} */}
                      <div
                        style={{
                          display: 'flex',
                          columnGap: '10px',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Image
                          src={toAbsoluteUrl(`/noc-flag/${item?.noc}.svg`)}
                          height={30}
                          style={{
                            border: '1px solid #dddddd',
                            borderRadius: '5px',
                          }}
                        />
                        {magicnum.NOC.map((item2, index) =>
                          item2?.order == item?.noc ? (
                            <span key={index} style={{color: 'black'}}>
                              {item2.code}
                            </span>
                          ) : null
                        )}
                      </div>
                    </a>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                    >
                      {/* <KTIcon iconName='geolocation' className='fs-4 me-1' /> */}
                      <span style={{color: 'blue'}}>
                        {sports.length > 0
                          ? t(`common:magicnum.SportCategories.${selectedSport}`).toUpperCase()
                          : ''}
                      </span>
                    </a>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                    >
                      {/* <KTIcon iconName='geolocation' className='fs-4 me-1' /> */}
                      <div className='flex gap-1'>
                        <span>{t('common:blood_type')}:</span>
                        <span style={{color: 'red'}}>{item?.blood_type}</span>
                      </div>
                    </a>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                    >
                      {/* <KTIcon iconName='geolocation' className='fs-4 me-1' /> */}
                      <div className='flex gap-1'>
                        <span>{t('common:meal_pref')}:</span>
                        <span style={{color: 'green'}}>{item?.food}</span>
                      </div>
                    </a>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                    >
                      {/* <KTIcon iconName='geolocation' className='fs-4 me-1' /> */}
                      <div className='flex gap-1'>
                        <span>{t('common:trans')}:</span>
                        {/* {magicnum.accreditationSubCategoryNew.map((item1, index) => {
                          return (
                            <span key={index} style={{color: 'Black'}}>
                              {item1?.transportation}
                            </span>
                          )
                        })} */}
                        {magicnum.accreditationSubCategoryNew.map((item1, index) => {
                          return item1?.id == item?.accr_sub_category ? (
                            <span key={index} style={{color: 'Black'}}>
                              {item1?.transportation}
                            </span>
                          ) : null
                        })}
                      </div>
                    </a>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                    >
                      {/* <KTIcon iconName='geolocation' className='fs-4 me-1' /> */}
                      <div className='flex gap-1'>
                        <span>{t('common:venue_acc')}:</span>
                        {magicnum.accreditationSubCategoryNew.map((item1, index) => {
                          return item1?.id == item?.accr_sub_category ? (
                            <span key={index} style={{color: 'Black'}}>
                              {item1?.venue.toUpperCase()}
                            </span>
                          ) : null
                        })}
                      </div>
                    </a>
                  </div>
                </div>

                {/* <div className='d-flex my-4'>
                  <a href='#' className='btn btn-sm btn-light me-2' id='kt_user_follow_button'>
                    <KTIcon iconName='check' className='fs-3 d-none' />

                    <span className='indicator-label'>Follow</span>
                    <span className='indicator-progress'>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  </a>
                  <a
                    href='#'
                    className='btn btn-sm btn-primary me-3'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_offer_a_deal'
                  >
                    Hire Me
                  </a>
                  <div className='me-0'>
                    <button
                      className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      data-kt-menu-flip='top-end'
                    >
                      <i className='bi bi-three-dots fs-3'></i>
                    </button>
                    <Dropdown1 />
                  </div>
                </div> */}
              </div>

              <div className='d-flex flex-wrap flex-stack'>
                <div className='d-flex flex-column flex-grow-1 pe-8 flex justify-center'>
                  <div className='d-flex flex-wrap'>
                    <div
                      className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 flex justify-center'
                      style={{background: ite?.color}}
                    >
                      <div className='d-flex align-items-center justify-center'>
                        <div
                          className='fw-bolder flex items-center justify-center'
                          style={{fontSize: '35px', color: 'white'}}
                        >
                          {item?.accr_category}
                        </div>
                      </div>
                    </div>
                    {sports.length > 0 ? (
                      <div className=' flex items-center justify-center border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                        <div className='d-flex align-items-center'>
                          {/* <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' /> */}
                          <div className='flex flex-col gap-1'>
                            <div className='fw-bolder'>
                              {sports.map((uu: any, index: number) => {
                                return (
                                  <div style={{fontSize: '20px', display: 'block'}} key={index}>
                                    {t(`common:magicnum.SportSubCategories.${uu.sub_category}`)}
                                  </div>
                                )
                              })}
                            </div>
                            {/* <div className='fw-bolder'>Men Doubles</div> */}
                            {/* <div className='fw-bolder'>Team Doubles</div> */}
                          </div>
                        </div>
                      </div>
                    ) : null}

                    <div className='flex flex-col items-center justify-center border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <div className='fs-2 fw-bolder'>
                          {!isNullOrEmpty(item?.acc_id)
                            ? item?.acc_id
                            : !isNullOrEmpty(accCard) &&
                              !isNullOrEmpty(item) &&
                              (!isNullOrEmpty(item?.acc_id) || count > 0)
                            ? accCard
                            : null}
                        </div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>
                        <span>{t('common:athlete.accreditation')}</span>
                      </div>
                      {!isNullOrEmpty(accCard) && isNullOrEmpty(item?.acc_id) && count == 0 ? (
                        <button
                          className='btn btn-bg-light btn-active-color-primary btn-sm'
                          style={{backgroundColor: '#00c100', color: 'white', width: '100%'}}
                          disabled={loadingSetAccrID}
                          onClick={() => setAcc(accCard)}
                        >
                          {t('common:athlete.setAccID')}
                        </button>
                      ) : null}
                    </div>

                    <div className='register-info flex items-center justify-center border border-gray-300 border-dashed rounded min-w-60px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center age'>
                        {/* <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' />
                        <div className='fs-2 fw-bolder'>60%</div> */}
                        <div>
                          <span>{t('common:athlete.age')}: </span>
                          <span style={{color: 'black'}}>{age}</span>
                        </div>
                        <span style={{color: 'black'}}>
                          {t(`common:${item?.gender}`).toUpperCase()}
                        </span>
                      </div>
                      {/* <span>{t('common:athlete.gender')}: </span> */}
                    </div>
                    {item?.accr_category == 'Aa' ? (
                      <div className='register-info flex items-center justify-center border border-gray-300 border-dashed rounded min-w-60px py-3 px-4 me-6 mb-3'>
                        <div className='d-flex align-items-center age'>
                          {/* <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' />
                        <div className='fs-2 fw-bolder'>60%</div> */}
                          <div className='flex gap-1'>
                            <span>{t('common:athlete.weight')}:</span>
                            <span style={{color: 'black'}}>{item?.weight}kg</span>
                          </div>
                          <div className='flex gap-1'>
                            <span>{t('common:athlete.height')}:</span>
                            <span style={{color: 'black'}}>{item?.height}cm</span>
                          </div>
                        </div>
                        {/* <span>{t('common:athlete.gender')}: </span> */}
                      </div>
                    ) : null}
                    <div className='register-info flex items-center justify-center border border-gray-300 border-dashed rounded min-w-60px py-3 px-4 me-6 mb-3'>
                      <QRCode
                        size={60}
                        style={{height: 'auto', maxWidth: '100%', width: '100%'}}
                        value={'https://ulaanbaatar2023.org/athlete/' + item?.id}
                        viewBox={`0 0 256 256`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='d-flex h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              {item?.accr_category != 'Aa' ? null : (
                <li className='nav-item'>
                  <a
                    className={`nav-link cursor-pointer text-active-primary me-6 ${
                      tab == 'tabsport' ? 'active' : ''
                    }`}
                    onClick={() => setTab('tabsport')}
                  >
                    {t('common:athlete.more.tabsport.title')}
                  </a>
                </li>
              )}
              <li className='nav-item'>
                <a
                  className={`nav-link cursor-pointer text-active-primary me-6 ${
                    tab == 'tabcontact' ? 'active' : ''
                  }`}
                  onClick={() => setTab('tabcontact')}
                >
                  {t('common:athlete.more.tabcontact.title')}
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className={`nav-link cursor-pointer text-active-primary me-6 ${
                    tab == 'tabvisa' ? 'active' : ''
                  }`}
                  onClick={() => setTab('tabvisa')}
                >
                  {t('common:athlete.more.tabvisa.title')}
                </a>
              </li>
              {item?.accr_category == 'E' ? (
                <li className='nav-item'>
                  <a
                    className={`nav-link cursor-pointer text-active-primary me-6 ${
                      tab == 'tabmedia' ? 'active' : ''
                    }`}
                    onClick={() => setTab('tabmedia')}
                  >
                    {t('common:athlete.more.tabmedia.title')}
                  </a>
                </li>
              ) : null}
              {/* {item?.accr_category == 'D' ? (
                <li className='nav-item'>
                  <a
                    className={`nav-link cursor-pointer text-active-primary me-6 ${
                      tab == 'tabtechnical' ? 'active' : ''
                    }`}
                    onClick={() => setTab('tabtechnical')}
                  >
                    {t('common:athlete.more.tabtechnical.title')}
                  </a>
                </li>
              ) : null} */}
              {EmployeeTypeID == 'super' ? (
                <li className='nav-item'>
                  <a
                    className={`nav-link cursor-pointer text-active-primary me-6 ${
                      tab == 'tabacccard' ? 'active' : ''
                    }`}
                    onClick={() => setTab('tabacccard')}
                  >
                    {t('common:athlete.more.tabacccard.title')}
                  </a>
                </li>
              ) : null}
              {/* <li className='nav-item'>
                <a
                  className={`nav-link cursor-pointer text-active-primary me-6 ${
                    tab == 'tabvip' ? 'active' : ''
                  }`}
                  onClick={() => setTab('tabvip')}
                >
                  {t('common:athlete.more.tabvip.title')}
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
          {tab == 'tabsport' && item?.accr_category == 'Aa' ? (
            <TabSport t={t} item={item} onSportSuccess={onSportSuccess} />
          ) : tab == 'tabcontact' ? (
            <TabContact t={t} item={item} />
          ) : tab == 'tabvisa' ? (
            <TabVisa t={t} item={item} />
          ) : tab == 'tabmedia' ? (
            <TabMedia t={t} item={item} />
          ) : tab == 'tabtechnical' ? (
            <TabTechnical t={t} item={item} />
          ) : tab == 'tabvip' ? (
            <TabVip t={t} item={item} />
          ) : tab == 'tabacccard' ? (
            <TabAccCard t={t} item={item} sports={sports} />
          ) : null}
        </div>
      </div>
    </>
  )
}

export {AthleteMore}
