import React, {FC, useEffect, useRef, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import DataTable from '../../modules/CustomBootsrapTable/CustomBootsrapTable'
import {dateAgo, isNullOrEmpty} from '../../../services/Utils'
import CustomModal from '../../modules/CustomModal/modal'
import html2canvas from 'html2canvas'
import {
  AthleteSportListReq,
  AthleteStatusChangeReq,
  AthleteVisaFlightListReq,
  BaseRequest,
  VolunteerListReq,
  magicnum,
} from '../../../services/Request'
import {
  AthleteSportList,
  AthleteStatusChange,
  AthleteVisaFlightGet,
  AthleteVisaFlightList,
  CDN_URL,
  VolunteerDelete,
  VolunteerList,
} from '../../../services/main'
import {toast} from 'react-toastify'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import useCookie from '../../../services/useCookie'
import {confirmAlert} from 'react-confirm-alert'
import {Image} from 'react-bootstrap'
import {url} from 'inspector'
import {color} from 'html2canvas/dist/types/css/types/color'
import {TabAccCard} from '../athlete/tab/tabacccard'
const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]

function usePrevious(value: any) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}

const Flight: FC = () => {
  const queryString = window.location.search
  const params = new URLSearchParams(queryString)
  let nocQ = params.get('noc')
  let categoryQ = params.get('sport')
  let pidQ = params.get('pid')
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<any>([])
  const [sport, setSport] = useState<any>([])
  const sport1 = sport[0]
  const [fligthData, setFlightData] = useState<any>([])
  const [item, setItem] = useState<any>(undefined)
  const [PID, setPID] = useState<any | null>(1)
  const [PTotal, setPTotal] = useState(1)
  const [sizePerPage, setSizePerPage] = useState(20)
  const [showModal, updateShowModal] = useState(false)
  const [showModalPrint, updateShowModalPrint] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [gender, setGender] = useState<any | null>('')
  const [accr_category, setAccr_category] = useState<any | null>('')
  const [accr_sub_category, setAccr_sub_category] = useState<any | null>('')
  const [category, setCategory] = useState<any | null>('')
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [arr_month, setArr_month] = useState('8')
  const [arr_day, setArr_day] = useState('')
  const [dep_month, setDep_month] = useState('')
  const [dep_day, setDep_day] = useState('')
  const [food, setFood] = useState('')
  const [acc_id, setAcc_id] = useState('')
  const [NocID] = useCookie('NocID', null)
  const [EmployeeTypeID] = useCookie('EmployeeTypeID', null)
  // const prevNoc = usePrevious(noc)
  const prevPID = usePrevious(PID)
  const prevAccrCategory = usePrevious(accr_category)
  const [imageBase64, setImageBase64] = React.useState<any>('')
  const [loadingImageData, setLaodingImageData] = useState(false)
  const [validate, setValidate] = useState<any>([])
  const [count, setCount] = useState(0)
  const [monthArr, setMonthArr] = useState<any>([])
  const [noc, setNoc] = useState<any | null>('')
  const [photo, setPhoto] = useState<any | null>('')
  const dago = dateAgo('2023-' + item?.arr_month + '-' + item?.arr_day + ' 20:30')
  const ddago = dateAgo('2023-' + fligthData?.dep_month + '-' + fligthData?.dep_day + ' 20:30')
  const ite = magicnum.accreditationCategoryNew.filter(
    (citem) => citem?.id == item?.accr_category
  )[0]
  useEffect(() => {
    if (!isNullOrEmpty(categoryQ)) {
      setCategory(categoryQ)
    }

    if (!isNullOrEmpty(pidQ)) {
      setPID(pidQ)
    }
    var monthArr = []
    for (var i = 1; i < 32; i++) {
      monthArr.push(i)
    }
    setMonthArr(monthArr)
  }, [categoryQ, nocQ, pidQ])

  useEffect(() => {
    list()
  }, [PID])

  useEffect(() => {
    if (!isNullOrEmpty(item)) {
      flightList()
    }
  }, [item?.aid])

  const onChangePage = (pid: any) => {
    if (pid >= 1) {
      setPID(pid)
      setLoading(true)
    }
  }

  const defaultRowFormat = (cell: any, row: any) => {
    return (
      <div className='flex justify-center items-center'>
        <span className='text-center'>{cell}</span>
      </div>
    )
  }

  const imgRowFormat = (cell: string, row: any) => {
    return (
      <Link to={`/athlete/${row.aid}`}>
        <div className='text-center'>
          <img
            src={CDN_URL + cell}
            height={60}
            width={60}
            style={{borderRadius: '50%', objectFit: 'cover'}}
          />
        </div>
      </Link>
    )
  }

  const nameRowFormat = (cell: string, row: any) => {
    return (
      <Link to={`/athlete/${row.aid}`}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <span>{row.lastname}</span>
          <span className='text-muted fw-semibold text-muted d-block'>{cell}</span>
        </div>
      </Link>
    )
  }

  const nocRowFormat = (cell: string, row: any) => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          rowGap: 5,
        }}
      >
        <Image src={toAbsoluteUrl(`/noc-flag/${cell}.svg`)} height={16} />
        <span>{t(`common:NOC.${cell}`)}</span>
      </div>
    )
  }

  const list = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = AthleteVisaFlightListReq
    req.pid = PID
    req.pSize = sizePerPage
    req.filter.firstname = firstname
    req.filter.lastname = lastname
    req.filter.noc = noc
    req.filter.accr_category = accr_category
    req.filter.accr_sub_category = accr_sub_category
    req.filter.arr_month = arr_month
    req.filter.arr_day = arr_day
    req.filter.dep_month = dep_month
    req.filter.dep_day = dep_day
    AthleteVisaFlightList(req, onSuccess, onFailed, 'POST')
  }

  const flightList = () => {
    setLoading(true)
    AthleteVisaFlightGet(item?.aid, BaseRequest, onListSuccess, onFailed, 'GET')
  }

  const onListSuccess = (response: any) => {
    setLoading(false)
    setFlightData(response?.data)
  }

  const onSuccess = (response: any) => {
    setLoading(false)
    setData(response.data)
    setPTotal(response.pagination.ptotal)
  }

  const onFailed = (error: string) => {
    setLoading(false)
    return toast.error(error)
  }

  const actionRowFormat = (cell: string, row: any) => {
    return (
      <div className='flex gap-2 flex-justify items-center'>
        {EmployeeTypeID == 'super' ? (
          <div
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            onClick={() => {
              updateShowModalPrint(true)
              setItem(row)
            }}
          >
            <KTIcon iconName='bi bi-printer-fill' className='fs-3' />
          </div>
        ) : null}
        <div
          className='text-end flex gap-1 justify-center items-center'
          onClick={() => {
            updateShowModal(true)
            setItem(row)
          }}
        >
          <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
            <KTIcon iconName='arrow-right' className='fs-3' />
          </div>
        </div>
      </div>
    )
  }

  const accr_categoryRowFormat = (cell: string, row: any) => {
    var ite = magicnum.accreditationCategoryNew.filter((item) => item?.id == cell)[0]
    var sport = [{name: ''}]
    if (!isNullOrEmpty(row.sport)) {
      sport = magicnum.SportCategories.filter((item) => (item.id = row.sport))
    }

    return (
      <div
        style={{
          backgroundColor: ite?.color,
          color: 'white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '5px',
          flexDirection: 'column',
        }}
      >
        <span className='fw-semibold d-block' style={{fontSize: '20px', color: 'white'}}>
          {ite?.id}
        </span>
        <span>{t(`common:magicnum.accreditationSubCategoryNew.${row.accr_sub_category}`)}</span>
        {isNullOrEmpty(row.sport) ? null : <span>{sport[0]?.name}</span>}
      </div>
    )
  }
  const dateRowFormat = (cell: string, row: any) => {
    var dago = dateAgo('2023-' + row?.arr_month + '-' + row?.arr_day + ' 20:30')
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          rowGap: 5,
        }}
      >
        <span
          className='fw-semibold d-block'
          style={{
            ...(dago < 4 && dago > 0
              ? {color: 'red', fontSize: 18}
              : dago < 1
              ? {color: 'green', fontSize: 18}
              : {color: 'black', fontSize: 18}),
          }}
        >
          {dago > 0 ? dago + ' days left' : 'Welcome'}
        </span>
        2023-{row?.arr_month}-{row?.arr_day} {row?.arr_time}
      </div>
    )
  }

  const accRowFormat = (cell: string, row: any) => {
    return (
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        {!isNullOrEmpty(cell) ? (
          <div className='flex flex-col gap-2 justify-center items-center'>
            <span>{cell}</span> <span>{row?.arr_flight_no}</span>
          </div>
        ) : (
          <Link to={`/athlete/${row.id}`}>GET</Link>
        )}
      </div>
    )
  }

  const dateDepRowFormat = (cell: string, row: any) => {
    var dago = dateAgo('2023-' + row?.dep_month + '-' + row?.dep_day + ' 20:30')
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          rowGap: 5,
        }}
      >
        <span
          className='fw-semibold d-block'
          style={{...(dago < 4 ? {color: 'red', fontSize: 18} : {color: 'black', fontSize: 18})}}
        >
          {dago > 1 ? dago + ' days left' : 'see again'}
        </span>
        2023-{row?.dep_month}-{row?.dep_day} {row?.dep_time}
      </div>
    )
  }

  var table = {
    sizePerPage: sizePerPage,
    headerKeyColumn: {
      title: 'id',
      dataField: 'id',
      dataSort: true,
      dataFormat: defaultRowFormat,
      hidden: true,
      filter: {
        type: 'TextFilter',
        placeholder: '...',
      },
    },
    headerOtherColumn: [
      {
        title: t('common:NEWS.image_link'),
        dataField: 'photo',
        dataSort: false,
        dataFormat: imgRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:athlete.name'),
        dataField: 'firstname',
        dataSort: false,
        dataFormat: nameRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:athlete.accr_category'),
        dataField: 'accr_category',
        dataSort: false,
        dataFormat: accr_categoryRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:athlete.acc_id'),
        dataField: 'acc_id',
        dataSort: false,
        dataFormat: accRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:athlete.noc'),
        dataField: 'noc',
        dataSort: false,
        dataFormat: nocRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:athlete.arrival'),
        dataField: 'id',
        dataSort: false,
        dataFormat: dateRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:athlete.departure'),
        dataField: 'id',
        dataSort: false,
        dataFormat: dateDepRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:athlete.action'),
        dataField: 'id',
        dataSort: false,
        dataFormat: actionRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
    ],
  }

  const domEl1 = useRef(null)

  const htmlToImageConvert = async (type: any) => {
    setLoading(true)
    let element: HTMLElement | null = null
    if (type == 'image') {
      element = domEl1.current
    }

    if (!element) {
      console.error('Invalid DOM element.')
      setLoading(false)
      return
    }

    try {
      const canvas = await html2canvas(element)

      const data = canvas.toDataURL('image/jpg')
      const link = document.createElement('a')

      if (typeof link.download === 'string') {
        link.href = data
        link.download = item?.firstname + '.jpg'

        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        setLoading(false)
      } else {
        window.open(data)
        console.log('no:')
        setLoading(false)
      }
    } catch (error) {
      console.error('An error occurred during conversion:', error)
      setLoading(false)
    }
  }

  const convertImageToBase64 = async (imgUrl: any) => {
    const base64 = await fetch(imgUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        return new Promise((res) => {
          reader.onloadend = () => {
            res(reader.result)
          }
        })
      })
    setPhoto(base64)
  }

  useEffect(() => {
    if (!isNullOrEmpty(item)) {
      convertImageToBase64(CDN_URL + item?.photo)
    }
  }, [item])

  useEffect(() => {
    if (!isNullOrEmpty(item) && item?.accr_category == 'Aa') {
      sportList()
    }
  }, [item?.aid])

  const sportList = () => {
    setLoading(true)
    var req = AthleteSportListReq
    req.filter.noc = item?.noc
    req.filter.athlete_id = item?.id
    AthleteSportList(req, onSportListSuccess, onFailed, 'POST')
  }

  const onSportListSuccess = (response: any) => {
    setLoading(false)
    setSport(response.data)
  }

  return (
    <>
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>{t('athlete.more.tabvisa.title')}</PageTitle>

      {showModal ? (
        <CustomModal
          visible={showModal}
          closeModal={() => updateShowModal(false)}
          className='VideoModal'
        >
          <div className='ticket' ref={domEl1} style={{width: 800, padding: '60px 20px'}}>
            <div
              className='ticket-container flex flex-col justify-center items-center gap-6'
              style={{width: '100%', height: '100%'}}
            >
              <div className='image flex gap-10'>
                <div className='flex flex-col gap-4'>
                  <img
                    src={photo}
                    width={250}
                    style={{borderRadius: '8px', objectFit: 'cover', border: '1px solid #d1cfcf'}}
                  />
                  <div
                    className='name flex gap-4 justify-center items-center'
                    style={{fontSize: 22, fontWeight: '500'}}
                  >
                    <span>{item?.firstname}</span>
                    <span>{item?.lastname}</span>
                  </div>
                </div>
                <div className='flex flex-col justify-center items-center gap-10'>
                  <div
                    className='noc flex flex-col gap-4 justify-center items-center'
                    style={{gap: 16, fontSize: 24, fontWeight: '500'}}
                  >
                    <Image
                      src={toAbsoluteUrl(`/noc-flag/${item?.noc}.svg`)}
                      width={100}
                      style={{borderRadius: 8, border: '1px solid #d1cfcf'}}
                    />
                    <span>{t(`common:NOC.${item?.noc}`)}</span>
                  </div>
                  <div className='other'>
                    {magicnum.accreditationSubCategoryNew.map((aitem, aindex) => {
                      return item?.accr_sub_category == aitem?.id ? (
                        <div
                          className='flex flex-col justify-center items-center gap-4'
                          style={{width: '100%'}}
                          key={aindex}
                        >
                          <div
                            className='flex flex-col justify-center items-center'
                            style={{
                              background: `${ite?.color}`,
                              border: 'none',
                              width: '100%',
                              borderRadius: 5,
                              padding: 10,
                            }}
                          >
                            <span style={{border: 'none', width: 'auto', height: 'auto'}}>
                              {item?.accr_category}
                            </span>
                            <span
                              style={{
                                border: 'none',
                                width: 'auto',
                                height: 'auto',
                                fontSize: 12,
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {t(
                                `common:magicnum.accreditationSubCategoryNew.${item?.accr_sub_category}`
                              )}
                            </span>
                          </div>
                          {isNullOrEmpty(fligthData?.baggage) ? null : (
                            <div
                              className='flex flex-col justify-center items-center'
                              style={{
                                border: '1px solid black',
                                width: '100%',
                                borderRadius: 5,
                                padding: 10,
                              }}
                            >
                              <span style={{border: 'none', width: 'auto', height: 'auto'}}>
                                {fligthData?.baggage}
                              </span>
                            </div>
                          )}
                          <div className='flex gap-4'>
                            {item?.accr_category == 'Aa' ? (
                              <>
                                <span>{aitem?.transportation}</span>
                                {magicnum.hotel.map((hitem, hindex) => {
                                  return hitem?.sid.map((hhitem, hhindex) => {
                                    return hhitem == sport1?.category ? (
                                      <span key={hhindex}>{hitem?.code}</span>
                                    ) : null
                                  })
                                })}
                              </>
                            ) : (
                              <div className='flex gap-4'>
                                <span>{aitem?.hotel}</span>
                                <span>{aitem?.transportation}</span>
                              </div>
                            )}
                            {aitem?.dining == true ? (
                              <span>
                                <div
                                  className=''
                                  style={{
                                    background: `url('/icons/png/dinner.png')`,
                                    width: '14px',
                                    height: '42px',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                  }}
                                />
                              </span>
                            ) : null}
                          </div>
                        </div>
                      ) : null
                    })}
                  </div>
                </div>
              </div>

              <div
                className='flex flex-col justify-center items-center gap-2'
                style={{
                  width: '100%',
                  fontSize: 22,
                  border: '1px solid #dddddd',
                  padding: '15px 0px',
                  borderRadius: 8,
                  fontWeight: '600',
                }}
              >
                <div className='flex gap-4'>
                  <span>Arrival Place:</span>
                  <span style={{fontWeight: '400'}}>{fligthData?.arr_place}</span>
                </div>
                <div className='flex gap-8'>
                  <div className='arrival flex gap-4 justify-center items-center'>
                    <span style={{fontSize: 18, fontWeight: '600'}}>Arrival:</span>
                    <span style={{fontSize: 16, fontWeight: '400'}}>
                      2023-{item?.arr_month}-{item?.arr_day} {item?.arr_time}
                    </span>
                    <span style={{fontSize: 16, fontWeight: '400'}}>
                      {dago > 0 ? (
                        dago + ' days left'
                      ) : (
                        <span
                          style={{
                            ...(dago < 0
                              ? {color: 'green', fontSize: 18}
                              : {color: 'black', fontSize: 18}),
                          }}
                        >
                          Welcome
                        </span>
                      )}
                    </span>
                  </div>
                  <div className='flex gap-2 justify-center items-center'>
                    <span style={{fontSize: 18, fontWeight: '600'}}>Flight No:</span>
                    <span style={{fontSize: 16, fontWeight: '400', textAlign: 'center'}}>
                      {fligthData?.arr_flight_no}
                    </span>
                  </div>
                </div>
                <div></div>
              </div>

              <div
                className='flex flex-col justify-center items-center gap-2'
                style={{
                  width: '100%',
                  fontSize: 22,
                  border: '1px solid #dddddd',
                  padding: '15px 0px',
                  borderRadius: 8,
                  fontWeight: '600',
                }}
              >
                <div className='flex gap-4'>
                  <span>Departure Place:</span>
                  <span style={{fontWeight: '400'}}>{fligthData?.dep_place}</span>
                </div>
                <div
                  className='arrival flex gap-4 justify-center items-center'
                  style={{fontSize: 18}}
                >
                  <span style={{fontWeight: '600'}}>Departure:</span>
                  <span style={{fontSize: 16, fontWeight: '400'}}>
                    {ddago > 0
                      ? `2023-${fligthData?.dep_month}-${fligthData?.dep_day} ${fligthData?.dep_time}`
                      : null}
                  </span>
                  <span style={{fontSize: 16, fontWeight: '400'}}>
                    {ddago > 0 ? ddago + ' days left' : 'welcome'}
                  </span>
                </div>
                <div></div>
              </div>

              <div
                className='flex flex-col justify-center items-center gap-2'
                style={{
                  width: '100%',
                  fontSize: 22,
                  border: '1px solid #dddddd',
                  padding: '15px 0px',
                  borderRadius: 8,
                  fontWeight: '600',
                }}
              >
                <div className='flex gap-2 justify-center items-center'>
                  <span>Passport Type:</span>
                  <span style={{fontWeight: '400'}}>
                    {t(`common:athlete.more.tabvisa.pass_type.${fligthData?.pass_type}`)}
                  </span>
                </div>
                <div className='flex gap-2 justify-center items-center'>
                  <span>Passport Expired Date:</span>
                  <span style={{fontWeight: '400'}}>{fligthData?.pass_expired_date}</span>
                </div>
                {/* <div className='flex gap-2 justify-center items-center'>
                  <span>Passport Serial Num ID:</span>
                  <span style={{fontWeight: '400'}}>{fligthData?.pass_serial_num_id}</span>
                </div> */}
              </div>

              {/* view pdf */}
              {/* <div>
                <Document file='https://example.com/path/to/your/pdf-file.pdf'>
                  <Page pageNumber={1} />
                </Document>
              </div> */}
            </div>
          </div>

          {/* Card Download Code Start */}
          <div className='flex justify-center items-center gap-4' style={{paddingBottom: 40}}>
            <div className='card-1-download flex justify-center items-center'>
              <a
                className='btn btn-sm btn-light-primary'
                href={`${CDN_URL}${fligthData?.pass_file}`}
                target='_blank'
                style={{padding: '15px 30px'}}
              >
                <KTIcon iconName='bi bi-file-earmark-pdf-fill' className='fs-1' />
                <span>Passport PDF View</span>
              </a>
            </div>
            <div className='card-1-download flex justify-center items-center'>
              <button
                className='btn btn-sm btn-light-primary'
                style={{padding: '15px 30px'}}
                onClick={() => htmlToImageConvert('image')}
              >
                <KTIcon iconName='bi bi-cloud-arrow-down-fill' className='fs-1' />
                {/* <i class="bi bi-cloud-arrow-down-fill"></i> */}
                <span>DOWNLOAD</span>
              </button>
            </div>
          </div>
          {/* Card Download Code End */}
        </CustomModal>
      ) : null}
      {showModalPrint ? (
        <CustomModal
          visible={showModalPrint}
          closeModal={() => updateShowModalPrint(false)}
          className='accr-modal'
        >
          <TabAccCard t={t} item={item} />
        </CustomModal>
      ) : null}
      <div className={`card mb-5`}>
        <div className='card-header border-1' style={{height: '60px'}}>
          <div
            className='filter flex items-center justify-center gap-0 cursor-pointer'
            onClick={() => setShowFilter(!showFilter)}
          >
            <KTIcon iconName='filter' className='fs-3' />
            <span className='text'>{t('common:filter')}</span>
          </div>
          <div className='card-toolbar'>
            <a href='#' className='btn btn-sm btn-light-primary' onClick={() => list()}>
              {t('common:search')}
            </a>
          </div>
        </div>
        {showFilter ? (
          <div className='card-body'>
            <div className='row'>
              <div className='col'>
                <select
                  name='arr_month'
                  data-control='select2'
                  data-hide-search='true'
                  value={arr_month}
                  className='form-select form-select-white'
                  onChange={(e) => {
                    setArr_month(e.target.value)
                  }}
                >
                  <option value=''>{t('common:athlete.selectArr_month')}</option>
                  <option value='8'>8</option>
                  <option value='9'>9</option>
                </select>
              </div>
              <div className='col'>
                <select
                  name='arr_day'
                  data-control='select2'
                  data-hide-search='true'
                  value={arr_day}
                  className='form-select form-select-white'
                  onChange={(e) => {
                    setArr_day(e.target.value)
                  }}
                >
                  <option value=''>{t('common:athlete.selectArr_day')}</option>
                  {monthArr.map((itg: any, indexg: any) => {
                    return (
                      <option value={itg} key={indexg}>
                        {itg}
                      </option>
                    )
                  })}
                </select>
              </div>
              <div className='col'>
                <select
                  name='dep_month'
                  data-control='select2'
                  data-hide-search='true'
                  value={dep_month}
                  className='form-select form-select-white'
                  onChange={(e) => {
                    setDep_month(e.target.value)
                  }}
                >
                  <option value=''>{t('common:athlete.selectDep_month')}</option>
                  <option value='8'>8</option>
                  <option value='9'>9</option>
                </select>
              </div>
              <div className='col'>
                <select
                  name='dep_day'
                  data-control='select2'
                  data-hide-search='true'
                  value={dep_day}
                  className='form-select form-select-white'
                  onChange={(e) => {
                    setDep_day(e.target.value)
                  }}
                >
                  <option value=''>{t('common:athlete.selectDep_day')}</option>
                  {monthArr.map((itg: any, indexg: any) => {
                    return (
                      <option value={itg} key={indexg}>
                        {itg}
                      </option>
                    )
                  })}
                </select>
              </div>
              <div className='col'></div>
            </div>
            <div className='row' style={{marginTop: 10}}>
              <div className='col'>
                <select
                  name='noc'
                  data-control='select2'
                  data-hide-search='true'
                  value={noc}
                  className='form-select form-select-white'
                  onChange={(e) => {
                    setNoc(e.target.value)
                  }}
                >
                  <option value=''>{t('common:NOC.selectnoc')}</option>
                  {magicnum.NOC.map((item2, index) => {
                    return (EmployeeTypeID == 'noc' && item2?.id == NocID) ||
                      EmployeeTypeID == 'super' ? (
                      <option value={item2.id} key={index}>
                        {t(`common:NOC.${item2.id}`)}
                      </option>
                    ) : null
                  })}
                </select>
              </div>
              <div className='col'>
                <select
                  name='accr_category'
                  data-control='select2'
                  data-hide-search='true'
                  value={accr_category}
                  className='form-select form-select-white'
                  onChange={(e) => {
                    setAccr_sub_category('')
                    setAccr_category(e.target.value)
                  }}
                >
                  <option value=''>{t('common:athlete.selectAccreditationCategory')}</option>
                  {magicnum.accreditationCategory.map((item2, index) => {
                    return EmployeeTypeID == 'noc' &&
                      (item2?.parent == 'S' || item2?.parent == 'OC') ? null : (
                      <option value={item2.id} key={index}>
                        {item2.id} - {t(`common:magicnum.accrDescription.${item2?.id}`)}
                      </option>
                    )
                  })}
                </select>
              </div>
              <div className='col'>
                <select
                  name='accr_sub_category'
                  data-control='select2'
                  data-hide-search='true'
                  value={accr_sub_category}
                  className='form-select form-select-white'
                  onChange={(e) => {
                    setAccr_sub_category(e.target.value)
                  }}
                >
                  <option value=''>{t('common:athlete.selectAccreditationSubCategory')}</option>
                  {magicnum.accreditationSubCategoryNew.map((item2, index) => {
                    return accr_category == item2.parent ? (
                      <option value={item2.id} key={index}>
                        {t(`common:magicnum.accreditationSubCategoryNew.${item2.id}`)}
                      </option>
                    ) : null
                  })}
                </select>
              </div>
              <div className='col'>
                <input
                  className='form-control form-control-solid'
                  placeholder='Firstname'
                  onChange={(e) => {
                    setFirstname(e.target.value)
                  }}
                  value={firstname}
                  maxLength={45}
                ></input>
              </div>
              <div className='col'>
                <input
                  className='form-control form-control-solid'
                  placeholder='Lastname'
                  onChange={(e) => {
                    setLastname(e.target.value)
                  }}
                  value={lastname}
                  maxLength={45}
                ></input>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <div className={`card`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>
              {t('common:athlete.more.tabvisa.title')}
            </span>
            <span className='text-muted mt-1 fw-semibold fs-7'>
              {t('common:athlete.more.tabvisa.total', {count: PTotal})}
            </span>
          </h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <DataTable
            data={data}
            table={table}
            PTotal={PTotal}
            pageChange={onChangePage}
            PID={PID}
            loading={loading}
          />
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export {Flight}
