import config from './config.json'
import CryptoJS from 'crypto-js'
var varBASE_URL = ''
var varCDN_URL = ''
var varAPP_URL = ''
var varMAIN_URL = ''
var debugMode = config.debug
var cookieMode = config.cookie

if (debugMode === 'test') {
  varBASE_URL = config.BASE_URL
  varCDN_URL = config.CDN_URL
  varAPP_URL = config.APP_URL
  varMAIN_URL = config.MAIN_URL
} else {
  varBASE_URL = config.RealBASE_URL
  varCDN_URL = config.RealCDN_URL
  varAPP_URL = config.RealAPP_URL
  varMAIN_URL = config.RealMAIN_URL
}
export const MAIN_URL = varMAIN_URL
export const APP_URL = varAPP_URL
export const BASE_URL = varBASE_URL
export const CDN_URL = varCDN_URL

export function setPageTitle(text) {
  document.title = text + ' | ' + ' ulaanbaatar2023.org'
}

function getCookie(name) {
  let cookie = {}
  document.cookie.split(';').forEach(function (el) {
    let [k, v] = el.split('=')
    cookie[k.trim()] = v
  })
  return cookie[name]
}

function isPriv(url, method, failCallback) {
  var isprivNo = true //false bn shuu
  // var realprivNoArray = url.split("/") //api/Library/LibraryList
  // var realprivNo = "" //Employee-Post
  // const Data1 = getCookie("Data1");
  // if (realprivNoArray[0] === "api") {
  //   for (var i = 0; i <= realprivNoArray.length - 1; i++) {
  //     if (realprivNoArray[i] !== "api" && realprivNoArray[i] !== "" && i <= 2) {
  //       realprivNo += realprivNoArray[i]
  //       realprivNo += "-"
  //     }
  //   }
  //   realprivNo = realprivNo + method
  //   var userPriv = !isNullOrEmpty(getRealData(Data1)) ? JSON.parse(getRealData(Data1)) : [] //localstroge iin data1 ni tuhain hereglegchiin privNo nuud yum
  //   userPriv.map((i, index) => {
  //     if (i.PrivNo === realprivNo) {
  //       isprivNo = true
  //     }
  //   })
  // } else if (realprivNoArray[0] === "publicapi") {
  //   isprivNo = true
  // }

  return isprivNo
} //ene code client der erh shalgaj baina ta harj bnu?ok

function deleteAllCookies() {
  const cookies = document.cookie.split(';')

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i]
    const eqPos = cookie.indexOf('=')
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
    console.log(name)
    if (cookieMode === 'test') {
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=localhost; path=/`
    } else {
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=.ulaanbaatar2023.org; path=/`
    }
    localStorage.clear()
    // document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}

function baseFetch(url, request, successCallback, failCallback, rMethod, rHeader) {
  const EmployeeID = getCookie('EmployeeID')
  const SystemToken = getCookie('SystemToken')
  //if (isPriv(url, rMethod, failCallback)) {
  var statusCode = ''
  var baseHeader = {
    'x-auth-id': EmployeeID,
    'x-auth-token': SystemToken,
    'x-api-key': CryptoJS.AES.encrypt(config.keyAPI, config.engryptPass).toString(),
    'Content-Type': 'application/json',
    lang: 'mn',
  }
  var baseHeaderFileUpload = {
    'x-auth-id': EmployeeID,
    'x-auth-token': SystemToken,
    'x-api-key': CryptoJS.AES.encrypt(config.keyAPI, config.engryptPass).toString(),
    lang: 'mn',
  }
  fetch(BASE_URL + url, {
    crossDomain: false,
    // mode: "cors",
    method: rMethod,
    headers: rHeader == 'file' ? baseHeaderFileUpload : baseHeader,
    body: rHeader == 'file' ? request : rMethod !== 'GET' ? JSON.stringify(request) : null,
  })
    .then((res) => {
      statusCode = res.status
      return res.json()
    })
    .then(function (data) {
      if (statusCode === 401) {
        deleteAllCookies()
        window.location.replace('/auth')
      } else if (statusCode === 200) {
        TokenHandler(url, data, successCallback)
      } else {
        errorJob(url, data.statusText, failCallback)
      }
    })
    .catch(function (error) {
      console.log('basefetch error:', error)
      errorJob(url, 'Алдаа гарсан тул, шинээр нэвтэрнэ үү', failCallback)
      window.location.replace('#/err')
    })
  // } else {
  //   errorJob(url, "Хандах эрх байхгүй байна.", failCallback)
  // }
} //baseFetch gedeg api duuddag yum bgaa js ter ni neg api duudhad iim coed bichigddeg teriig ni bagasgatsan bgaa  TypeZurchilList(req, this.onSuccess, this.onFailed, "POST");

function TokenHandler(url, response, successCallback) {
  var bytes = CryptoJS.AES.decrypt(response, config.engryptPass)
  var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
  successCallback(decryptedData)
}

function errorJob(url, error, failCallback) {
  failCallback(error)
  //return toast.error(error);
}

//#region [ AuthCreate & Verify ]

// Нэвтрэх үед token авах
export function loginGetToken(request, successCallback, failCallback, method) {
  baseFetch('publicapi/auth/signin', request, successCallback, failCallback, method)
  return ''
}

export function signOut(request, successCallback, failCallback, method) {
  baseFetch('publicapi/auth/signout', request, successCallback, failCallback, method)
  return ''
}
//#endregion

//#region [ Ажилчдын жагсаалт ]
export function listEmployee(request, successCallback, failCallback, method) {
  baseFetch('api/employee/getallemployee', request, successCallback, failCallback, method)
  return ''
}

export function EmployeeChangeInfo(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/employee/update', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function EmployeeChangeStatus(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/employee/changeStatus', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function EmployeeChangePhoto(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/employee/changephoto', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function EmployeeAdd(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/employee/addemployee', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function ChangePass(request, successCallback, failCallback, method) {
  baseFetch('api/employee/changepassword', request, successCallback, failCallback, method)
  return ''
}

export function GetEmployee(id, request, successCallback, failCallback, method) {
  baseFetch('api/employee/getemployee/' + id, request, successCallback, failCallback, method)
  return ''
}

export function EmployeeDelete(employeeID, request, successCallback, failCallback, method) {
  baseFetch(
    'api/employee/deleteemployee/' + employeeID,
    request,
    successCallback,
    failCallback,
    method
  )
  return ''
}

//#endregion

//#region [Privilege]
// EmployeeList =>request(GetEmployeeByIdReq)
export function UserPrivList(id, request, successCallback, failCallback, method) {
  baseFetch('api/priv/userprivlist/' + id, request, successCallback, failCallback, method)
  return ''
}

//PrivGroupList List =>request(BaseRequest)
export function PrivilegeGroupList(request, successCallback, failCallback, method) {
  baseFetch('api/privgroup/listmpriv_group', request, successCallback, failCallback, method)
  return ''
}

//PrivGroupAdd Add =>request(PrivilegeGroupAddReq)
export function PrivilegeGroupAdd(request, successCallback, failCallback, method) {
  baseFetch('api/privgroup/addmpriv_group', request, successCallback, failCallback, method)
  return ''
}

//PrivGroupDelete Delete =>request(PrivilegeGroupDeleteReq)
export function PrivilegeGroupDelete(id, request, successCallback, failCallback, method) {
  baseFetch('api/privgroup/deletempriv_group/' + id, request, successCallback, failCallback, method)
  return ''
}

//PrivList Delete =>request(PrivilegeGroupAddReq)
export function PrivilegeGroupGet(id, request, successCallback, failCallback, method) {
  baseFetch('api/privgroup/getmpriv_group/' + id, request, successCallback, failCallback, method)
  return ''
}

//PrivList Delete =>request(PrivilegeGroupAddReq)
export function PrivilegeGroupUpdate(request, successCallback, failCallback, method) {
  baseFetch('api/privgroup/updatempriv_group', request, successCallback, failCallback, method)
  return ''
}

//PrivList List =>request(BaseRequest)
export function PrivilegeList(request, successCallback, failCallback, method) {
  baseFetch('api/priv/listpriv', request, successCallback, failCallback, method)
  return ''
}

//PrivList Add =>request(PrivilegeAddReq)
export function PrivilegeAdd(request, successCallback, failCallback, method) {
  baseFetch('api/priv/addmpriv', request, successCallback, failCallback, method)
  return ''
}

//PrivList Delete =>request(PrivilegeDeleteReq)
export function PrivilegeDelete(id, request, successCallback, failCallback, method) {
  baseFetch('api/priv/deletempriv/' + id, request, successCallback, failCallback, method)
  return ''
}

//PrivList Delete =>request(PrivilegeUserListReq)
export function GetUSerPrivile(id, request, successCallback, failCallback, method) {
  baseFetch('api/priv/getmpriv/' + id, request, successCallback, failCallback, method)
  return ''
}

//PrivList Delete =>request(PrivilegeUserListReq)
export function UpdateMPriv(request, successCallback, failCallback, method) {
  baseFetch('api/priv/updatempriv', request, successCallback, failCallback, method)
  return ''
}

//AddMPriv_GroupUser =>request(PrivilegeMP_GroupUserAddReq)
export function AddMPriv_GroupUser(request, successCallback, failCallback, method) {
  baseFetch('api/privgroupuser/addmpriv_groupuser', request, successCallback, failCallback, method)
  return ''
}

//UpdateMPriv_GroupUser =>request(PrivilegeMP_GroupUserAddReq)
export function UpdateMPriv_GroupUser(request, successCallback, failCallback, method) {
  baseFetch(
    'api/privgroupuser/updatempriv_groupuser',
    request,
    successCallback,
    failCallback,
    method
  )
  return ''
}

//AddMPriv_GroupPriv =>request(AddMPriv_GroupPrivReq)
export function AddMPriv_GroupPriv(request, successCallback, failCallback, method) {
  baseFetch('api/privgrouppriv/addmpriv_grouppriv', request, successCallback, failCallback, method)
  return ''
}

//GetMPriv_GroupPriv =>request(GetMPriv_GroupPrivReq)
export function GetMPriv_GroupPriv(request, successCallback, failCallback, method) {
  baseFetch('api/privgroup/addmpriv_grouppriv', request, successCallback, failCallback, method)
  return ''
}

export function GetMPriv_GetAllMPGroupPriv(request, successCallback, failCallback, method) {
  baseFetch('api/privgroup/getallmpriv_grouppriv', request, successCallback, failCallback, method)
  return ''
}

//ListMPriv_GroupPriv =>request(ListMPriv_GroupPrivReq)
export function ListMPriv_GroupPriv(id, request, successCallback, failCallback, method) {
  baseFetch(
    'api/privgrouppriv/listmpriv_grouppriv/' + id,
    request,
    successCallback,
    failCallback,
    method
  )
  return ''
}

//UpdateMPriv_GroupPriv =>request(AddMPriv_GroupPrivReq)
export function UpdateMPriv_GroupPriv(request, successCallback, failCallback, method) {
  baseFetch(
    'api/privgrouppriv/updatempriv_grouppriv',
    request,
    successCallback,
    failCallback,
    method
  )
  return ''
}

//UpdateMPriv_GroupPriv =>request(GetEmployeeByIdReq)
export function ListMPriv_GroupsForUser(userkey, request, successCallback, failCallback, method) {
  baseFetch(
    'api/privgroupuser/getmpriv_groupuser/' + userkey,
    request,
    successCallback,
    failCallback,
    method
  )
  return ''
}
//#endregion

//#region [Log]
export function LogAction(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/log/action', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function LogError(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/log/error', request, successCallback, failCallback, method, rHeader)
  return ''
}
//#endregion

//#region [User]
export function UserList(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/user/list', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function UserSungah(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/user/sungah', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function UserGet(id, request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/user/get/' + id, request, successCallback, failCallback, method, rHeader)
  return ''
}

export function UserDelete(id, request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/user/delete/' + id, request, successCallback, failCallback, method, rHeader)
  return ''
}

export function UserAdd(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/user/add', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function UserUpdate(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/user/update', request, successCallback, failCallback, method, rHeader)
  return ''
}
//#endregion

//#region [News]
export function NewsList(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/news/list', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function NewsAdd(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/news/add', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function NewsChangeImage(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/news/changeimage', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function NewsUpdate(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/news/update', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function NewsGet(id, request, successCallback, failCallback, method) {
  baseFetch('api/news/get/' + id, request, successCallback, failCallback, method)
  return ''
}

export function NewsDelete(id, request, successCallback, failCallback, method) {
  baseFetch('api/news/delete/' + id, request, successCallback, failCallback, method)
  return ''
}

export function NewsChangeStatus(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/news/changeStatus', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function NewsContentAdd(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/news_content/add', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function NewsContentUpdate(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/news_content/update', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function NewsContentGet(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/news_content/get', request, successCallback, failCallback, method, rHeader)
  return ''
}
//#endregion

//#region [Pages]
export function PagesList(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/pages/list', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function PagesAdd(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/pages/add', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function PagesUpdate(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/pages/update', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function PagesGet(id, request, successCallback, failCallback, method) {
  baseFetch('api/pages/get/' + id, request, successCallback, failCallback, method)
  return ''
}

export function PagesDelete(id, request, successCallback, failCallback, method) {
  baseFetch('api/pages/delete/' + id, request, successCallback, failCallback, method)
  return ''
}

export function PagesContentAdd(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/pages_content/add', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function PagesContentUpdate(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/pages_content/update', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function PagesContentGet(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/pages_content/get', request, successCallback, failCallback, method, rHeader)
  return ''
}
//#endregion

//#region [Videos]
export function VideosList(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/videos/list', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function VideosAdd(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/videos/add', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function VideosUpdate(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/videos/update', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function VideosGet(id, request, successCallback, failCallback, method) {
  baseFetch('api/videos/get/' + id, request, successCallback, failCallback, method)
  return ''
}

export function VideosDelete(id, request, successCallback, failCallback, method) {
  baseFetch('api/videos/delete/' + id, request, successCallback, failCallback, method)
  return ''
}

export function VideosChangeStatus(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/videos/changeStatus', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function VideoChangeImage(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/videos/changeimage', request, successCallback, failCallback, method, rHeader)
  return ''
}
//#endregion

//#region [Sports]
export function SportsAdd(request, successCallback, failCallback, method, checkPriv, rHeader) {
  baseFetch('api/sports/add', request, successCallback, failCallback, method, checkPriv, rHeader)
  return ''
}
//#endregion

//#region [Athlete]
export function AthleteChangeImage(
  request,
  successCallback,
  failCallback,
  method,
  checkPriv,
  rHeader
) {
  baseFetch(
    'api/athlete/changeimage',
    request,
    successCallback,
    failCallback,
    method,
    checkPriv,
    rHeader
  )
  return ''
}

export function AthleteAdd(request, successCallback, failCallback, method, checkPriv, rHeader) {
  baseFetch('api/athlete/add', request, successCallback, failCallback, method, checkPriv, rHeader)
  return ''
}

export function AthleteUpdate(request, successCallback, failCallback, method, checkPriv, rHeader) {
  baseFetch(
    'api/athlete/update',
    request,
    successCallback,
    failCallback,
    method,
    checkPriv,
    rHeader
  )
  return ''
}

export function AthleteList(request, successCallback, failCallback, method, checkPriv, rHeader) {
  baseFetch('api/athlete/list', request, successCallback, failCallback, method, checkPriv, rHeader)
  return ''
}

export function AthleteListExcel(
  request,
  successCallback,
  failCallback,
  method,
  checkPriv,
  rHeader
) {
  baseFetch('api/athlete/excel', request, successCallback, failCallback, method, checkPriv, rHeader)
  return ''
}

export function AthleteStatusChange(
  request,
  successCallback,
  failCallback,
  method,
  checkPriv,
  rHeader
) {
  baseFetch(
    'api/athlete/changeStatus',
    request,
    successCallback,
    failCallback,
    method,
    checkPriv,
    rHeader
  )
  return ''
}

export function AthleteSetAccID(
  request,
  successCallback,
  failCallback,
  method,
  checkPriv,
  rHeader
) {
  baseFetch(
    'api/athlete/setAccID',
    request,
    successCallback,
    failCallback,
    method,
    checkPriv,
    rHeader
  )
  return ''
}

export function AthleteChangeGmobile(
  request,
  successCallback,
  failCallback,
  method,
  checkPriv,
  rHeader
) {
  baseFetch(
    'api/athlete/changeGmobile',
    request,
    successCallback,
    failCallback,
    method,
    checkPriv,
    rHeader
  )
  return ''
}

export function AthleteChangePrintSuccess(
  request,
  successCallback,
  failCallback,
  method,
  checkPriv,
  rHeader
) {
  baseFetch(
    'api/athlete/changePrintStatus',
    request,
    successCallback,
    failCallback,
    method,
    checkPriv,
    rHeader
  )
  return ''
}

export function AthleteGet(id, request, successCallback, failCallback, method) {
  baseFetch('api/athlete/get/' + id, request, successCallback, failCallback, method)
  return ''
}

export function AthleteDelete(id, request, successCallback, failCallback, method) {
  baseFetch('api/athlete/delete/' + id, request, successCallback, failCallback, method)
  return ''
}
//#endregion

//#region [AthleteSport]
export function AthleteSportAdd(
  request,
  successCallback,
  failCallback,
  method,
  checkPriv,
  rHeader
) {
  baseFetch(
    'api/athlete_sport/add',
    request,
    successCallback,
    failCallback,
    method,
    checkPriv,
    rHeader
  )
  return ''
}

export function AthleteSportList(
  request,
  successCallback,
  failCallback,
  method,
  checkPriv,
  rHeader
) {
  baseFetch(
    'api/athlete_sport/list',
    request,
    successCallback,
    failCallback,
    method,
    checkPriv,
    rHeader
  )
  return ''
}

export function AthleteSportWithAthleteList(
  request,
  successCallback,
  failCallback,
  method,
  checkPriv,
  rHeader
) {
  baseFetch(
    'api/athlete_sport/listWithAthlete',
    request,
    successCallback,
    failCallback,
    method,
    checkPriv,
    rHeader
  )
  return ''
}

export function AthleteSportDelete(id, request, successCallback, failCallback, method) {
  baseFetch('api/athlete_sport/delete/' + id, request, successCallback, failCallback, method)
  return ''
}

export function AthleteSportChangeStatus(request, successCallback, failCallback, method) {
  baseFetch('api/athlete_sport/changeStatus', request, successCallback, failCallback, method)
  return ''
}
//#endregion

//#region [AthleteContact]
export function AthleteContactAdd(
  request,
  successCallback,
  failCallback,
  method,
  checkPriv,
  rHeader
) {
  baseFetch('api/dcontacts/add', request, successCallback, failCallback, method, checkPriv, rHeader)
  return ''
}

export function AthleteContactList(
  request,
  successCallback,
  failCallback,
  method,
  checkPriv,
  rHeader
) {
  baseFetch(
    'api/dcontacts/list',
    request,
    successCallback,
    failCallback,
    method,
    checkPriv,
    rHeader
  )
  return ''
}

export function AthleteContactDelete(id, request, successCallback, failCallback, method) {
  baseFetch('api/dcontacts/delete/' + id, request, successCallback, failCallback, method)
  return ''
}

export function AthleteContactUpdate(request, successCallback, failCallback, method) {
  baseFetch('api/dcontacts/update', request, successCallback, failCallback, method)
  return ''
}
//#endregion

//#region [VISA & Arrival & Department]
export function AthleteVisaFlightAdd(
  request,
  successCallback,
  failCallback,
  method,
  checkPriv,
  rHeader
) {
  baseFetch(
    'api/sport_visa_flight/add',
    request,
    successCallback,
    failCallback,
    method,
    checkPriv,
    rHeader
  )
  return ''
}

export function AthleteVisaFlightUpdate(
  request,
  successCallback,
  failCallback,
  method,
  checkPriv,
  rHeader
) {
  baseFetch(
    'api/sport_visa_flight/update',
    request,
    successCallback,
    failCallback,
    method,
    checkPriv,
    rHeader
  )
  return ''
}

export function AthleteVisaFlightPasswordUpdate(
  request,
  successCallback,
  failCallback,
  method,
  checkPriv,
  rHeader
) {
  baseFetch(
    'api/sport_visa_flight/changepassfile',
    request,
    successCallback,
    failCallback,
    method,
    checkPriv,
    rHeader
  )
  return ''
}

export function AthleteVisaFlightList(
  request,
  successCallback,
  failCallback,
  method,
  checkPriv,
  rHeader
) {
  baseFetch(
    'api/sport_visa_flight/list',
    request,
    successCallback,
    failCallback,
    method,
    checkPriv,
    rHeader
  )
  return ''
}

export function AthleteVisaFlightGet(id, request, successCallback, failCallback, method) {
  baseFetch('api/sport_visa_flight/get/' + id, request, successCallback, failCallback, method)
  return ''
}

export function AthleteVisaFlightDelete(id, request, successCallback, failCallback, method) {
  baseFetch('api/sport_visa_flight/delete/' + id, request, successCallback, failCallback, method)
  return ''
}
//#endregion

//#region [AthleteTechnical]
export function AthleteTechnicalAdd(
  request,
  successCallback,
  failCallback,
  method,
  checkPriv,
  rHeader
) {
  baseFetch(
    'api/athlete_technical/add',
    request,
    successCallback,
    failCallback,
    method,
    checkPriv,
    rHeader
  )
  return ''
}

export function AthleteTechnicalUpdate(
  request,
  successCallback,
  failCallback,
  method,
  checkPriv,
  rHeader
) {
  baseFetch(
    'api/athlete_technical/update',
    request,
    successCallback,
    failCallback,
    method,
    checkPriv,
    rHeader
  )
  return ''
}

export function AthleteTechnicalList(
  request,
  successCallback,
  failCallback,
  method,
  checkPriv,
  rHeader
) {
  baseFetch(
    'api/athlete_technical/list',
    request,
    successCallback,
    failCallback,
    method,
    checkPriv,
    rHeader
  )
  return ''
}

export function AthleteTechnicalGet(id, request, successCallback, failCallback, method) {
  baseFetch('api/athlete_technical/get/' + id, request, successCallback, failCallback, method)
  return ''
}

export function AthleteTechnicalDelete(id, request, successCallback, failCallback, method) {
  baseFetch('api/athlete_technical/delete/' + id, request, successCallback, failCallback, method)
  return ''
}
//#endregion

//#region [AthleteMedia]
export function AthleteMediaAdd(
  request,
  successCallback,
  failCallback,
  method,
  checkPriv,
  rHeader
) {
  baseFetch(
    'api/athlete_media/add',
    request,
    successCallback,
    failCallback,
    method,
    checkPriv,
    rHeader
  )
  return ''
}

export function AthleteMediaUpdate(
  request,
  successCallback,
  failCallback,
  method,
  checkPriv,
  rHeader
) {
  baseFetch(
    'api/athlete_media/update',
    request,
    successCallback,
    failCallback,
    method,
    checkPriv,
    rHeader
  )
  return ''
}

export function AthleteMediaList(
  request,
  successCallback,
  failCallback,
  method,
  checkPriv,
  rHeader
) {
  baseFetch(
    'api/athlete_media/list',
    request,
    successCallback,
    failCallback,
    method,
    checkPriv,
    rHeader
  )
  return ''
}

export function AthleteMediaGet(id, request, successCallback, failCallback, method) {
  baseFetch('api/athlete_media/get/' + id, request, successCallback, failCallback, method)
  return ''
}

export function AthleteMediaDelete(id, request, successCallback, failCallback, method) {
  baseFetch('api/athlete_media/delete/' + id, request, successCallback, failCallback, method)
  return ''
}
//#endregion

//#region [Dashboard]
export function DashboardAthlete(
  request,
  successCallback,
  failCallback,
  method,
  checkPriv,
  rHeader
) {
  baseFetch(
    'api/dashboard/athlete',
    request,
    successCallback,
    failCallback,
    method,
    checkPriv,
    rHeader
  )
  return ''
}

export function DashboardVolunteer(
  request,
  successCallback,
  failCallback,
  method,
  checkPriv,
  rHeader
) {
  baseFetch(
    'api/dashboard/volunteer',
    request,
    successCallback,
    failCallback,
    method,
    checkPriv,
    rHeader
  )
  return ''
}

export function DashboardFlight(
  request,
  successCallback,
  failCallback,
  method,
  checkPriv,
  rHeader
) {
  baseFetch(
    'api/dashboard/flight',
    request,
    successCallback,
    failCallback,
    method,
    checkPriv,
    rHeader
  )
  return ''
}

export function DashboardSports(
  request,
  successCallback,
  failCallback,
  method,
  checkPriv,
  rHeader
) {
  baseFetch(
    'api/dashboard/sports',
    request,
    successCallback,
    failCallback,
    method,
    checkPriv,
    rHeader
  )
  return ''
}
//#endregion

//#region [Volunteer]
export function VolunteerAdd(request, successCallback, failCallback, method, checkPriv, rHeader) {
  baseFetch('api/volunteer/add', request, successCallback, failCallback, method, checkPriv, rHeader)
  return ''
}

export function VolunteerAddImage(
  request,
  successCallback,
  failCallback,
  method,
  checkPriv,
  rHeader
) {
  baseFetch(
    'api/volunteer/add_image',
    request,
    successCallback,
    failCallback,
    method,
    checkPriv,
    rHeader
  )
  return ''
}

export function VolunteerUpdate(
  request,
  successCallback,
  failCallback,
  method,
  checkPriv,
  rHeader
) {
  baseFetch(
    'api/volunteer/update',
    request,
    successCallback,
    failCallback,
    method,
    checkPriv,
    rHeader
  )
  return ''
}

export function VolunteerList(request, successCallback, failCallback, method, checkPriv, rHeader) {
  baseFetch(
    'api/volunteer/list',
    request,
    successCallback,
    failCallback,
    method,
    checkPriv,
    rHeader
  )
  return ''
}

export function VolunteerStatusChange(
  request,
  successCallback,
  failCallback,
  method,
  checkPriv,
  rHeader
) {
  baseFetch(
    'api/volunteer/changeStatus',
    request,
    successCallback,
    failCallback,
    method,
    checkPriv,
    rHeader
  )
  return ''
}

export function VolunteerGet(id, request, successCallback, failCallback, method) {
  baseFetch('api/volunteer/get/' + id, request, successCallback, failCallback, method)
  return ''
}

export function VolunteerDelete(id, request, successCallback, failCallback, method) {
  baseFetch('api/volunteer/delete/' + id, request, successCallback, failCallback, method)
  return ''
}

export function VolunteerChangeImage(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/volunteer/changeimage', request, successCallback, failCallback, method, rHeader)
  return ''
}
//#endregion

//#region [Event]
export function EventAdd(request, successCallback, failCallback, method, checkPriv, rHeader) {
  baseFetch('api/sh_event/add', request, successCallback, failCallback, method, checkPriv, rHeader)
  return ''
}

export function EventAddPoint(request, successCallback, failCallback, method, checkPriv, rHeader) {
  baseFetch(
    'api/sh_event/add_point',
    request,
    successCallback,
    failCallback,
    method,
    checkPriv,
    rHeader
  )
  return ''
}

export function EventList(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/sh_event/list', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function EventDelete(id, request, successCallback, failCallback, method) {
  baseFetch('api/sh_event/delete/' + id, request, successCallback, failCallback, method)
  return ''
}

export function EventChangeStatus(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/sh_event/changeStatus', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function EventAddYoutube(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/sh_event/add_live', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function EventUpdate(request, successCallback, failCallback, method, checkPriv, rHeader) {
  baseFetch(
    'api/sh_event/update',
    request,
    successCallback,
    failCallback,
    method,
    checkPriv,
    rHeader
  )
  return ''
}

export function EventGet(id, request, successCallback, failCallback, method) {
  baseFetch('api/sh_event/get/' + id, request, successCallback, failCallback, method)
  return ''
}

export function ScheduleSingleAdd(
  request,
  successCallback,
  failCallback,
  method,
  checkPriv,
  rHeader
) {
  baseFetch(
    'api/sh_event_single/add',
    request,
    successCallback,
    failCallback,
    method,
    checkPriv,
    rHeader
  )
  return ''
}

export function MedalAdd(request, successCallback, failCallback, method, checkPriv, rHeader) {
  baseFetch('api/medals/add', request, successCallback, failCallback, method, checkPriv, rHeader)
  return ''
}

export function MedalList(request, successCallback, failCallback, method, checkPriv, rHeader) {
  baseFetch('api/medals/list', request, successCallback, failCallback, method, checkPriv, rHeader)
  return ''
}

export function SingleAddPoint(request, successCallback, failCallback, method, checkPriv, rHeader) {
  baseFetch(
    'api/sh_event_single/add_point',
    request,
    successCallback,
    failCallback,
    method,
    checkPriv,
    rHeader
  )
  return ''
}

export function SingleList(request, successCallback, failCallback, method, checkPriv, rHeader) {
  baseFetch(
    'api/sh_event_single/list',
    request,
    successCallback,
    failCallback,
    method,
    checkPriv,
    rHeader
  )
  return ''
}

export function MedalDelete(id, request, successCallback, failCallback, method) {
  baseFetch('api/medals/delete/' + id, request, successCallback, failCallback, method)
  return ''
}

export function SingleDelete(id, request, successCallback, failCallback, method) {
  baseFetch('api/sh_event_single/delete/' + id, request, successCallback, failCallback, method)
  return ''
}

export function EventReportAdd(
  request,
  successCallback,
  failCallback,
  method,
  checkPriv,
  rHeader
) {
  baseFetch(
    'api/sh_event_report/add',
    request,
    successCallback,
    failCallback,
    method,
    checkPriv,
    rHeader
  )
  return ''
}

export function EventReportList(
  request,
  successCallback,
  failCallback,
  method,
  checkPriv,
  rHeader
) {
  baseFetch(
    'api/sh_event_report/list',
    request,
    successCallback,
    failCallback,
    method,
    checkPriv,
    rHeader
  )
  return ''
}

export function EventReportDelete(id, request, successCallback, failCallback, method) {
  baseFetch('api/sh_event_report/delete/' + id, request, successCallback, failCallback, method)
  return ''
}
//#endregion

//#region [AthleteSport]

export function AthletesList(request, successCallback, failCallback, method, checkPriv, rHeader) {
  baseFetch(
    'api/athlete_sport/athletes',
    request,
    successCallback,
    failCallback,
    method,
    checkPriv,
    rHeader
  )
  return ''
}
//#endregion
