import React, {FC, useEffect, useRef, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import DataTable from '../../modules/CustomBootsrapTable/CustomBootsrapTable'
import {isNullOrEmpty} from '../../../services/Utils'
import {AthleteSportWithAthleteListReq, magicnum} from '../../../services/Request'
import {AthleteSportWithAthleteList, CDN_URL} from '../../../services/main'
import {toast} from 'react-toastify'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import {Image} from 'react-bootstrap'
import useCookie from '../../../services/useCookie'
const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]

function usePrevious(value: any) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}

const Sports: FC = () => {
  const queryString = window.location.search
  const params = new URLSearchParams(queryString)
  let nocQ = params.get('noc')
  let categoryQ = params.get('sport')
  let pidQ = params.get('pid')
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [item, setItem] = useState(undefined)
  const [PID, setPID] = useState<any | null>(1)
  const [PTotal, setPTotal] = useState(1)
  const [sizePerPage, setSizePerPage] = useState(20)
  const [showModal, updateShowModal] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [noc, setNoc] = useState<any | null>('')
  const [accr_category, setAccr_category] = useState<any | null>('')
  const [category, setCategory] = useState<any | null>('')
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [food, setFood] = useState('')
  const [acc_id, setAcc_id] = useState('')
  const [NocID] = useCookie('NocID', null)
  const [EmployeeTypeID] = useCookie('EmployeeTypeID', null)
  const prevNoc = usePrevious(noc)
  const prevPID = usePrevious(PID)
  const prevAccrCategory = usePrevious(accr_category)
  useEffect(() => {
    if (!isNullOrEmpty(categoryQ)) {
      setCategory(categoryQ)
    }
    if (!isNullOrEmpty(nocQ)) {
      setNoc(nocQ)
    }
    if (!isNullOrEmpty(pidQ)) {
      setPID(pidQ)
    }
  }, [categoryQ, nocQ, pidQ])

  useEffect(() => {
    list()
  }, [PID])

  useEffect(() => {
    if (!isNullOrEmpty(noc)) {
      list()
    }
    if (!isNullOrEmpty(category)) {
      list()
    }
  }, [noc, category])

  const search = () => {
    if (PID > 1) {
      setPID(1)
    } else {
      list()
    }
  }

  const list = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = AthleteSportWithAthleteListReq
    req.pid = PID
    req.pSize = sizePerPage
    if (EmployeeTypeID == 'noc') {
      req.filter.noc = NocID
    } else {
      req.filter.noc = noc
    }
    req.filter.category = category
    req.filter.firstname = firstname
    req.filter.lastname = lastname
    AthleteSportWithAthleteList(req, onSuccess, onFailed, 'POST')
  }

  const onSuccess = (response: any) => {
    setLoading(false)
    setData(response.data)
    setPTotal(response.pagination.ptotal)
  }

  const onFailed = (error: string) => {
    setLoading(false)
    return toast.error(error)
  }

  const onChangePage = (pid: any) => {
    if (pid >= 1) {
      setPID(pid)
      setLoading(true)
    }
  }

  const defaultRowFormat = (cell: any, row: any) => {
    return (
      <div className='flex justify-center items-center'>
        <span className='text-center'>{cell}</span>
      </div>
    )
  }

  const imgRowFormat = (cell: string, row: any) => {
    return (
      <Link to={`/athlete/${row.id}`}>
        <div className='text-center'>
          <img
            src={CDN_URL + cell}
            height={60}
            width={60}
            style={{borderRadius: '50%', objectFit: 'cover'}}
          />
        </div>
      </Link>
    )
  }

  const nameRowFormat = (cell: string, row: any) => {
    return (
      <Link to={`/athlete/${row?.id}`}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <span>{row.lastname}</span>
          <span className='text-muted fw-semibold text-muted d-block'>{cell}</span>
        </div>
      </Link>
    )
  }

  const categoryRowFormat = (cell: string, row: any) => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '5px',
          flexDirection: 'column',
        }}
      >
        <img src={`/sports/webp/hover/${cell}.webp`} width={50} height={50} />
        <span className='text-muted fw-semibold text-muted d-block'>
          {t(`common:magicnum.SportSubCategories.${row.sub_category}`)}
        </span>
      </div>
    )
  }

  const nocRowFormat = (cell: string, row: any) => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          rowGap: 5,
        }}
      >
        <Image src={toAbsoluteUrl(`/noc-flag/${cell}.svg`)} height={16} />
        <span>{t(`common:NOC.${cell}`)}</span>
      </div>
    )
  }

  const statusRowFormat = (cell: string, row: any) => {
    var gg = magicnum.StatusSport.filter((ite) => ite.id == row.status)
    return row?.status == 0 ? (
      <div className='flex justify-center items-center'>
        <span className={`badge ${gg[0]?.class} fw-semibold`}>
          {t(`common:magicnum.StatusSport.${row?.status}`)}
        </span>
      </div>
    ) : (
      <div className='flex justify-center items-center'>
        <span className={`badge ${gg[0]?.class} fw-semibold`}>
          {t(`common:magicnum.StatusSport.${row?.status}`)}
        </span>
      </div>
    )
  }

  var table = {
    sizePerPage: sizePerPage,
    headerKeyColumn: {
      title: 'id',
      dataField: 'id',
      dataSort: true,
      dataFormat: defaultRowFormat,
      hidden: true,
      filter: {
        type: 'TextFilter',
        placeholder: '...',
      },
    },
    headerOtherColumn: [
      {
        title: t('common:NEWS.image_link'),
        dataField: 'photo',
        dataSort: false,
        dataFormat: imgRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:athlete.name'),
        dataField: 'firstname',
        dataSort: false,
        dataFormat: nameRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:athlete.gender'),
        dataField: 'gender',
        dataSort: false,
        dataFormat: defaultRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },

      {
        title: t('common:athlete.accr_category'),
        dataField: 'category',
        dataSort: false,
        dataFormat: categoryRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:athlete.noc'),
        dataField: 'noc',
        dataSort: false,
        dataFormat: nocRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:athlete.acc_id'),
        dataField: 'acc_id',
        dataSort: false,
        dataFormat: defaultRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:athlete.status'),
        dataField: 'gender',
        dataSort: false,
        dataFormat: statusRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
    ],
  }

  return (
    <>
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>{t('common:admin_sports.sport')}</PageTitle>
      <div className={`card mb-5`}>
        <div className='card-header border-1' style={{height: '60px'}}>
          <div
            className='filter flex items-center justify-center gap-0 cursor-pointer'
            onClick={() => setShowFilter(!showFilter)}
          >
            <KTIcon iconName='filter' className='fs-3' />
            <span className='text'>{t('common:filter')}</span>
          </div>
          <div className='card-toolbar'>
            <a href='#' className='btn btn-sm btn-light-primary' onClick={() => search()}>
              {t('common:search')}
            </a>
          </div>
        </div>
        {showFilter ? (
          <div className='card-body'>
            <div className='row'>
              <div className='col'>
                <select
                  name='noc'
                  data-control='select2'
                  data-hide-search='true'
                  value={noc}
                  className='form-select form-select-white'
                  onChange={(e) => {
                    setNoc(e.target.value)
                  }}
                >
                  <option value=''>{t('common:NOC.selectnoc')}</option>
                  {magicnum.NOC.map((item2, index) => {
                    return (EmployeeTypeID == 'noc' && item2?.id == NocID) ||
                      EmployeeTypeID == 'super' ? (
                      <option value={item2.id} key={index}>
                        {t(`common:NOC.${item2.id}`)}
                      </option>
                    ) : null
                  })}
                </select>
              </div>
              <div className='col'>
                <select
                  name='accr_category'
                  data-control='select2'
                  data-hide-search='true'
                  value={category}
                  className='form-select form-select-white'
                  onChange={(e) => {
                    setCategory(e.target.value)
                  }}
                >
                  <option value=''>{t('common:athlete.selectAccreditationCategory')}</option>
                  {magicnum.SportCategories.map((sitem, sindex) => {
                    return (
                      <option value={sitem?.id} key={sindex}>
                        {t(`common:magicnum.SportCategories.${sitem?.id}`)}
                      </option>
                    )
                  })}
                </select>
              </div>

              <div className='col'>
                <input
                  className='form-control form-control-solid'
                  placeholder='Firstname'
                  onChange={(e) => {
                    setFirstname(e.target.value)
                  }}
                  value={firstname}
                  maxLength={45}
                ></input>
              </div>
              <div className='col'>
                <input
                  className='form-control form-control-solid'
                  placeholder='Lastname'
                  onChange={(e) => {
                    setLastname(e.target.value)
                  }}
                  value={lastname}
                  maxLength={45}
                ></input>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <div className={`card`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>{t('common:admin_sports.sports')}</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>
              {t('common:admin_sports.total', {count: PTotal})}
            </span>
          </h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <DataTable
            data={data}
            table={table}
            PTotal={PTotal}
            pageChange={onChangePage}
            PID={PID}
            loading={loading}
          />
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export {Sports}
